import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    GET_USER_PAYMENTS,
    ADD_USER_PACKAGE_PAYMENT,
    GET_TOP_SALES_LIST
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'PAYMENT'
}

export const PaymentEntity = new NetworkEntity(options)

PaymentEntity.getUserPayments = function (commit, id) {
    commit(GET_USER_PAYMENTS.ACTION)
    return axios
        .get(`${this.endpoint}/payments/users/${id}`)
        .then(({ data: {data} }) => {
            commit(GET_USER_PAYMENTS.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_USER_PAYMENTS.FAILURE, err)
        })
}

PaymentEntity.addUserPackagePayment = function (commit, id, data) {
    commit(ADD_USER_PACKAGE_PAYMENT.ACTION)
    return axios
        .post(`${this.endpoint}/payments/${id}/add-package-payment`, data)
        .then(({data}) => {
            commit(ADD_USER_PACKAGE_PAYMENT.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_USER_PAYMENTS.FAILURE, err)
        })
}

PaymentEntity.getTopSalesList = function (commit, params) {
    commit(GET_TOP_SALES_LIST.ACTION)
    let q = PaymentEntity.queryStringify(params)
    return axios
        .get(`${this.endpoint}/payments/top-sales-list${q}`)
        .then(({ data: {data} }) => {
            commit(GET_TOP_SALES_LIST.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_TOP_SALES_LIST.FAILURE, err)
        })
}