import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    GET_COMPANY_SETTING,
    GET_COMPANY_BODY_INDEX_FIELDS_SETTING
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'COMPANY'
}

export const CompanyEntity = new NetworkEntity(options)

CompanyEntity.getCompanySettings = function (commit) {
    commit(GET_COMPANY_SETTING.ACTION)
    return axios
        .get(`${this.endpoint}/company/settings`)
        .then(({ data: {data} }) => {
            commit(GET_COMPANY_SETTING.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_COMPANY_SETTING.FAILURE, err)
        })
}

CompanyEntity.getCompanyBodyIndexFieldSettings = function (commit) {
    commit(GET_COMPANY_BODY_INDEX_FIELDS_SETTING.ACTION)
    return axios
        .get(`${this.endpoint}/company/settings/body-index-fields`)
        .then(({data: {data} }) => {
            commit(GET_COMPANY_BODY_INDEX_FIELDS_SETTING.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_COMPANY_BODY_INDEX_FIELDS_SETTING.FAILURE, err)
        })
}