<template>
  <div v-if="status==='master'">
    <img class="avatar avatar-xss avatar-4x3 me-2" src="@/assets/svg/payment/mastercard.svg" alt="Master Card">
<!--    <span class="text-dark">•••• 2278</span>-->
  </div>
  <div v-else-if="status === 'visa'">
    <img class="avatar avatar-xss avatar-4x3 me-2" src="@/assets/svg/payment/visa.svg" alt="Visa Card">
  </div>
  <div v-else-if="status==='paypal'">
    <img class="avatar avatar-xss avatar-4x3 me-2" src="@/assets/svg/payment/paypal-icon.svg" alt="Pay-bal">
<!--      <span class="text-dark">••••@gmail.com</span>-->
  </div>
  <div v-else-if="status==='aliPay'">
    <img class="avatar avatar-xss avatar-4x3 me-2" src="@/assets/svg/payment/alipay.svg" alt="Alipay">
<!--      <span class="text-dark">宋••</span>-->
  </div>
  <div v-else-if="status==='weChat'">
    <img class="avatar avatar-xss avatar-4x3 me-2" src="@/assets/svg/payment/wechat_icon.svg" alt="WeChat">
<!--      <span class="text-dark">宋••</span>-->
  </div>
  <div v-else-if="status==='cash'">
    <img class="avatar avatar-xss avatar-4x3 me-2" src="@/assets/svg/payment/cash_icon.svg" alt="Cash">
  </div>
  <div v-else-if="status==='eTransfer'">
    <img class="avatar avatar-sm avatar-4x3 me-2" src="@/assets/svg/payment/e-transfer.jpg" alt="e-Transfer">
  </div>
</template>
<script>
export default {
  name: 'payment-method',
  props: {
    status: {
      type: String,
      required: true,
      default: 'pending'
    },
  }
}
</script>