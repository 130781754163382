import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    fetchingTopSales: false,
    topSalesData: [],
    userPayments: [],
    paymentMethodOptions: [
        { value: 'weChat', text: 'weChat' },
        { value: 'aliPay', text: 'aliPay' },
        { value: 'master', text: 'MasterCard' },
        { value: 'visa', text: 'VISA' },
        { value: 'paypal', text: 'PayPal' },
        { value: 'eTransfer', text: 'e-Transfer'},
        { value: 'cash', text: 'Cash' },
    ]
})

export const payment = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}