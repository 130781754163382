export const localStorage = window.localStorage
export const sessionStorage = window.sessionStorage

export const setItem = (storage, key, value) => {
    try {
        storage.setItem(key, value)
    } catch (e) {
        console.log(e)
    }
}

export const getItem = (storage, key, remove = false) => {
    try {
        let value = storage.getItem(key)
        if (remove) {
            storage.removeItem(key)
        }
        return value
    } catch (e) {
        return undefined
    }
}

export const removeItem = (storage, key) => {
    try {
        storage.removeItem(key)
    } catch (e) {
        console.error(e)
    }
}