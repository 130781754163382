import * as TYPES from './types'

const DURATION = 5000
let timeout

export const setAlert = ({ commit, dispatch }, value) => {
    clearTimeout(timeout)
    commit(TYPES.SET_ALERT, value)

    if (value.autoDismiss) {
        timeout = setTimeout(() => dispatch('dismissAlert'), DURATION)
    }
}

export const dismissAlert = ({ commit }) => {
    commit(TYPES.DISMISS_ALERT)
}

export const setScreenSize = ({ commit }, size) => {
    commit(TYPES.SET_SCREEN_SIZE, size)
}

export const setNavbarExpanded = ({ commit }, expanded) => {
    commit(TYPES.SET_NAVBAR_EXPANDED, expanded)
}

export const setNavbarExpandedMobile = ({ commit }, data) => {
    commit(TYPES.SET_NAVBAR_EXPANDED_MOBILE, data)
}

export default {
    setAlert,
    dismissAlert,
    setScreenSize,
    setNavbarExpanded,
    setNavbarExpandedMobile
}
