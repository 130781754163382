<template>
  <div class="row justify-content-lg-center">
    <div class="col-lg-8">
      <progress-bar
          :current-step="currentStep"
          :steps="steps"
          @switchStep="switchStep"
      />
      <div class="card card-lg">
        <add-user-profile
            v-if="currentStep === 1"
            user-type="trainer"
            @input="updateUserProfileValue"
        />
        <billing-address
            v-if="currentStep === 2"
            @input="updateBillingValue"
        />
        <confirmation-form
            v-if="currentStep === 3"
            :form="{...profile, ...billing}"
        />
        <!-- Footer -->
        <div class="card-footer d-flex align-items-center">
          <b-button
              v-if="currentStep !== 1"
              variant="light"
              class="btn btn-ghost-secondary"
              @click="switchStep(currentStep - 1)"
          ><i class="bi-chevron-left"></i> Previous Step</b-button>
          <div class="ms-auto">
            <b-button
                v-if="currentStep !== 3"
                variant="primary"
                @click="switchStep(currentStep + 1)"
            >Next <i class="bi-chevron-right"></i></b-button>
            <b-button
                v-if="currentStep === 3"
                variant="primary"
                @click="handleAddTrainer"
            >
              Add Trainer
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        id="add-trainer-successful-modal"
    >
      <template #modal-header>
        <h3>Added Trainer <span class="badge bg-success">Success</span></h3>
      </template>
      <div class="d-block text-center">
        <h3>Congratulation!</h3>
        <img class="card-img mb-3" src="@/assets/svg/illustrations/oc-hi-five.svg" style="max-width: 15rem;" alt="added trainer"/>
        <p>Added <span class="h1 text-info">{{ successModalDescription }} </span>Successfully!</p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              class="float-end"
              variant="primary"
              @click="handleCloseModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <duplicate-user-modal :user-email="profile.email" @back="handleBackToStepOne"/>
    <something-wrong-alert :show-alert="showAlert" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { DuplicateUserModal, ProgressBar, SomethingWrongAlert } from '@/layout'

import {
  AddUserProfile,
  BillingAddress,
  ConfirmationForm
} from './addUserSteps'
export default {
  name: 'add-trainer',
  components: {
    ConfirmationForm,
    BillingAddress,
    ProgressBar,
    AddUserProfile,
    DuplicateUserModal,
    SomethingWrongAlert
  },
  data () {
    return {
      steps: [
        { index: 1, title: 'Profile'},
        { index: 2, title: 'Billing Address'},
        { index: 3, title: 'Confirmation'}
      ],
      currentStep: 1,
      userType: 'trainer',
      canSubmit: false,
      profile: {
        first_name: null,
        last_name: null,
        email: null,
        dateOfBirth: null,
        startOfDate: null,
        endOfDate: null,
        jobTitle: 'trainer',
        department: 'trainer',
        role: null,
        userType: 'employee'
      },
      billing: {
        phone: null,
        address: null,
        zipCode: null
      },
      showAlert: false
    }
  },
  computed: {
    ...mapState('company', ['companySettings']),
    ...mapState('trainer', ['fetching', 'addedTrainer']),
    disableNext () {
      if (this.currentStep === 1) {
        return Object.values(this.profile).some(value => value === null)
      } else if (this.currentStep === 2) {
        return Object.values(this.billing).some(value => value === null)
      }
      return false
    },
    successModalDescription () {
      return this.profile.first_name + ' ' + this.profile.last_name
    }
  },
  methods: {
    ...mapActions('company', ['getCompanySettings']),
    ...mapActions('trainer', ['addTrainer']),
    switchStep (index) {
      this.currentStep = index
    },
    updateUserProfileValue(value, key) {
      this.profile[key] = value
    },
    updateBillingValue(value, key) {
      this.billing[key] = value
    },
    async handleAddTrainer () {
      let newTrainer = { ...this.profile, ...this.billing}
      await this.addTrainer(newTrainer)
      if ('error' in this.addedTrainer) {
        switch (this.addedTrainer.error) {
          case 'DUPLICATE_USER':
            this.$bvModal.show('duplicate-user-modal')
            break
          default:
            this.showAlert = true
        }
      } else {
        this.$bvModal.show('add-trainer-successful-modal')
      }
    },
    handleBackToStepOne () {
      this.currentStep = 1
      this.$bvModal.hide('duplicate-user-modal')
    },
    handleCloseModal () {
      this.$router.push({name: 'user-trainers'})
    }
  },
  async mounted() {
    if (this.companySettings === null) {
      await this.getCompanySettings()
    }
  }
}
</script>