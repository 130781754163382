<template>
  <div class="card mb-3">
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title">
        Today's Schedule
      </h4>
    </div>
    <div class="card-body">
      <ul class="list-group list-group-flush list-group-no-gutters">
        <div v-if="!todayCourses.length">
          <h3>No reservation for today.</h3>
        </div>
        <course-list-item
            v-else
            v-for="(courseItem, index) in todayCourses"
            :key="`course-item-`+index"
            :title="courseItem.courseName"
            :client-name="courseItem.clientName"
            :trainer-name="courseItem.trainerName"
            :duration="courseItem.duration"
            :start-time="courseItem.startTime"
            :date="courseItem.date"
            :tags="courseItem.tags"
        />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { CourseListItem } from '@/components/users/userProfile/profileContent'
import moment from 'moment'
export default {
  name: 'today-event',
  components: {
    CourseListItem
  },
  props: {
    userId: {
      type: String||Number,
      required: true,
      default: 0
    },
    userType: {
      type: String,
      required: true,
      default: 'client'
    }
  },
  computed: {
    ...mapState('course', ['fetching', 'userCoursesQuickSummary']),
    todayCourses () {
      let now = moment().format('l')
      return this.userCoursesQuickSummary.filter((course) => moment(course.date).format('l') === now)
    }
  },
  methods: {
    ...mapActions('course', ['listUserCoursesQuickSummary']),
  },
  async mounted () {
    await this.listUserCoursesQuickSummary({
      id: this.userId,
      params: {
        weekly: true,
        userType: this.userType
      }
    })
  }
}
</script>