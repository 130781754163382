<template>
  <div class="row justify-content-lg-center">
    <div class="col-lg-9">
      <progress-bar
          :current-step="currentStep"
          :steps="steps"
          @switchStep="switchStep"
      />
      <div class="card card-lg">
        <add-client-profile
            v-if="currentStep === 1"
            @input="updateUserProfileValue"
        />
        <billing-address
            v-if="currentStep === 2"
            @input="updateBillingValue"
        />
        <add-membership-package
            v-if="currentStep === 3"
            @input="updateMembershipPackage"
        />
<!--        <body-composition-analysis-->
<!--            v-if="currentStep === 4"-->
<!--            @input="updateBodyIndexFieldsData"-->
<!--        />-->

        <confirmation-form
            v-if="currentStep === 4"
            :form="{...profile, ...billing, ...clientPackage}"
        />
        <!-- Footer -->
        <div class="card-footer d-flex align-items-center">
          <b-button
              v-if="currentStep !== 1"
              variant="light"
              class="btn btn-ghost-secondary"
              @click="switchStep(currentStep-1)"
          ><i class="bi-chevron-left"></i> Previous Step</b-button>
          <div class="ms-auto">
            <b-button
                v-if="currentStep !== 4"
                variant="primary"
                :disabled="disableNext"
                @click="switchStep(currentStep+1)"
            >Next <i class="bi-chevron-right"></i></b-button>
            <b-button
                v-if="currentStep === 4"
                variant="primary"
                :disabled="fetching"
                @click="handleAddClient"
            >
              <b-spinner
                  v-if="fetching"
                  variant="primary"
                  label="Spinning"
              /> Add Client
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        id="add-client-successful-modal"
    >
      <template #modal-header>
        <h3>Added Client <span class="badge bg-success">Success</span></h3>
      </template>

      <div class="d-block text-center">
        <h3>Congratulation!</h3>
        <img class="card-img mb-3" src="@/assets/svg/illustrations/oc-hi-five.svg" style="max-width: 15rem;" alt="added client"/>
        <p>Added <span class="h1 text-info">{{ successModalDescription }} </span>Successfully!</p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              class="float-end"
              variant="primary"
              @click="handleCloseModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <duplicate-user-modal :user-email="profile.email" @back="handleBackToStepOne"/>
    <something-wrong-alert :show-alert="showAlert" />
  </div>
</template>
<script>
import { DuplicateUserModal, ProgressBar, SomethingWrongAlert } from '@/layout'
import {AddClientProfile, AddMembershipPackage, BillingAddress, ConfirmationForm} from './addClientSteps'
import {mapActions, mapState} from 'vuex'

export default {
  components: {
    // BodyCompositionAnalysis,
    AddClientProfile,
    BillingAddress,
    AddMembershipPackage,
    ProgressBar,
    DuplicateUserModal,
    ConfirmationForm,
    SomethingWrongAlert
  },
  data () {
   return {
     steps: [
       { index: 1, title: 'Profile'},
       { index: 2, title: 'Billing Address' },
       { index: 3, title: 'Add Membership Package'},
       // { index: 4, title: 'Body Mass Index'},
       { index: 4, title: 'Confirmation'}
     ],
     currentStep: 1,
     userType: 'client',
     canSubmit: false,
     profile: {
       first_name: null,
       last_name: null,
       email: null,
       dateOfBirth: null,
       startOfDate: null,
     },
     billing: {
       phone: null,
       address: null,
       zipCode: null
     },
     clientPackage: {
       clientType: null,
       reports_to: 0,
       packageId: null,
       extra: {
         membership_duration: 0,
         membership_duration_symbol: 'day',
         courses: 0
       },
       salesId: null,
       paymentMethod: null
     },
     bodyIndexFieldsData: null,
     showAlert: false
   }
  },
  computed: {
    ...mapState('company', ['companySettings', 'companyBodyIndexFieldSettings']),
    ...mapState('client', ['fetching', 'addedClient']),
    disableNext () {
      if (this.currentStep === 1) {
        return Object.values(this.profile).some(value => value === null)
      } else if (this.currentStep === 2) {
        return Object.values(this.billing).some(value => value === null)
      } else if (this.currentStep === 3) {
        return Object.values(this.clientPackage).some(value => value === null)
      }
      // else if (this.currentStep === 4) {
      //   return Object.values(this.bodyIndexFieldsData.some(value => value === null))
      // }
      return false
    },
    successModalDescription () {
      return this.profile.first_name + ' ' + this.profile.last_name
    }
  },
  methods: {
    ...mapActions('company', ['getCompanySettings', 'getCompanyBodyIndexFieldSettings']),
    ...mapActions('client', ['addClient']),
    switchStep (index) {
      this.currentStep = index
    },
    updateUserProfileValue(value, key) {
      this.profile[key] = value
    },
    updateBillingValue(value, key) {
      this.billing[key] = value
    },
    updateMembershipPackage (value, key) {
      if (['membership_duration', 'membership_duration_symbol', 'courses'].includes(key)) {
        this.clientPackage.extra[key] = value
      } else {
        this.clientPackage[key] = value
      }
    },
    updateBodyIndexFieldsData (value) {
      this.bodyIndexFieldsData = value
    },
    async handleAddClient () {
      let newClient = {...this.profile, ...this.billing, ...this.clientPackage, userType: this.userType}
      await this.addClient(newClient)
      if ('error' in this.addedClient) {
        switch (this.addedClient.error) {
          case 'DUPLICATE_USER':
            this.$bvModal.show('duplicate-user-modal')
            break
          default:
            this.showAlert = true
        }
      } else {
        this.$bvModal.show('add-client-successful-modal')
      }
    },
    handleBackToStepOne () {
      this.currentStep = 1
      this.$bvModal.hide('duplicate-user-modal')
    },
    handleCloseModal () {
      this.$router.push({name: 'client-overview'})
    }
  },
  async mounted() {
    if (this.companySettings === null) {
      await this.getCompanySettings()
    }
  }
}
</script>