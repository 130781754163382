import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    privateTrainingSessionId: null,
    courseList: [],
    userCoursesQuickSummary: [],
    clientCourses: [],
    trainerCourses: [],
    courseTags: [],
    courseTypes: [],
    courseGoals: [],
    bodyParts: [],
    userCoursesSummary: [],
    fetchingUserCoursesSummary: false,
    addedCourses: null,
    fetchingTrainerCoursesSummary: false,
    trainerCoursesSummary: []
})

export const course = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}