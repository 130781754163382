import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    LIST_MEMBERSHIP_PACKAGES,
    LIST_COURSES_PACKAGES,
    CREATE_COURSES_PACKAGE,
    CREATE_MEMBERSHIP_PACKAGE,
    UPDATE_COURSES_PACKAGE,
    UPDATE_MEMBERSHIP_PACKAGE,
    DELETE_COURSES_PACKAGE,
    DELETE_MEMBERSHIP_PACKAGE
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'PRODUCT'
}

export const ProductEntity = new NetworkEntity(options)

ProductEntity.getMembershipPackages = function (commit) {
    commit(LIST_MEMBERSHIP_PACKAGES.ACTION)
    return axios
        .get(`${this.endpoint}/products/membership-packages`)
        .then(({ data: {data} }) => {
            commit(LIST_MEMBERSHIP_PACKAGES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_MEMBERSHIP_PACKAGES.FAILURE, err)
        })
}

ProductEntity.getCoursePackages = function (commit) {
    commit(LIST_COURSES_PACKAGES.ACTION)
    return axios
        .get(`${this.endpoint}/products/courses-packages`)
        .then(({ data: {data} }) => {
            commit(LIST_COURSES_PACKAGES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_COURSES_PACKAGES.FAILURE, err)
        })
}

ProductEntity.createMembershipPackage = function (commit, data) {
    commit(CREATE_MEMBERSHIP_PACKAGE.ACTION)
    return axios
        .post(`${this.endpoint}/products/add-membership-package`, data)
        .then(({data}) => {
            commit(CREATE_MEMBERSHIP_PACKAGE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(CREATE_MEMBERSHIP_PACKAGE.FAILURE, err)
        })
}

ProductEntity.createCoursesPackage = function (commit, data) {
    commit(CREATE_COURSES_PACKAGE.ACTION)
    return axios
        .post(`${this.endpoint}/products/add-courses-package`, data)
        .then(({data}) => {
            commit(CREATE_COURSES_PACKAGE, data)
            return data
        })
        .catch(err => {
            commit(CREATE_COURSES_PACKAGE.FAILURE, err)
        })
}

ProductEntity.updateCoursePackage = function (commit, data) {
    commit(UPDATE_COURSES_PACKAGE.ACTION)
    return axios
        .put(`${this.endpoint}/products/update-courses-package`, data)
        .then(({data}) => {
            commit(UPDATE_COURSES_PACKAGE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(UPDATE_COURSES_PACKAGE.FAILURE, err)
        })
}

ProductEntity.updateMembershipPackage = function (commit, data) {
    commit(UPDATE_MEMBERSHIP_PACKAGE.ACTION)
    return axios
        .put(`${this.endpoint}/products/update-membership-package`, data)
        .then(({data}) => {
            commit(UPDATE_MEMBERSHIP_PACKAGE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(UPDATE_MEMBERSHIP_PACKAGE.FAILURE, err)
        })
}

ProductEntity.deleteCoursePackage = function (commit, id) {
    commit(DELETE_COURSES_PACKAGE.ACTION)
    return axios
        .delete(`${this.endpoint}/products/delete-courses-package/${id}`)
        .then(({data}) => {
            commit(DELETE_COURSES_PACKAGE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(DELETE_COURSES_PACKAGE.FAILURE, err)
        })
}

ProductEntity.deleteMembershipPackage = function (commit, id) {
    commit(DELETE_MEMBERSHIP_PACKAGE.ACTION)
    return axios
        .delete(`${this.endpoint}/products/delete-membership-package/${id}`)
        .then(({data}) => {
            commit(DELETE_MEMBERSHIP_PACKAGE.SUCCESS, data)
        })
        .catch(err => {
            commit(DELETE_MEMBERSHIP_PACKAGE.FAILURE, err)
        })
}