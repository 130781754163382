import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = {
    fetching: false,
    bearerToken: ''
}
export const auth = {
    namespaced: true,
    state: initialState,
    actions,
    getters,
    mutations
}

export default auth