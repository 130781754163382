<template>
  <div class="card">
    <div class="card-header card-header-content-md-between">
      <div class="mb-2 mb-md-0">
        <b-form-group>
          <b-input-group
              class="input-group-merge input-group-flush"
          >
            <div class="input-group-prepend input-group-text">
              <i class="bi-search"></i>
            </div>
            <b-form-input
                v-model="filter"
                class=""
                type="search"
                placeholder="Search Orders"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <div class="d-grid d-sm-flex gap-2">
        <b-button
            v-if="['admin', 'trainer'].includes(usingAppAs[0]) && userType === 'client'"
            variant="primary"
            @click="handleShowAddPackageModal"
        ><i class="bi bi-plus nav-icon"></i>Add Package</b-button>
        <b-dropdown
            v-if="visible"
            variant="out-secondary"
            toggle-class="btn-white"
            no-caret
            right
            menu-class="dropdown-card dropdown-menu"
        >
          <template #button-content>
            <i class="bi-table me-1"></i>Product Type
          </template>
          <div class="card card-sm">
            <div class="card-body">
              <div class="d-grid gap-3">
                <b-form-group>
                  <template #label>
                    <b-form-checkbox
                        v-model="allSelected"
                        :indeterminate="indeterminate"
                        aria-describedby="productTypes"
                        aria-controls="productTypes"
                        @change="toggleAll"
                    >
                      {{ allSelected ? 'Un-select All' : 'Select All' }}
                    </b-form-checkbox>
                  </template>
                  <template v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                        id="productTypes"
                        v-model="selected"
                        :options="productTypes"
                        :aria-describedby="ariaDescribedby"
                        name="productTypes"
                        class="ml-4"
                        aria-label="productTypes"
                        stacked
                    ></b-form-checkbox-group>
                  </template>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <b-table
            v-if="!fetching"
            head-variant="light"
            borderless
            class="table-align-middle table-nowrap card-table"
            :fields="fields"
            :items="userPayments"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
          <template #cell(id)="data">
            <span>#</span>{{ data.value }}
          </template>
          <template #cell(payment_status)="data">
            <payment-status :status="data.value" />
          </template>
          <template #cell(start_at)="data">
            <div>{{ getCreatedAt(data.value) }}</div>
          </template>
          <template #cell(product_type)="data">
            <div>{{ $t(`payment.productType.${data.value}`) }}</div>
          </template>
          <template #cell(fulfillment_status)="data">
            <fulfillment-status :status="data.value" />
          </template>
          <template #cell(payment_method)="data">
            <payment-method :status="data.value" />
          </template>
          <template #cell(amount)="data">
            <span>$</span>{{ data.value }}
          </template>
          <template #cell(actions)="row">
            <b-button-group>
              <b-button
                  variant="out-secondary"
                  class="btn-white"
                  size="sm"
                  @click="handleButtonClick(row.item, 'view')"
              ><i class="bi-eye me-1"></i>View</b-button>
              <b-dropdown
                  v-if="row.item.actions.includes('edit') || row.item.actions.includes('delete')"
                  variant="out-secondary"
                  toggle-class="btn-white btn-icon dropdown-toggle-empty"
                  size="sm"
                  right
              >
                <b-dropdown-item v-if="row.item.actions.includes('edit')" @click="handleButtonClick(row.item, 'edit')"><i class="bi-pencil-fill dropdown-item-icon"></i>Edit</b-dropdown-item>
                <b-dropdown-item class="btn-outline-danger" @click="handleButtonClick(row.item, 'delete')"><i class="bi-trash dropdown-item-icon"></i>Delete</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>
        </b-table>
        <b-spinner
            v-else
            variant="primary"
            label="Spinning"
        />
      </div>
    </div>
    <view-payment-order-modal
        v-if="Object.keys(selectedRow).length !== 0"
        :order-type="orderType" :order="selectedRow"
    />
    <add-payment-order-modal
        :key="`add-payment-order-`+showAddPaymentOrderModal"
        :client-id="clientId"
        :client-name="clientName"
        @refresh="handleRefreshPayments"
        @close="reRenderModal"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import {
  PaymentStatus,
  FulfillmentStatus,
  PaymentMethod
} from '@/layout'

import {
  ViewPaymentOrderModal,
  AddPaymentOrderModal
} from './components/'

export default {
  name: 'profile-payment-and-orders',
  components: {
    PaymentStatus,
    FulfillmentStatus,
    PaymentMethod,
    ViewPaymentOrderModal,
    AddPaymentOrderModal
  },
  props: {
    userId: {
      type: [String, Number],
      require: true,
      default: 0
    },
    userType: {
      type: String,
      require: false,
      default: 'client'
    },
    clientName: {
      type: String,
      require: false,
      default: 'client name'
    },
    clientId: {
      type: [String, Number],
      require: false,
      default: '0'
    }
  },
  data () {
    return {
      showAddPaymentOrderModal: 0,
      filter: null,
      visible: false,
      productTypes: ['Course', 'Snack', 'drink', 'cloth'],
      selected: [],
      allSelected: false,
      indeterminate: false,
      selectedRow: {},
      currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'id',
          label: 'ORDER',
          sortable: false
        },
        {
          key: 'product_type',
          label: 'PRODUCT TYPE',
          sortable: true
        },
        {
          key: 'start_at',
          label: 'START DATE',
          sortable: true
        },
        {
          key: 'payment_status',
          label: 'PACKAGE STATUS',
          sortable: true
        },
        {
          key: 'fulfillment_status',
          sortable: true
        },
        {
          key: 'payment_method',
          sortable: true
        },
        {
          key: 'amount',
          label: 'AMOUNT',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        },
      ],
      items: [
        { id: '#35463', product: 'Course', date: 'Aug 17, 2020, 12:54 (ET)', payment_status: 'Paid', fulfillment_status: 'fulfilled', payment_method: 'master', total: '$499.00', actions: 'View'}
      ]
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('company', ['companySettings']),
    ...mapState('payment', ['fetching', 'userPayments']),
    ...mapState('product', ['coursePackages', 'membershipPackages', 'coursesFields', 'membershipFields']),
    orderType () {
      if (!Object.keys(this.selectedRow).length) {
        return 'membershipPackage'
      }
      return this.selectedRow.product_type
    }
  },
  methods: {
    ...mapActions('payment', ['getUserPayments']),
    ...mapActions('product', [
      'getCoursePackages',
      'getMembershipPackages'
    ]),
    ...mapActions('company', ['getCompanySettings']),
    toggleAll(checked) {
      this.selected = checked ? this.productTypes.slice() : []
    },
    getCreatedAt (date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    handleShowAddPackageModal () {
      this.$bvModal.show('add-payment-order-modal')
    },
    handleButtonClick(row, action) {
      this.selectedRow = row
      setTimeout(() => {
        switch (action) {
          case 'view':
            this.$bvModal.show('view-payment-order-modal')
        }
      }, 100)
    },
    async handleRefreshPayments () {
      await this.getUserPayments(this.userId)
    },
    reRenderModal () {
      this.showAddPaymentOrderModal++
    }
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.productTypes.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  },
  async mounted() {
    await this.getUserPayments(this.$route.params.userId)
    if (this.coursePackages.length === 0) {
      await this.getCoursePackages()
    }
    if (this.membershipPackages.length === 0) {
      await this.getMembershipPackages()
    }
    if (this.companySettings === null) {
      await this.getCompanySettings()
    }
  }
}
</script>