<template>
  <div>
    <h2 class="hs-docs-heading">
      Bootstrap template
    </h2>
    <b-link target="_blank" href="https://bootstrap-vue.org/docs/components/modal">https://bootstrap-vue.org/docs/components/modal</b-link>
    <h2 class="hs-docs-heading">
      Front End - Vue2
    </h2>
    <b-link target="_blank" href="https://v2.vuejs.org/">https://v2.vuejs.org/</b-link>
    <h2 class="hs-docs-heading">
      themes
    </h2>
    <b-link target="_blank" href="https://themes.getbootstrap.com/preview/?theme_id=55924">https://themes.getbootstrap.com/preview/?theme_id=55924</b-link>
    <h2 class="hs-docs-heading">
      DropFile
    </h2>
    <b-link target="_blank" href="https://rowanwins.github.io/vue-dropzone/docs/dist/#/iconDemo">https://rowanwins.github.io/vue-dropzone/docs/dist/#/iconDemo</b-link>
    <h2 class="hs-docs-heading">
      Text Editor
    </h2>
    <b-link target="_blank" href="https://www.vue2editor.com/examples/#basic-setup">https://www.vue2editor.com/examples/#basic-setup</b-link>
    <h2 class="hs-docs-heading">
      Calendar
    </h2>
    <b-link target="_blank" href="https://fullcalendar.io/docs/vue">https://fullcalendar.io/docs/vue</b-link>
    <b-link target="_blank" href="https://fullcalendar.io/docs/external-dragging">https://fullcalendar.io/docs/external-dragging</b-link>

    <h2 class="hs-docs-heading">
      Excel Editor for VUE 2
    </h2>
    <b-link target="_blank" href="https://www.npmjs.com/package/vue-excel-editor">https://www.npmjs.com/package/vue-excel-editor</b-link>
    <h2 class="hs-docs-heading">Vuex</h2>
    <b-link target="_blank" href="https://vuex.vuejs.org/installation.html">https://vuex.vuejs.org/installation.html</b-link>
  </div>
</template>
<script>
export default {
  name: 'documentation-overview'
}
</script>
<style scoped>
.hs-docs-heading {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
</style>