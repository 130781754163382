import * as TYPES from './types'

const getUserPayments = state => {
    state.fetching = true
}

const getUserPaymentsSuccess = (state, data) => {
    state.fetching = false
    state.userPayments = data
}

const getUserPaymentsFailure = (state, err) => {
    state.fetching = false
    console.log(err)
}

const addUserPackagePayment = () => {
}

const addUserPackagePaymentSuccess = () => {
}

const addUserPackagePaymentFailure = (state, error) => {
    console.log(error)
}

const getTopSalesList = (state) => {
    state.fetchingTopSales = true
}

const getTopSalesListSuccess = (state, data) => {
    state.topSalesData = data
    state.fetchingTopSales = false
}

const getTopSalesListFailure = (state, error) => {
    console.log(error)
    state.fetchingTopSales = false
}

export default {
    [TYPES.GET_USER_PAYMENTS.ACTION]: getUserPayments,
    [TYPES.GET_USER_PAYMENTS.SUCCESS]: getUserPaymentsSuccess,
    [TYPES.GET_USER_PAYMENTS.FAILURE]: getUserPaymentsFailure,
    [TYPES.ADD_USER_PACKAGE_PAYMENT.ACTION]: addUserPackagePayment,
    [TYPES.ADD_USER_PACKAGE_PAYMENT.SUCCESS]: addUserPackagePaymentSuccess,
    [TYPES.ADD_USER_PACKAGE_PAYMENT.FAILURE]: addUserPackagePaymentFailure,
    [TYPES.GET_TOP_SALES_LIST.ACTION]: getTopSalesList,
    [TYPES.GET_TOP_SALES_LIST.SUCCESS]: getTopSalesListSuccess,
    [TYPES.GET_TOP_SALES_LIST.FAILURE]: getTopSalesListFailure
}