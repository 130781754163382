<template>
  <div class="mkc-collapse">
    <b-button
        v-b-toggle="vBToggle"
        variant="light"
        :aria-expanded="isVisible"
        class="mkc-collapse-button"
    >
      <b-icon v-if="icon" :icon="icon"></b-icon>
      {{ collapseName }}
      <strong v-if="isVisible" aria-hidden="true" class="ml-auto">-</strong>
      <strong v-else aria-hidden="true" class="ml-auto">+</strong>
    </b-button>
    <b-collapse
        v-for="item in items"
        :key="item.key"
        :id="item.key"
        class="mt-2"
        v-model="isVisible"
    >
      <b-card>
        <router-link :to="{ name: item.link }">{{ item.name }}</router-link>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>

export default {
  name: 'mkc-collapse',
  props: {
    collapseName: {
      type: String,
      required: true,
      default: 'collapse'
    },
    icon: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true,
      default: null
    },
  },
  data () {
    return {
      isVisible: false, // starts un-collapsed
    }
  },
  computed: {
    vBToggle () {
      return this.items.map( item => { return item.key } )
    }
  },
  methods: {

  }
}
</script>
<style scoped="scoped">
.mkc-collapse {
  width: 100%;
}
.mkc-collapse-button {
  padding: 0;
  width: 100%;
}
</style>