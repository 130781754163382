<template>
  <b-modal
      id="add-membership"
      title="Add Membership Package"
      class="add-membership"
      size="lg"
      :ok-disabled="hasAlert"
      @ok="submit"
  >
    <div v-if="hasAlert" class="alert alert-warning" role="alert">
      Please input all fields
    </div>
    <label for="membershipName" class="col-form-label form-label">Name</label>
    <label class="sr-only" for="name-cn">Name(中文)</label>
    <div class="input-group input-group-md-vertical">
      <b-form-input
          type="text"
          class="form-control"
          placeholder="Name(中文)"
          :state="form.name_cn !== ''"
          v-model="form.name_cn"
      />
      <b-form-input
          type="text"
          class="form-control"
          placeholder="Name(English)"
          :state="form.name_en !== ''"
          v-model="form.name_en"
      />
    </div>
    <label for="membershipDescription" class="col-form-label form-label">Description</label>
    <div class="input-group input-group-md-vertical">
      <b-form-textarea
          type="text"
          class="form-control"
          placeholder="Description(中文)"
          :state="form.description_cn !== ''"
          v-model="form.description_cn"
      />
      <b-form-textarea
          type="text"
          class="form-control"
          placeholder="Description(English)"
          :state="form.description_en !== ''"
          v-model="form.description_en"
      />
    </div>
    <label for="amount" class="col-form-label form-label">Amount</label>
    <b-input-group prepend="$" append=".00" class="w-lg-50">
      <b-form-input
          id="amount"
          class="p-4"
          type="number"
          :state="form.amount !== ''"
          v-model="form.amount"
      />
    </b-input-group>
    <label for="duration" class="col-form-label form-label">Duration</label>
    <b-input-group class="input-group input-group-sm-vertical w-lg-50">
      <b-form-input
          id="duration"
          type="number"
          :state="form.duration !== ''"
          v-model="form.duration"
      />
      <template #append>
        <b-form-select
            class="form-select p-1"
            :options="membershipDurationOptions"
            :state="form.duration_symbol !== ''"
            v-model="form.duration_symbol"
        />
      </template>
    </b-input-group>
    <label
        for="shared" class="col-form-label form-label">How many people ganna use this package ?</label>
    <b-form-input
        id="shared"
        type="number"
        class="w-50"
        :state="form.shared !== ''"
        v-model="form.shared"
    />
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'add-membership-package-modal',
  data () {
   return {
     alertInfo: '',
     name: null,
     description: 'Year',
     // options: [
     //   { value: 'year', text: 'Year(s)' },
     //   { value: 'month', text: 'Month(s)' },
     //   { value: 'week', text: 'Week(s)' },
     //   { value: 'day', text: 'Day(s)' },
     // ],
     form: {
       name_en: '',
       name_cn: '',
       description_en: '',
       description_cn: '',
       amount: '',
       duration_symbol: '',
       duration: '',
       shared: ''
     }
   }
  },
  computed: {
    ...mapState('product', ['membershipDurationOptions']),
    hasAlert () {
      return Object.values(this.form).some(item => {return item === ''})
    }
  },
  methods: {
    submit () {
      let data = {
        name: JSON.stringify({
          en: this.form.name_en,
          cn: this.form.name_cn
        }),
        description: JSON.stringify({
          en: this.form.description_en,
          cn: this.form.description_cn
        }),
        amount: this.form.amount,
        duration: this.form.duration + ' ' + this.form.duration_symbol,
        shared: this.form.shared
      }
      this.$emit('submit', data)
    }
  }
}
</script>
