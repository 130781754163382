<template>
  <LineChart
      :options="chartOptions"
      :data="chartData"
  />
</template>

<script>
import { Line as LineChart } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
)

export default {
  name: 'line-chart',
  components: { LineChart },
  props: {
    chartData: {
      type: Object || Array,
      required: true,
      default: null
    },
    chartOptions: {
      type: Object || Array,
      required: false,
      default ()  {
       return {
         maintainAspectRatio: false,
         responsive: true,
         scales: {
           y: {
             grid: {
               drawBorder: false,
               color: '#eeeef1'
             },
             min: 25,
             max: 150,
             ticks: {
               stepSize: 25,
               color: '#eeeef1',
               padding: 10
             }
           },
           x: {
             grid: {
               drawBorder: false,
               display: false
             },
             ticks: {
               labelOffset: 20,
               maxTicksLimit: 11,
               padding: 20,
               maxRotation: 0,
               minRotation: 0,
               font: {
                 "size": 12
               },
               color: '#eeeef1'
             }
           }
         },
         plugins: {
           legend: {
             labels: {
               usePointStyle: true,
               color: '#eeeef1'
             },
           }
         }
       }
      }
    }
  },
  data() {
    return {
      // chartData: {
      //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      //   datasets: [
      //     {
      //       label: 'Weight(lbs)',
      //       backgroundColor: '#f87979',
      //       data: [143.9, 139.9, 137.6, 136.2, 137.3, 134.3, 133.4]
      //     },
      //     {
      //       label: 'SMM(lbs)',
      //       backgroundColor: '#997208',
      //       data: [44.3, 44.1, 43.4, 43.4, 43.6, 43.4, 43.6]
      //     },
      //     {
      //       label: 'BPF(%)',
      //       backgroundColor: '#cc2bbf',
      //       data: [41.3, 40.7, 39.2, 39.0, 39.4, 38.6, 37.8]
      //     }
      //   ]
      // },
      // chartOptions: {
      //   maintainAspectRatio: false,
      //   responsive: true,
      //   // plugins: {
      //   //   tooltip: {
      //   //     enabled: true
      //   //   }
      //   // }
      // }
    }
  }
}
</script>