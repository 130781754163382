<template>
  <div class="card mb-3 mb-lg-5">
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title">Media</h4>
    </div>
    <div class="card-body">
      <drop-file />
    </div>
  </div>
</template>
<script>
import { DropFile } from "@/layout";

export default {
  name: 'product-pictures',
  components: {
    DropFile
  }
}
</script>