<template>
  <b-modal
      id="update-recipe-modal"
      @ok="submit"
  >
    <b-form-textarea
        id="textarea-default"
        rows="6"
        :value="description"
        @input="value => input(value)"
    />
    <div class="text-end">
      -- Author {{ author }}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'update-recipe-modal',
  props: {
    description: {
      type: String,
      required: false,
      default: 'recipe description'
    },
    author: {
      type: String,
      required: false,
      default: 'recipe description'
    }
  },
  data () {
    return {
      recipe: ''
    }
  },
  methods: {
    input (value) {
      this.recipe = value
    },
    submit () {
      this.$emit('submit', this.recipe)
    }
  }
}
</script>