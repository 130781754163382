<template>
  <div class="card card-lg">
    <div class="profile-cover">
      <div class="profile-cover-img-wrapper">
        <img class="profile-cover-img" src="@/assets/img/1920x400/img1.jpg">
      </div>
    </div>
    <div class="avatar avatar-xxl avatar-circle avatar-border-lg profile-cover-avatar">
      <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Image Description">
    </div>
    <div class="card-body">
      <dl class="row">
        <dt class="col-sm-6 text-sm-end">Full name:</dt>
        <dd class="col-sm-6">{{ form.first_name + ' ' + form.last_name }}</dd>
        <dt class="col-sm-6 text-sm-end">Email</dt>
        <dd class="col-sm-6">{{ form.email }}</dd>
        <dt class="col-sm-6 text-sm-end">Date of Birth:</dt>
        <dd class="col-sm-6">{{ form.dateOfBirth }}</dd>
        <dt class="col-sm-6 text-sm-end">Start of Date:</dt>
        <dd class="col-sm-6">{{ form.startOfDate }}</dd>
        <dt class="col-sm-6 text-sm-end">End of Date:</dt>
        <dd class="col-sm-6">{{ form.endOfDate }}</dd>
        <dt class="col-sm-6 text-sm-end">Phone:</dt>
        <dd class="col-sm-6">{{ form.phone }}</dd>
        <dt class="col-sm-6 text-sm-end">Address:</dt>
        <dd class="col-sm-6">{{ form.address }}</dd>
        <dt class="col-sm-6 text-sm-end">Zip Code:</dt>
        <dd class="col-sm-6">{{ form.zipCode }}</dd>
        <dt v-if="userType==='employee'" class="col-sm-6 text-sm-end">Department:</dt>
        <dd v-if="userType==='employee'" class="col-sm-6">{{ $t(`profile.department.${form.department}`) }}</dd>
        <dt v-if="userType==='employee'" class="col-sm-6 text-sm-end">Job Title:</dt>
        <dd v-if="userType==='employee'" class="col-sm-6">{{ form.jobTitle }}</dd>
        <dt v-if="userType==='employee'" class="col-sm-6 text-sm-end">Employee Role:</dt>
        <dd v-if="userType==='employee'" class="col-sm-6">{{ form.role }}</dd>
        <dt class="col-sm-6 text-sm-end">Gender:</dt>
        <dd class="col-sm-6">{{ $t(`profile.gender.${form.gender}`) }}</dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  name: 'confirmation-form',
  props: {
    form: {
      type: Object,
      required: false,
      default: () => {}
    },
    userType: {
      type: String,
      required: false,
      default: 'employee'
    }
  }
}
</script>