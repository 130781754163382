<template>
  <div>
    <b-dropdown
        id="dropdown-profile"
        no-caret
        right
        variant="link"
        menu-class="navbar-dropdown-menu-borderless"
    >
      <template #button-content>
        <div class="avatar avatar-sm avatar-circle">
          <b-avatar badge :badge-variant="statusBadge" class="avatar-img" src="https://placekitten.com/300/300"></b-avatar>
        </div>
      </template>
      <b-dropdown-item class="dropdown-item-text pl-0">
        <div class="d-flex align-items-center">
          <div class="avatar avatar-sm avatar-circle">
            <b-avatar class="avatar-img" src="https://placekitten.com/300/300"></b-avatar>
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mb-0">{{ currentUser.first_name + ' ' + currentUser.last_name }}</h5>
            <p class="card-text text-body">{{ currentUser.email }}</p>
          </div>
        </div>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="redirectToProfilePage">Profile & account</b-dropdown-item>
<!--      <b-dropdown-item>Settings</b-dropdown-item>-->
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="signOut">Sign out</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name:'profile-icon',
  data () {
    return {
      menu: false,
      subMenu: false
    }
  },
  computed: {
    ...mapState('user', { currentUser: 'data'}),
    statusBadge () {
      switch (this.currentUser.status)
      {
        case 'incomplete':
          return 'light'
        case 'pending':
          return 'warning'
        case 'active':
          return 'success'
        case 'suspended':
          return 'secondary'
        case 'dismissed':
          return 'danger'
        default:
          return 'danger'
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    redirectToProfilePage() {
      this.$router.push({name: 'my-profile'})
    },
    signOut () {
      this.logout('User Log out')
    }
  }
}
</script>