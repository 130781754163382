<template>
  <b-modal
      id="modify-on-leave-modal"
      :title="`Modify On-Leave Reason`"
      size="lg"
  >
    <div class="row mb-3">
      <div class="col-sm-6">
        <img class="card-img" src="@/assets/svg/empty/oc-lost.svg" style="max-width: 15rem;" alt="dismissed user"/>
      </div>
      <div class="col-sm-6">
        <h4>You can modify the on Leave period for user before it begins. </h4>
        <p>Please select a period between the user's start date and end date. The selected period should fall within the range of [ <span class="text-info">{{ userStartOfDate }}</span> ] to [ <span class="text-info">{{ userEndOfDate }}</span> ]</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-4">
          <label for="on-leave-effective-date" class="form-label" id="onLeaveEffectiveDateLabel">Effective Date</label>
          <b-form-datepicker
              id="on-leave-effective-date"
              :value="effectiveDate"
              :state="validateEffectiveDate"
              @input="value => handleUpdateOnLeaveInfo('effectiveDate', value)"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-4">
          <label for="on-leave-return-date" class="form-label" id="onLeaveReturnDateLabel">Return Date</label>
          <b-form-datepicker
              id="on-leave-effective-date"
              :value="returnDate"
              :state="validateReturnDate"
              @input="value => handleUpdateOnLeaveInfo('returnDate', value)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-4">
          <label for="on-leave-reason" class="form-label" id="onLeaveReasonLabel">Reason</label>
          <multi-select
              :value="onLeaveReason"
              :options="onLeaveReasonOptions"
              :searchable="false"
              label="name"
              placeholder="Select an Reason"
              track-by="name"
              :allow-empty="false"
              @input="handleReason"
          >
          </multi-select>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <div class="float-end">
          <b-button
              v-if="validateEffectiveDate && validateReturnDate && onLeaveReason !== null"
              variant="primary"
              class="mr-2"
              @click="handleSubmitOnLeaveRequest"
              :disabled="fetching"
          >
            Update
          </b-button>
          <b-button
              variant="out-secondary"
              class="btn-outline-primary float-end ml-2"
              @click="handleCloseModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import moment from 'moment/moment'

export default {
  name: 'modify-on-leave-modal',
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    userType: {
      type: String,
      required: true,
      default: 'employee'
    },
    userId: {
      type: Number||String,
      required: true,
      default: 0
    },
    userStartOfDate: {
      type: String,
      required: true,
      default: '2020-02-03'
    },
    userEndOfDate: {
      type: String,
      required: true,
      default: '2020-03-03'
    },
    effectiveDate: {
      type: String,
      required: true,
      default: '2020-03-03'
    },
    returnDate: {
      type: String,
      required: true,
      default: '2020-03-03'
    },
    onLeaveReason: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      onLeaveReasonOptionsValue: [
        'vacation',
        'shortTermDisability',
        'longTermDisability',
        'otherReason'
      ]
    }
  },
  computed: {
    onLeaveReasonOptions () {
      return this.onLeaveReasonOptionsValue.map(reason => {
        return { value: reason, name: this.$t(`profile.onLeaveReasonOptions.${reason}`) }
      })
    },
    validateEffectiveDate () {
      return this.effectiveDate !== null &&
          moment(this.userStartOfDate).isBefore(moment(this.effectiveDate)) &&
          moment(this.userEndOfDate).isAfter(moment(this.effectiveDate)) &&
          moment(this.effectiveDate).isBefore(moment(this.returnDate))
    },
    validateReturnDate () {
      return this.returnDate !== null &&
          moment(this.userStartOfDate).isBefore(moment(this.returnDate)) &&
          moment(this.userEndOfDate).isAfter(moment(this.returnDate)) &&
          moment(this.effectiveDate).isBefore(moment(this.returnDate))
    }
  },
  methods: {
    handleReason(reason) {
      this.handleUpdateOnLeaveInfo('reasonName', reason)
    },
    handleUpdateOnLeaveInfo (name, value) {
      this.$emit('input', name, value)
    },
    handleSubmitOnLeaveRequest () {
      this.$emit('submit')
    },
    handleCloseModal () {
      this.$bvModal.hide('modify-on-leave-modal')
    }
  }
}
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="css" scoped>
.form-control.focus {
  background-color: #25282a !important;
}
</style>
