<template>
  <div>
    <b-alert
        v-model="showAlert"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="warning"
        dismissible
    >
      {{ alertInfo }}
    </b-alert>
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <div class="card mb-3 mb-lg-5">
      <div class="overflow-auto">
        <mkc-table
            :fetching="fetching"
            :per-page="perPage"
            :table-columns="tableColumns"
            :table-cells="trainers"
            :menu-type="`trainerDashboardForAdmin`"
            @table-button-click="handleActions"
        />
      </div>
    </div>
    <trainer-course-summary-table />
    <delete-data-modal
        :title="deleteTrainerModal.title"
        :data="deleteTrainerModal.data"
        :description="deleteTrainerModal.description"
        @handle-delete-user="handleDeleteTrainer"
    />
    <active-modal
        :fetching="fetchingReason"
        :title="userStatusModalData.title"
        :user-name="userStatusModalData.userName"
        :user-type="userStatusModalData.userType"
        :user-id="userStatusModalData.userId"
        @submit="handleActivateUser"
    />
    <dismissed-modal
        :fetching="fetchingReason"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        @submit="handleDismissUser"
    />
    <on-leave-modal
        :fetching="fetchingReason"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        @submit="handleOnLeaveUser"
    />
    <modify-on-leave-modal
        :fetching="fetchingReason"
        :on-leave-reason="userReasonData.reasonName"
        :return-date="userReasonData.returnDate"
        :effective-date="userReasonData.effectiveDate"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        @input="handleUpdateReason"
        @submit="handleModifyUserReason('onLeave')"
    />
    <modify-dismiss-modal
        :fetching="fetchingReason"
        :dismiss-reason="userReasonData.reasonName"
        :effective-date="userReasonData.effectiveDate"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        @input="handleUpdateReason"
        @submit="handleModifyUserReason('dismissed')"
    />
    <user-reason-existed-modal />
    <failed-active-user-modal />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import {
  MkcTable,
  PageHeader,
  DeleteDataModal,
  ActiveModal,
  DismissedModal,
  OnLeaveModal,
  UserReasonExistedModal,
  ModifyOnLeaveModal,
  ModifyDismissModal,
  FailedActiveUserModal
} from '@/layout'
import { TrainerCourseSummaryTable } from '@/components/users/userProfile/components'
import moment from 'moment/moment'

export default {
  name: 'users-trainers',
  components: {
    MkcTable,
    PageHeader,
    DeleteDataModal,
    ActiveModal,
    DismissedModal,
    OnLeaveModal,
    UserReasonExistedModal,
    ModifyOnLeaveModal,
    ModifyDismissModal,
    FailedActiveUserModal,
    TrainerCourseSummaryTable
  },
  data () {
    return {
      headerTitle: 'Trainers',
      headerItems: [
        {
          text: 'Employees',
          href: '#'
        },
        {
          text: 'Trainers',
          active: true
        }
      ],
      perPage: 10,
      currentPage: 1,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'name', label: 'Full Name', sortable: true },
        { key: 'height', label: 'Height', sortable: true },
        { key: 'weight', label: 'Weight', sortable: true },
        { key: 'members', label: 'Members', sortable: true },
        { key: 'startOfDate', label: 'Start of Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      deleteTrainerModal: {
        title: 'Delete Trainer',
        data: 'Trainer data',
        description: "You will lost the trainer data forever"
      },
      selectedTrainer: null,
      userStatusModalData: {
        title: 'Withdraw OnLeave',
        userId: 0,
        userType: 'trainer',
        userName: 'Trainer Name',
        startOfDate: '2020-02-03',
        endOfDate: '2020-03-03'
      },
      userReasonData: {
        id: null,
        effectiveDate: '',
        returnDate: '',
        reasonName: {}
      },
      alertInfo: null,
      showAlert: false
    }
  },
  computed: {
    ...mapState('trainer', ['fetching', 'trainers']),
    ...mapState('user', ['fetchingReason', 'userReason']),
    ...mapState('user', { usingAppAs: 'useAppAs' }),
  },
  methods: {
    ...mapActions('trainer', ['getTrainers', 'deleteTrainer']),
    ...mapActions('user', ['createUserReason', 'modifyUserReason', 'activateUser']),
    handleActions ({ row, action }) {
      this.selectedTrainer = row
      this.userStatusModalData.userId = row.id
      this.userStatusModalData.startOfDate = row.startOfDate
      this.userStatusModalData.endOfDate = row.endOfDate
      switch (action) {
        case 'delete':
          this.deleteTrainerModal.data = row.name.first_name  + ' ' + row.name.last_name
          this.$bvModal.show('delete-data-modal')
          break
        case 'edit':
        case 'view':
          this.$router.push({name: 'user-profile', params: {userId: this.selectedTrainer.id, userType: 'trainer'}})
          break
        case 'dismiss':
          this.$bvModal.show('dismissed-modal')
          break
        case 'onLeave':
          this.$bvModal.show('on-leave-modal')
          break
        case 'withdrawOnLeave':
          this.userStatusModalData.title = 'withdraw OnLeave'
          this.userReasonData.id = row.reasons.id
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.checkIfCanWithdrawUser()
          break
        case 'withdrawDismissed':
          this.userStatusModalData.title = 'withdraw Dismissed'
          this.userReasonData.id = row.reasons.id
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.checkIfCanWithdrawUser()
          break
        case 'withdrawPending':
          this.userStatusModalData.title = 'Withdraw Pending'
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.$bvModal.show('active-user-modal')
          break
        case 'modifyOnLeave':
          this.userReasonData.id = row.reasons.id
          this.userReasonData.reasonName = {value: row.reasons.reason_name, name: this.$t(`profile.onLeaveReasonOptions.${row.reasons.reason_name}`) }
          this.userReasonData.effectiveDate = row.reasons.started_at
          this.userReasonData.returnDate = row.reasons.ended_at
          this.$bvModal.show('modify-on-leave-modal')
          break
        case 'modifyDismissed':
          this.userReasonData.id = row.reasons.id
          this.userReasonData.reasonName = {value: row.reasons.reason_name, name: this.$t(`profile.dismissedReasonOptions.${row.reasons.reason_name}`) }
          this.userReasonData.effectiveDate = row.reasons.started_at
          this.$bvModal.show('modify-dismiss-modal')
          break
      }
    },
    checkIfCanWithdrawUser () {
      let now = moment.now()
      if (!moment(this.userStatusModalData.endOfDate).isBefore(now)) {
        this.$bvModal.show('failed-active-user-modal')
      } else {
        this.$bvModal.show('active-user-modal')
      }
    },
    async handleActivateUser () {
      await this.activateUser( { id: this.selectedTrainer.id, data: { id: this.userReasonData.id }})
      this.alertInfo = 'Activate ' + this.selectedTrainer.name.first_name + ' ' + this.selectedTrainer.last_name+ ' Successfully !'
      this.showAlert = true
      this.$bvModal.hide('active-user-modal')
    },
    async handleDeleteTrainer () {
      await this.deleteTrainer(this.selectedTrainer.id)
      await  this.getTrainers()
    },
    async handleDismissUser (data) {
      await this.createUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('dismissed-modal')
      if ('error' in this.userReason) {
        this.handleError(this.userReason.error)
      } else {
        this.alertInfo = 'Submit User Dismiss Request Successfully !'
        this.showAlert = true
      }
    },
    async handleOnLeaveUser (data) {
      await this.createUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('on-leave-modal')
      if ('error' in this.userReason) {
        this.handleError(this.userReason.error)
      } else {
        this.alertInfo = 'Submit User On Leave Request Successfully !'
        this.showAlert = true
      }
    },
    handleUpdateReason (name, value) {
      switch (name) {
        case 'effectiveDate':
          this.userReasonData.effectiveDate = value
          break
        case 'returnDate':
          this.userReasonData.returnDate = value
          break
        case 'reasonName':
          this.userReasonData.reasonName = value
          break
      }
    },
    async handleModifyUserReason (reasonType) {
      let data = {
        id: this.userReasonData.id,
        reason_type: reasonType,
        reason_name: this.userReasonData.reasonName.name,
        started_at: this.userReasonData.effectiveDate
      }
      if (reasonType === 'onLeave') {
        data['ended_at'] = this.userReasonData.returnDate
      }
      await this.modifyUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('modify-on-leave-modal')
      this.$bvModal.hide('modify-dismiss-modal')
    },
    handleError(error) {
      switch (error) {
        case 'REASON_EXISTED':
          this.$bvModal.show('user-reason-existed-modal')
          break
        default:
          this.alertInfo = 'Something Wrong Happened, Please contact IT team. Thanks !'
          this.showAlert = true
      }
    }
  },
  async mounted () {
    await this.getTrainers()
  }
}
</script>