import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    trainers: [],
    addedTrainer: null,
    trainerProfile: null
})

export const trainer = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}