<template>
  <b-modal
      id="create-course-modal"
      size="lg"
      title="New Reservation"
      @close="handleCloseModal"
  >
    <div class="row mb-4">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-list-ul nav-icon"></i>
          <div class="flex-grow-1">Course Name</div>
        </div>
      </div>
      <div class="col-sm">
        <b-form-select
            :value="privateTrainingSessionId"
            :options="courseOptions"
            disabled
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
        ></b-form-select>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-calendar-event nav-icon"></i>
          <div class="flex-grow-1">Status</div>
        </div>
      </div>
      <div class="col-sm">
        <span class="badge bg-soft-warning text-warning rounded-pill position-absolute top-50">Scheduled</span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-calendar-event nav-icon"></i>
          <div class="flex-grow-1">Date</div>
        </div>
      </div>
      <div class="col-sm">
        <dl class="row">
          <dt class="col-sm-3">From:</dt>
          <dd class="col-sm-9">{{ getDateTime(event.start) }}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">End:</dt>
          <dd class="col-sm-9">{{ getDateTime(event.end) }}</dd>
        </dl>
      </div>
    </div>
    <!-- Trainer -->
    <div class="row mb-4">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-people nav-icon"></i>
          <div class="flex-grow-1">Trainer</div>
        </div>
      </div>
      <div class="col-sm">
        <b-form-select
            :value="targetTrainer"
            :options="trainersOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            @input="value => handleInput('trainerId', value)"
        ></b-form-select>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-people nav-icon"></i>
          <div class="flex-grow-1">Client</div>
        </div>
      </div>
      <div class="col-sm">
        <b-form-select
            :value="targetClient"
            :options="clientOptions"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            @input="value => handleInput('clientId', value)"
        />
      </div>
    </div>
    <!-- Course Tags -->
    <div class="row mb-4">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-text-left nav-icon"></i>
          <div class="flex-grow-1">Description</div>
        </div>
      </div>
      <div class="col-sm">
        <multi-select
            v-model="tags"
            tag-placeholder="Add this as new tag"
            placeholder="Search or add a tag"
            label="name"
            track-by="id"
            :options="courseTagOptions"
            :multiple="true"
            :taggable="true"
        >
        </multi-select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3 mb-2 mb-sm-0">
        <div class="d-flex align-items-center mt-2">
          <i class="bi-person nav-icon"></i>
          <div class="flex-grow-1">Created/Updated by</div>
        </div>
      </div>
      <div class="col-sm">
        <!-- Media -->
        <a class="d-inline-flex align-items-center" href="#">
          <div class="avatar avatar-sm avatar-circle me-3">
            <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Created By">
          </div>
          <div class="flex-grow-1">
            <h5 class="text-inherit mb-0">{{ eventCreatedBy }}</h5>
          </div>
        </a>
        <!-- End Media -->
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            v-if="showCreateCourseButton"
            class="float-start"
            variant="primary"
            :disabled="loading"
            @click="handleCreateReservation"
        >
          Create Reservation
        </b-button>
        <b-button
            variant="out-secondary"
            class="btn-outline-primary float-end"
            @click="handleCloseModal"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'create-course-modal',
  props: {
    event: {
      type: Object,
      required: false,
      default: null
    },
    userType: {
      type: String,
      required: false,
      default: null
    },
    profile: {
      type: Object,
      required: false,
      default: null
    },
    targetClient: {
      type: Number,
      required: false,
      default: null
    },
    targetTrainer: {
      type: Number,
      required: false,
      default: null
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    },
  },
  data () {
    return {
      clientId: null,
      trainerId: null,
      tags: [],
      status: 'scheduled',
      loading: false
    }
  },
  computed: {
    ...mapState('user', {'loginUser': 'data'}),
    ...mapState('trainer', ['trainers']),
    ...mapState('client', ['clients', 'clientsForAddingCourses']),
    ...mapState('course', ['privateTrainingSessionId', 'courseTags', 'courseList', 'courseTypes', 'courseGoals', 'bodyParts', 'addedCourses']),
    courseOptions () {
      // Only allow to select private training session right now
      return this.courseList.filter(course => course.name === 'privateTrainingSession').map(course => {
        return { item: course.id, name: this.getCourseName(course.name) }
      })
    },
    courseTagOptions() {
      return this.courseTags.map(tag => {
        return { id: tag.id, name: tag.name[this.locale] }
      })
    },
    clientOptions () {
      if (this.userType === 'client') {
        return [{ item: this.profile.id, name: this.profile.name.first_name + ' ' + this.profile.name.last_name  }]
      }
      return this.clientsForAddingCourses.map(client => {
        return { item: client.id, name: client.name.first_name + ' ' + client.name.last_name }
      })
    },
    trainersOptions () {
      if (this.userType === 'trainer') {
        return [{ item: this.profile.id, name: this.profile.name.first_name + ' ' + this.profile.name.last_name  }]
      }
      return this.trainers.map(trainer => {
        return { item: trainer.id, name: trainer.name.first_name + ' ' + trainer.name.last_name}
      })
    },
    eventCreatedBy () {
      return this.loginUser.first_name + ' ' + this.loginUser.last_name
    },
    showCreateCourseButton () {
      return (this.clientId !== null || this.targetClient !== null)
          && (this.trainerId !== null || this.targetTrainer !== null)
          && (this.tags.length !== 0)
    }
  },
  methods: {
    ...mapActions('course', ['listCourseTags', 'listCourses', 'createReservation']),
    ...mapActions('trainer', ['getTrainers']),
    ...mapActions('client', ['getClients']),
    getCourseName (name) {
      if (this.$te('course.courseName.' + name)) {
        return this.$t('course.courseName.' + name)
      } else {
        return name
      }
    },
    getDateTime (time) {
      return moment(time).format('llll')
    },
    handleInput(name, value) {
      if (name === 'clientId') {
        this.clientId = value
      } else if (name === 'trainerId') {
        this.trainerId = value
      }
    },
    async handleCreateReservation () {
      this.loading = true
      let eventData = {
        clientId: this.clientId === null ? this.targetClient : this.clientId,
        trainerId: this.trainerId === null ? this.targetTrainer : this.trainerId,
        courseId: this.privateTrainingSessionId,
        status: 'scheduled',
        tags: this.tags.map(item => { return item.id }),
        startedAt: moment(this.event.start).format(),
        endedAt: moment(this.event.end).format(),
      }
      await this.createReservation({
        data: eventData
      })
      setTimeout(() => {
        this.loading = false
      }, 3000);
      this.handleCloseModal()
      this.$emit('save', this.addedCourses)
    },
    handleCloseModal () {
      this.clientId = null
      this.trainerId = null
      this.tags = []
      this.status = 'scheduled'
      this.loading = false
      this.$bvModal.hide('create-course-modal')
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>