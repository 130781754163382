<template>
  <span
      v-if="status==='done'"
      class="badge bg-soft-success text-success"
      :class="isTitle ?'ms-sm-3' : null"
  >
    <span class="legend-indicator bg-success"></span>{{ $t('payment.paymentStatus.' + status) }}
  </span>
  <span
      v-else-if="status==='inProgress'"
      class="badge bg-soft-info text-info"
      :class="isTitle ?'ms-sm-3' : null"
  >
    <span class="legend-indicator bg-info"></span>{{ $t('payment.paymentStatus.' + status) }}
  </span>
  <span
      v-else-if="status==='notStart'"
      class="badge bg-soft-warning text-warning"
      :class="isTitle ?'ms-sm-3' : null"
  >
    <span class="legend-indicator bg-warning"></span>{{ $t('payment.paymentStatus.' + status) }}
  </span>
  <span
      v-else-if="status==='cancelled'"
      class="badge bg-soft-danger text-danger"
      :class="isTitle ?'ms-sm-3' : null"
  >
    <span class="legend-indicator bg-danger"></span>{{ $t('payment.paymentStatus.' + status) }}
  </span>
</template>
<script>
export default {
  name: 'payment-status',
  props: {
    status: {
      type: String,
      required: true,
      default: 'pending'
    },
    isTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>