import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    companySettings: null,
    companyBodyIndexFieldSettings: []
})

export const company = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}