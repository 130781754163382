<template>
  <b-modal
      id="add-course"
      title="Add course"
      @ok="submit"
  >
    <label for="courseName" class="col-form-label form-label">Course Name</label>
    <b-form-input id="courseName" v-model="name" type="text" placeholder="Course Name" class="mb-4" required></b-form-input>
    <b-form-textarea
        id="course-description"
        placeholder="Input Course Description"
        rows="6"
        v-model="description"
        class="mb-1"
    />
    <b-form-checkbox
        id="isDefault"
        v-model="isDefault"
        :value="true"
        :unchecked-value="false"
    >
      set to default Course
    </b-form-checkbox>
    <small class="text-warning" v-if="isDefault">* You will not edit/delete the course if u set it to default</small>
  </b-modal>
</template>
<script>
export default {
  name: 'add-course-modal',
  data () {
    return {
      name: null,
      description: null,
      isDefault: false
    }
  },
  methods: {
    submit () {
      let data = {
        name: this.name,
        description: this.description,
        isDefault: this.isDefault
      }
      this.$emit('submit', data)
    }
  }
}
</script>