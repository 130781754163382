<template>
  <b-modal
      id="failed-active-user-modal"
      title="Failed Active User"
      ok-only
  >
    <div class="card h-100 text-center">
      <div class="card-body">
        <img class="card-img" src="@/assets/svg/empty/oc-error.svg" alt="something wrong" >
      </div>
      <div class="card-footer">
        <h3 class="card-title">Oops! This user cannot active since. This user has reached the expiration date.</h3>
        <p class="card-text fs-6"><span class="text-danger">Note:</span> Each User can only be activated before the user expired.</p>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'failed-active-user-modal'
}
</script>