import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'
import store from './store'
import VueEcho from 'vue-echo-laravel/vue-echo';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import PortalVue from 'portal-vue'
import VueExcelEditor from 'vue-excel-editor'
import VueExcelColumn from 'vue-excel-editor'
import { setupCalendar } from 'v-calendar';
import Multiselect from 'vue-multiselect'



Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// Vue.use(PortalVue)
Vue.use(VueI18n)
Vue.use(VueExcelEditor)
Vue.use(VueExcelColumn)
Vue.use(setupCalendar, {})
Vue.use(FlagIcon);
// register globally
Vue.component('multi-select', Multiselect)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.css'

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-vue/src/index.scss'

import './assets/css/theme-dark.css'
// import './assets/css/theme.css'
import './assets/css/bootstrap-icons/font/bootstrap-icons.css'
import router from './router'
import { axios } from './base/network'
import { sync } from 'vuex-router-sync'
import IdleVue from 'idle-vue'
import { localInCookie } from '@/base/utils'

import { translations } from './translations'

const i18n = new VueI18n({
  locale: localInCookie(),
  fallbackLocale: 'en',
  messages: translations
})

axios.init().then(() => {
  sync(store, router)

  Vue.mixin({
    methods: {
      $can (permissionNameArray) {
        return permissionNameArray.every(
            permission => store.state.user.data.permissions.includes(permission)
        )
      }
    }
  })
})

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  store,
  startAtIdle: false,
  idleTime: 900000,
})

Vue.use(VueEcho, {
  auth: {
    headers: {
      Accept: 'application/json'
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

