<template>
  <vue-excel-editor
      v-model="excelData"
      filter-row
      readonly
      :cell-style="rowStyle"
  >
    <vue-excel-column
        v-for="column in columns"
        :key="`column-${column.name}`"
        :field="column.name"
        :label="getLabel(column)"
        :type="column.type"
        width="80px"
    />
  </vue-excel-editor>
</template>
<script>
export default {
  name: 'excel-editor',
  components: {
  },
  props: {
    records: {
      type: Array,
      required: false,
      default: null
    },
    columns: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      excelData: this.records
    }
  },
  methods: {
    rowStyle() {
      return { fontWeight: 'bold', fontSize: '12pt', color: 'black'}
    },
    getLabel (field) {
      if (this.$te(`profile.bodyIndexFieldLabel.${field.name}`))
      {
        return this.$t(`profile.bodyIndexFieldLabel.${field.name}`) + ' (' + field.symbol + ')'
      }
      return field.label + ' (' + field.symbol + ')'
    }
  }
}
</script>