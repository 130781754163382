<template>
  <div>
    <b-alert
        v-model="showAlert"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="warning"
        dismissible
    >
      {{ alertInfo }}
    </b-alert>
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <div
        v-if="!fetching"
        class="row"
    >
      <div
          class="col-sm-6 col-lg-3 mb-3 mb-lg-5"
          v-for="(stat, index) in statCards"
          :key="index"
      >
        <stat-card
            :sub-title="stat.subtitle"
            :data="stat.data"
        />
      </div>
    </div>
    <div class="mb-5">
      <b-tabs content-class="mt-3" class="js-nav-scroller hs-nav-scroller-horizontal mb-5">
        <b-tab title="Membership" active>
          <mkc-table
              :fetching="fetching"
              :per-page="perPage"
              :table-columns="membershipColumns"
              :table-cells="memberships"
              :menu-type="menuType"
              @table-button-click="handleActions"
          />
        </b-tab>
        <b-tab title="Private Training Membership">
          <mkc-table
              :fetching="fetching"
              :per-page="perPage"
              :table-columns="tableColumns"
              :table-cells="proMemberships"
              :menu-type="menuType"
              has-columns
              @table-button-click="handleActions"
          />
        </b-tab>
      </b-tabs>
    </div>
    <delete-data-modal
        :title="deleteClientModal.title"
        :data="deleteClientModal.data"
        :description="deleteClientModal.description"
        @handle-delete-user="handleDeleteClient"
    />
    <active-modal
        :fetching="fetchingReason"
        :title="userStatusModalData.title"
        :user-name="userStatusModalData.userName"
        :user-type="userStatusModalData.userType"
        :user-id="userStatusModalData.userId"
        @submit="handleActivateUser"
    />
    <dismissed-modal
        :fetching="fetchingReason"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        @submit="handleDismissUser"
    />
    <on-leave-modal
        :fetching="fetchingReason"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        @submit="handleOnLeaveUser"
    />
    <modify-on-leave-modal
        :fetching="fetchingReason"
        :on-leave-reason="userReasonData.reasonName"
        :return-date="userReasonData.returnDate"
        :effective-date="userReasonData.effectiveDate"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        @input="handleUpdateReason"
        @submit="handleModifyUserReason('onLeave')"
    />
    <modify-dismiss-modal
        :fetching="fetchingReason"
        :dismiss-reason="userReasonData.reasonName"
        :effective-date="userReasonData.effectiveDate"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        @input="handleUpdateReason"
        @submit="handleModifyUserReason('dismissed')"
    />
    <user-reason-existed-modal />
    <failed-active-user-modal />
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import {
  PageHeader,
  StatCard,
  MkcTable,
  DeleteDataModal,
  ActiveModal,
  DismissedModal,
  OnLeaveModal,
  UserReasonExistedModal,
  ModifyOnLeaveModal,
  ModifyDismissModal,
  FailedActiveUserModal
} from '@/layout'

export default {
  name: 'clientOverview',
  components: {
    PageHeader,
    StatCard,
    MkcTable,
    DeleteDataModal,
    ActiveModal,
    DismissedModal,
    OnLeaveModal,
    UserReasonExistedModal,
    ModifyOnLeaveModal,
    ModifyDismissModal,
    FailedActiveUserModal
  },
  data () {
    return {
      headerTitle: 'Clients',
      headerItems: [
        {
          text: 'Clients',
          href: '#'
        },
        {
          text: 'Overview',
          active: true
        }
      ],
      perPage: 10,
      currentPage: 1,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true, checked: true},
        { key: 'name', label: 'Full Name', sortable: true, checked: true},
        { key: 'age', label: 'Age', sortable: true, checked: true },
        { key: 'clientType', label: 'Client Type', sortable: true, checked: true },
        { key: 'startOfDate', label: 'Start Date', sortable: true, checked: true},
        { key: 'status', label: 'Status', sortable: true, checked: true},
        { key: 'completed_courses', label: 'Completed Courses', sortable: true, checked: true},
        { key: 'upcoming_courses', label: 'Upcoming Courses', sortable: true, checked: true},
        { key: 'goal', label: 'Goal', sortable: true, checked: true},
        { key: 'reports_to', label: 'trainer', sortable: true, checked: true},
        { key: 'weight', label: 'Weight(lbs)', sortable: true, checked: true},
        { key: 'smm', label: 'SMM', sortable: true, checked: true},
        {
          key: 'actions',
          label: 'Actions',
          sortable: false,
          checked: true
        }
      ],
      membershipColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'name', label: 'Full Name', sortable: true},
        { key: 'age', label: 'Age', sortable: true },
        { key: 'status', label: 'Status', sortable: true},
        { key: 'clientType', label: 'Client Type', sortable: true },
        { key: 'startOfDate', label: 'Start Date', sortable: true},
        { key: 'endOfDate', label: 'End Date', sortable: true},
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      // Example for table Cell
      tableCells: [
        {
          id: 1,
          name: { first_name: 'A', last_name: 'B', email: 'chenwei@site.com' },
          age: 30,
          start_date: '10/10/2020',
          status: 'active',
          completed_courses: 100,
          goal: 'Lose weight',
          trainer: 'Man man',
          weight: '100',
          smm: '28',
          edit: true
        }
      ],
      deleteClientModal: {
        title: 'Delete Client Modal',
        data: 'client data',
        description: "You will lost the data forever"
      },
      selectedClient: null,
      userStatusModalData: {
        title: 'Withdraw OnLeave',
        userId: 0,
        userType: 'client',
        userName: 'Client Name',
        startOfDate: '2020-02-03',
        endOfDate: '2020-03-03'
      },
      userReasonData: {
        id: null,
        effectiveDate: '',
        returnDate: '',
        reasonName: {}
      },
      alertInfo: null,
      showAlert: false,
      currentAction: null
    }
  },
  computed: {
    ...mapState('client', ['fetching', 'clients', 'clientsCountSummary']),
    ...mapState('user', ['fetchingReason', 'userReason']),
    ...mapState('user', { usingAppAs: 'useAppAs' }),
    menuType () {
      if (this.usingAppAs[0] === 'trainer') {
        return 'clientDashboardForTrainer'
      }
      return 'clientDashboardForAdmin'
    },
    statCards () {
      return [
        { key: '1', subtitle: 'Total Clients', data: {from: this.clientsCountSummary.totalClientsOneMonthAgo, to: this.clientsCountSummary.totalClients}},
        { key: '2', subtitle: 'Active Clients', data: {from: this.clientsCountSummary.activeClientsOneMonthAgo, to: this.clientsCountSummary.activeClients}}
      ]
    },
    memberships () {
      if (!this.fetching) {
        return this.clients.filter(client => client.clientType !== 'pro')
      }
      return []
    },
    proMemberships () {
      if (!this.fetching) {
        return this.clients.filter(client => client.clientType === 'pro')
      }
      return []
    }
  },
  methods: {
    ...mapActions('client', ['getClients', 'getClientsCountSummary', 'deleteClient']),
    ...mapActions('user', ['createUserReason', 'modifyUserReason', 'activateUser', 'withdrawPending']),
    handleActions ({ row, action }) {
      this.selectedClient = row
      this.userStatusModalData.userId = row.id
      this.userStatusModalData.startOfDate = row.startOfDate
      this.userStatusModalData.endOfDate = row.endOfDate
      this.currentAction = action
      switch (action) {
        case 'delete':
          this.deleteClientModal.data = row.name.first_name  + ' ' + row.name.last_name
          this.$bvModal.show('delete-data-modal')
          break
        case 'edit':
        case 'view':
          this.$router.push({name: 'user-profile', params: {userId: this.selectedClient.id, userType: 'client'}})
          break
        case 'dismiss':
          this.$bvModal.show('dismissed-modal')
          break
        case 'onLeave':
          this.$bvModal.show('on-leave-modal')
          break
        case 'withdrawOnLeave':
          this.userStatusModalData.title = 'withdraw OnLeave'
          this.userReasonData.id = row.reasons.id
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.checkIfCanWithdrawUser()
          break
        case 'withdrawDismissed':
          this.userStatusModalData.title = 'withdraw Dismissed'
          this.userReasonData.id = row.reasons.id
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.checkIfCanWithdrawUser()
          break
        case 'withdrawPending':
          this.userStatusModalData.title = 'Withdraw Pending'
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.$bvModal.show('active-user-modal')
          break
        case 'modifyOnLeave':
          this.userReasonData.id = row.reasons.id
          this.userReasonData.reasonName = {value: row.reasons.reason_name, name: this.$t(`profile.onLeaveReasonOptions.${row.reasons.reason_name}`) }
          this.userReasonData.effectiveDate = row.reasons.started_at
          this.userReasonData.returnDate = row.reasons.ended_at
          this.$bvModal.show('modify-on-leave-modal')
          break
        case 'modifyDismissed':
          this.userReasonData.id = row.reasons.id
          this.userReasonData.reasonName = {value: row.reasons.reason_name, name: this.$t(`profile.dismissedReasonOptions.${row.reasons.reason_name}`) }
          this.userReasonData.effectiveDate = row.reasons.started_at
          this.$bvModal.show('modify-dismiss-modal')
          break
      }
    },
    checkIfCanWithdrawUser () {
      let now = moment.now()
      if (!moment(this.userStatusModalData.endOfDate).isBefore(now)) {
        this.$bvModal.show('failed-active-user-modal')
      } else {
        this.$bvModal.show('active-user-modal')
      }
    },
    async handleActivateUser () {
      if (this.currentAction === 'withdrawPending') {
        await this.withdrawPending({ id: this.selectedClient.id })
      } else {
        await this.activateUser( { id: this.selectedClient.id, data: { id: this.userReasonData.id }})
      }
      this.alertInfo = 'Activate ' + this.selectedClient.name.first_name + ' ' + this.selectedClient.last_name+ ' Successfully !'
      this.showAlert = true
      this.$bvModal.hide('active-user-modal')
    },
    async handleDeleteClient () {
      await this.deleteClient(this.selectedClient.id)
          .then(this.getClients())
          .then(this.getClientsCountSummary())
    },
    async handleDismissUser (data) {
      await this.createUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('dismissed-modal')
      if ('error' in this.userReason) {
        this.handleError(this.userReason.error)
      } else {
        this.alertInfo = 'Submit User Dismiss Request Successfully !'
        this.showAlert = true
      }
    },
    async handleOnLeaveUser (data) {
      await this.createUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('on-leave-modal')
      if ('error' in this.userReason) {
        this.handleError(this.userReason.error)
      } else {
        this.alertInfo = 'Submit User On Leave Request Successfully !'
        this.showAlert = true
      }
    },
    handleUpdateReason (name, value) {
      switch (name) {
        case 'effectiveDate':
          this.userReasonData.effectiveDate = value
          break
        case 'returnDate':
          this.userReasonData.returnDate = value
          break
        case 'reasonName':
          this.userReasonData.reasonName = value
          break
      }
    },
    async handleModifyUserReason (reasonType) {
      let data = {
        id: this.userReasonData.id,
        reason_type: reasonType,
        reason_name: this.userReasonData.reasonName.name,
        started_at: this.userReasonData.effectiveDate
      }
      if (reasonType === 'onLeave') {
        data['ended_at'] = this.userReasonData.returnDate
      }
      await this.modifyUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('modify-on-leave-modal')
      this.$bvModal.hide('modify-dismiss-modal')
    },
    handleError(error) {
      switch (error) {
        case 'REASON_EXISTED':
          this.$bvModal.show('user-reason-existed-modal')
          break
        default:
          this.alertInfo = 'Something Wrong Happened, Please contact IT team. Thanks !'
          this.showAlert = true
      }
    }
  },
  async mounted () {
    await this.getClients()
    await this.getClientsCountSummary()
  }
}
</script>