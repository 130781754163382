import enLocal from 'element-ui/lib/locale/lang/en'
import cnLocal from 'element-ui/lib/locale/lang/zh-CN'
import { default as en } from './en'
import { default as cn } from './cn'

export const translations = {
    en: {
        ...en,
        ...enLocal

    },
    cn: {
        ...cn,
        ...cnLocal
    }
}