import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    LIST_TRAINERS,
    ADD_TRAINER,
    DELETE_TRAINER,
    GET_TRAINER_PROFILE
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'TRAINER'
}

export const TrainerEntity = new NetworkEntity(options)

TrainerEntity.getTrainer = function (commit) {
    commit(LIST_TRAINERS.ACTION)
    return axios
        .get(`${this.endpoint}/trainers`)
        .then(({ data: {data} }) => {
            commit(LIST_TRAINERS.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_TRAINERS.FAILURE, err)
        })
}

TrainerEntity.addTrainer = function(commit, data) {
    commit(ADD_TRAINER.ACTION)
    return axios
        .post(`${this.endpoint}/trainers/create`, data)
        .then(({ data: {data} }) => {
            commit(ADD_TRAINER.SUCCESS, data)
        })
        .catch(err => {
            commit(ADD_TRAINER.FAILURE, err)
        })
}

TrainerEntity.deleteTrainer = function (commit, id) {
    commit(DELETE_TRAINER.ACTION)
    return axios
        .delete(`${this.endpoint}/trainers/delete/${id}`)
        .then(({data}) => {
            commit(DELETE_TRAINER.SUCCESS, data)
        })
        .catch(err => {
            commit(DELETE_TRAINER.FAILURE, err)
        })
}

TrainerEntity.getTrainerProfile = function (commit, id) {
    commit(GET_TRAINER_PROFILE.ACTION)
    return axios
        .get(`${this.endpoint}/trainers/${id}/profile`)
        .then(({data: {data} }) => {
            commit(GET_TRAINER_PROFILE.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_TRAINER_PROFILE.FAILURE, err)
        })
}