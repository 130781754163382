import * as TYPES from './types'

const listClients = state => {
    state.fetching = true
}

const listClientsSuccess = (state, data) => {
    state.clients = data
    state.clientsForAddingCourses = data.filter(client => client.status === 'active' && client.upcoming_courses > 0)
    state.fetching = false
}

const listClientsFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getClientsCountSummary = state => {
    state.fetching = true
}

const getClientsCountSummarySuccess = (state, data) => {
    state.clientsCountSummary = data
    state.fetching = false
}

const getClientsCountSummaryFailure  = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getClientTypesSummary = state => {
    state.fetching = true
}

const getClientTypesSummarySuccess = (state, data) => {
    state.clientTypesSummary = data
    state.fetching = false
}

const getClientTypesSummaryFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getClientProfile = state => {
    state.fetching = true
}

const getClientProfileSuccess = (state, data) => {
    state.clientProfile = data
    state.fetching = false
}

const getClientProfileFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const deleteClient = () => {}
const deleteClientSuccess = () => {}
const deleteClientFailure = (state, error) => {
    console.log(error)
}

const addClient = state => {
    state.fetching = true
}

const addClientSuccess = (state, data) => {
    state.addedClient = data
    state.fetching = false
}

const addClientFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const listRecipes = state => {
    state.fetchingRecipes = true
}

const listRecipesSuccess = (state, data) => {
    state.recipes = data
    state.fetchingRecipes = false
}

const listRecipesFailure = (state, error) => {
    state.fetchingRecipes = false
    console.log(error)
}

const createRecipe = state => {
    state.fetchingRecipes = true
}

const createRecipeSuccess = (state) => {
    state.fetchingRecipes = false
}

const createRecipeFailure = (state, error) => {
    state.fetchingRecipes = false
    console.log(error)
}

const updateRecipe = state => {
    state.fetchingRecipes = true
}

const updateRecipeSuccess = (state) => {
    state.fetchingRecipes = false
}

const updateRecipeFailure = (state, error) => {
    state.fetchingRecipes = false
    console.log(error)
}

const deleteRecipe = state => {
    state.fetchingRecipes = true
}

const deleteRecipeSuccess = (state) => {
    state.fetchingRecipes = false
}

const deleteRecipeFailure = (state, error) => {
    state.fetchingRecipes = false
    console.log(error)
}

const updateTrainer = () => {}

const updateTrainerSuccess = () => {}

const updateTrainerFailure = (state, error) => {
    console.log(error)
}

export default {
    [TYPES.LIST_CLIENTS.ACTION]: listClients,
    [TYPES.LIST_CLIENTS.SUCCESS]: listClientsSuccess,
    [TYPES.LIST_CLIENTS.FAILURE]: listClientsFailure,
    [TYPES.GET_CLIENTS_COUNT_SUMMARY.ACTION]: getClientsCountSummary,
    [TYPES.GET_CLIENTS_COUNT_SUMMARY.SUCCESS]: getClientsCountSummarySuccess,
    [TYPES.GET_CLIENTS_COUNT_SUMMARY.FAILURE]: getClientsCountSummaryFailure,
    [TYPES.GET_CLIENT_TYPES_SUMMARY.ACTION]: getClientTypesSummary,
    [TYPES.GET_CLIENT_TYPES_SUMMARY.SUCCESS]: getClientTypesSummarySuccess,
    [TYPES.GET_CLIENT_TYPES_SUMMARY.FAILURE]: getClientTypesSummaryFailure,
    [TYPES.GET_CLIENT_PROFILE.ACTION]: getClientProfile,
    [TYPES.GET_CLIENT_PROFILE.SUCCESS]: getClientProfileSuccess,
    [TYPES.GET_CLIENT_PROFILE.FAILURE]: getClientProfileFailure,
    [TYPES.DELETE_CLIENT.ACTION]: deleteClient,
    [TYPES.DELETE_CLIENT.SUCCESS]: deleteClientSuccess,
    [TYPES.DELETE_CLIENT.FAILURE]: deleteClientFailure,
    [TYPES.ADD_CLIENT.ACTION]: addClient,
    [TYPES.ADD_CLIENT.SUCCESS]: addClientSuccess,
    [TYPES.ADD_CLIENT.FAILURE]: addClientFailure,
    [TYPES.LIST_RECIPES.ACTION]: listRecipes,
    [TYPES.LIST_RECIPES.SUCCESS]: listRecipesSuccess,
    [TYPES.LIST_RECIPES.FAILURE]: listRecipesFailure,
    [TYPES.CREATE_RECIPE.ACTION]: createRecipe,
    [TYPES.CREATE_RECIPE.SUCCESS]: createRecipeSuccess,
    [TYPES.CREATE_RECIPE.FAILURE]: createRecipeFailure,
    [TYPES.UPDATE_RECIPE.ACTION]: updateRecipe,
    [TYPES.UPDATE_RECIPE.SUCCESS]: updateRecipeSuccess,
    [TYPES.UPDATE_RECIPE.FAILURE]: updateRecipeFailure,
    [TYPES.DELETE_RECIPE.ACTION]: deleteRecipe,
    [TYPES.DELETE_RECIPE.SUCCESS]: deleteRecipeSuccess,
    [TYPES.DELETE_RECIPE.FAILURE]: deleteRecipeFailure,
    [TYPES.UPDATE_TRAINER.ACTION]: updateTrainer,
    [TYPES.UPDATE_TRAINER.SUCCESS]: updateTrainerSuccess,
    [TYPES.UPDATE_TRAINER.FAILURE]: updateTrainerFailure
}