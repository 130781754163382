<template>
  <b-spinner
      v-if="fetchingUser"
  />
  <div
      v-else
      class="row justify-content-lg-center"
  >
    <profile-header
        v-if="!fetchingUser"
        :first-name="userProfile.name.first_name"
        :last-name="userProfile.name.last_name"
        :user-status="userProfile.status"
        :address="userProfile.address"
        :client-type="userProfile.clientType ? userProfile.clientType : null"
        :start-of-date="userProfile.startOfDate"
    />
    <div class="col-lg-11">
      <b-tabs content-class="mt-3" nav-class="align-items-center" v-model="tabIndex" class="js-nav-scroller hs-nav-scroller-horizontal mb-5">
        <b-tab title="Profile" active>
          <profile-detail
              v-if="!fetchingUser"
              :profile="userProfile"
              @refresh="refreshPage"
          />
        </b-tab>
        <b-tab v-if="['client', 'trainer'].includes(userType)" title="Body Composition History">
          <profile-body-composition-analysis :user-id="userId"/>
        </b-tab>
        <b-tab v-if="['client', 'trainer'].includes(userType)" title="Calendar">
          <mkc-calendar
              v-if="!fetchingUser"
              :user-id="userId"
              :profile="userProfile"
              :visible="renderCalendar"
          />
        </b-tab>
        <b-tab
            v-if="!fetchingUser &&(userType === 'trainer' || (userType === 'employee' && userProfile.role === 'manager'))"
            title="Members">
          <profile-members
              :user-id="userId"
          />
        </b-tab>
        <b-tab
            v-if="!fetchingUser && (userType === 'employee' && userProfile.role === 'manager')"
            title="Departments"
        >
          <profile-departments />
        </b-tab>
        <b-tab title="Payments & Orders">
          <profile-payment-and-orders
              v-if="!fetchingUser"
              :user-id="userId"
              :user-type="userType"
              :client-name="userProfile.name.first_name + ' ' + userProfile.name.last_name"
              :client-id="userProfile.id"
          />
        </b-tab>
        <template #tabs-end>
          <li role="presentation" class="nav-item ms-auto">
            <div class="d-flex gap-2">
              <b-link
                  v-if="profileButton"
                  class="btn btn-white btn-sm"
                  href="#"
                  @click="showModal"
              >
                <i class="bi-person-plus-fill me-1"></i>{{ profileButton }}
              </b-link>
              <b-dropdown
                  no-caret
                  dropleft
                  variant="link"
                  toggle-class="text-decoration-none btn-ghost-secondary btn-icon"
                  menu-class="navbar-dropdown-menu-borderless"
                  class="nav-scroller-dropdown"
              >
                <template #button-content>
                  <b-button class="btn-white btn-icon btn-sm"><i class="bi-three-dots-vertical"></i></b-button>
                </template>
                <b-dropdown-header>Settings</b-dropdown-header>
                <b-dropdown-item-button
                    v-if="!fetchingUser && (userType === 'client' && usingAppAs[0] === 'admin')"
                    @click="showChangeTrainerModal"
                >
                  <i class="bi bi-slash-circle dropdown-item-icon"></i>Change Trainer
                </b-dropdown-item-button>
                <b-dropdown-item-button>
                  <i class="bi bi-slash-circle dropdown-item-icon"></i>Set Status
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    @click="showChangePasswordModal"
                >
                  <i class="bi bi-shield-lock dropdown-item-icon"></i>Change Password
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
          </li>
        </template>
      </b-tabs>
    </div>
    <b-alert
        v-model="showAlert"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="success"
        dismissible
    >
      Reset Password Successfully!
    </b-alert>
    <edit-profile-modal
        :profile="userProfile"
        :user-type="userType"
    />
    <change-password-modal
        :user-id="userId"
        @message="handleAlert"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import {
  ProfileBodyCompositionAnalysis,
  ProfileHeader,
  ProfileDetail,
  ProfileMembers,
  ProfileDepartments,
  ProfilePaymentAndOrders
} from "./profileContent"

import { EditProfileModal, ChangePasswordModal } from './components'
import MkcCalendar from "@/components/calendar/Calendar"

export default {
  name: 'user-profile',
  components: {
    MkcCalendar,
    ProfileDepartments,
    ProfileBodyCompositionAnalysis,
    ProfileMembers,
    ProfileDetail,
    ProfileHeader,
    ProfilePaymentAndOrders,
    EditProfileModal,
    ChangePasswordModal
  },
  data () {
    return {
      tabIndex: 0,
      profile: {
        name: 'Mark Williams',
        department: 'IT',
        position: 'Full Stack Developer',
        email: 'mar@site.com',
        phone: '+1 (555)752-01-10',
        members: 7
      },
      userId: 0,
      showAlert: false
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('userProfile', ['fetchingUser', 'userProfile']),
    profileButton () {
      switch (this.tabIndex) {
        case 0:
          return 'Edit Profile'
        // case 1:
        //   return 'View/Update Result Sheet'
        default:
          return null
      }
    },
    renderCalendar () {
      return this.tabIndex === 2
    },
    userType () {
      return this.userProfile.userType
    }
  },
  methods: {
    ...mapActions('userProfile', ['getUserProfile']),
    showModal () {
      switch (this.tabIndex) {
        case 0:
          this.$bvModal.show('edit-profile-modal')
          break
        // case 1:
        //   this.$bvModal.show('update-view-body-index-modal')
        //   break
        default:
          console.log('default')
      }
    },
    showChangeTrainerModal () {
      this.$bvModal.show('change-trainer-modal')
    },
    showChangePasswordModal () {
      this.$bvModal.show('change-password-modal')
    },
    handleAlert () {
      this.showAlert = true
    },
    async refreshPage () {
      await this.getUserProfile(this.$route.params.userId)
    }
  },
  async mounted() {
    this.userId = this.$route.params.userId
    await this.getUserProfile(this.$route.params.userId)
  }
}
</script>