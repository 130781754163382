<template>
  <div>
    <page-header
        :title="headerTitle"
        :button="headerButton"
    />
   <div>
     <b-tabs>
       <b-tab title="All Products" active>
         <products-table />
       </b-tab>
       <b-tab title="Publish"></b-tab>
       <b-tab title="Published"></b-tab>
     </b-tabs>
   </div>
  </div>
</template>
<script>
import { PageHeader } from "@/layout";
import { ProductsTable } from "./Components";

export default {
  name: 'products-overview',
  components: {
    ProductsTable,
    PageHeader
  },
  data () {
    return{
      headerTitle: 'Products',
      headerButton: 'Add Product'
    }
  }
}
</script>