<template>
  <div>
    <b-modal
        id="edit-course-modal"
        size="lg"
        :title="titleOfModal"
    >
      <div class="row mb-4">
        <div class="col-sm-3 mb-2 mb-sm-0">
          <div class="d-flex align-items-center mt-2">
            <i class="bi-list-ul nav-icon"></i>
            <div class="flex-grow-1">Course Name</div>
          </div>
        </div>
        <div class="col-sm">
          <b-form-select
              :value="privateTrainingSessionId"
              :options="courseOptions"
              disabled
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
          ></b-form-select>
        </div>
        <div class="row mb-4">
          <div class="col-sm-3 mb-2 mb-sm-0">
            <div class="d-flex align-items-center mt-2">
              <i class="bi-calendar-event nav-icon"></i>
              <div class="flex-grow-1">Status</div>
            </div>
          </div>
          <div class="col-sm">
            <span v-if="eventStatus=== 'completed'" class="badge bg-soft-success text-success rounded-pill position-absolute top-50">Completed</span>
            <span v-else-if="eventStatus === 'cancelled'" class="badge bg-soft-danger text-danger rounded-pill position-absolute top-50">Cancelled</span>
            <span v-else-if="eventStatus === 'scheduled'" class="badge bg-soft-warning text-warning rounded-pill position-absolute top-50">Scheduled</span>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-3 mb-2 mb-sm-0">
          <div class="d-flex align-items-center mt-2">
            <i class="bi-calendar-event nav-icon"></i>
            <div class="flex-grow-1">Date</div>
          </div>
        </div>
        <div class="col-sm">
          <dl class="row">
            <dt class="col-sm-3">From:</dt>
            <dd class="col-sm-9">{{ getDateTime(event.start) }}</dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">End:</dt>
            <dd class="col-sm-9">{{ getDateTime(event.end) }}</dd>
          </dl>
        </div>
      </div>
      <!-- Trainer -->
      <div class="row mb-4">
        <div class="col-sm-3 mb-2 mb-sm-0">
          <div class="d-flex align-items-center mt-2">
            <i class="bi-people nav-icon"></i>
            <div class="flex-grow-1">Trainer</div>
          </div>
        </div>
        <div class="col-sm">
          <b-form-select
              :value="targetTrainer"
              :options="trainersOptions"
              disabled
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              @input="value => handleInput('trainerId', value)"
          ></b-form-select>
        </div>
      </div>
      <!-- Guest -->
      <div class="row mb-4">
        <div class="col-sm-3 mb-2 mb-sm-0">
          <div class="d-flex align-items-center mt-2">
            <i class="bi-people nav-icon"></i>
            <div class="flex-grow-1">Client</div>
          </div>
        </div>
        <div class="col-sm">
          <b-form-select
              :value="targetClient"
              :options="clientOptions"
              disabled
              class="mb-3"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              @input="value => handleInput('clientId', value)"
          />
        </div>
      </div>
      <!-- Course Tags -->
      <div class="row mb-4">
        <div class="col-sm-3 mb-2 mb-sm-0">
          <div class="d-flex align-items-center mt-2">
            <i class="bi-text-left nav-icon"></i>
            <div class="flex-grow-1">Description</div>
          </div>
        </div>
        <div class="col-sm">
          <multi-select
              :value="tags"
              tag-placeholder="Add this as new tag"
              placeholder="Search or add a tag"
              label="name"
              track-by="id"
              :options="courseTagOptions"
              :disabled="disabledByEventAction"
              :multiple="true"
              :taggable="true"
              @input="handleTags"
          >
          </multi-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 mb-2 mb-sm-0">
          <div class="d-flex align-items-center mt-2">
            <i class="bi-person nav-icon"></i>
            <div class="flex-grow-1">Created/Updated by</div>
          </div>
        </div>
        <div class="col-sm">
          <!-- Media -->
          <a class="d-inline-flex align-items-center" href="#">
            <div class="avatar avatar-sm avatar-circle me-3">
              <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Created By">
            </div>
            <div class="flex-grow-1">
              <h5 class="text-inherit mb-0">{{ eventCreatedBy }}</h5>
            </div>
          </a>
          <!-- End Media -->
        </div>
      </div>
      <template #modal-footer>
        <div v-if="eventStatus === 'scheduled'" class="w-100">
          <div class="float-start">
            <b-button
                variant="primary"
                class="mr-2"
                @click="handleMarkedAsDone"
            >
              Marked as Done
            </b-button>
            <b-button
                variant="danger"
                class="btn-danger"
                @click="handleCancelledReservation"
            >
              Cancelled
            </b-button>
          </div>
          <b-button
              variant="out-secondary"
              class="btn-outline-primary float-end ml-2"
              @click="handleCloseModal"
          >
            Close
          </b-button>
          <b-button
              v-if="edit"
              variant="out-secondary"
              class="btn-soft-primary float-end"
              @click="handleSave"
          >
            Save
          </b-button>
        </div>
        <div v-else class="row">
          <b-button
              variant="out-secondary"
              class="btn-outline-primary float-end"
              @click="handleCloseModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'


export default {
  name: 'edit-course-modal',
  props: {
    event: {
      type: Object,
      require: false,
      default: null
    },
    userType: {
      type: String,
      required: false,
      default: null
    },
    profile: {
      type: Object,
      required: false,
      default: null
    },
    tags: {
      type: Array,
      required: false,
      default: null
    },
    targetClient: {
      type: Number,
      required: false,
      default: null
    },
    targetTrainer: {
      type: Number,
      required: false,
      default: null
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    },
    action: {
      type: String,
      required: false,
      default: 'view'
    }
  },
  components () {
  },
  data () {
    return {
      selectedCourse: 1,
      selectedClient: this.targetClient,
      startDate: '',
      selectedTrainer: this.targetTrainer,
      edit: false,
      value: [
        { name: 'Javascript', code: 'js' }
      ],
      options: [
        { name: 'Vue.js', code: 'vu' },
        { name: 'Javascript', code: 'js' },
        { name: 'Open Source', code: 'os' }
      ]
    }
  },
  computed: {
    ...mapState('user', {'loginUser': 'data'}),
    ...mapState('trainer', ['trainers']),
    ...mapState('client', ['clientsForAddingCourses']),
    ...mapState('course', ['privateTrainingSessionId', 'courseTags', 'courseList', 'courseTypes', 'courseGoals', 'bodyParts']),
    titleOfModal () {
      if (this.action === 'create') {
        return 'New Reservation'
      }
      if (this.action === 'view') {
        return 'View Reservation'
      }
      if (this.action === 'edit') {
        return 'Edit Reservation'
      }
      return 'New Reservation'
    },
    eventStatus () {
      if ('extendedProps' in this.event) {
        if (this.event.extendedProps.status === 'completed')
        {
          return 'completed'
        } else if (this.event.extendedProps.status === 'cancelled') {
          return 'cancelled'
        } else {
          return 'scheduled'
        }
      }
      return 'scheduled'
    },
    courseOptions () {
      // Only allow to select private training session right now
      return this.courseList.filter(course => course.name === 'privateTrainingSession').map(course => {
        return { item: course.id, name: this.getCourseName(course.name) }
      })
    },
    courseTagOptions() {
      return this.courseTags.map(tag => {
        return { id: tag.id, name: tag.name[this.locale] }
      })
    },
    clientOptions () {
      if (this.userType === 'client') {
        return [{ item: this.profile.id, name: this.profile.name.first_name + ' ' + this.profile.name.last_name  }]
      }
      return this.clientsForAddingCourses.map(client => {
        return { item: client.id, name: client.name.first_name + ' ' + client.name.last_name }
      })
    },
    trainersOptions () {
      if (this.userType === 'trainer') {
        return [{ item: this.profile.id, name: this.profile.name.first_name + ' ' + this.profile.name.last_name  }]
      }
      return this.trainers.map(trainer => {
        return { item: trainer.id, name: trainer.name.first_name + ' ' + trainer.name.last_name}
      })
    },
    eventCreatedBy () {
      try {
        return this.event.extendedProps.createdBy
      } catch (error) {
        return this.loginUser.first_name + ' ' + this.loginUser.last_name
      }
    },
    disabledByEventAction () {
      return this.action === 'view'
    }
  },
  methods: {
    ...mapActions('course', ['listCourseTags', 'listCourses', 'updatedReservationStatus', 'updateReservationDetailForClient']),
    ...mapActions('trainer', ['getTrainers']),
    ...mapActions('client', ['getClients']),
    getCourseName (name) {
      if (this.$te('course.courseName.' + name)) {
        return this.$t('course.courseName.' + name)
      } else {
        return name
      }
    },
    getDateTime (time) {
      return moment(time).format('llll')
    },
    handleTags (tags) {
      this.handleInput('tags', tags)
    },
    handleInput (name, value) {
      this.edit = true
      this.$emit('input', name, value)
    },
    async handleSave() {
      let saveTags = this.tags.map(tag => { return tag.id })
      let data =  {
        id: this.event.id,
        clientId: this.targetClient,
        trainerId: this.targetTrainer,
        courseId: this.privateTrainingSessionId,
        tags: saveTags,
        startedAt: moment(this.event.start).format(),
        endedAt: moment(this.event.end).format(),
      }
      await this.updateReservationDetailForClient({
        id: this.event.id,
        data: data
      })
      this.edit = false
      let clientName = this.event.extendedProps.clientName
      let message = 'Updated reservation detail with ' + clientName
      this.$bvToast.toast(`${message}`, {
        title: 'Reservation Updated',
        variant: 'info',
        solid: true,
        autoHideDelay: 3000,
      })
      this.handleCloseModal()
    },
    handleCloseModal () {
      this.$bvModal.hide('edit-course-modal')
    },
    async handleMarkedAsDone () {
      await this.updatedReservationStatus({
        id: this.event.id,
        data: {
          status: 'completed'
        }
      })
      this.$emit('input', 'status', 'completed')
      let clientName = this.event.extendedProps.clientName
      let message = 'Marked a reservation with ' + clientName + ' as Done.'
      this.makeToast(message, 'success')
      this.handleCloseModal()
    },
    async handleCancelledReservation () {
      await this.updatedReservationStatus({
        id: this.event.id,
        data: {
          status: 'cancelled'
        }
      })
      this.$emit('input', 'status', 'cancelled')
      let clientName = this.event.extendedProps.clientName
      let message = 'Cancelled a reservation with ' + clientName
      this.makeToast(message, 'danger')
      this.handleCloseModal()
    },
    makeToast(message, variant) {
      this.$bvToast.toast(`${message}`, {
        title: 'Reservation Status Updated',
        variant: variant,
        solid: true,
        autoHideDelay: 3000,
      })
    },
  },
  async mounted() {
    // if (this.courseTags.length === 0) {
    //   await this.listCourseTags()
    // }
    // if (this.courseList.length === 0) {
    //   await this.listCourses()
    // }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>