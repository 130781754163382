<template>
  <b-modal
      id="add-recipe"
      title="Add recipe"
      @ok="submit"
  >
    <label id="addRecipeLabel" class="col-form-label form-label">{{ currentDate }}</label>
    <b-form-textarea
        id="textarea-default"
        placeholder="Input Recipe..."
        v-model="text"
        rows="6"
    ></b-form-textarea>
  </b-modal>
</template>
<script>
import moment from "moment"
export default {
  name: 'add-recipe-modal',
  data () {
    return {
      text: '',
      currentDate: moment().format('MMMM Do YYYY, h:mm:ss a')
    }
  },
  methods: {
    submit () {
      this.$emit('submit', this.text)
    }
  }
}
</script>