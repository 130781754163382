import * as TYPES from './types'

const getMyProfile = state => {
    state.fetching = true
}

const getMyProfileSuccess = (state, data) => {
    state.myProfile = data
    reformatMyProfile(state, data)
    state.fetching = false
}

const reformatMyProfile = (state, data) => {
    let myProfile = {
        department: null,
        address: null,
        phone: null,
        startOfDate: null,
        endOfDate: null,
        jobTitle: null
    }
    data.forEach(field => {
        switch (field.name) {
            case 'gender':
                myProfile.gender = field.value
                break
            case 'dateOfBirth':
                myProfile.dateOfBirth = field.value
                break
            case 'department':
                myProfile.department = field.value
                break
            case 'address':
                myProfile.address = field.value
                break
            case 'phone':
                myProfile.phone = field.value
                break
            case 'startOfDate':
                myProfile.startOfDate = field.value
                break
            case 'endOfDate':
                myProfile.endOfDate = field.value
                break
            case 'jobTitle':
                myProfile.jobTitle = field.value
                break
            case 'weChat':
                myProfile.weChat = field.value
                break
            case 'clientType':
                myProfile.clientType = field.value
                break
        }
    })

    state.myProfileJson = myProfile
}

const getMyProfileFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getUserProfile = (state) => {
    state.fetchingUser = true
}

const getUserProfileSuccess = (state, data) => {
    state.userProfile = data
    state.fetchingUser = false
}

const getUserProfileFailure = (state, error) => {
    state.fetchingUser = false
    console.log(error)
}

const updateUserProfile = (state) => {
    state.fetchingUser = true
}

const updateUserProfileSuccess = (state, data) => {
    state.fetchingUser = false
    state.userProfile = data
}

const updateUserProfileFailure = (state, error) => {
    state.fetchingUser = false
    console.log(error)
}

export default {
    [TYPES.GET_MY_PROFILE.ACTION]: getMyProfile,
    [TYPES.GET_MY_PROFILE.SUCCESS]: getMyProfileSuccess,
    [TYPES.GET_MY_PROFILE.FAILURE]: getMyProfileFailure,
    [TYPES.GET_USER_PROFILE.ACTION]: getUserProfile,
    [TYPES.GET_USER_PROFILE.SUCCESS]: getUserProfileSuccess,
    [TYPES.GET_USER_PROFILE.FAILURE]: getUserProfileFailure,
    [TYPES.UPDATE_USER_PROFILE.ACTION]: updateUserProfile,
    [TYPES.UPDATE_USER_PROFILE.SUCCESS]: updateUserProfileSuccess,
    [TYPES.UPDATE_USER_PROFILE.FAILURE]: updateUserProfileFailure
}