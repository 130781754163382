<template>
  <div>
    <div class="row">
      <!-- Weight -->
      <div class="col-sm-6 col-lg-4 mb-3 mb-lg-5">
        <body-single-data-card
            v-if="weightHistories.length === 1"
            title="Weight"
            :data="weightHistories[0]"
        />
        <stat-card-plus
            v-else-if="weightHistories.length > 1"
            :chart-data="chartWeightData"
            sub-title="Weight"
            :data="{from: 143.9, to: 133.4}"
        />
      </div>
      <div class="col-sm-6 col-lg-4 mb-3 mb-lg-5">
        <!-- SMF -->
        <body-single-data-card
            v-if="smmHistories.length === 1"
            title="SMF"
            :data="pbfHistories[0]"
        />
        <stat-card-plus
            v-else-if="smmHistories.length > 1"
            :chart-data="chartWeightData"
            sub-title="SMF"
            :data="{from: 133.4, to: 143.9}"
        />
      </div>
      <div class="col-sm-6 col-lg-4 mb-3 mb-lg-5">
        <!-- PBF -->
        <body-single-data-card
            v-if="pbfHistories.length === 1"
            title="PBF"
            :data="pbfHistories[0]"
        />
        <stat-card-plus
            v-else-if="pbfHistories.length > 1"
            :chart-data="chartWeightData"
            sub-title="PBF"
            :data="{from: 20, to: 20}"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 mb-3 mb-lg-5">
        <div class="card h-100">
          <div class="card-header card-header-content-between">
            <h4 class="card-header-title mb-2 mb-sm-0">
              Circumference Measurements
            </h4>
            <b-button
                v-if="!fetchingBodyIndexRecords && userBodyIndexRecords.length !== 0"
                variant="out-secondary"
                class="btn-white"
                @click="showUpdateViewBodyIndexModal">View Details</b-button>
          </div>
          <div
              v-if="!fetchingBodyIndexRecords && userBodyIndexRecords.length === 0"
              class="card-body"
          >
            <img class="img-fluid" src="@/assets/svg/empty/oc-thinking.svg" alt="Empty Result"/>
            <p class="card-text text-center">No data to show</p>
            <p class="card-text text-center">Please contact your trainer or administrator to update your profile.</p>
          </div>
          <b-spinner v-else-if="fetchingBodyIndexRecords" type="grow"></b-spinner>
          <div
              v-else
              v-for="field in defaultIndexFields"
              :key="'measurement-'+field.name"
              class="card-body"
          >
            <line-chart
                :chart-data="getBodyIndexRecordHistory(field.name, field.symbol)"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-7 mb-3 mb-lg-5">
        <div class="card h-100">
          <div class="card-header card-header-content-sm-between">
            <h4 class="card-header-title mb-2 mb-sm-0">
              Body Analysis
            </h4>
          </div>
          <div class="card-body">
            <gallery-lightbox
                v-if="visible"
                :Images="inBodyImages"
            />
            <div
                v-else
            >
              <img class="img-fluid" src="@/assets/svg/empty/oc-thinking.svg" alt="Empty Result"/>
              <p class="card-text text-center">No data to show</p>
              <p class="card-text text-center">The function will come soon.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 mb-3 mb-lg-5">
        <course-status :user-id="(userId === 0 || userId === '0') ? user.id : userId" />
      </div>
      <div class="col-lg-6 mb-3 mb-lg-5">
        <profile-recipe
            :user-id="userId === 0 || userId === '0' ? user.id : userId"
        />
      </div>
    </div>
    <update-view-body-index-modal
        v-if="!fetchingBodyIndexRecords && userBodyIndexRecords.length !== 0"
        :columns="companyBodyIndexFieldSettings"
        :records="bodyRecords"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import {
  LineChart,
  GalleryLightbox,
  StatCardPlus,
  BodySingleDataCard
} from '@/layout'
import ProfileRecipe  from './ProfileRecipe'
import { CourseStatus } from './components'
import { UpdateViewBodyIndexModal } from '@/components/users/userProfile/components'
export default {
  name: 'profile-body-composition-analysis',
  components: {
    GalleryLightbox,
    LineChart,
    StatCardPlus,
    ProfileRecipe,
    BodySingleDataCard,
    CourseStatus,
    UpdateViewBodyIndexModal
  },
  props: {
    userId: {
      type: [Number, String],
      required: false,
      default: 0
    }
  },
  data () {
    return {
      visible: false,
      defaultMeasurements: [
          'waistline', 'hips', 'vfl'
      ],
      chartWeightData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            datalabels: {
              display: false
            },
            label: 'Weight(lbs)',
            // backgroundColor: '#f87979',
            data: [143.9, 139.9, 137.6, 136.2, 137.3, 134.3, 133.4],
            "backgroundColor": "transparent",
            "borderColor": "#377dff",
            "borderWidth": 2,
            "pointBackgroundColor": "transparent",
            "pointHoverBackgroundColor": "#377dff",
            "pointBorderColor": "transparent",
            "pointHoverBorderColor": "#377dff",
            "pointRadius": 2,
            "pointHoverRadius": 2,
            "tension": 0
          }
        ]
      },
      inBodyImages: [
        {
          name: 'inBody',
          src: 'https://images.squarespace-cdn.com/content/v1/5b1e92d545776ed2926a3870/1624468736498-4W6QTAHMPJHJNYNJ22N1/INBODY+RESULT+SHEET.png',
          date: '03/05/2022'
        },
        {
          name: 'inBody',
          src: 'https://studio79.ca/wp-content/uploads/2018/05/inbody_results1.jpg',
          date: '04/05/2022'
        },
        {
          name: 'inBody',
          src: 'https://inbodyusa.com/wp-content/uploads/770_RS-1.png',
          date: '05/05/2022'
        }
      ]
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('company', ['companyBodyIndexFieldSettings']),
    ...mapState('user', ['fetchingBodyIndexRecords', 'userBodyIndexRecords']),
    weightHistories () {
      return this.userBodyIndexRecords.map(record => {
        return { value: record.records['weight'], updated_at: moment(record.updated_at).format("MMM Do YY"), symbol: 'kg' }
      })
    },
    smmHistories () {
      return this.userBodyIndexRecords.map(record => {
        return { value: record.records['smm'], updated_at: moment(record.updated_at).format("MMM Do YY"), symbol: 'kg' }
      })
    },
    pbfHistories () {
      return this.userBodyIndexRecords.map(record => {
        return { value: record.records['pbf'], updated_at: moment(record.updated_at).format("MMM Do YY"), symbol: '%' }
      })
    },
    defaultIndexFields () {
      return this.companyBodyIndexFieldSettings.filter(field => this.defaultMeasurements.includes(field.name))
    },
    bodyRecords () {
      return this.userBodyIndexRecords.map(record => { return record.records })
    }
  },
  methods: {
    ...mapActions('company', ['getCompanyBodyIndexFieldSettings']),
    ...mapActions('user', ['listUserBodyIndexRecords']),
    getLabel (name, symbol) {
      if (this.$te(`profile.bodyIndexFieldLabel.${name}`))
      {
        return this.$t(`profile.bodyIndexFieldLabel.${name}`) + ' (' + symbol + ')'
      }
      return name + ' (' + symbol + ')'
    },
    getBodyIndexRecordHistory (index, symbol) {
      let labels = []
      let bodyData = []
      this.userBodyIndexRecords.forEach(record => {
        if (record.records[index]) {
          labels.push(moment(record.updated_at).format("MMM Do YY"))
          bodyData.push(record.records[index])
        }
      })
      return {
        labels: labels,
        datasets: [
          {
            label: this.getLabel(index, symbol),
            borderColor: '#36A2EB',
            backgroundColor: '#9BD0F5',
            pointHoverBackgroundColor: '#ffffff',
            strokeColor: '#15bedb',
            data: bodyData,
            datalabels: {
              display: false
            }
          }
        ],
        options: {
          color: '#ffffff'
        }
      }
    },
    showUpdateViewBodyIndexModal () {
      this.$bvModal.show('update-view-body-index-modal')
    }
  },
  async mounted() {
    if (!this.companyBodyIndexFieldSettings.length) {
      await this.getCompanyBodyIndexFieldSettings()
    }
    let id = this.userId === 0 ? this.user.id : this.userId
    await this.listUserBodyIndexRecords(id)
  }
}
</script>