<template>
  <b-modal
      id="dismissed-modal"
      :title="`Dismiss `+ userType"
      size="lg"
  >
    <div class="row mb-3">
      <div class="col-sm-6">
        <img class="card-img mb-3" src="@/assets/svg/empty/oc-lost.svg" style="max-width: 15rem;" alt="dismissed user"/>
      </div>
      <div class="col-sm-6">
        <h4></h4>
        <p>"Dismiss" means the user's employment has been terminated.</p>
        <p>It's a formal way of ending their relationship with the company.</p>
        <p>Please select a date between the user's start date and end date. The selected period should fall within the range of [ <span class="text-info">{{ userStartOfDate }}</span> ] to [ <span class="text-info">{{ userEndOfDate }}</span> ]</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-4">
          <label for="dismissed-effective-date" class="form-label" id="dismissedEffectiveDateLabel">Effective Date</label>
          <b-form-datepicker
              id="dismissed-effective-date"
              v-model="effectiveDate"
              :state="validateEffectiveDate"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-4">
          <label for="dismissed-reason" class="form-label" id="dismissedReasonLabel"> Dismiss Reason</label>
          <multi-select
             v-model="dismissedReason"
             :options="dismissedReasonOptions"
             :searchable="false"
             :show-labels="false" placeholder="Select an Reason"
             track-by="name"
             label="name"
             :allow-empty="false"
          >
          </multi-select>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <div class="float-end">
          <b-button
              v-if="validateEffectiveDate && dismissedReason !== null"
              variant="primary"
              class="mr-2"
              @click="handleSubmitDismissRequest"
              :disabled="fetching"
          >
            Submit
          </b-button>
          <b-button
              variant="out-secondary"
              class="btn-outline-primary float-end ml-2"
              @click="handleCloseModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import moment from 'moment'
export default {
  name: 'dismissed-modal',
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    userType: {
      type: String,
      required: true,
      default: 'employee'
    },
    userId: {
      type: Number||String,
      required: true,
      default: 0
    },
    userStartOfDate: {
      type: String,
      required: true,
      default: '2020-02-03'
    },
    userEndOfDate: {
      type: String,
      required: true,
      default: '2020-03-03'
    }
  },
  data () {
    return {
      dismissedReason: null,
      dismissedReasonOptionsValue: [
        'termination',
        'layOff',
        'resignation',
        'retirement',
        'completionOfContract',
        'deceased'
      ],
      effectiveDate: null
    }
  },
  computed: {
    dismissedReasonOptions () {
      return this.dismissedReasonOptionsValue.map(reason => {
        return { value: reason, name: this.$t(`profile.dismissedReasonOptions.${reason}`) }
      })
    },
    validateEffectiveDate () {
      return this.effectiveDate !== null &&
          moment(this.userStartOfDate).isBefore(moment(this.effectiveDate)) &&
          moment(this.userEndOfDate).isAfter(moment(this.effectiveDate))
    }
  },
  methods: {
    handleSubmitDismissRequest () {
      let data = {
        reason_type: 'dismissed',
        reason_name: this.dismissedReason.value,
        started_at: this.effectiveDate
      }
      this.$emit('submit', data)
    },
    handleCloseModal () {
      this.$bvModal.hide('dismissed-modal')
    }
  }
}
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="css" scoped>
.form-control.focus {
  background-color: #25282a !important;
}
</style>