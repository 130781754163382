<template>
  <div class="card mb-3 mb-lg-5">
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title">{{ $t('profile.profileInfoCard.title') }}</h4>
      <b-dropdown
          v-if="myProfile"
          dropright
          no-caret
          variant="link"
          toggle-class="text-decoration-none btn-ghost-secondary btn-icon"
          menu-class="navbar-dropdown-menu-borderless"
      >
        <template #button-content>
          <b-button class="btn-white btn-icon btn-sm"><i class="bi-three-dots-vertical"></i></b-button>
        </template>
        <b-dropdown-header>Settings</b-dropdown-header>
        <b-dropdown-item-button
            @click="showUpdateProfileModal"
        >
          <i class="bi bi-slash-circle dropdown-item-icon"></i>Update Profile
        </b-dropdown-item-button>
        <b-dropdown-item-button
            @click="showChangePasswordModal"
        >
          <i class="bi bi-shield-lock dropdown-item-icon"></i>Change Password
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <div class="card-body">
      <ul class="list-unstyled list-py-2 text-dark mb-0">
        <li class="pb-0">
          <span class="card-subtitle">{{ $t('profile.profileInfoCard.subTitle') }}</span>
        </li>
        <li><i class="bi-person dropdown-item-icon"></i>{{ getName }}</li>
        <li><i class="bi-briefcase dropdown-item-icon"></i>{{ getDepartment }}</li>
        <li><i class="bi-building dropdown-item-icon"></i>{{ profile.address }}</li>
        <li class="pt-4 pb-0"><span class="card-subtitle">Contacts</span></li>
        <li><i class="bi-at dropdown-item-icon"></i>{{ getEmail }}</li>
        <li><i class="bi-phone dropdown-item-icon"></i>{{ profile.phone }}</li>
        <li v-if="profile.weChat">
          <i class="bi-grid dropdown-item-icon"></i>
          WeChat: {{ profile.weChat }}
          <img v-if="visible" class="w-100" src="@/assets/img/qr_code.png" alt="QR code">
        </li>
        <li><i class="bi bi-grid dropdown-item-icon"></i>Gender: {{ profile.gender }}</li>
        <li><i class="dropdown-item-icon"></i>Birth: {{ profile.dateOfBirth }}</li>
        <li><i class="dropdown-item-icon"></i>Start Date: {{ profile.startOfDate }}</li>
        <li><i class="dropdown-item-icon"></i>End Date: {{ profile.endOfDate }}</li>
        <!-- Show Trainer Name for Pro Client -->
        <li v-if="profile.userType === 'client' && profile.clientType === 'pro'" class="pt-4 pb-0"><span class="card-subtitle">Trainer</span></li>
        <li v-if="profile.userType === 'client' && profile.clientType === 'pro'"><i class="bi bi-person dropdown-item-icon"></i>{{ profile.reports_to.name }}</li>
        <!-- Show How many client for trainer-->
<!--        <li v-if="profile.userType === 'trainer'" class="pt-4 pb-0"><span class="card-subtitle">Clients</span></li>-->
<!--        <li v-if="profile.userType === 'trainer'" class="fs-6 text-body"><i class="bi-people dropdown-item-icon"></i> You have {{ profile.members }} clients</li>-->
<!--        <li v-if="profile.userType === 'manager' || profile.userType === 'admin'" class="pt-4 pb-0"><span class="card-subtitle">Employees</span></li>-->
<!--        <li v-if="profile.userType === 'manager' || profile.userType === 'admin'" class="fs-6 text-body"><i class="bi-people dropdown-item-icon"></i>You have 10 employees</li>-->
      </ul>
    </div>
    <b-alert
        v-model="showAlert"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="success"
        dismissible
    >
      Reset Password Successfully!
    </b-alert>
    <edit-profile-modal
        v-if="myProfile"
        :profile="profile"
        :user-type="profile.userType"
    />
    <change-password-modal
        v-if="myProfile"
        :user-id="profile.id"
        @message="handleAlert"
    />
    <change-trainer-modal
        v-if="profile.userType === 'client' && profile.clientType === 'pro'"
        :current-trainer="profile.reports_to.id"
        :trainer-name="profile.reports_to.name"
        :client-id="profile.id"
        @refresh="refreshPage"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  ChangePasswordModal,
  ChangeTrainerModal,
  EditProfileModal
} from '@/components/users/userProfile/components'

export default {
  name: 'profile-info-card',
  components: {
    ChangePasswordModal,
    ChangeTrainerModal,
    EditProfileModal
  },
  props: {
    profile: {
      type: Object,
      required: true,
      default: null
    },
    myProfile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      visible: false,
      showAlert: false
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('client', ['clients']),
    ...mapState('userProfile', ['fetching', 'myProfileJson']),
    getName () {
      if (this.profile.first_name) {
        return this.profile.first_name + ' ' + this.profile.last_name
      } else {
        return this.profile.name.first_name + ' '  + this.profile.name.last_name
      }
    },
    getEmail () {
     return this.profile.email ?? this.profile.name.email
    },
    getDepartment () {
      if (this.profile.department) {
        return this.profile.department
      } else {
        return 'no department'
      }
    }
  },
  methods: {
    showUpdateProfileModal () {
      this.$bvModal.show('edit-profile-modal')
    },
    showChangePasswordModal () {
      this.$bvModal.show('change-password-modal')
    },
    handleAlert () {
      this.showAlert = true
    },
    async refreshPage () {
      await this.$emit('refresh')
    }
  }
}
</script>