<template>
  <div>
    <b-alert
        v-model="showAlert"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="warning"
        dismissible
    >
      {{ alertInfo }}
    </b-alert>
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <div class="card">
      <div class="overflow-auto">
        <mkc-table
            :fetching="fetching"
            :per-page="perPage"
            :table-columns="tableColumns"
            :table-cells="employees"
            :menu-type="`employeeDashboardForAdmin`"
            @table-button-click="handleActions"
        />
      </div>
    </div>
    <delete-data-modal
        :title="deleteEmployeeModal.title"
        :data="deleteEmployeeModal.data"
        :description="deleteEmployeeModal.description"
        @handle-delete-user="handleDeleteEmployee"
    />
    <active-modal
        :fetching="fetchingReason"
        :title="userStatusModalData.title"
        :user-name="userStatusModalData.userName"
        :user-type="userStatusModalData.userType"
        :user-id="userStatusModalData.userId"
        @submit="handleActivateUser"
    />
    <dismissed-modal
        :fetching="fetchingReason"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        @submit="handleDismissUser"
    />
    <on-leave-modal
        :fetching="fetchingReason"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        @submit="handleOnLeaveUser"
    />
    <modify-on-leave-modal
        :fetching="fetchingReason"
        :on-leave-reason="userReasonData.reasonName"
        :return-date="userReasonData.returnDate"
        :effective-date="userReasonData.effectiveDate"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        @input="handleUpdateReason"
        @submit="handleModifyUserReason('onLeave')"
    />
    <modify-dismiss-modal
        :fetching="fetchingReason"
        :dismiss-reason="userReasonData.reasonName"
        :effective-date="userReasonData.effectiveDate"
        :user-start-of-date="userStatusModalData.startOfDate"
        :user-end-of-date="userStatusModalData.endOfDate"
        :user-id="userStatusModalData.userId"
        :user-type="userStatusModalData.userType"
        @input="handleUpdateReason"
        @submit="handleModifyUserReason('dismissed')"
    />
    <user-reason-existed-modal />
    <failed-active-user-modal />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import {
  PageHeader,
  MkcTable,
  DeleteDataModal,
  ActiveModal,
  DismissedModal,
  OnLeaveModal,
  UserReasonExistedModal,
  ModifyOnLeaveModal,
  ModifyDismissModal,
  FailedActiveUserModal
} from '@/layout'
import moment from "moment";
export default {
  name: 'admin-board',
  components: {
    DeleteDataModal,
    PageHeader,
    MkcTable,
    ActiveModal,
    DismissedModal,
    OnLeaveModal,
    UserReasonExistedModal,
    ModifyOnLeaveModal,
    ModifyDismissModal,
    FailedActiveUserModal
  },
  data () {
    return {
      headerTitle: 'Employees',
      headerItems: [
        {
          text: 'Employees',
          href: '#'
        },
        {
          text: 'Admin Board',
          active: true
        }
      ],
      perPage: 10,
      currentPage: 1,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'name', label: 'Full Name', sortable: true},
        { key: 'position', label: 'Position', sortable: true},
        { key: 'status', label: 'Status', sortable: true},
        { key: 'days', label: 'Active days', sortable: true},
        { key: 'role', label: 'Role', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false}
      ],
      tableCells: [
        { id: 1, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, position: {jobTitle: 'Director', department: 'Human resources'}, status: 'active', days: 0, role: 'admin', edit: true},
      ],
      deleteEmployeeModal: {
        title: 'Delete Employee',
        data: 'Employee data',
        description: "You will lost the employee data forever"
      },
      selectedEmployee: null,
      userStatusModalData: {
        title: 'Withdraw OnLeave',
        userId: 0,
        userType: 'employee',
        userName: 'Employee Name',
        startOfDate: '2020-02-03',
        endOfDate: '2020-03-03'
      },
      userReasonData: {
        id: null,
        effectiveDate: '',
        returnDate: '',
        reasonName: {}
      },
      alertInfo: null,
      showAlert: false
    }
  },
  computed: {
    ...mapState('employee', ['fetching', 'employees']),
    ...mapState('user', ['fetchingReason', 'userReason']),
    ...mapState('user', { usingAppAs: 'useAppAs' })
  },
  methods: {
    ...mapActions('employee', ['getEmployees', 'deleteEmployee']),
    ...mapActions('user', ['createUserReason', 'modifyUserReason', 'activateUser']),
    handleActions({row, action}) {
      this.selectedEmployee = row
      this.userStatusModalData.userId = row.id
      this.userStatusModalData.startOfDate = row.startOfDate
      this.userStatusModalData.endOfDate = row.endOfDate
      switch (action) {
        case 'delete':
          this.deleteEmployeeModal.data = row.name.first_name  + ' ' + row.name.last_name
          this.$bvModal.show('delete-data-modal')
          break
        case 'edit':
        case 'view':
          this.$router.push({name: 'user-profile', params: {userId: this.selectedEmployee.id, userType: 'employee'}})
          break
        case 'dismiss':
          this.$bvModal.show('dismissed-modal')
          break
        case 'onLeave':
          this.$bvModal.show('on-leave-modal')
          break
        case 'withdrawOnLeave':
          this.userStatusModalData.title = 'withdraw OnLeave'
          this.userReasonData.id = row.reasons.id
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.checkIfCanWithdrawUser()
          break
        case 'withdrawDismissed':
          this.userStatusModalData.title = 'withdraw Dismissed'
          this.userReasonData.id = row.reasons.id
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.checkIfCanWithdrawUser()
          break
        case 'withdrawPending':
          this.userStatusModalData.title = 'Withdraw Pending'
          this.userStatusModalData.userName = row.name.first_name  + ' ' + row.name.last_name
          this.$bvModal.show('active-user-modal')
          break
        case 'modifyOnLeave':
          this.userReasonData.id = row.reasons.id
          this.userReasonData.reasonName = {value: row.reasons.reason_name, name: this.$t(`profile.onLeaveReasonOptions.${row.reasons.reason_name}`) }
          this.userReasonData.effectiveDate = row.reasons.started_at
          this.userReasonData.returnDate = row.reasons.ended_at
          this.$bvModal.show('modify-on-leave-modal')
          break
        case 'modifyDismissed':
          this.userReasonData.id = row.reasons.id
          this.userReasonData.reasonName = {value: row.reasons.reason_name, name: this.$t(`profile.dismissedReasonOptions.${row.reasons.reason_name}`) }
          this.userReasonData.effectiveDate = row.reasons.started_at
          this.$bvModal.show('modify-dismiss-modal')
          break
      }
    },
    async handleDeleteEmployee () {
      await this.deleteEmployee(this.selectedEmployee.id)
      await this.getEmployees()
    },
    checkIfCanWithdrawUser () {
      let now = moment.now()
      if (!moment(this.userStatusModalData.endOfDate).isBefore(now)) {
        this.$bvModal.show('failed-active-user-modal')
      } else {
        this.$bvModal.show('active-user-modal')
      }
    },
    async handleActivateUser () {
      await this.activateUser( { id: this.selectedEmployee.id, data: { id: this.userReasonData.id }})
      this.alertInfo = 'Activate ' + this.selectedEmployee.name.first_name + ' ' + this.selectedTrainer.last_name+ ' Successfully !'
      this.showAlert = true
      this.$bvModal.hide('active-user-modal')
    },
    async handleDismissUser (data) {
      await this.createUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('dismissed-modal')
      if ('error' in this.userReason) {
        this.handleError(this.userReason.error)
      } else {
        this.alertInfo = 'Submit User Dismiss Request Successfully !'
        this.showAlert = true
      }
    },
    async handleOnLeaveUser (data) {
      await this.createUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('on-leave-modal')
      if ('error' in this.userReason) {
        this.handleError(this.userReason.error)
      } else {
        this.alertInfo = 'Submit User On Leave Request Successfully !'
        this.showAlert = true
      }
    },
    handleUpdateReason (name, value) {
      switch (name) {
        case 'effectiveDate':
          this.userReasonData.effectiveDate = value
          break
        case 'returnDate':
          this.userReasonData.returnDate = value
          break
        case 'reasonName':
          this.userReasonData.reasonName = value
          break
      }
    },
    async handleModifyUserReason (reasonType) {
      let data = {
        id: this.userReasonData.id,
        reason_type: reasonType,
        reason_name: this.userReasonData.reasonName.name,
        started_at: this.userReasonData.effectiveDate
      }
      if (reasonType === 'onLeave') {
        data['ended_at'] = this.userReasonData.returnDate
      }
      await this.modifyUserReason({ id: this.userStatusModalData.userId, data: data })
      this.$bvModal.hide('modify-on-leave-modal')
      this.$bvModal.hide('modify-dismiss-modal')
    },
    handleError(error) {
      switch (error) {
        case 'REASON_EXISTED':
          this.$bvModal.show('user-reason-existed-modal')
          break
        default:
          this.alertInfo = 'Something Wrong Happened, Please contact IT team. Thanks !'
          this.showAlert = true
      }
    }
  },
  async mounted () {
    await this.getEmployees()
  }

}
</script>