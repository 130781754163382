import { apiTypeHelper } from '@/base/network'

export const LIST_CLIENTS = apiTypeHelper('LIST_CLIENTS')
export const GET_CLIENTS_COUNT_SUMMARY = apiTypeHelper('GET_CLIENTS_COUNT_SUMMARY')
export const GET_CLIENT_TYPES_SUMMARY = apiTypeHelper('GET_CLIENT_TYPES_SUMMARY')
export const GET_CLIENT_PROFILE = apiTypeHelper('GET_CLIENT_PROFILE')
export const DELETE_CLIENT = apiTypeHelper('DELETE_CLIENT')
export const ADD_CLIENT = apiTypeHelper('ADD_CLIENT')

// list, create, update, delete recipe
export const LIST_RECIPES = apiTypeHelper('LIST_RECIPES')
export const CREATE_RECIPE = apiTypeHelper('CREATE_RECIPE')
export const UPDATE_RECIPE = apiTypeHelper('UPDATE_RECIPE')
export const DELETE_RECIPE = apiTypeHelper('DELETE_RECIPE')

export const UPDATE_TRAINER = apiTypeHelper('UPDATE_TRAINER')