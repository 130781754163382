import * as TYPES from './types'

const setAlert = (state, { type, message }) => {
    state.type = type
    state.message = message
    state.visible = true
}

const dismissAlert = state => {
    state.visible = false
}

const setScreenSize = (state, size) => {
    if (size <= 576) {
        state.sm = true
        state.md = false
        state.lg = false
        state.xl = false
    } else if (size <= 768) {
        state.sm = false
        state.md = true
        state.lg = false
        state.xl = false
    } else if (size <= 992) {
        state.sm = false
        state.md = false
        state.lg = true
        state.xl = false
    } else {
        state.sm = false
        state.md = false
        state.lg = false
        state.xl = true
    }
}

const setNavBarExpanded = (state) => {
    state.navbarExpanded = !state.navbarExpanded
}

const setNavNarExpandedMobile = (state) => {
    state.navbarExpandedMobile = !state.navbarExpandedMobile
}

export default {
    [TYPES.SET_ALERT]: setAlert,
    [TYPES.DISMISS_ALERT]: dismissAlert,
    [TYPES.SET_SCREEN_SIZE]: setScreenSize,
    [TYPES.SET_NAVBAR_EXPANDED]: setNavBarExpanded,
    [TYPES.SET_NAVBAR_EXPANDED_MOBILE]: setNavNarExpandedMobile
}