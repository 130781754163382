<template>
  <div class="col">
    <h3 class="mb-5"> 5 departments</h3>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
      <div v-for="department in departments" :key="`department-`+department.id"
           class="col mb-3 mb-lg-5">
        <department-card :department="department" />
      </div>
    </div>
  </div>
</template>
<script>
import { DepartmentCard } from "@/layout";
export default {
  name: 'profile-departments',
  components: { DepartmentCard },
  data () {
    return {
      departments: [
        {
          id: 1,
          name: 'IT',
          description: 'Focusing on innovative and disruptive business models',
          members: [
            'CS', 'RR', 'LH'
          ]
        }
      ]
    }
  }
}
</script>