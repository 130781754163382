<template>
  <b-alert
      :show="showAlert"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 20000;"
      variant="danger"
      dismissible
  >
    Something Wrong Happened, Please contact IT team. Thanks !
  </b-alert>
</template>
<script>
export default {
  name: 'something-wrong-alert',
  props: {
    showAlert: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>