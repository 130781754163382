<template>
  <b-form class="edit-profile-content">
    <profile-header />
    <b-row class="mb-4">
      <label for="editFirstNameModalLabel" class="col-sm-3 col-form-label form-label">Full name</label>
      <b-col sm="9">
        <b-input-group
            class="input-group-sm-vertical"
        >
          <b-form-input
              id="editFirstNameModalLabel"
              placeholder="First Name"
              :value="'name' in profile ? profile.name.first_name : profile.first_name"
              @input="value => handleInput('first_name', value)"
          ></b-form-input>
          <b-form-input
              id="editLastNameModalLabel"
              placeholder="Last Name"
              :value="'name' in profile ? profile.name.last_name : profile.last_name"
              @input="value => handleInput('last_name', value)"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editEmailModalLabel" class="col-sm-3 col-form-label form-label">Email</label>
      <b-col sm="9">
        <b-form-input
            id="editEmailModalLabel"
            placeholder="Email"
            type="email"
            :value="'name' in profile ? profile.name.email : profile.email"
            @input="value => handleInput('email', value)"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editPhoneLabel" class="col-sm-3 col-form-label form-label">Phone</label>
      <b-col sm="9">
        <b-form-input
            id="editPhoneLabel"
            type="number"
            placeholder="+x(xxx)xxx-xx-xx"
            :value="profile.phone"
            @input="value => handleInput('phone', value)"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editDateOfBirth" class="col-sm-3 col-form-label form-label">Date of Birth</label>
      <b-col sm="9">
        <b-form-datepicker
            id="editDateOfBirth"
            class="mb-2"
            :value="profile.dateOfBirth"
            @input="value => handleInput('dateOfBirth', value)"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editGenderLabel" class="col-sm-3 col-form-label form-label">Gender</label>
      <b-col sm="9">
        <b-form-select
            id="editGenderLabel"
            class="form-select p-1"
            :value="profile.gender"
            :options="genderOptions"
            @input="value => handleInput('gender', value)"
        />
      </b-col>
    </b-row>
    <b-row
        v-if="userType === 'employee'"
        class="mb-4"
    >
      <label for="editJobTitle" class="col-sm-3 col-form-label form-label">Job Title</label>
      <b-col sm="9">
        <b-form-input
            id="editJobTitle"
            :value="profile.jobTitle"
            @input="value => handleInput('jobTitle', value)"
        />
      </b-col>
    </b-row>
    <b-row
        v-if="userType === 'employee'"
        class="mb-4"
    >
      <label for="editDepartment" class="col-sm-3 col-form-label form-label">Department</label>
      <b-col sm="9">
        <b-form-select
            :value="profile.department"
            :options="departmentOptions"
            @input="value => handleInput('department', value)"
        />
      </b-col>
    </b-row>
    <b-row
        v-if="['trainer', 'admin'].includes(usingAppAs[0])"
        class="mb-4"
    >
      <label for="editStartOfDate" class="col-sm-3 col-form-label form-label">Start Of Date</label>
      <b-col sm="9">
        <b-form-datepicker
            :value="profile.startOfDate"
            @input="value => handleInput('startOfDate', value)"
        />
      </b-col>
    </b-row>
    <b-row
        v-if="visible"
        class="mb-4"
    >
      <label for="editUserStatus" class="col-sm-3 col-form-label form-label">Status</label>
      <b-col sm="9">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              id="radio-group-1"
              v-model="selectedStatus"
              :options="statusOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import { ProfileHeader } from '@/layout'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'edit-profile-content',
  components: {
    ProfileHeader
  },
  props: {
    profile: {
      type: Object,
      required: false,
      default: null
    },
    userType: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      visible: false,
      selectedStatus: 'active',
      statusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'dismissed', text: 'Dismissed' },
        { value: 'suspended', text: 'Suspended' },
      ]
    }
  },
  computed: {
    ...mapState('company', ['companySettings']),
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    genderOptions () {
      if (this.companySettings === null) {
        return null
      }
      return this.companySettings.find(field => field.name === 'gender')
          .options.map(option => { return {value: option ,text: this.$t(`profile.gender.${option}`) }})
    },
    departmentOptions () {
      if (this.companySettings === null) {
        return null
      }
      if (this.form.role === 'admin') {
        return [{ value: 'admin', text: 'Admin' }]
      }
      return this.companySettings.find(field => field.name === 'department')
          .options.map(option => { return {value: option, text: this.$t(`profile.department.${option}`)} })
    },
  },
  methods: {
    ...mapActions('company', ['getCompanySettings']),
    handleInput(name, value) {
      if (name === 'startOfDate') {
        let newStartOfDate = moment(value).format()
        let startOfDate = moment(this.profile.startOfDate).format()
        let endOfDate = moment(this.profile.endOfDate).format()
        let diff = moment(newStartOfDate).diff(startOfDate, 'days')
        let newEndOfDate = null
        if (diff < 0 ) {
          newEndOfDate = moment(endOfDate).subtract(diff * -1, 'days')
        } else {
          newEndOfDate = moment(endOfDate).add(diff, 'days').format('ll')
        }
        this.$emit('input', 'endOfDate', newEndOfDate)
      }
      this.$emit('input', name, value)
    }
  },
  async mounted() {
    if (this.companySettings === null) {
      await this.getCompanySettings()
    }
  }
}
</script>
<style lang="css" scoped>
.edit-profile-content {
  .b-form-datepicker {
    background-color: #25282a;
  }
}
</style>