<template>
  <b-modal
      id="active-user-modal"
      :title="title"
  >
    <div class="card h-100 text-center">
      <div class="card-body">
        <img class="card-img" src="@/assets/svg/illustrations/oc-hi-five.svg" style="max-width: 15rem;" alt="activate user"/>
      </div>
      <div class="card-footer">
        <h3>Are u sure u wanna withDraw <span class="text-warning">{{ $t(`profile.userType.${userType}`) }} - {{ userName }}</span> ?</h3>
        <p> The {{ $t(`profile.userType.${userType}`) }} will be active immediately.</p>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <div class="float-end">
          <b-button
              variant="primary"
              class="mr-2"
              :disabled="fetching"
              @click="handleActivateUser"
          >
            WithDraw {{ $t(`profile.userType.${userType}`) }}
          </b-button>
          <b-button
              variant="out-secondary"
              class="btn-outline-primary "
              @click="handleCloseModal"
          >
            Close
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'active-user-modal',
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: 'Withdraw OnLeave'
    },
    userId: {
      type: Number||String,
      required: true,
      default: 0
    },
    userType: {
      type: String,
      required: true,
      default: 'client'
    },
    userName: {
      type: String,
      required: true,
      default: 'User name'
    }
  },
  methods: {
    handleCloseModal () {
      this.$bvModal.hide('active-user-modal')
    },
    handleActivateUser () {
      this.$emit('submit')
    }
  }
}
</script>