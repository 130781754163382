<template>
  <div class="card card-body mb-3 mb-lg-5">
    <div class="d-flex">
      <div class="flex-grow-1">
        <h6 class="card-subtitle mb-3">Account Balance</h6>
        <h3 class="card-title">$1,000</h3>

        <div class="d-flex align-items-center">
          <span class="d-block fs-6">10 orders</span>
        </div>
      </div>

      <span class="icon icon-soft-secondary icon-sm icon-circle ms-3">
        <i class="bi-shop"></i>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'account-balance'
}
</script>