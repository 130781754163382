<template>
  <b-modal
      id="delete-recipe-modal"
      @ok="submit"
  >
    <template #modal-title>
      <span class="text-danger">Delete the Recipe ?</span>
    </template>
    <h5 class="text-warning">You will lose this Recipe Data</h5>
    <b-form-textarea
        id="textarea-default"
        :value="description"
        rows="6"
        disabled
    />
    <div class="text-end">
      -- Author {{ author }}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'delete-recipe-modal',
  props: {
    description: {
      type: String,
      required: false,
      default: 'recipe description'
    },
    author: {
      type: String,
      required: false,
      default: 'recipe description'
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
</script>