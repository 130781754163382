import { TrainerEntity } from './api'

export const getTrainers = async ({commit}) => {
    await TrainerEntity.getTrainer(commit)
}

export const addTrainer = async ({commit}, data) => {
    await TrainerEntity.addTrainer(commit, data)
}

export const deleteTrainer = async ({commit}, id) => {
    await TrainerEntity.deleteTrainer(commit, id)
}

export const getTrainerProfile = async ({commit}, id) => {
    await TrainerEntity.getTrainerProfile(commit, id)
}

export default {
    getTrainers,
    addTrainer,
    deleteTrainer,
    getTrainerProfile
}
