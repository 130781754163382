<template>
  <div>
    <div class="profile-cover">
      <div class="profile-cover-img-wrapper">
        <img class="profile-cover-img" src="@/assets/img/1920x400/img1.jpg" alt="Image Description">
        <div class="profile-cover-content profile-cover-uploader p-3">
          <input id="editProfileCoverUploaderModal" type="file" class="profile-cover-uploader-input">
          <label class="profile-cover-uploader-label btn btn-sm btn-white" for="editProfileCoverUploaderModal">
            <i class="bi-camera-fill"></i>
            <span class="d-none d-sm-inline-block ms-1">Upload header</span>
          </label>
        </div>
      </div>
    </div>
    <div class="text-center mb-5">
      <div class="avatar avatar-xxl avatar-circle profile-cover-avatar">
        <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Image Description">
        <span class="avatar-status avatar-status-success"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'profile-header'
}
</script>