import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth'

Vue.use(Vuex)

export const authStore = {
    modules: {
        auth
    }
}

export default authStore