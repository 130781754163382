import axios from 'axios'
import { isEmpty, compact } from 'lodash'

export class NetworkEntity {
    constructor ({ endpoint, moduleName }) {
        // super();
        this.endpoint = endpoint
        this.moduleName = moduleName
    }

    queryStringify (paramsObj) {
        const params = []

        for (var param in paramsObj) {
            const value =
                paramsObj[param] instanceof Array
            ? compact(paramsObj[param])
                    : paramsObj[param]
            const isNotEmptyCollection = !isEmpty(value) || typeof value === 'boolean'
            const isNotEmptyPrimitive = value && value.toString().length > 0

            if (isNotEmptyPrimitive || isNotEmptyCollection) {
                params.push(`${param}=${value}`)
            }
        }
        return params.length >0 ? `?${params.join('&')}` : ''
    }

    queryStringifyArray (paramsObj, arrayName) {
        const params = []

        for (const type in paramsObj) {
            const value = paramsObj[type].join(',')
            params.push(`${arrayName}[${type}]=${value}`)
        }
        return params.length > 0 ? `&${params.join('&')}` : ''
    }

    get (commit, id) {
        commit(`GET_${this.moduleName}.ACTION`)
        return axios
            .get(`${this.endpoint}/${id}`)
            .then(({ data: { data } }) => {
                commit(`GET_${this.moduleName}.SUCCESS`, data)
                return data
            })
            .catch(err => {
                commit(`GET_${this.moduleName}.FAILURE`, err)
                throw err
            })
    }

    post (commit, body) {
        commit(`POST_${this.moduleName}.ACTION`)
        return axios
            .post(`${this.endpoint}`, body)
            .then(({ data: { data } }) => {
                commit(`POST_${this.moduleName}.SUCCESS`, data)
                return data
            })
            .catch(err => {
                commit(`POST_${this.moduleName}.FAILURE`, err)
                throw err
            })
    }

    put (commit, { id, body }) {
        commit(`PUT_${this.moduleName}.ACTION`)
        return axios
            .put(`${this.endpoint}/${id}`, body)
            .then(({ data: { data } }) => {
                commit(`PUT_${this.moduleName}.SUCCESS`, data)
            })
            .catch(err => {
                commit(`PUT_${this.moduleName}.FAILURE`, err)
                throw err
            })
    }

    patch (commit, { id, body }) {
        commit(`PATCH_${this.moduleName}.ACTION`)
        return axios
            .patch(`${this.endpoint}/${id}`, body)
            .then(({ data: { data } }) => {
                commit(`PATCH_${this.moduleName}.SUCCESS`, data)
            })
            .catch(err => {
                commit(`PATCH_${this.moduleName}.FAILURE`, err)
                throw err
            })
    }
    delete (commit, { id, body }) {
        commit(`DELETE_${this.moduleName}.ACTION`)
        return axios
            .delete(`${this.endpoint}/${id}`, body)
            .then(({ data: { data } }) => {
                commit(`DELETE_${this.moduleName}.SUCCESS`, data)
            })
            .catch(err => {
                commit(`DELETE_${this.moduleName}.FAILURE`, err)
                throw err
            })
    }
}
export default NetworkEntity