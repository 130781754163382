import { render, staticRenderFns } from "./StatCardPlus.vue?vue&type=template&id=6ab31531"
import script from "./StatCardPlus.vue?vue&type=script&lang=js"
export * from "./StatCardPlus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports