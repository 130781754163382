<template>
  <div class="card">
    <div class="card-header card-header-content-md-between">
      <h4 v-if="title !== null" class="card-header-title">{{ title }}</h4>
      <div class="mb-2 mb-md-0">
        <b-form-group>
          <b-input-group
              class="input-group-merge input-group-flush"
          >
            <div class="input-group-prepend input-group-text">
              <i class="bi-search"></i>
            </div>
            <b-form-input
                v-model="filter"
                class=""
                type="search"
                placeholder="Search Users"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <div class="d-grid d-sm-flex gap-2">
        <b-dropdown v-if="hasFilter">
          <b-dropdown-item>First Action</b-dropdown-item>
        </b-dropdown>
        <b-dropdown
            v-if="hasColumns"
            variant="outline-dark"
            no-caret
            right
            menu-class="dropdown-menu-end dropdown-card dropdown-card-width"
        >
          <template #button-content>
            <i class="bi-table me-1"></i>Columns
          </template>
          <div class="card card-sm">
            <div class="card-body">
              <div class="d-grid gap-3">
                <b-form-checkbox
                    v-for="column in filterColumns"
                    :key="column.key"
                    v-model="column.checked"
                    switch
                    size="md"
                >
                   {{ column.label }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
          v-if="!fetching"
          show-empty
          small
          id="my-table"
          :fields="visibleColumns"
          :items="tableCells"
          head-variant="dark"
          sort-icon-left
          borderless
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          class="table-align-middle"
      >
        <template #head()="props">
          <span>{{ props.label.toUpperCase() }}</span>
        </template>
        <template #cell(index)="props">
          <div class="pl-3">
            {{ props.index + 1 }}
          </div>
        </template>
        <!-- A custom formatted column -->
        <template #cell(name)="props">
          <div class="d-flex align-items-center">
            <div class="avatar avatar-circle">
              <img class="avatar-img" src="../assets/img/160x160/img1.jpg">
            </div>
            <div class="ms-3">
            <span class="d-block h5 text-inherit mb-0">
              {{ props.value.first_name.toUpperCase() +' '+props.value.last_name.toUpperCase() }}
            </span>
              <span class="d-block fs-5 text-body">{{ props.value.email }}</span>
            </div>
          </div>
        </template>

        <!--      &lt;!&ndash; A virtual composite column &ndash;&gt;-->
        <!--      <template #cell(nameage)="data">-->
        <!--        {{ data.item.name.first }} is {{ data.item.age }} years old-->
        <!--      </template>-->
        <template #cell(position)="props">
          <span class="d-block h5 mb-0">{{ props.value.jobTitle }}</span>
          <span class="d-block fs-5">{{ props.value.department }}</span>
        </template>

        <template #cell(data)="props">
          <span class="d-block h5 mb-0">{{ props.value.height }} cm</span>
          <span class="d-block fs-5">{{ props.value.weight }} kg</span>
        </template>

        <template #cell(goal)="props">
          {{ $te('profile.goal.' + props.value) ? $t(`profile.goal.` + props.value) : props.value }}
        </template>

        <template #cell(clientType)="props">
          {{ $te('profile.clientType.' + props.value) ? $t(`profile.clientType.` + props.value) : props.value }}
        </template>

        <template #cell(status)="props">
          <span :class="getStatusIcon(props.value)"></span>{{ $t('general.status.' + props.value) }}
        </template>

        <template #cell(actions)="row">
          <contextual-menu
              :row="row.item"
              :action-variant="row.item.status"
              :menu-type="menuType"
              @contextual-menu-click="handleButtonClick"
          />
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="props">
          <i>{{ props.value }}</i>
        </template>
      </b-table>
      <b-spinner
          v-else
          variant="primary"
          class="align-middle"
      ></b-spinner>
    </div>

    <div class="card-footer">
      <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
          <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
            <p>Current Page: {{ currentPage }}</p>
          </div>
        </div>
        <div class="col-sm-auto">
          <div class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ContextualMenu } from '@/layout/index'

export default {
  name: 'mkc-table',
  components: {
    ContextualMenu
  },
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    hasColumns: {
      type: Boolean,
      required: false,
      default: false
    },
    perPage: {
      type: Number,
      required: false,
      default: 4
    },
    tableColumns: {
      type: Array,
      required: true,
      default: null
    },
    tableCells: {
      type: Array,
      required: true,
      default: null
    },
    menuType: {
      type: String,
      required: false,
      default: 'clientDashboard'
    }
  },
  data () {
    return {
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      filter: null,
    }
  },
  computed: {
    rows () {
      return this.tableCells.length
    },
    visibleColumns () {
      if (!this.hasColumns) {
        return this.tableColumns
      }
      return this.tableColumns.filter(column => column.checked === true)
    },
    filterColumns () {
      return this.tableColumns.filter(column => column.key !== 'actions')
    }
  },
  methods: {
    getStatusIcon (status) {
      switch (status) {
        case 'active':
          return 'legend-indicator bg-success'
        case 'pending':
        case 'onLeave':
          return 'legend-indicator bg-warning'
        case 'dismissed':
          return 'legend-indicator bg-danger'
        default:
          return 'legend-indicator bg-success'
      }
    },
    handleButtonClick (row, action) {
      this.$emit('table-button-click', {row, action})
    }
  }
}
</script>
<style>
.dropdown-card-width {
  width: 15rem;
}
</style>