<template>
  <div class="mx-auto" style="max-width: 30rem;">
    <div class="card card-lg mb-5">
      <div class="card-body">
        <b-form>
          <div class="text-center">
            <div class="mb-5">
              <h1 class="display-5">{{ $t('profile.signIn.title') }}</h1>
            </div>
          </div>
          <div class="mb-4">
            <label for="email" class="col-form-label form-label">{{$t('profile.signIn.email') }}</label>
            <b-form-group>
              <b-form-input
                  id="email"
                  type="email"
                  :state="isValidEmail"
                  v-model="form.email"
                  placeholder="Enter email"
                  required
                  aria-describedby="input-email-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-email-feedback">
                {{ $t('profile.signIn.invalidEmail') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="mb-4">
            <label for="password" class="col-form-label form-label">{{ $t('profile.signIn.password') }}</label>
            <b-form-group
                class="input-group-merge"
            >
              <b-form-input
                  id="password"
                  v-model="form.password"
                  :type="showPassword ?'text':'password'"
                  placeholder="8+ characters required"
                  required
              >
              </b-form-input>
              <div class="input-group-append input-group-text">
                <b-button variant="link" class="p-0" @click="showHidePwIcon"><i :class="showPassword?'bi-eye':'bi-eye-slash'"></i></b-button>
              </div>
            </b-form-group>
            <b-form-radio
                class="mb-4 mr-sm-2"
                @click="toggleRememberMe"
            >
              {{ $t('profile.signIn.rememberMe') }}
            </b-form-radio>
            <b-button
                :disabled="disableSubmit"
                block
                variant="primary"
                class="text-center"
                @click="signIn"
            >
              <b-spinner v-if="submitting" small type="grow"></b-spinner> {{ $t('profile.signIn.submitButton') }}
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'sign-in',
  components: {
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
      },
      rememberMe: true,
      showPassword: false,
      submitting: false
    }
  },
  computed: {
    disableSubmit () {
      return !this.isValidEmail && !this.isValidPassword
    },
    isValidEmail () {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    },
    isValidPassword () {
      return this.form.password.length > 8
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('alert', ['setAlert', 'dismissAlert']),
    showHidePwIcon () {
      this.showPassword = !this.showPassword
    },
    toggleRememberMe () {
      this.rememberMe = !this.rememberMe
      localStorage.setItem('rememberMe', JSON.stringify(this.rememberMe))
      if (!this.rememberMe) {
        // If rememberMe is turned off, clear the stored username and password
        localStorage.removeItem('email')
        localStorage.removeItem('password')
      } else {
        // If rememberMe is turned on, store the username and password
        localStorage.setItem('email', this.form.email)
        localStorage.setItem('password', this.form.password)
      }
    },
    async signIn () {
      this.submitting = true
      try {
        await this.login({
          ...this.form
        })
      } catch (e) {
        if (e.message === 'NO_PERMISSION') {
          // Session does not have permission to login
          this.setAlert({
            type: 'danger',
            message: 'Session does not have permission to login'
          })
        } else {
          this.setAlert({
            type: 'danger',
            message: this.$t('profile.signIn.invalidCredential')
          })
        }
      }
      this.submitting = false
    }
  },
  created() {
    // Check if there's a stored rememberMe value in localStorage
    const rememberMe = localStorage.getItem('rememberMe')
    if (rememberMe) {
      this.rememberMe = JSON.parse(rememberMe)
      if (this.rememberMe) {
        // If rememberMe is true, retrieve and set the stored username and password
        const storedUsername = localStorage.getItem('email')
        const storedPassword = localStorage.getItem('password')
        if (storedUsername && storedPassword) {
          this.form.email= storedUsername
          this.form.password = storedPassword
        }
      }
    }
  }
}
</script>