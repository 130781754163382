<template>
  <div class="card mt-5">
    <div class="card-header card-header-content-between">
      <div class="mb-2 mb-md-0">
        <b-form-group>
          <b-input-group
              class="input-group-merge input-group-flush"
          >
            <div class="input-group-prepend input-group-text">
              <i class="bi-search"></i>
            </div>
            <b-form-input
                v-model="filter"
                class=""
                type="search"
                placeholder="Search Products"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <div class="d-grid d-sm-flex gap-2">
        <b-dropdown
            variant="out-secondary"
            toggle-class="btn-white"
            no-caret
            right
            menu-class="dropdown-card dropdown-menu"
        >
          <template #button-content>
            <i class="bi-table me-1"></i>Columns
          </template>
          <div class="card card-sm">
            <div class="card-body">
              <div class="d-grid gap-3">
                <b-form-checkbox name="check-button" size="md" switch>
                  Quantity
                </b-form-checkbox>
                <b-form-checkbox name="check-button" size="md" switch>
                  SKU
                </b-form-checkbox>
                <b-form-checkbox name="check-button" size="md" switch>
                  Variants
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
          id="products-table"
          head-variant="light"
          selectable
          borderless
          select-mode="single"
          class="table-align-middle table-nowrap card-table"
          :busy="isBusy"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
      >
        <template #cell(product)="data">
          <a class="d-flex align-items-center" hred="#">
            <div class="flex-shrink-0">
              <img class="avatar" src="../../../../assets/img/400x400/img5.jpg">
            </div>
            <div class="flex-grow-1 ms-3">
              <h5 class="text-inherit mb-0">{{ data.value }}</h5>
            </div>
          </a>
        </template>
        <template #cell(stocks)="data">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="stocksCheckbox" :checked="data.value">
            <label class="form-check-label" for="stocksCheckbox"></label>
          </div>
        </template>
        <template #cell(price)="data">
          ${{data.value}}
        </template>
        <template #cell(actions)="data">
         <b-button-group>
           <b-button
               variant="out-secondary"
               class="btn-white"
               size="sm"
           ><i class="bi-pencil-fill me-1"></i>{{ data.value }}</b-button>
           <b-dropdown
               variant="out-secondary"
               toggle-class="btn-white btn-icon dropdown-toggle-empty"
               size="sm"
               right
           >
             <b-dropdown-item><i class="bi-trash dropdown-item-icon"></i>Delete</b-dropdown-item>
             <b-dropdown-item><i class="bi-upload dropdown-item-icon"></i>Publish</b-dropdown-item>
             <b-dropdown-item><i class="bi-x-lg dropdown-item-icon"></i>UnPublish</b-dropdown-item>
           </b-dropdown>
         </b-button-group>
        </template>
      </b-table>
    </div>
    <div class="card-footer">
      <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
          <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
            <p>Current Page: {{ currentPage }}</p>
          </div>
        </div>
        <div class="col-sm-auto">
          <div class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="products-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'products-table',
  data () {
    return {
      filter: null,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      sortBy: 'product',
      sortDesc: false,
      items: [
        { product: 'Givenchy perfume', type: 'Protein', stocks: true, price: 30, quantity: 30, actions: 'Edit' },
        { product: 'Givenchy perfume', type: 'Shoes', stocks: false, price:40, quantity: 30, actions: 'Edit' },
        { product: 'Givenchy perfume', type: 'Clothing', stocks: true, price:40, quantity: 30, actions: 'Edit' },
        { product: 'Givenchy perfume', type: 'Clothing', stocks: false, price:40, quantity: 30, actions: 'Edit' }
      ],
      fields: [
        {
          key: 'product',
          sortable: true
        },
        {
          key: 'type',
          sortable: true
        },
        {
          key: 'stocks',
          sortable: true
        },
        {
          key: 'price',
          sortable: true
        },
        {
          key: 'quantity',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        },

      ]
    }
  },
  computed: {
    rows () {
      return this.items.length
    }
  }
}
</script>