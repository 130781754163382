import moment from 'moment'
import { getItem, removeItem } from '@/base/utils/storage'

export const getStore = () =>
    sessionStorage.getItem('token') ? sessionStorage : localStorage

export const getToken = function () {
    let encryptedToken = getItem(getStore(), 'token')
    if (encryptedToken === null) return encryptedToken
    try {
        const crypto = require('crypto')
        let encryptionKey = getEncryptionKey()
        let cipher = crypto.createDecipher('aes-128-cbc', encryptionKey)
        let decrypted = cipher.update(encryptedToken, 'hex', 'utf8')
        decrypted += cipher.final('utf8')
        return  decrypted
    } catch (e) {
        clearToken()
        return null
    }
}
export const clearToken = function () {
    removeItem(getStore(), 'token')
    removeItem(getStore(), 'expiry_date')
}

export const getExpiryDate = function () {
    return getItem(getStore(), 'expiry_date')
}

export const tokenIsExpired = () => {
    return moment.duration(moment(getExpiryDate()).diff(moment())).asMinutes() < 0
}

export const decodeToken = token => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
}

export const getEncryptionKey = function () {
    return 'userIp'
}

export const setToken = function (storage, token) {
    const crypto = require('crypto')
    let encryptionKey = getEncryptionKey()
    let cipher = crypto.createCipher('aes-128-cbc', encryptionKey)
    let encryptedToken = cipher.update(token, 'utf8', 'hex')
    encryptedToken += cipher.final('hex')
    storage.setItem('token', encryptedToken)
}