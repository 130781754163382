import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    CREATE_COURSE, DELETE_COURSE,
    LIST_COURSES, UPDATE_COURSE,
    LIST_USER_COURSES_QUICK_SUMMARY,
    GET_CLIENT_COURSES,
    LIST_TRAINER_COURSES,
    LIST_COURSE_TAGS,
    UPDATED_RESERVATION_STATUS,
    CREATE_RESERVATION,
    UPDATE_RESERVATION_DETAILS,
    GET_USER_COURSES_SUMMARY,
    LIST_TRAINER_COURSES_SUMMARY
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'COURSE'
}

export const CourseEntity = new NetworkEntity(options)

CourseEntity.getCourses = function (commit) {
    commit(LIST_COURSES.ACTION)
    return axios
        .get(`${this.endpoint}/courses`)
        .then(({ data: {data} }) => {
            commit(LIST_COURSES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_COURSES.FAILURE, err)
        })
}

CourseEntity.createCourse = function (commit, data) {
    commit(CREATE_COURSE.ACTION)
    return axios
        .post(`${this.endpoint}/courses/create`, data)
        .then(({data}) => {
            commit(CREATE_COURSE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(CREATE_COURSE.FAILURE, err)
        })
}

CourseEntity.updateCourse = function (commit, data) {
    commit(UPDATE_COURSE.ACTION)
    return axios
        .put(`${this.endpoint}/courses/update/${data.id}`, data)
        .then(({data}) => {
            commit(UPDATE_COURSE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(UPDATE_COURSE.FAILURE, err)
        })
}

CourseEntity.getClientCourse = function (commit, { id, params }) {
    commit(GET_CLIENT_COURSES.ACTION)
    let q = CourseEntity.queryStringify(params)
    return axios
        .get(`${this.endpoint}/courses/client/${id}${q}`)
        .then(({ data: {data} }) => {
            commit(GET_CLIENT_COURSES.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(GET_CLIENT_COURSES.FAILURE, err)
        })
}

CourseEntity.listUserCoursesQuickSummary = function (commit, {id, params}) {
    commit(LIST_USER_COURSES_QUICK_SUMMARY.ACTION)
    let q = CourseEntity.queryStringify(params)
    let userType = params.userType
    return axios
        .get(`${this.endpoint}/courses/${userType}/${id}${q}`)
        .then(({ data: {data} }) => {
            commit(LIST_USER_COURSES_QUICK_SUMMARY.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_USER_COURSES_QUICK_SUMMARY.FAILURE, err)
        })
}

CourseEntity.listTrainerCourses = function (commit, id) {
    commit(LIST_TRAINER_COURSES.ACTION)
    return axios
        .get(`${this.endpoint}/courses/trainer/${id}`)
        .then(({ data: {data} }) => {
            commit(LIST_TRAINER_COURSES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_TRAINER_COURSES.FAILURE, err)
        })
}

CourseEntity.deleteCourse = function (commit, id) {
    commit(DELETE_COURSE.ACTION)
    return axios
        .delete(`${this.endpoint}/courses/delete/${id}`)
        .then(({data}) => {
            commit(DELETE_COURSE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(DELETE_COURSE.FAILURE, err)
        })
}

CourseEntity.listCourseTags = function (commit) {
    commit(LIST_COURSE_TAGS.ACTION)
    return axios
        .get(`${this.endpoint}/courses/course-tags`)
        .then(({ data: {data} }) => {
            commit(LIST_COURSE_TAGS.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_COURSE_TAGS.FAILURE, err)
        })
}

CourseEntity.createReservation = function (commit, data) {
    commit(CREATE_RESERVATION.ACTION)
    return axios
        .post(`${this.endpoint}/courses/reservations/create`, data)
        .then(({data}) => {
            commit(CREATE_RESERVATION.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(CREATE_RESERVATION.FAILURE, err)
        })
}

CourseEntity.updateReservationDetailForClient = function (commit, id, data) {
    commit(UPDATE_RESERVATION_DETAILS.ACTION)
    return axios
        .post(`${this.endpoint}/courses/reservations/${id}/details`, data)
        .then(({data}) => {
            commit(UPDATE_RESERVATION_DETAILS.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(UPDATE_RESERVATION_DETAILS.FAILURE, err)
        })
}

CourseEntity.updatedReservationStatus = function (commit, id, data) {
    commit(UPDATED_RESERVATION_STATUS.ACTION)
    return axios
        .post(`${this.endpoint}/courses/reservations/${id}/status`, data)
        .then(({data}) => {
            commit(UPDATED_RESERVATION_STATUS.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(UPDATED_RESERVATION_STATUS.FAILURE, err)
        })
}

CourseEntity.getUserCoursesSummary = function (commit, id) {
    commit(GET_USER_COURSES_SUMMARY.ACTION)
    return axios
        .get(`${this.endpoint}/courses/users/${id}`)
        .then(({ data: {data} }) => {
            commit(GET_USER_COURSES_SUMMARY.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(GET_USER_COURSES_SUMMARY.FAILURE, err)
        })
}

CourseEntity.listTrainerCoursesSummary = function (commit, params) {
    commit(LIST_TRAINER_COURSES_SUMMARY.ACTION)
    let q = CourseEntity.queryStringify(params)
    return axios
        .get(`${this.endpoint}/courses/courses-summary${q}`)
        .then(({ data: {data} }) => {
            commit(LIST_TRAINER_COURSES_SUMMARY.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(LIST_TRAINER_COURSES_SUMMARY.FAILURE, err)
        })
}
