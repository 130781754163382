<template>
  <router-link class="navbar-brand" to="/">
    <div class="navbar-brand-logo">
      <div class="d-flex">
        <img class="w-25 mkc-logo" alt="MonkeyKing Club logo" src="@/assets/svg/logos/wlogo2.svg">
        <div class="logo-text">WU FITNESS</div>
      </div>
    </div>
    <div class="navbar-brand-logo-mini">
      <img class="w-100 mkc-logo" alt="MonkeyKing Club logo" src="@/assets/svg/logos/wlogo2.svg">
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'nav-logo'
}
</script>
<style>
.mkc-logo {
  margin-top: 10px;
}
.logo-text {
  font-size: 18px;
  font-weight: bold;
  margin: auto 0 0 10px;
}
</style>