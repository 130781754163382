import * as TYPES from './types'
import { initialState } from '@/store/user/index'

const setUser = (state, data) => {
    state.data = data
    state.signedIn = true
    state.fetching = false
    useAppAs(state)
}

const getUser = state => {
    state.fetching = true
}

const getUserFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const useAppAs = (state) => {
    state.useAppAs = state.data.roles
}

const clearUser = (state) => {
    const s = initialState()
    Object.keys(s).forEach(key => {
        state[key] =s[key]
    })
}

const listActivities = (state) => {
    state.fetchingActivities = true
}

const listActivitiesSuccess = (state, data) => {
    state.fetchingActivities = false
    state.userActivities = data
}

const listActivitiesFailure = (state, error) => {
    state.fetchingActivities = false
    console.log(error)
}

const listUserBodyIndexRecords = (state) => {
    state.fetchingBodyIndexRecords = true
}

const listUserBodyIndexRecordsSuccess = (state, data) => {
    state.fetchingBodyIndexRecords = false
    state.userBodyIndexRecords = data
}

const listUserBodyIndexRecordsFailure = (state, error) => {
    state.fetchingBodyIndexRecords = false
    console.log(error)
}

const createUserReason = (state) => {
    state.fetchingReason = true
    state.userReason = null
}

const createUserReasonSuccess = (state, data) => {
    state.fetchingReason = false
    state.userReason = data
}

const createUserReasonFailure = (state, error) => {
    state.fetchingReason = false
    console.log(error)
}

const modifyUserReason = (state) => {
    state.fetchingReason = true
    state.userReason = null
}

const modifyUserReasonSuccess = (state, data) => {
    state.fetchingReason = false
    state.userReason = data
}

const modifyUserReasonFailure = (state, error) => {
    state.fetchingReason = false
    console.log(error)
}

const activateUser = (state) => {
    state.fetchingReason = true
}

const activateUserSuccess = (state) => {
    state.fetchingReason = false
}

const activateUserFailure = (state, error) => {
    state.fetchingReason = false
    console.log(error)
}

export default {
    [TYPES.ME.ACTION]: getUser,
    [TYPES.ME.SUCCESS]: setUser,
    [TYPES.ME.FAILURE]: getUserFailure,
    [TYPES.USE_APP_AS]: useAppAs,
    [TYPES.CLEAR_USER]: clearUser,

    [TYPES.LIST_ACTIVITIES.ACTION]: listActivities,
    [TYPES.LIST_ACTIVITIES.SUCCESS]: listActivitiesSuccess,
    [TYPES.LIST_ACTIVITIES.FAILURE]: listActivitiesFailure,

    [TYPES.LIST_BODY_INDEX_RECORD.ACTION]: listUserBodyIndexRecords,
    [TYPES.LIST_BODY_INDEX_RECORD.SUCCESS]: listUserBodyIndexRecordsSuccess,
    [TYPES.LIST_BODY_INDEX_RECORD.FAILURE]: listUserBodyIndexRecordsFailure,

    [TYPES.CREATE_USER_REASON.ACTION]: createUserReason,
    [TYPES.CREATE_USER_REASON.SUCCESS]: createUserReasonSuccess,
    [TYPES.CREATE_USER_REASON.FAILURE]: createUserReasonFailure,

    [TYPES.MODIFY_USER_REASON.ACTION]: modifyUserReason,
    [TYPES.MODIFY_USER_REASON.SUCCESS]: modifyUserReasonSuccess,
    [TYPES.MODIFY_USER_REASON.FAILURE]: modifyUserReasonFailure,

    [TYPES.ACTIVATE_USER.ACTION]: activateUser,
    [TYPES.ACTIVATE_USER.SUCCESS]: activateUserSuccess,
    [TYPES.ACTIVATE_USER.FAILURE]: activateUserFailure,

    [TYPES.WITHDRAW_PENDING.ACTION]: activateUser,
    [TYPES.WITHDRAW_PENDING.SUCCESS]: activateUserSuccess,
    [TYPES.WITHDRAW_PENDING.FAILURE]: activateUserFailure
}