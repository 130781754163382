import { PaymentEntity } from  './api'

export const getUserPayments = async ({commit}, id) => {
    await PaymentEntity.getUserPayments(commit, id)
}

export const addUserPackagePayment = async ({commit}, { id, data }) => {
    await PaymentEntity.addUserPackagePayment(commit, id, data)
}

export const getTopSalesList = async ({commit}, params) => {
    await PaymentEntity.getTopSalesList(commit, params)
}

export default {
    getUserPayments,
    addUserPackagePayment,
    getTopSalesList
}