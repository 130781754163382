<template>
  <div class="card-body body-composition-analysis">
    <div>
      <h3>Body Index Data</h3>
      <vue-excel-editor
          v-model="bodyIndexFieldsData"
          no-header-edit
          disable-panel-setting
          disable-panel-filter
          :cell-style="rowStyle"
          class="vue-excel-editor"
          @update="onUpdate"
      >
        <vue-excel-column
            v-for="field in companyBodyIndexFieldSettings"
            :key="`body-index-field-${field.id}`"
            :field="field.name"
            :label="getLabel(field)"
            :type="field.type"
            width="100px"
        />
      </vue-excel-editor>
    </div>
    <div class="row">
      <h3>Body Index File Uploads</h3>
      <drop-file />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { DropFile } from '@/layout'

export default {
  name: 'body-composition-analysis',
  components: {
    DropFile
  },
  data () {
    return {
      bodyIndexFieldsData: [{
        height: "",
        weight: "",
        smm: "",
        pbf: "",
        leftArm: "",
        rightArm: "",
        leftThigh: "",
        rightThigh: "",
        leftCalf: "",
        rightCalf: "",
        waistline: "",
        hip: "",
        vfl: "",
        goal: ""
      }]
    }
  },
  computed: {
    ...mapState('company', ['companySettings', 'companyBodyIndexFieldSettings'])
  },
  methods: {
    onUpdate () {
      this.$emit('input', this.bodyIndexFieldsData[0])
    },
    rowStyle() {
      return { fontWeight: 'bold', fontSize: '12pt', color: 'black'}
    },
    getLabel (field) {
      if (this.$te(`profile.bodyIndexFieldLabel.${field.name}`))
      {
        return this.$t(`profile.bodyIndexFieldLabel.${field.name}`) + ' (' + field.symbol + ')'
      }
      return field.label + ' (' + field.symbol + ')'
    },
    logValidationError (error, row, field) {
      // For row validation, the field argument will be null value
      console.log(error, row, field)
    }
  }
}
</script>
<style scoped>
.body-composition-analysis {
  .vue-excel-editor{
    color: black;
    margin-bottom: 20px;
  }
}
</style>