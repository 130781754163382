<template>
  <div class="row justify-content-lg-center">
    <profile-header
        v-if="!fetching && myProfileJson !== null"
        :first-name="user.first_name"
        :last-name="user.last_name"
        :user-status="user.status"
        :address="myProfileJson.address"
        :client-type="myProfileJson.clientType"
        :start-of-date="myProfileJson.startOfDate"
    />
    <b-spinner
        v-else
        variant="primary"
    ></b-spinner>
    <profile-detail
        v-if="!fetching && myProfileJson !== null"
        :profile="profile"
        my-profile
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import {
  ProfileHeader,
    ProfileDetail
} from './userProfile/profileContent'

export default {
  name: 'my-profile',
  components: {
    ProfileHeader,
    ProfileDetail
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('userProfile', ['fetching', 'myProfileJson']),
    profile () {
      return { ...this.user, ...this.myProfileJson, userType: this.usingAppAs[0]}
    }
  },
  methods: {
    ...mapActions('userProfile', ['getMyProfile'])
  },
  async mounted () {
    await this.getMyProfile(this.user.id)
  }
}
</script>