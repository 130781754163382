<template>
  <b-modal
      id="change-trainer-modal"
      title="Change Trainer"
  >
    <form>
      <b-row class="mb-4">
        <label for="currentTrainerLabel" class="col-sm-3 col-form-label form-label">Current Trainer</label>
        <b-col sm="9">
          <b-form-group class="input-group-merge">
            <b-form-input
                id="currentTrainerLabel"
                disabled
                :value="trainerName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="9">
          <label for="newTrainerLabel" class="form-label">Change Trainer to</label>
          <b-form-group class="input-group-merge">
            <b-form-select
                v-model="trainer"
                :options="trainersOptions"
                value-field="item"
                text-field="name"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="out-secondary"
            class="btn-outline-primary float-end ml-2"
            @click="handleCloseModal"
        >
          Close
        </b-button>
        <b-button
            variant="primary"
            class="float-end"
            :disabled="!disabledSubmit"
            @click="handleUpdateTrainer"
        >
          Update Trainer
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'change-trainer-modal',
  props: {
    currentTrainer: {
      type: Number,
      required: true,
      default: null
    },
    trainerName: {
      type: String,
      required: true,
      default: ''
    },
    clientId: {
      type: Number,
      required: true,
      default: null
    }
  },
  data () {
    return {
      trainer: null,
    }
  },
  computed: {
    ...mapState('trainer', ['trainers']),
    trainersOptions () {
      return this.trainers.map(trainer => {
        return {item: trainer.id, name: trainer.name.first_name + ' ' + trainer.name.last_name}
      })
    },
    disabledSubmit () {
      return this.currentTrainer !== this.trainer && this.trainer !== null
    }
  },
  methods: {
    ...mapActions('client', ['updateTrainer']),
    async handleUpdateTrainer () {
      let data = { client: this.clientId, trainer: this.trainer }
      // console.log(data)
      await this.updateTrainer(data)
      await this.handleCloseModal()
      await this.$emit('refresh')
    },
    handleCloseModal () {
      this.$bvModal.hide('change-trainer-modal')
    }
  }
}
</script>