<template>
  <b-modal title="View or Update Result Sheet" id="update-view-body-index-modal" size="lg">
    <excel-editor
        :records="records"
        :columns="columns"
    />
    <template #modal-footer>
      <div class="d-grid d-flex gap-2">
        <b-button variant="outline-primary">Save</b-button>
        <b-button
            variant="primary"
            class="ms-auto"
            @click="$bvModal.hide('update-view-body-index-modal')"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { ExcelEditor } from '@/layout'

export default {
  name: 'update-view-body-index-modal',
  components: {
    ExcelEditor
  },
  props: {
    records: {
      type: Array,
      required: false,
      default: null
    },
    columns: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
    }
  }
}
</script>