<template>
  <div class="card-body add-client-profile">
    <div class="row mb-4">
      <label class="col-sm-3 col-form-label form-label">Profile</label>
      <div class="col-sm-9">
        <avatar-upload />
      </div>
    </div>

    <!-- Full Name -->
    <div class="row mb-4">
      <label for="firstName" class="col-sm-3 col-form-label form-label">Full Name</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="firstName"
              type="text"
              required
              placeholder="First Name"
              :state="form.first_name !== null"
              v-model="form.first_name"
              @input="value => updateValue(value, 'first_name')"
          />
          <b-form-input
              id="LastName"
              type="text"
              required
              placeholder="Last Name"
              v-model="form.last_name"
              :state="form.last_name !== null"
              @input="value => updateValue(value, 'last_name')"
          />
        </div>
      </div>
    </div>
    <!-- Email -->
    <div class="row mb-4">
      <label for="email" class="col-sm-3 col-form-label form-label">Email</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="email"
              type="email"
              :state="isValidEmail"
              v-model="form.email"
              @input="value => updateValue(value, 'email')"
              required
              placeholder="Enter Email"
              aria-describedby="input-email-feedback"
          />
          <b-form-invalid-feedback id="input-email-feedback">
            {{ $t('profile.signIn.invalidEmail') }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <!-- Date of Birth -->
    <div class="row mb-4">
      <label for="dateOfBirth" class="col-sm-3 col-form-label form-label">Date of Birth</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="dateOfBirth"
              type="date"
              v-model="form.dateOfBirth"
              :state="form.dateOfBirth !== null"
              @input="value => updateValue(value, 'dateOfBirth')"
          />
        </div>
      </div>
    </div>
    <!-- Gender -->
    <div class="row mb-4">
      <label for="gender" class="col col-form-label form-label">Gender</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-select
              id="gender"
              class="form-select p-1"
              :options="genderOptions"
              :value="null"
              v-model="form.gender"
              :state="form.gender !== null"
              @input="value => updateValue(value, 'gender')"
          />
        </div>
      </div>
    </div>
    <!-- Start -->
    <div class="row mb-4">
      <label for="startOfDate" class="col-sm-3 col-form-label form-label">Start of Date</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="startOfDate"
              type="date"
              required
              v-model="form.startOfDate"
              :state="form.startOfDate !== null"
              @input="value => updateValue(value, 'startOfDate')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AvatarUpload } from '@/layout'
import { mapState } from 'vuex'
export default {
  name: 'add-client-profile',
  components: {
    AvatarUpload
  },
  data () {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        dateOfBirth: null,
        startOfDate: null,
        gender: null,
        role: 'client',
        packageId: null
      },
    }
  },
  computed: {
    ...mapState('company', ['companySettings']),
    isValidEmail () {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    },
    genderOptions () {
      if (this.companySettings === null) {
        return null
      }
      return this.companySettings.find(field => field.name === 'gender')
          .options.map(option => { return {value: option ,text: this.$t(`profile.gender.${option}`) }})
    },
    clientTypeOptions () {
      if (this.companySettings === null) {
        return null
      }
      return this.companySettings.find(field => field.name === 'clientType')
          .options.map(option => { return this.$t(`profile.clientType.${option}`) })
    }
  },
  methods: {
    updateValue (value, key) {
      this.$emit('input', value, key)
    }
  }
}
</script>
<style lang="css" scoped>
.add-client-profile {
  .form-select {
    background-color: #25282a;
  }
}
</style>