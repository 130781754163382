<template>
  <div>
    <b-spinner v-if="fetching" type="grow"></b-spinner>
    <FullCalendar
        v-else-if="!fetching && visible"
        id="fullCalendar"
        ref="fullCalendar"
        class="fullcalendar-custom"
        :key="componentKey"
        :options="calendarOptions"
        style="min-height: 1000px;"
    >
      <template v-slot:eventContent='arg'>
        <div :id="'event-'+arg.event.id">
          <b v-if="!sm">{{ getEventDateRange(arg.event.start, arg.event.end) }}</b>
          <div v-if="sm">{{ arg.event.extendedProps.clientName }} </div>
          <div v-if="!sm">Client: {{ arg.event.extendedProps.clientName }} </div>
          <div v-if="!sm">Trainer: {{ arg.event.extendedProps.trainerName }} </div>
        </div>
<!--        <b-popover-->
<!--            triggers="hover"-->
<!--            :target="'event-'+arg.event.id"-->
<!--            custom-class="popover fullcalendar-event-popover bs-popover-auto fade"-->
<!--        >-->
<!--          <h3 class="mb-4">{{ getCourseName(arg.event.extendedProps.courseName) }}</h3>-->
<!--          <div class="d-flex mb-4">-->
<!--            <i class="bi bi-clock nav-icon"></i>-->
<!--            <div class="flex-grow-1">-->
<!--              <span class="d-block text-dark mb-2">{{ arg.event.start }}</span>-->
<!--              <span class="d-block">Duration: <span class="text-dark text-capitalize">{{ getDuration(arg.event.start, arg.event.end) }}</span> hour(s)</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-4">-->
<!--            <i class="bi bi-people nav-icon"></i>-->
<!--            <div class="flex-grow-1">-->
<!--                <span class="d-block text-dark">-->
<!--                  <ul class="list-unstyled mb-0">-->
<!--                    <li class="d-flex align-items-center mb-2">-->
<!--                      <img class="avatar avatar-xs avatar-circle me-2" src="@/assets/img/160x160/img1.jpg" alt="client name">-->
<!--                      <span>{{ arg.event.extendedProps.clientName }}</span>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-4">-->
<!--            <i class="bi bi-fast-forward-circle nav-icon"></i>-->
<!--            <div class="flex-grow-1">-->
<!--              <span v-if="arg.event.extendedProps.status=== 'completed'" class="badge bg-soft-success text-success rounded-pill">Completed</span>-->
<!--              <span v-else-if="arg.event.extendedProps.status === 'cancelled'" class="badge bg-soft-danger text-danger rounded-pill">Cancelled</span>-->
<!--              <span v-else-if="arg.event.extendedProps.status === 'scheduled'" class="badge bg-soft-warning text-warning rounded-pill">Scheduled</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-4">-->
<!--            <i class="bi bi-text-left nav-icon"></i>-->
<!--            <div class="flex-grow-1">-->
<!--              <span class="d-block text-dark">Wu Fitness</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex mb-4">-->
<!--            <i class="bi bi-list-check nav-icon"></i>-->
<!--            <div class="flex-grow-1">-->
<!--              <span class="d-block text-dark">{{ getTags(arg.event.extendedProps.tags) }}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex align-items-center mb-4">-->
<!--            <div class="avatar avatar-xs avatar-circle me-2">-->
<!--              <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="trainer name">-->
<!--            </div>-->
<!--            <div class="flex-grow-1">-->
<!--              <span class="d-block text-dark">{{ arg.event.extendedProps.trainerName }}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="d-flex justify-content-end">-->
<!--            <b-button variant="primary" @click="openEditCourseModal(arg.event)">-->
<!--              <i class="bi bi-pencil"></i>-->
<!--              Edit-->
<!--            </b-button>-->
<!--          </div>-->
<!--        </b-popover>-->
      </template>
    </FullCalendar>
    <edit-course-modal
        v-if="!fetching"
        :event="currentEvent"
        :user-type="userType"
        :profile="profile"
        :target-client="targetClientId"
        :target-trainer="targetTrainerId"
        :tags="targetTags"
        :local="locale"
        :action="eventAction"
        @input="handleUpdateEvent"
    />
    <create-course-modal
        :event="currentEvent"
        :user-type="userType"
        :profile="profile"
        :target-client="targetClientId"
        :target-trainer="targetTrainerId"
        :local="locale"
        @input="handleCreateEventInput"
        @save="handleCreateReservation"
    />
    <course-quota-exhausted-modal />
    <something-wrong-alert :show-alert="showAlert" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import FullCalendar from '@fullcalendar/vue'
import { CreateCourseModal, EditCourseModal, CourseQuotaExhaustedModal } from '@/components/calendar'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import { getLocale } from '@/base/utils'
import { SomethingWrongAlert } from '@/layout'


export default {
  name: 'calendar-detail',
  components: {
    CreateCourseModal,
    FullCalendar,
    EditCourseModal,
    CourseQuotaExhaustedModal,
    SomethingWrongAlert
  },
  props: {
    fetching: {
      type: Boolean,
      required: false,
      default: false
    },
    courseEvents: {
      type: Array,
      required: false,
      default: () => []
    },
    userType: {
      type: String,
      required: false,
      default: null
    },
    visible: {
      type: Boolean,
      require: false,
      default: false
    },
    profile: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      currentEvent: {
        start: moment.now(),
        end: moment.now()
      },
      locale: getLocale(),
      showEditCourseModal: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'today prev,next',
          center: 'title',
          // right: 'dayGridMonth,timeGridWeek,timeGridDay'
          right: 'timeGridWeek,timeGridDay'
        },
        // customButtons: {
        //  customFormSelect: {
        //    text: 'custom!',
        //    click: function() {
        //      // let calendarApi = this.$refs.fullCalendar.getApi()
        //      // calendarApi.prev();
        //      this.handlePrev()
        //    }
        //  }
        // },
        slotDuration: '00:15',
        allDaySlot: false,
        initialView: 'timeGridWeek',
        //initialEvents: this.courseEvents, // alternatively, use the `events` setting to fetch from a feed
        events: this.courseEvents,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:00:00",
        firstDay: 1,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        eventChange: this.handleEventChange
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      eventAction: 'create',
      // currentEvents: [],
      componentKey: 0,
      showAlert: false
    }
  },
  computed: {
    ...mapState('course', ['courseTags', 'privateTrainingSessionId', 'courseList']),
    ...mapState('alert', ['sm']),
    targetClientId () {
      if (this.userType === 'client') {
        return this.profile.id
      }
      if (!('extendedProps' in this.currentEvent)) {
        return null
      }
      if (!('clientId' in this.currentEvent.extendedProps)) {
        return null
      }
      return this.currentEvent.extendedProps.clientId
    },
    targetTrainerId () {
      if (this.userType === 'trainer') {
        return this.profile.id
      }
      if (!('extendedProps' in this.currentEvent)) {
        return null
      }
      if (!('trainerId' in this.currentEvent.extendedProps)) {
        return null
      }
      return this.currentEvent.extendedProps.trainerId
    },
    targetTags () {
      if (!('extendedProps' in this.currentEvent)) {
        return []
      }
      if (!('tags' in this.currentEvent.extendedProps)) {
        return []
      }
      return this.courseTags.filter(tag => this.currentEvent.extendedProps.tags.includes(tag.id))
          .map(tag => {
            return { id: tag.id, name: tag.name[this.locale] }
          })
    }
  },
  methods: {
    ...mapActions('course', [
      'listCourseTags',
      'listCourses',
      'updateReservationDetailForClient',
      'listTrainerCourses',
      'getClientCourses'
    ]),
    setEventAction (event) {
      if ('extendedProps' in event) {
        if (['completed', 'cancelled'].includes(event.extendedProps.status))
        {
          this.eventAction = 'view'
        } else {
          this.eventAction = 'edit'
        }
      } else {
        this.eventAction = 'create'
      }
    },
    getTags(tagsIds){
      if (tagsIds === undefined || tagsIds === null)
      {
        return null
      }
      return this.courseTags.filter(tag => tagsIds.includes(tag.id)).map(tag => {
        return tag.name[this.locale]
      }).toString()
    },
    getDuration (start, end) {
      return moment(end).diff(start, 'hours')
    },
    getEventDateRange (start, end) {
      let startTime = moment(start).format('LT')
      let endTime = moment(end).format('LT')
      return startTime + ' - ' + endTime
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    openEditCourseModal (event) {
      this.currentEvent = event
      this.setEventAction(event)
      this.$bvModal.show('edit-course-modal')
    },
    handleDateSelect(selectInfo) {
      this.eventAction = 'create'
      this.currentEvent = selectInfo
      this.$bvModal.show('create-course-modal')
      // this.openEditCourseModal(selectInfo)
    },
    handleEventClick(clickInfo) {
      let event = clickInfo.event
      this.currentEvent = event
      this.setEventAction(event)
      this.$bvModal.show('edit-course-modal')
    },
    handleEventChange (changeInfo) {
      let oldEvent = changeInfo.oldEvent
      let event = changeInfo.event

      if (moment(event.start).format() !== moment(oldEvent.start).format() ||
          moment(event.end).format() !== moment(oldEvent.end).format()
      ) {
        let data = {
          id: event.id,
          clientId: event.extendedProps.clientId,
          trainerId: event.extendedProps.trainerId,
          courseId: event.extendedProps.courseId,
          tags: event.extendedProps.tags,
          startedAt: moment(event.start).format(),
          endedAt: moment(event.end).format(),
        }
        this.updateReservationDetailForClient({
          id: event.id,
          data: data
        })
        this.makeToast(event)
      }
    },
    makeToast(event) {
      let clientName = event.extendedProps.clientName
      let message = 'Reschedule a reservation with ' + clientName + ' started at ' + event.start
      this.$bvToast.toast(`${message}`, {
        title: 'Reservation Rescheduled',
        variant: 'info',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    handleCreateEventInput(name, value) {
      if (name === 'clientId') {
        this.targetClientId = value
      } else if (name === 'trainerId') {
        this.targetTrainerId = value
      }
    },
    async handleUpdateEvent (name, value) {
      if (name === 'tags') {
        let tagsValue = value.map(tag => { return tag.id})
        this.currentEvent.setExtendedProp(name, tagsValue)
      } else {
        this.currentEvent.setExtendedProp(name, value)
      }
      if (name === 'status') {
        this.calendarOptions.events.forEach(event => {
          if (event.id.toString() === this.currentEvent.id) {
            event.status = value
            event.backgroundColor = this.getEventColor(value)
          }
        })
        let fullCalendarApi = this.$refs.fullCalendar.getApi()
        fullCalendarApi.render()
      }
    },
    getEventColor (status) {
      switch (status) {
        case 'completed':
          return 'rgba(237,76,120)'
        case 'scheduled':
          return 'rgba(55,125,255)'
        default:
          return '#FFE599'
      }
    },
    getCourseName (name) {
      if (this.$te('course.courseName.' + name)) {
        return this.$t('course.courseName.' + name)
      } else {
        return name
      }
    },
    async handleCreateReservation(newEvent) {
      if ('error' in newEvent) {
        switch (newEvent.error) {
          case 'COURSE_QUOTA_EXHAUSTED':
            this.$bvModal.show('course-quota-exhausted-modal')
            break
          default:
            this.$bvModal.show('something-wrong-alert')
        }
      } else {
        let fullCalendarApi = this.$refs.fullCalendar.getApi()
        fullCalendarApi.addEvent(newEvent.data)
        fullCalendarApi.render()
      }
    }
  },
  async mounted() {
    if (this.courseTags.length === 0) {
      await this.listCourseTags()
    }
    if (this.courseList.length === 0) {
      await this.listCourses()
    }
  },
  watch: {
    courseEvents(newValue) {
      this.calendarOptions.events = newValue
    }
  }
}
</script>
<style lang='css' scoped>

</style>