<template>
  <div class="row">
    <div class="col-lg-4">
      <!-- Membership summary -->
     <active-days-card
         :profile="profile"
     />
      <!-- Account Balance  -->
      <account-balance v-if="visible && (usingAppAs === 'client' || profile.userType === 'client')" />
      <!-- Profile Info-->
      <profile-info-card
          :profile="profile"
          :my-profile="myProfile"
          @refresh="refreshPage"
      />
    </div>
    <div class="col-lg-8">
      <div class="d-grid gap-3 gap-lg-5">
        <course-summary
            v-if="showCourseSummary"
            :fetching="fetching"
            :course-list-data="userCoursesQuickSummary"
        />
        <activity-steam
            :fetching="fetchingActivities"
            :step-items="userActivities"
        />
        <div class="row">
          <div class="col-sm-6 mb-sm-0">
            <div class="card h-100">
              <div class="card-header">
                <h4 class="card-header-title">
                  Calendar
                </h4>
              </div>
              <div class="card-body">
                <small-calendar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import {
  ActivitySteam,
  SmallCalendar
} from '@/layout'
import CourseSummary from './CourseSummary'
import { ActiveDaysCard, AccountBalance, ProfileInfoCard } from './components'
export default {
  components: {
    ActiveDaysCard,
    ActivitySteam,
    SmallCalendar,
    CourseSummary,
    AccountBalance,
    ProfileInfoCard
  },
  name: 'profile-detail',
  props: {
    profile: {
      type: Object,
      required: true,
      default: null
    },
    myProfile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState('course', ['fetching', 'userCoursesQuickSummary']),
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs', userActivities: 'userActivities', fetchingActivities: 'fetchingActivities' }),
    showCourseSummary () {
      return (this.profile.userType === 'client' && this.profile.clientType === 'pro') || this.profile.userType === 'trainer'
    }
  },
  methods: {
    ...mapActions('course', ['listUserCoursesQuickSummary']),
    ...mapActions('user', ['listActivities']),
    async refreshPage () {
      await this.$emit('refresh')
    }
  },
  async mounted () {
    let id = this.myProfile ? this.user.id : this.profile.id
    if (this.showCourseSummary){
      await this.listUserCoursesQuickSummary({
        id: id,
        params: {
          weekly: true,
          userType: this.profile.userType
        }
      })
    }
    await this.listActivities(id)
  }
}
</script>