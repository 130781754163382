<template>
  <div>
    <ul class="js-step-progress step step-sm step-icon-sm step step-inline step-item-between mb-3 mb-md-5">
      <li
          v-for="step in steps"
          :key="step.step"
          class="step-item"
          :class="step.index === currentStep ? 'active focus' : ''"
          @click="switchProgress(step.index)"
      >
        <a class="step-content-wrapper">
          <span class="step-icon step-icon-soft-dark">{{ step.index }}</span>
          <div class="step-content">
            <span class="step-title">{{ step.title }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'progress-bar',
  props: {
    steps: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [
        { index: 0, title: 'step 1'},
        { index: 1, title: 'step 2'}
      ]
    },
    currentStep: {
      type: Number,
      required: true,
      default: 1
    }
  },
  methods: {
    switchProgress (index) {
      this.$emit('switchStep', index)
    }
  }
}
</script>