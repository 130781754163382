<template>
  <div>
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <div class="row">
      <div
          class="col-sm-6 col-lg-3 mb-3 mb-lg-5"
          v-for="(stat, index) in statCards"
          :key="index"
      >
        <stat-card
            :sub-title="stat.subtitle"
            :data="stat.data"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-4 mb-lg-5">
        <div class="card h-100">
          <div class="card-header card-header-content-between">
            <h4 class="card-header-title">Top Sales</h4>
            <ul class="nav nav-segment nav-fill">
              <li class="nav-item">
                <a class="nav-link" :class="isThisWeek ? 'active' :null" @click="handleSwitchSalesPanel('current')">This week</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="!isThisWeek? 'active': null" @click="handleSwitchSalesPanel('last')">Last month</a>
              </li>
            </ul>
          </div>
          <div class="card-body-height">
            <top-sales-table :fetching="fetchingTopSales" :table-cells="topSalesData"/>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4 mb-lg-5">
        <div class="card h-100">
          <div class="card-header card-header-content-between">
            <h4 class="card-header-title">Client Types Summary</h4>
          </div>
          <div class="card-body-height"
               v-if="!fetchingClientTypesSummary"
          >
              <doughnut-chart
                  :chart-data="chartData"
              />
          </div>
          <b-spinner
              v-else
              variant="primary"
              class="align-middle"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { PageHeader, StatCard, DoughnutChart } from '@/layout'
import { TopSalesTable } from './components'

export default {
  name: 'UsersOverview',
  components: {
    PageHeader,
    StatCard,
    TopSalesTable,
    DoughnutChart
  },
  data () {
    return {
      headerTitle: 'Users',
      headerItems: [
        {
          text: 'Employees',
          href: '#'
        },
        {
          text: 'Overview',
          active: true
        }
      ],
      perPage: 4,
      currentPage: 1,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'name', label: 'Full Name', sortable: true},
        { key: 'position', label: 'Position', sortable: true},
        { key: 'status', label: 'Status', sortable: true},
        { key: 'members', label: 'Members', sortable: true},
        { key: 'role', label: 'Role', sortable: true },
        { key: 'edit', label: 'Actions', sortable: false },
      ],
      tableCells: [
        { id: 1, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, position: {position: 'Director', department: 'Human resources'}, status: 'active', members: 0, role: 'admin', edit: true},
        { id: 2, name: { first_name: 'A', last_name: 'B', email: 'ella@site.com' }, position: {position: 'Co-founder', department: 'All departments'}, status: 'pending',  members: 0, role: 'manager', edit: true},
        { id: 3, name: { first_name: 'C', last_name: 'D', email: 'ella@site.com' }, position: {position: 'trainer', department: 'Trainers'}, status: 'active',  members: 5, role: 'employee', edit: true },
      ],
      isThisWeek: true
    }
  },
  computed: {
    ...mapState('employee', ['fetching', 'employeesCountSummary']),
    ...mapState('client', ['clientTypesSummary']),
    ...mapState('client', { fetchingClientTypesSummary: 'fetching' }),
    ...mapState('payment', ['fetchingTopSales', 'topSalesData']),
    statCards () {
      return [
        {  key: '1', subtitle: 'Active employees', data: {from: this.employeesCountSummary.totalEmployeesOneMonthAgo, to: this.employeesCountSummary.activeEmployees}},
        {  key: '2', subtitle: 'Active trainers', data: {from: this.employeesCountSummary.activeTrainersOneMonthAgo, to: this.employeesCountSummary.activeTrainers}},
      ]
    },
    chartData () {
      return {
        labels: ['Pro', 'Yearly', 'Seasonal', 'Monthly', 'Weekly', 'dayPass'],
        datasets: [
          {
            backgroundColor: [
              '#377dff',
              '#ffc107',
              '#00c9db',
              '#e7eaf3',
              '#DD1B16',
              '#00ab8e'
            ],
            data: [
              this.clientTypesSummary.pro,
              this.clientTypesSummary.yearly,
              this.clientTypesSummary.seasonal,
              this.clientTypesSummary.monthly,
              this.clientTypesSummary.weekly,
              this.clientTypesSummary.dayPass,
            ],
            borderWidth: 5,
            borderColor: "#2c3e50",
          },
        ]
      }
    }
  },
  methods: {
    ...mapActions('employee', ['getEmployeesCountSummary']),
    ...mapActions('client', ['getClientTypesSummary']),
    ...mapActions('payment', ['getTopSalesList']),
    handleEditProfile () {
      this.router.push({ name: 'user-profile', params: { userId: 123 } })
    },
    handleSwitchSalesPanel(panel) {
      if (panel === 'current') {
        this.isThisWeek = true
        let params = { range: 'thisWeek' }
        this.getTopSalesList(params)
      } else {
        this.isThisWeek = false
        let params = { range: 'lastMonth' }
        this.getTopSalesList(params)

      }
    }
  },
  async mounted () {
    await this.getEmployeesCountSummary()
    await this.getClientTypesSummary()
    let params = { range: 'thisWeek' }
    await this.getTopSalesList(params)
  }
}
</script>