<template>
  <div class="d-flex">
    <div class="flex-grow-1">
      <h6 class="card-subtitle mb-3">{{ cardSubTitle }}</h6>
      <h3 class="card-title">{{ cardTitle }}</h3>
      <div class="d-flex align-items-center">
        <span class="d-block fs-6">{{ data.orders }} orders</span>
        <trend-icon :to="data.from" :from="data.to" />
      </div>
    </div>
    <span class="icon icon-soft-secondary icon-sm icon-circle ms-3">
      <i class="bi-shop"></i>
    </span>
  </div>
</template>
<script>
import TrendIcon from "./TrendIcon";

export default {
  name: 'sales-card',
  components: {
    TrendIcon
  },
  props: {
    cardTitle: {
      type: String,
      required: true,
      default: ''
    },
    cardSubTitle: {
      type: String,
      required: true,
      default: ''
    },
    data: {
      type: Object,
      required: false,
      default: null
    },
  }
}
</script>