<template>
  <div>
    <b-table
        striped
        class="table-lg table-nowrap card-table"
        :items="items"
    ></b-table>
  </div>
</template>
<script>
export default {
  name: 'table-card',
  data() {
    return {
      items: [
        { recipe: '早：蛋白200g, 中： 鸡肉 牛肉 虾100g， 晚: 鸡肉牛肉150g虾蔬菜200g', created_at: '2023-06-01' },
        { recipe: 'Larsen', created_at: '2023-05-01' },
        { recipe: 'Geneva', created_at: '2023-04-01' },
        { recipe: 'Jami', created_at: '2023-03-01' }
      ]
    }
  }
}
</script>