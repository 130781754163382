import { ProductEntity } from './api'

export const getCoursePackages = async ({commit}) => {
    await ProductEntity.getCoursePackages(commit)
}

export const getMembershipPackages = async ({commit}) => {
    await ProductEntity.getMembershipPackages(commit)
}

export const createCoursesPackage = async ({commit}, data) => {
    await ProductEntity.createCoursesPackage(commit, data)
}

export const createMembershipPackage = async ({commit}, data) => {
    await ProductEntity.createMembershipPackage(commit, data)
}

export const updateCoursePackage = async ({commit}, data) => {
    await ProductEntity.updateCoursePackage(commit, data)
}

export const updateMembershipPackage = async ({commit}, data) => {
    await ProductEntity.updateMembershipPackage(commit, data)
}

export const deleteCoursePackage = async ({commit}, id) => {
    await ProductEntity.deleteCoursePackage(commit, id)
}

export const deleteMembershipPackage = async ({commit}, id) => {
    await ProductEntity.deleteMembershipPackage(commit, id)
}

export default {
    getCoursePackages,
    getMembershipPackages,
    createCoursesPackage,
    createMembershipPackage,
    updateCoursePackage,
    updateMembershipPackage,
    deleteCoursePackage,
    deleteMembershipPackage
}