import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    signedIn: false,
    data: {
        roles: [],
    },
    useAppAs: null,
    fetchingActivities: false,
    userActivities: [],
    fetchingBodyIndexRecords: false,
    userBodyIndexRecords: [],
    fetchingReason: false,
    userReason: null
})

export const user = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}