<template>
  <div>
    <page-header
        :title="headerTitle"
    />
    <div class="card-header card-header-content-md-between">
      <div class="mb-2 mb-md-0">
        <b-form-group>
          <b-input-group
              class="input-group-merge input-group-flush"
          >
            <div class="input-group-prepend input-group-text">
              <i class="bi-search"></i>
            </div>
            <b-form-input
                v-model="filter"
                class=""
                type="search"
                placeholder="Search Events"
            />
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <calendar-detail
        class="mb-3"
        v-if="!fetching"
        :fetching="fetching"
        visible
        :user-type="usingAppAs[0]"
        :profile="user"
        :course-events="trainerCourses"
    />
    <courses-history-table
        v-if="!fetching"
        user-type="trainer"
        :user-id="user.id"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import { PageHeader } from '@/layout'
import CalendarDetail from '@/components/calendar/CalendarDetail'
import CoursesHistoryTable from '@/components/clients/bookingComponents/CoursesHistoryTable'

export default {
  name: 'my-calendar',
  components: {
    CoursesHistoryTable,
    PageHeader,
    CalendarDetail
  },
  data () {
    return {
      headerTitle: 'My Calendar',
      filter: null
    }
  },
  computed: {
    ...mapState('trainer', ['trainers']),
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('course', ['fetching', 'trainerCourses']),
    ...mapState('client', ['clients']),
  },
  methods: {
    ...mapActions('trainer', ['getTrainers']),
    ...mapActions('course', ['listTrainerCourses']),
    ...mapActions('client', ['getClients']),
  },
  async mounted() {
    if (this.trainers.length === 0) {
      await this.getTrainers()
    }
    if (this.clients.length === 0) {
      await this.getClients()
    }
    if (this.trainers.length === 0 && this.usingAppAs[0] === 'admin') {
      await this.getTrainers()
    } else {
      // load trainer courses
      await this.listTrainerCourses(this.user.id)
    }
  }
}
</script>