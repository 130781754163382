<template>
  <div class="card h-100">
    <div class="card-pinned">
      <div class="card-pinned-top-end">
        <b-dropdown right no-caret variant="light" toggle-class="btn-ghost-secondary btn-icon btn-sm rounded-circle">
          <template #button-content>
            <i class="bi-three-dots-vertical"></i>
          </template>
          <b-dropdown-item href="#">Edit</b-dropdown-item>
          <b-dropdown-item href="#">Another action</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item variant="danger" href="#">Delete</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="card-body text-center">
      <div class="avatar avatar-xl avatar-soft-primary avatar-circle avatar-centered mb-3">
        <span v-if="member.profileImage !== null" class="avatar-initials">R</span>
        <img v-else class="avatar-img" src="../assets/img/160x160/img1.jpg" alt="Image Description">
        <span class="avatar-status avatar-sm-status avatar-status-warning"></span>
      </div>
      <h3 class="mb-1">
        <a class="text-dark" href="#">{{ member.name }}</a>
      </h3>
      <div class="mb-3">
        <i class="bi-building me-1"></i>
        <span>Design</span>
      </div>
      <ul class="list-inline mb-0">
        <li v-for="(tag, index) in member.tags" :key="`tag-`+member.id+`-`+index" class="list-inline-item"><a class="badge bg-soft-secondary text-secondary p-2" href="#">{{ tag }}</a></li>
      </ul>
    </div>
    <div class="card-footer text-center">
      <div v-if="member.role === 'employee'" class="row justify-content-between align-items-center">
        <div class="col-auto py-1">
          <a class="fs-6 text-body" href="#">{{ member.completedCourse }} course completed</a>
        </div>
      </div>
      <div v-else-if="member.role === 'client'"></div>
      <div v-else-if="member.role === 'member'" class="row col-divider">
        <div class="col">
          <span class="h4">19</span>
          <span class="d-block fs-5">Completed</span>
        </div>
        <div class="col">
          <span class="h4">10</span>
          <span class="d-block fs-5">Left</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'member-card',
  props: {
    member: {
      type: Object,
      require: true,
      default: null
    }
  }
}
</script>