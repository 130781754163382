import { get } from 'lodash'

export const SUPER_ADMIN = 'super-admin'
export const ADMIN = 'admin'
export const MANAGER = 'manager'
export const TRAINER = 'trainer'
export const EMPLOYEE = 'employee'
export const CLIENT = 'client'
export const ALL = 'all'

export const ADMIN_ROLES = [ADMIN]
export const CLIENT_ROLES = [CLIENT]
export const MANAGER_ROLES = [MANAGER]
export const EMPLOYEE_ROLES = [EMPLOYEE]

export const SUPPORTED_ROLES = [ADMIN, MANAGER, TRAINER, CLIENT, EMPLOYEE]

const getRole = user => get(user.data, 'roles', [])

export const isAdmin = user => getRole(user).some(role => role === ADMIN)
export const isTrainer = user => getRole(user).some(role => role === TRAINER)
export const isManager = user => getRole(user).some(role => role === MANAGER)
export const isEmployee = user => getRole(user).some(role => role === EMPLOYEE)
export const isClient = user => getRole(user).some(role => role === CLIENT)
export const isSuperAdmin = user => getRole(user).some(role => role === SUPER_ADMIN)
export const canSessionViewApp = session => session.roles.some(role => SUPPORTED_ROLES.includes(role))

export const HOME_ROUTE = (roles) => {
    if (roles.includes(ADMIN) || roles.includes(TRAINER)) {
        return { name: 'client-overview' }
    } else {
        return {name: 'my-profile'} // need update
    }
}

