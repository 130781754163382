<template>
  <span
      v-if="trend > 0"
      class="badge bg-soft-success text-success p-1 ms-2"
  >
    <b-icon icon="graph-up"></b-icon> {{ trend }}
  </span>
  <span
      v-else-if="trend === 0"
      class="badge bg-soft-secondary text-body ms-2"
  >
    {{ trend }}
  </span>
  <span
      v-else
      class="badge bg-soft-danger text-danger p-1 ms-2"
  >
    <b-icon icon="graph-down"></b-icon> {{ trend }}
  </span>
</template>
<script>
export default {
  name: 'trend-icon',
  props: {
    from: {
      type: Number,
      required: true,
      default: 0
    },
    to: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    trend () {
      return this.to - this.from
    }
  }
}
</script>