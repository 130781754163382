<template>
  <div class="card h-100">
    <div class="card-body">
      <h6 class="card-subtitle mb-2">{{ subTitle }}</h6>

      <div class="row align-items-center gx-2">
        <div class="col">
          <span class="js-counter display-4 text-dark" :data-value="data.to">{{ data.to }}</span>
          <span class="text-body fs-5 ms-1">from {{ data.from }}</span>
        </div>
        <div class="col-auto">
          <trend-icon :to="data.to" :from="data.from" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TrendIcon from '@/layout/TrendIcon'
export default {
  name: 'stat-card',
  components: {
    TrendIcon
  },
  props: {
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    data: {
      type: Object,
      required: false,
      default: null
    }
  },
}
</script>