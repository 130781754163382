import * as TYPES from './types'

const listMembershipPackages = state => {
    state.fetching = true
}

const listMembershipPackagesSuccess = (state, data) => {
    state.membershipPackages = data
    state.fetching = false
}

const listMembershipPackagesFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const listCoursesPackages = state => {
    state.fetching = true
}

const listCoursesPackagesSuccess = (state, data) => {
    state.coursePackages = data
    state.fetching = false
}

const listCoursesPackagesFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const createCoursesPackage = () => {}
const createCoursesPackageSuccess = () => {}
const createCoursesPackageFailure = (state, error) => {
    console.log(error)
}

const createMembershipPackage = () => {}
const createMembershipPackageSuccess = () => {}
const createMembershipPackageFailure = (state, error) => {
    console.log(error)
}

const updateCoursePackage = () => {}
const updateCoursePackageSuccess = () => {}
const updateCoursePackageFailure = (state, error) => {
    console.log(error)
}

const updateMembershipPackage = () => {}
const updateMembershipPackageSuccess = () => {}
const updateMembershipPackageFailure = (state, error) => {
    console.log(error)
}

const deleteCoursePackage = () => {}
const deleteCoursePackageSuccess = () => {}
const deleteCoursePackageFailure = (state, error) => {
    console.log(error)
}

const deleteMembershipPackage = () => {}
const deleteMembershipPackageSuccess = () => {}
const deleteMembershipPackageFailure = (state, error) => {
    console.log(error)
}

export default {
    [TYPES.LIST_MEMBERSHIP_PACKAGES.ACTION]: listMembershipPackages,
    [TYPES.LIST_MEMBERSHIP_PACKAGES.SUCCESS]: listMembershipPackagesSuccess,
    [TYPES.LIST_MEMBERSHIP_PACKAGES.FAILURE]: listMembershipPackagesFailure,
    [TYPES.LIST_COURSES_PACKAGES.ACTION]: listCoursesPackages,
    [TYPES.LIST_COURSES_PACKAGES.SUCCESS]: listCoursesPackagesSuccess,
    [TYPES.LIST_COURSES_PACKAGES.FAILURE]: listCoursesPackagesFailure,
    [TYPES.CREATE_COURSES_PACKAGE.ACTION]: createCoursesPackage,
    [TYPES.CREATE_COURSES_PACKAGE.SUCCESS]: createCoursesPackageSuccess,
    [TYPES.CREATE_COURSES_PACKAGE.FAILURE]: createCoursesPackageFailure,
    [TYPES.CREATE_MEMBERSHIP_PACKAGE.ACTION]: createMembershipPackage,
    [TYPES.CREATE_MEMBERSHIP_PACKAGE.SUCCESS]: createMembershipPackageSuccess,
    [TYPES.CREATE_MEMBERSHIP_PACKAGE.FAILURE]: createMembershipPackageFailure,
    [TYPES.UPDATE_COURSES_PACKAGE.ACTION]: updateCoursePackage,
    [TYPES.UPDATE_COURSES_PACKAGE.SUCCESS]: updateCoursePackageSuccess,
    [TYPES.UPDATE_COURSES_PACKAGE.FAILURE]: updateCoursePackageFailure,
    [TYPES.UPDATE_MEMBERSHIP_PACKAGE.ACTION]: updateMembershipPackage,
    [TYPES.UPDATE_MEMBERSHIP_PACKAGE.SUCCESS]: updateMembershipPackageSuccess,
    [TYPES.UPDATE_MEMBERSHIP_PACKAGE.FAILURE]: updateMembershipPackageFailure,
    [TYPES.DELETE_COURSES_PACKAGE.ACTION]: deleteCoursePackage,
    [TYPES.DELETE_COURSES_PACKAGE.SUCCESS]: deleteCoursePackageSuccess,
    [TYPES.DELETE_COURSES_PACKAGE.FAILURE]: deleteCoursePackageFailure,
    [TYPES.DELETE_MEMBERSHIP_PACKAGE.ACTION]: deleteMembershipPackage,
    [TYPES.DELETE_MEMBERSHIP_PACKAGE.SUCCESS]: deleteMembershipPackageSuccess,
    [TYPES.DELETE_MEMBERSHIP_PACKAGE.FAILURE]: deleteMembershipPackageFailure
}