import { apiTypeHelper } from '@/base/network'

export const LIST_COURSES = apiTypeHelper('LIST_COURSES')
export const CREATE_COURSE = apiTypeHelper('CREATE_COURSE')
export const UPDATE_COURSE = apiTypeHelper('UPDATE_COURSE')
export const LIST_USER_COURSES_QUICK_SUMMARY = apiTypeHelper('LIST_USER_COURSES_QUICK_SUMMARY')
export const GET_CLIENT_COURSES = apiTypeHelper('GET_CLIENT_COURSES')
export const LIST_TRAINER_COURSES = apiTypeHelper('LIST_TRAINER_COURSES')
export const DELETE_COURSE = apiTypeHelper('DELETE_COURSE')

export const LIST_COURSE_TAGS = apiTypeHelper('LIST_COURSE_TAGS')

export const GET_USER_COURSES_SUMMARY = apiTypeHelper('GET_USER_COURSES_SUMMARY')
export const UPDATED_RESERVATION_STATUS = apiTypeHelper('UPDATED_RESERVATION_STATUS')
export const UPDATE_RESERVATION_DETAILS = apiTypeHelper('UPDATE_RESERVATION_DETAILS')
export const CREATE_RESERVATION = apiTypeHelper('CREATE_RESERVATION')

export const LIST_TRAINER_COURSES_SUMMARY = apiTypeHelper('LIST_TRAINER_COURSES_SUMMARY')