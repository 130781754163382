export const publicPagesUrl = () => {
    switch (window.location.origin) {
        case 'https:fitnesswu.ca':
            return 'https:fitnesswu.ca'
        case 'https://api.wufitness.ca':
            return 'https://api.wufitness.ca'
        default:
            return 'https://api.wufitness.ca'
    }
}

export const betaLogoutUrl = () => `${window.location.origin}/sign-in`