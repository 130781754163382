<template>
  <div class="navbar-vertical-content">
    <div class="nav nav-pills nav-vertical card-navbar-nav">
      <span class="dropdown-header">Dashboard</span>
      <small class="bi-three-dots nav-subtitle-replacer"></small>
      <div
          v-if="isAdmin || isManager"
          class="nav-item"
      >
        <b-link
            href="#"
            v-b-toggle.collapse-employees
            variant="link"
            :disabled="!isVisible"
            class="nav-link dropdown-toggle"
        ><i class="bi-people nav-icon"></i><span class="nav-link-title">Employees</span></b-link>
        <b-collapse
            id="collapse-employees"
            class="nav-collapse"
            :visible="isVisible"
            :class="!navbarExpanded ? 'nav-collapse-action-mobile' : null"
        >
          <router-link :to="{ name: 'user-overview' }" class="nav-link">Overview</router-link>
          <router-link :to="{ name: 'admin-board' }" class="nav-link">Employees</router-link>
          <router-link :to="{ name: 'user-trainers' }" class="nav-link">Trainers</router-link>
          <router-link :to="{ name: 'add-trainer' }" class="nav-link">Add Trainer<span class="badge bg-info rounded-pill ms-1">Hot</span></router-link>
          <router-link :to="{ name: 'add-employee' }" class="nav-link">Add Employee<span class="badge bg-info rounded-pill ms-1">Hot</span></router-link>
        </b-collapse>
      </div>
      <div
          v-if="isAdmin || isTrainer"
          class="nav-item"
      >
        <b-link
            href="#"
            v-b-toggle.collapse-clients
            variant="link"
            class="nav-link dropdown-toggle"
            :disabled="!isVisible"
        ><i class="bi-people nav-icon"></i><span class="nav-link-title">Clients</span></b-link>
        <b-collapse
            id="collapse-clients"
            class="nav-collapse"
            :visible="isVisible"
            :class="!navbarExpanded ? 'nav-collapse-action-mobile' : null"
        >
          <router-link :to="{ name: 'client-overview' }" class="nav-link">Overview</router-link>
          <router-link :to="{ name: 'add-client' }" class="nav-link">Add client<span class="badge bg-info rounded-pill ms-1">Hot</span></router-link>
        </b-collapse>
      </div>
      <div
          v-if="isAdmin"
          class="nav-item"
      >
        <b-link
            href="#"
            v-b-toggle.collapse-ecommerce
            variant="link"
            class="nav-link dropdown-toggle"
            :disabled="!isVisible"
        >
          <i class="bi-basket nav-icon"></i><span class="nav-link-title">E-commerce</span>
        </b-link>
        <b-collapse
            id="collapse-ecommerce"
            class="nav-collapse"
            :visible="isVisible"
            :class="!navbarExpanded ? 'nav-collapse-action-mobile' : null"
        >
          <router-link :to="{ name: 'e-commerce-overview' }" class="nav-link">Overview</router-link>
          <b-link
              href="#"
              v-b-toggle.collapse-products
              variant="link"
              class="nav-link dropdown-toggle"
          >
            Products
          </b-link>
          <b-collapse
              id="collapse-products"
              class="nav-collapse"
          >
            <router-link :to="{ name: 'e-commerce-products' }" class="nav-link">Products</router-link>
            <router-link :to="{ name: 'add-product' }" class="nav-link">Add Product</router-link>
          </b-collapse>
        </b-collapse>
      </div>
      <div
          v-if="isTrainer"
          class="nav-item"
      >
        <router-link class="nav-link" :to="{ name: 'calendar' }"><i class="bi-calendar-week nav-icon"></i><span class="nav-link-title">Calendar</span></router-link>
      </div>
      <div
          v-if="isClient"
          class="nav-item"
      >
        <router-link class="nav-link" :to="{ name: 'client-bookings' }"><i class="bi-calendar-week nav-icon"></i><span class="nav-link-title">Bookings</span></router-link>
      </div>
      <div class="nav-item">
        <router-link class="nav-link" :to="{name: 'my-profile'}"><i class="bi bi-person nav-icon"></i><span class="nav-link-title">My Profile</span></router-link>
      </div>
      <div v-if="isClient || isTrainer" class="nav-item">
        <router-link class="nav-link" :to="{name: 'profile-body-index'}"><i class="bi bi-calculator nav-icon"></i><span class="nav-link-title">Body Composition Analysis</span></router-link>
      </div>
      <div v-if="isClient" class="nav-item">
        <router-link class="nav-link" :to="{name: 'my-payments'}"><i class="bi bi-palette nav-icon"></i><span class="nav-link-title">My Payments</span></router-link>
      </div>
      <span
          v-if="isAdmin"
          class="dropdown-header mt-4"
      >
        Settings
      </span>
<!--      <div class="nav-item">-->
<!--        <router-link class="nav-link" :to="{ name: '#' }"><i class="bi-book nav-icon"></i><span class="nav-link-title">Settings</span></router-link>-->
<!--      </div>-->
      <div
          v-if="isAdmin || isTrainer"
          class="nav-item"
      >
        <router-link class="nav-link" :to="{ name: 'courses-overview' }"><i class="bi-book nav-icon"></i><span class="nav-link-title">Courses</span></router-link>
      </div>
      <div
          v-if="isAdmin || isTrainer"
          class="nav-item"
      >
        <router-link class="nav-link" :to="{ name: 'membership-packages-overview' }"><i class="bi-book nav-icon"></i><span class="nav-link-title">Membership Packages</span></router-link>
      </div>
      <span
          v-if="isAdmin"
          class="dropdown-header mt-4"
      >Documentation</span>
      <div
          v-if="isAdmin"
          class="nav-item"
      >
        <router-link class="nav-link" :to="{ name: 'documentation-overview' }"><i class="bi-book nav-icon"></i><span class="nav-link-title">Documentation</span></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'nav-bar-content',
  data() {
    return {
    }
  },
  computed: {
    ...mapState('alert', ['sm', 'navbarExpanded', 'navbarExpandedMobile']),
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    isVisible () {
      return this.sm ? this.navbarExpandedMobile : this.navbarExpanded
    },
    isAdmin () {
      return this.user.roles.some(role => role === 'admin')
    },
    isManager () {
      return this.user.roles.some(role => role === 'manager')

    },
    isTrainer () {
      return this.user.roles.some(role => role === 'trainer')
    },
    isEmployee () {
      return this.user.roles.some(role => role === 'employee')
    },
    isClient () {
      return this.user.roles.some(role => role === 'client')
    },
  },
  methods: {
  }
}
</script>
<style lang="css">
</style>