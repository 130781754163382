<template>
  <b-modal
      id="change-password-modal"
      title="Change Password"
  >
    <form>
      <b-row v-if="visible" class="mb-4">
        <label for="editUserModalCurrentPasswordLabel" class="col-sm-3 col-form-label form-label">Current Password</label>
        <b-col sm="9">
          <b-form-group class="input-group-merge">
            <b-form-input
                id="editUserModalCurrentPasswordLabel"
                :type="showPassword?'text':'password'"
            />
            <div class="input-group-append input-group-text">
              <b-button variant="link" class="p-0" @click="showHidePwIcon"><i :class="showPassword?'bi-eye':'bi-eye-slash'"></i></b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <label for="editUserNewPassword" class="col-sm-3 col-form-label form-label">New password</label>
        <b-col sm="9">
          <b-form-input
              id="editUserNewPassword"
              :type="showResetPassword?'text':'password'"
              :state="isValidPassword"
              v-model="newPassword"
              aria-describedby="input-password-feedback"
          />
          <b-form-invalid-feedback id="input-password-feedback">
            Enter minimum eight characters, at least one letter and one number
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <label for="editUserConfirmNewPasswordLabel" class="col-sm-3 col-form-label form-label">Confirm new password</label>
        <b-col sm="9">
          <b-form-input
              id="editUserConfirmNewPasswordLabel"
              :type="showResetPassword?'text':'password'"
              :state="isValidRepeatPassword"
              v-model="repeatPassword"
              aria-describedby="input-repeat-password-feedback"
          />
          <b-form-invalid-feedback id="input-repeat-password-feedback">
            Password not matching
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="9" offset-sm="3">
          <b-form-checkbox v-model="showResetPassword">Show password</b-form-checkbox>
        </b-col>
      </b-row>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="out-secondary"
            class="btn-outline-primary float-end ml-2"
            @click="handleCloseModal"
        >
          Close
        </b-button>
        <b-button
            v-if="isValidPassword && isValidRepeatPassword && newPassword !== ''"
            variant="primary"
            class="btn-soft-primary float-end"
            @click="handleUpdatePassword"
        >
          Update Password
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'change-password-modal',
  props: {
    userId: {
      type: [String, Number],
      required: true,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      showPassword: false,
      showResetPassword: false,
      newPassword: '',
      repeatPassword: ''
    }
  },
  computed: {
    isValidPassword () {
      //Minimum eight characters, at least one letter and one number
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
      return regex.test(this.newPassword) || (this.newPassword ===  '' && this.repeatPassword === '')
    },
    isValidRepeatPassword () {
      return this.newPassword === this.repeatPassword
    }
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    showHidePwIcon () {
      this.showPassword = !this.showPassword
    },
    async handleUpdatePassword () {
      let data = {
        id: this.userId,
        pw: this.newPassword
      }
      await this.resetPassword(data)
      await this.$emit('message')
      this.handleCloseModal()
    },
    handleCloseModal () {
      this.$bvModal.hide('change-password-modal')
    }
  }
}
</script>