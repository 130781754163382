<template>
  <div
      v-if="!fetchingUserCoursesSummary"
      class="card"
  >
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title mb-2 mb-sm-0">
        Course Status
      </h4>
      <b-dropdown
          variant="out-secondary"
          toggle-class="btn-white"
          right
          menu-class="dropdown-card dropdown-menu"
      >
        <template #button-content>
          <div> {{ $t(`course.statusOptions.${currentOption}`) }}</div>
        </template>
        <b-dropdown-item
            v-for="option in statusOptions"
            :key="option"
            @click="handleChangeStatusOption(option)"
        >
         {{ $t(`course.statusOptions.${option}`) }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="card-body">
      <div
          v-if="'balanced' in userCoursesSummary"
          class="flex-grow-1 ms-3"
      >
        <h5 class="text-hover-primary mb-0">Courses Balance</h5>
        <span class="fs-6 text-body">{{ userCoursesSummary.balanced }} courses</span>
      </div>
      <div class="chartjs-custom mx-auto" style="height: 16rem;">
        <BubbleChart
            :chart-data="chartCoursesData"
        />
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <span class="legend-indicator bg-primary"></span> Completed
        </div>
        <div class="col-auto">
          <span class="legend-indicator" style="background-color: #7000f2;"></span> Scheduled
        </div>
        <div class="col-auto">
          <span class="legend-indicator bg-info"></span> Cancelled
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import {
  BubbleChart,
} from '@/layout'
export default {
  name: 'course-status',
  components: {
    BubbleChart
  },
  props: {
    userId: {
      type: [Number, String],
      required: false,
      default: ''
    }
  },
  data () {
    return {
      statusOptions: ['monthly', 'yearly', 'total'],
      currentOption: 'monthly'
    }
  },
  computed: {
    ...mapState('course', ['fetchingUserCoursesSummary', 'userCoursesSummary']),
    chartCoursesData () {
      let completedData = null
      let cancelledData = null
      let scheduledData = null
      if (this.fetchingUserCoursesSummary || this.userCoursesSummary.length === 0) {
        return { datasets: [] }
      }
      if (this.currentOption === 'monthly') {
        completedData = this.userCoursesSummary.monthly.find(item => Object.keys(item)[0] === 'completed').completed
        cancelledData = this.userCoursesSummary.monthly.find(item => Object.keys(item)[0] === 'cancelled').cancelled
        scheduledData = this.userCoursesSummary.monthly.find(item => Object.keys(item)[0] === 'scheduled').scheduled
      } else if (this.currentOption === 'yearly') {
        completedData = this.userCoursesSummary.yearly.find(item => Object.keys(item)[0] === 'completed').completed
        cancelledData = this.userCoursesSummary.yearly.find(item => Object.keys(item)[0] === 'cancelled').cancelled
        scheduledData = this.userCoursesSummary.yearly.find(item => Object.keys(item)[0] === 'scheduled').scheduled
      } else {
        completedData = this.userCoursesSummary.total.find(item => Object.keys(item)[0] === 'completed').completed
        cancelledData = this.userCoursesSummary.total.find(item => Object.keys(item)[0] === 'cancelled').cancelled
        scheduledData = this.userCoursesSummary.total.find(item => Object.keys(item)[0] === 'scheduled').scheduled
      }
      let datasets = [
        {
          datalabels: {
            color: '#ffffff',
          },
          label: 'Completed',
          "backgroundColor": "rgba(55, 125, 255, 0.9)",
          data: [
            {x: 50, y: 65, r: 99, v: completedData}
          ]
        },
        {
          label: 'Scheduled',
          "backgroundColor": "rgba(100, 0, 214, 0.8)",
          data: [
            {x: 46, y: 42, r: 65, v: scheduledData}
          ]
        },
        {
          label: 'Cancelled',
          "color": "#fff",
          "backgroundColor": "#00c9db",
          "borderColor": "transparent",
          data: [
            {x: 48, y: 15, r: 38, v: cancelledData}
          ]
        }
      ]
      return { datasets: datasets }
    }
  },
  methods: {
    ...mapActions('course', ['getUserCoursesSummary']),
    handleChangeStatusOption (option) {
      this.currentOption = option
    }
  },
  async mounted() {
    await this.getUserCoursesSummary(this.userId)
  }
}
</script>