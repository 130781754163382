<template>
  <div class="navbar-nav-wrap-content-start">
    <b-button
        variant="link"
        class="navbar-aside-toggler text-decoration-none btn-ghost-secondary btn-icon rounded-circle"
        style="opacity: 1;"
        @click="handleExpanded"
    >
      <i
          class="bi-arrow-bar-right navbar-toggler-full-align"
      />
    </b-button>
    <div class="dropdown ms-2">
      <div class="d-none d-lg-block">
        <b-input-group class="input-group-merge input-group-borderless input-group-hover-light navbar-input-group">
          <div class="input-group-prepend input-group-text">
            <i class="bi-search"></i>
          </div>
          <b-form-input
              v-model="filter"
              class=""
              type="search"
              placeholder="Search in our Platform"
          />
        </b-input-group>
      </div>
      <b-button
          variant="link"
          class="btn-icon rounded-circle d-lg-none btn-ghost-secondary"
      >
        <i class="bi-search"></i>
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'global-search-input',
  data () {
    return {
      filter: null
    }
  },
  computed: {
    ...mapState('alert', ['navbarExpanded']),
  },
  methods: {
    ...mapActions('alert', ['setNavbarExpandedMobile']),
    handleExpanded () {
      this.setNavbarExpandedMobile()
    }
  }
}
</script>