import { CompanyEntity } from './api'

export const getCompanySettings = async ({commit}) => {
    await CompanyEntity.getCompanySettings(commit)
}

export const getCompanyBodyIndexFieldSettings = async ({commit}) => {
    await CompanyEntity.getCompanyBodyIndexFieldSettings(commit)
}

export default {
    getCompanySettings,
    getCompanyBodyIndexFieldSettings
}