<template>
  <b-modal id="delete-data-modal" :title="title" hide-footer>
    <div class="card-body text-center">
      <img class="card-img mb-3" src="@/assets/svg/empty/oc-lost.svg" style="max-width: 15rem;" alt="delete data"/>
      <h3 class="text-center text-danger">Are u sure u wanna delete {{ data }} ?</h3>
      <h3 class="text-center text-danger">{{ description}}</h3>
    </div>
    <div class="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2">
      <b-button class="mt-3" @click="closeModal">Cancel</b-button>
      <b-button class="mt-3" variant="danger" @click="deleteData">Delete</b-button>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'delete-data-modal',
  props: {
    title: {
      type: String,
      default: 'title',
      required: true
    },
    description: {
      type: String,
      default: 'description',
      required: false
    },
    data: {
      type: String,
      default: 'data',
      required: true
    },
  },
  methods: {
    deleteData () {
      this.$emit('handle-delete-user')
    },
    closeModal () {
      this.$bvModal.hide('delete-data-modal')
    }
  }
}
</script>