<template>
  <div class="card-body">
    <!-- Phone -->
    <div class="row mb-4">
      <label for="phone" class="col-sm-3 col-form-label form-label">Phone</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="phone"
              type="number"
              placeholder="111-111-1111"
              required
              v-model="billing.phone"
              :state="billing.phone !== null"
              @input="value => updateValue(value, 'phone')"
          />
        </div>
      </div>
    </div>
    <!-- Address -->
    <div class="row mb-4">
      <label for="address" class="col-sm-3 col-form-label form-label">Address</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="address"
              type="text"
              placeholder="Your address"
              required
              v-model="billing.address"
              :state="billing.address !== null"
              @input="value => updateValue(value, 'address')"
          />
        </div>
      </div>
    </div>
    <!-- Zip Code -->
    <div class="row mb-4">
      <label for="zipCode" class="col-sm-3 col-form-label form-label">Zip code</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="zipCode"
              type="text"
              placeholder="Your Zip code"
              required
              v-model="billing.zipCode"
              :state="billing.zipCode !== null"
              @input="value => updateValue(value, 'zipCode')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'billing-address',
  data () {
    return {
      billing: {
        phone: null,
        address: null,
        zipCode: null
      }
    }
  },
  methods: {
    updateValue (value, key) {
      this.$emit('input', value, key)
    }
  }
}
</script>