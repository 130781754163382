<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">Organization</h4>
    </div>
    <div class="card-body">
      <div class="mb-4">
        <label for="vendorLabel" class="form-label">Vendor</label>
        <input type="text" class="form-control" name="vendor" id="vendorLabel" placeholder="eg. Nike" aria-label="eg. Nike">
      </div>
      <div class="mb-4">
        <label for="categoryLabel-ts-control" class="form-label" id="categoryLabel-ts-label">Category</label>
        <b-form-select v-model="selected" :options="options"></b-form-select>
      </div>
      <div class="mb-4">
        <label for="tagsLabel" class="form-label">Tags</label>
        <b-form-tags input-id="tags-basic" v-model="value"></b-form-tags>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'product-organization',
  data () {
    return {
      selected: 'cloth',
      options: [
        { value: 'cloth', text: 'Clothing' },
        { value: 'shoes', text: 'shoes' },
        { value: 'food', text: 'Food' },
        { value: 'drink', text: 'drink' },
      ],
      value: ['apple', 'orange']
    }
  }
}
</script>