<template>
  <li class="list-group-item">
    <div class="d-flex justify-content-between">
      <div class="mb-1">
        <h5 class="mb-0">{{ getCourseName(title) }}</h5>
        <p class="mb-1"><span class="text-white">Client: </span>{{ clientName }}</p>
        <p><span class="text-white">Trainer: </span>{{ trainerName }}</p>
      </div>
      <p>{{ getTags(tags) }}</p>
    </div>
    <div class="d-flex justify-content-between">
      <span class="h4 text-primary">Duration: {{ duration }}hour(s)</span>
      <span class="fs-5">{{startTime}}</span>
    </div>
  </li>
</template>
<script>
import { getLocale } from '@/base/utils'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'course-default-list-item',
  props: {
    title: {
      type: String,
      required: true,
      default: 'course title'
    },
    clientName: {
      type: String,
      required: true,
      default: 'client Name'
    },
    trainerName: {
      type: String,
      required: true,
      default: 'trainer Name'
    },
    duration: {
      type: Number,
      required: true,
      default: 1
    },
    startTime: {
      type: String,
      required: true,
      default: '8:00 - 10: 00'
    },
    tags: {
      type: Array,
      required:false,
      default: () => []
    },
    date: {
      type: String,
      required: true,
      default: '15 May, 2020'
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState('course', ['courseTags']),
    locale () {
      return getLocale()
    }
  },
  methods: {
    ...mapActions('course', ['listCourseTags']),
    getCourseName (name) {
      if (this.$te('course.courseName.' + name)) {
        return this.$t('course.courseName.' + name)
      } else {
        return name
      }
    },
    getTags(tagsIds){
      if (tagsIds === undefined || tagsIds === null)
      {
        return null
      }
      return this.courseTags.filter(tag => tagsIds.includes(tag.id)).map(tag => {
        return tag.name[this.locale]
      }).toString()
    }
  }
}
</script>