<template>
  <div class="card">
    <div class="card-header card-header-content-md-between">
      <h4 class="card-header-title">Reservation History</h4>
    </div>
    <div class="table-responsive">
      <b-table
          v-if="!fetching"
          show-empty
          id="user-courses-history-table"
          :fields="tableColumns"
          :items="tableItems"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by="`end`"
          sort-desc
          class="table-align-middle table-nowrap card-table"
      >
        <template #cell(status)="data">
          <span :class="getStatusIcon(data.value)"></span>{{ data.value }}
        </template>
        <template #cell(tags)="data">
          {{ getTags(data.value) }}
        </template>
        <template #cell(is_free)="data">
          {{ data.value === 1 ? 'Yes' : data.value === 0 ? 'No' : null }}
        </template>
        <template #cell(title)="data">
          {{ $t('course.courseName.'+ data.value) }}
        </template>
      </b-table>
    </div>
    <div class="card-footer">
      <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
          <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
            <p>Current Page: {{ currentPage }}</p>
          </div>
        </div>
        <div class="col-sm-auto">
          <div class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="user-courses-history-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getLocale } from '@/base/utils'
import {mapState} from 'vuex'

export default {
  name: 'user-courses-history-table',
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    tableItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      perPage: 10,
      currentPage: 1,
      sortBy: 'id',
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'title', label: 'Course Name', sortable: true},
        { key: 'start', label: 'Start Time', sortable: true},
        { key: 'end', label: 'End Time', sortable: true},
        { key: 'tags', label: 'Tags', sortable: false},
        { key: 'status', label: 'Status', sortable: true},
        { key: 'clientName', label: 'Client Name', sortable: true},
        { key: 'trainerName', label: 'Trainer Name', sortable: true},
        { key: 'is_free', label: 'Is Free', sortable: true},
        { key: 'createdBy', label: 'CreatedBy', sortable: false},
      ]
    }
  },
  computed: {
    ...mapState('course', ['courseTags']),
    locale () {
      return getLocale()
    },
    rows () {
      return this.tableItems.length
    }
  },
  methods: {
    getStatusIcon (status) {
      switch (status) {
        case 'completed':
          return 'legend-indicator bg-primary'
        case 'scheduled':
          return 'legend-indicator bg-info'
        case 'cancelled':
          return 'legend-indicator bg-warning'
        default:
          return 'legend-indicator bg-warning'
      }
    },
    getTags(tagsIds){
      if (tagsIds === undefined || tagsIds === null)
      {
        return null
      }
      return this.courseTags.filter(tag => tagsIds.includes(tag.id)).map(tag => {
        return tag.name[this.locale]
      }).toString()
    }
  }
}
</script>