import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    clients: [],
    clientsForAddingCourses: [],
    clientsCountSummary: [],
    clientTypesSummary: [],
    clientProfile: {},
    initialClientType: ['yearly', 'seasonal', 'monthly', 'weekly', 'dayPass'],
    fetchingRecipes: false,
    recipes: [],
    addedClient: null
})

export const client = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}