import { UserProfileEntity } from './api'

export const getMyProfile = async ({ commit }, id) => {
    await UserProfileEntity.getMyProfile(commit, id)
}

export const getUserProfile = async ({commit}, id) => {
    await UserProfileEntity.getUserProfile(commit, id)
}

export const updateUserProfile = async ({commit}, {id, data}) => {
    await UserProfileEntity.updateUserProfile(commit, id, data)
}

export default {
    getMyProfile,
    getUserProfile,
    updateUserProfile
}