<template>
  <div class="row">
    <div class="col-lg-4 mb-3 mb-lg-5">
      <div class="d-grid gap-2 gap-lg-4">
        <a class="card card-hover-shadow" href="#">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img class="avatar avatar-lg avatar-4x3" src="../../../assets/svg/illustrations/oc-megaphone.svg" alt="Image Description" style="min-height: 5rem;">
              </div>
              <div class="flex-grow-1 ms-4">
                <h3 class="text-inherit mb-1">Product</h3>
                <span class="text-body">Create a new product</span>
              </div>
              <div class="ms-2 text-end">
                <i class="bi-chevron-right text-body text-inherit"></i>
              </div>
            </div>
          </div>
        </a>
        <a class="card card-hover-shadow" href="#">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img class="avatar avatar-lg avatar-4x3" src="../../../assets/svg/illustrations/oc-discount.svg" alt="Image Description" style="min-height: 5rem;" data-hs-theme-appearance="default">
              </div>

              <div class="flex-grow-1 ms-4">
                <h3 class="text-inherit mb-1">Discount</h3>
                <span class="text-body">Create a new discount</span>
              </div>

              <div class="ms-2 text-end">
                <i class="bi-chevron-right text-body text-inherit"></i>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-lg-8 mb-3 mb-lg-5">
      <div class="card h-100">
        <div class="card-header card-header-content-between">
          <h4 class="card-header-title">
            Top Products
          </h4>
          <b-button
              size="sm"
              variant="outline-secondary"
              class="btn-ghost-secondary"
          >View all</b-button>
        </div>
        <div class="card-body-height">
          <top-products-table />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopProductsTable from "./TopProductsTable";
export default {
  name: 'products-overview',
  components: {
    TopProductsTable
  }
}
</script>