<template>
  <div v-if="!fetching" class="card">
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title">
        Quick Summary for Course
      </h4>
      <b-button variant="outline-secondary" class="btn-white">This week</b-button>
    </div>
    <div class="card-body">
      <b-tabs
          fill
          card
          pills
          nav-class="js-tabs-to-dropdown nav nav-segment nav-fill"
          js-nav-scroller hs-nav-scroller-horizontal mb-5
      >
        <b-tab>
          <template #title>
            <span class="legend-indicator bg-primary"></span>Completed
          </template>
          <ul class="list-group list-group-flush list-group-no-gutters">
            <course-list-item
                v-for="(courseItem, index) in completedCourses"
                :key="`course-item-`+index"
                :title="courseItem.courseName"
                :client-name="courseItem.clientName"
                :trainer-name="courseItem.trainerName"
                :duration="courseItem.duration"
                :start-time="courseItem.start"
                :tags="courseItem.tags"
            />
          </ul>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="legend-indicator bg-warning"></span>Scheduled
          </template>
          <ul class="list-group list-group-flush list-group-no-gutters">
            <course-list-item
                v-for="(courseItem, index) in scheduledCourses"
                :key="`course-item-`+index"
                :title="courseItem.courseName"
                :client-name="courseItem.clientName"
                :trainer-name="courseItem.trainerName"
                :duration="courseItem.duration"
                :start-time="courseItem.startTime"
                :date="courseItem.date"
                :tags="courseItem.tags"
            />
          </ul>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="legend-indicator bg-info"></span>Cancelled
          </template>
          <ul class="list-group list-group-flush list-group-no-gutters">
            <course-list-item
                v-for="(courseItem, index) in cancelledCourses"
                :key="`course-item-`+index"
                :title="courseItem.courseName"
                :client-name="courseItem.clientName"
                :trainer-name="courseItem.trainerName"
                :duration="courseItem.duration"
                :start-time="courseItem.startTime"
                :date="courseItem.date"
                :tags="courseItem.tags"
            />
          </ul>
        </b-tab>
      </b-tabs>
    </div>
  </div>
  <b-spinner
      v-else
      variant="primary"
      class="align-middle"
  ></b-spinner>
</template>
<script>
import { CourseListItem } from './components'

export default {
  name: 'course-summary',
  components: {
    CourseListItem
  },
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    courseListData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      // courseListData: [
      //   {
      //     title: 'traditional strength training',
      //     userName: 'Chenwei Song',
      //     duration: 1,
      //     startTime: '8:00 - 10: 00',
      //     date: '15 May, 2020'
      //   },
      // ]
    }
  },
  computed: {
    completedCourses  () {
      return this.courseListData.filter((course) => course.status === 'completed')
    },
    scheduledCourses () {
      return this.courseListData.filter((course) => course.status === 'scheduled')
    },
    cancelledCourses () {
      return this.courseListData.filter((course) => course.status === 'cancelled')
    }
  }
}
</script>