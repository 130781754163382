<template>
  <div class="table-responsive">
    <b-table
        v-if="!fetching"
        show-empty
        small
        id="top-sales-table"
        head-variant="dark"
        sort-icon-left
        borderless
        :per-page="perPage"
        :current-page="currentPage"
        :fields="tableColumns"
        :items="tableCells"
        class="table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <template #cell(index)="data">
        <div v-html="getRank(data.index + 1)"></div>
      </template>
      <template #cell(sales)="data">
        <div class="d-flex align-items-center">
          <div class="ms-3">
            <span class="d-block h5 text-inherit mb-0">
              {{ data.value.first_name.toUpperCase() + ' '+ data.value.last_name.toUpperCase() }}
            </span>
            <span class="d-block fs-5 text-body">{{ data.value.email }}</span>
          </div>
        </div>
      </template>
      <template #cell(total_membership_amount)="data">
        <h4 class="mb-0">${{ data.value }}</h4>
      </template>
      <template #cell(total_course_amount)="data">
        <h4 class="mb-0">${{ data.value }}</h4>
      </template>
    </b-table>
    <b-spinner v-else />
  </div>
</template>
<script>
export default {
  name: 'top-sales-table',
  props: {
    fetching: {
      type: Boolean,
      required: true,
      default: false
    },
    tableCells: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      perPage: 5,
      currentPage: 1,
      tableColumns: [
        { key: 'index', label: 'Rank'},
        { key: 'sales', label: 'Sales Info'},
        { key: 'total_membership_amount', label: 'Membership AMOUNT', sortable: true},
        { key: 'total_course_amount', label: 'COURSES AMOUNT', sortable: true},
      ],
      // tableCells: [
      //   { id: 1, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, members: 20 },
      //   { id: 2, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, members: 15 },
      //   { id: 3, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, members: 10 },
      //   { id: 4, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, members: 5 },
      //   { id: 5, name: { first_name: 'John', last_name: 'Doe', email: 'ella@site.com' }, members: 3 },
      // ]
    }
  },
  methods: {
    getRank (rank) {
      switch (rank) {
        case 1:
          return `<span class="text-warning" style="font-size: 1.5rem;">🥇</span>`
        case 2:
          return `<span class="text-secondary" style="font-size: 1.5rem;">🥈</span>`
        case 3:
          return `<span style="font-size: 1.5rem; color: #924b18;">🥉</span>`
        default:
          return `<span style="padding-left: 8px;">${rank}</span>`
      }
    }
  }
}
</script>