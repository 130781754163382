<template>
  <div class="card mb-3 mb-lg-5">
    <div class="card-header">
      <h4 class="card-header-title">Pricing</h4>
    </div>
    <div class="card-body">
      <div class="mb-4">
        <label for="priceNameLabel" class="form-label">Price</label>
        <div class="input-group">
          <input type="text" class="form-control" name="priceName" id="priceNameLabel" placeholder="0.00" aria-label="0.00">
          <div class="input-group-append">
            <b-form-select v-model="selected" :options="options"></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'product-price',
  data () {
    return {
      selected: 'cad',
      options: [
        { value: 'cad', text: 'CAD' },
        { value: 'rmb', text: 'RMB' },
      ],
    }
  }
}
</script>