<template>
  <div class="card-body">
    <div class="row">
      <!-- Height -->
      <div class="col-sm-4">
        <div class="mb-4">
          <label for="height" class="col col-form-label form-label">Height (cm)</label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm-vertical">
              <b-form-input id="height" type="number" placeholder="160"></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Age -->
      <div class="col-sm-4">
        <div class="mb-4">
          <label for="age" class="col col-form-label form-label">Age</label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm-vertical">
              <b-form-input id="age" type="number" placeholder="28"></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <!-- Gender -->
      <div class="col-sm-4">
        <div class="mb-4">
          <label for="gender" class="col col-form-label form-label">Gender</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="gender" id="male" value="male">
            <label class="form-check-label" for="male">Male</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="gender" id="female" value="female">
            <label class="form-check-label" for="female">female</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Weight -->
      <div class="col-sm-4">
        <div class="mb-4">
          <label for="weight" class="col col-form-label form-label">Weight (lbs)</label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm-vertical">
              <b-form-input id="weight" type="number" placeholder="50"></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <!-- SMM -->
      <div class="col-sm-4">
        <div class="mb-4">
          <label for="smm" class="col col-form-label form-label">SMM (lbs)</label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm-vertical">
              <b-form-input id="smm" type="number" placeholder="48"></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <!-- PBF -->
      <div class="col-sm-4">
        <div class="mb-4">
          <label for="pmf" class="col col-form-label form-label">PMF (%)</label>
          <div class="col-sm-9">
            <div class="input-group input-group-sm-vertical">
              <b-form-input id="pmf" type="number" placeholder="42"></b-form-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <drop-file />
    </div>
  </div>
</template>
<script>
import DropFile from "@/layout/DropFile";
export default {
  name: 'body-composition-analysis',
  components: {
    DropFile
  }
}
</script>