import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    GET_MY_PROFILE,
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'USER_PROFILE'
}

export const UserProfileEntity = new NetworkEntity(options)

UserProfileEntity.getMyProfile = function (commit, id) {
    commit(GET_MY_PROFILE.ACTION)
    return axios
        .get(`${this.endpoint}/${id}/profile`)
        .then(({ data: { data } }) => {
            commit(GET_MY_PROFILE.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_MY_PROFILE.FAILURE, err)
        })
}

UserProfileEntity.getUserProfile = function (commit, id) {
    commit(GET_USER_PROFILE.ACTION)
    return axios
        .get(`${this.endpoint}/users/${id}/profile`)
        .then(({ data: { data } }) => {
            commit(GET_USER_PROFILE.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_USER_PROFILE.FAILURE, err)
        })
}

UserProfileEntity.updateUserProfile = function (commit, id, data) {
    commit(UPDATE_USER_PROFILE.ACTION)
    return axios
        .post(`${this.endpoint}/users/${id}/profile`, data)
        .then(({ data: { data } }) => {
            commit(UPDATE_USER_PROFILE.SUCCESS, data)
        })
        .catch(err => {
            commit(UPDATE_USER_PROFILE.FAILURE, err)
        })
}