import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    myProfile: [],
    myProfileJson: null,
    fetchingUser: false,
    userProfile: null
})

export const userProfile = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}
