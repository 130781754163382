import { render, staticRenderFns } from "./OnLeaveModal.vue?vue&type=template&id=481cd48e&scoped=true"
import script from "./OnLeaveModal.vue?vue&type=script&lang=js"
export * from "./OnLeaveModal.vue?vue&type=script&lang=js"
import style0 from "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./OnLeaveModal.vue?vue&type=style&index=1&id=481cd48e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481cd48e",
  null
  
)

export default component.exports