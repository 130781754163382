<template>
 <div class="chartjs-matrix-custom">
   <div v-for="weekday in 8" :key="`week-`+ weekday" class="d-flex">
     <div class="weekday" v-if="[2,4,6].includes(weekday)">
       <b-button disabled variant="link" class="p-0">{{ daysOfWeek[weekday-1] }}</b-button>
     </div>
     <div class="weekday" v-else><b-button disabled variant="link" class="p-0"></b-button></div>
     <div v-for="month in currentMonthDays" :key="`day-`+month" class="">
       <b-button
           v-if="weekday !== 8"
           :id="`hour-`+month"
           variant="link"
           v-b-tooltip.hover
           class="p-0 icon-button"
           :style="`color: rgba(55, 125, 255,`+hours[hour]+`)`"
       >
         <div class="square-icon"></div>
       </b-button>
       <b-tooltip
           v-if="weekday !== 8"
           :target="`hour-`+month"
       >
         <strong>2hours</strong> on 2023-08-01
       </b-tooltip>
       <b-button
           v-else
           variant="link"
           disabled
           class="p-0"
           style="width: 28px;"
       >
         {{showDay(month)}}
       </b-button>
     </div>
   </div>
   <ul id="matrixLegend" class="mb-0 mt-4 hs-chartjs-matrix-legend">
     <li class="hs-chartjs-matrix-legend-min">0</li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.04)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.09)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.14)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.18)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.23)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.28)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.33)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.38)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.42)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.47)"></li>
     <li class="hs-chartjs-matrix-legend-item" style="background-color: rgba(55, 125, 255, 0.52)"></li>
     <li class="hs-chartjs-matrix-legend-max">24</li>
   </ul>
 </div>
</template>
<script>
export default {
  name: 'matrix-chart',
  data () {
    return {
      daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      hours:{
        2: '0.04',
        4: '0.09',
        6: '0.14',
        8: '0.18',
        10: '0.23',
        12: '0.28',
        14: '0.33',
        16: '0.38',
        18: '0.42',
        20: '0.47',
        22: '0.52'
      },
    }
  },
  computed: {
    currentMonthDays () {
      let date = new Date();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return new Date(year, month, 0).getDate()
    },
    hour () {
      let trainingHours = Math.floor(Math.random() * 23)
      if (trainingHours %2 !== 0 ) {
        trainingHours++
      }
      return trainingHours
    }
  },
  methods: {
    showDay (day) {
      let days = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31]
      if (days.includes(day)) {
        return day
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.weekday {
  flex: 0 0 auto;
  width: 3.333333%;
  font-size: 16px;
}
.icon-button {
  margin-right: 2px;
  height: 100%;
}
.day-button {
  width: 28px;
}
.square-icon {
  width: 25px;
  height: 100%;
  background: #00b596;
}
</style>