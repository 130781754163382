import { apiTypeHelper } from '@/base/network'

export const ME = apiTypeHelper('ME')
export const USE_APP_AS = 'USE_APP_AS'
export const CLEAR_USER = 'CLEAR_USER'

export const LIST_ACTIVITIES = apiTypeHelper('LIST_ACTIVITIES')
export const LIST_BODY_INDEX_RECORD = apiTypeHelper('LIST_BODY_INDEX_RECORD')
export const CREATE_USER_REASON = apiTypeHelper('CREATE_USER_REASON')
export const MODIFY_USER_REASON = apiTypeHelper('MODIFY_USER_REASON')
export const ACTIVATE_USER = apiTypeHelper('ACTIVATE_USER')
export const WITHDRAW_PENDING = apiTypeHelper('WITHDRAW_PENDING')