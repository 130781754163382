import * as TYPES from './types'

const listCourses = state => {
    state.fetching = true
}

const listCoursesSuccess = (state, data) => {
    state.courseList = data
    state.privateTrainingSessionId = data.find(course => course.name === 'privateTrainingSession').id
    state.fetching = false
}

const listCoursesFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const createCourse = () => {
}

const createCourseSuccess = () => {
}

const createCourseFailure = (state, error) => {
    console.log(error)
}

const updateCourse = () => {
}

const updateCourseSuccess = () => {
}

const updateCourseFailure = (state, error) => {
    console.log(error)
}

const deleteCourse = () => {}
const deleteCourseSuccess = () => {}
const deleteCourseFailure = (state, error) => {
    console.log(error)
}

const listUserCoursesQuickSummary = state => {
    state.fetching = true
}
const listUserCoursesQuickSummarySuccess = (state, data) => {
    state.userCoursesQuickSummary = data
    state.fetching = false
}

const listUserCoursesQuickSummaryFailure = (state, error) => {
    console.log(error)
    state.fetching = false
}

const getClientCourses = state => {
    state.fetching = true
}

const getClientCoursesSuccess = (state, data) => {
    state.clientCourses = data
    state.fetching = false
}

const getClientCoursesFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const listTrainerCourses = (state) => {
    state.fetching = true
}

const listTrainerCoursesSuccess = (state, data) => {
    state.trainerCourses = data
    state.fetching = false
}

const listTrainerCoursesFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const listCourseTags = state => {
    state.fetching = true
}

const listCourseTagsSuccess = (state, data) => {
    state.fetching = false
    state.courseTags = data
    data.forEach(item => {
        if (item.tag === 'course_type') {
            state.courseTypes.push(item)
        } else if (item.tag === 'course_goal') {
            state.courseGoals.push(item)
        } else if (item.tag === 'body_parts') {
            state.bodyParts.push(item)
        }
    })
}

const listCourseTagsFailure = (state, error) => {
    console.log(error)
    state.fetching = false
}

const createReservation = () => {
}

const createReservationSuccess = (state, data) => {
    state.addedCourses = data
}

const createReservationFailure = (state, error) => {
    console.log(error)
}

const updateReservationDetailForClient =  () => {}
const updateReservationDetailForClientSuccess = () => {}
const updateReservationDetailForClientFailure = () => {}

const updatedReservationStatus = () => {
    // state.fetching = true
}

const updatedReservationStatusSuccess = () => {
    // state.fetching = false
}

const updatedReservationStatusFailure = (state, error) => {
    // state.fetching = false
    console.log(error)
}

const getUserCoursesSummary = state => {
    state.fetchingUserCoursesSummary = true
}

const getUserCoursesSummarySuccess = (state, data) => {
    state.userCoursesSummary = data
    state.fetchingUserCoursesSummary = false
}

const getUserCoursesSummaryFailure = (state, err) => {
    state.fetchingUserCoursesSummary = false
    console.log(err)
}

const listTrainerCoursesSummary = state => {
    state.fetchingTrainerCoursesSummary = true
}

const listTrainerCoursesSummarySuccess = (state, data) => {
    state.fetchingTrainerCoursesSummary = false

    const groupedData = Object.values(data.reduce((groups, item) => {
        const { id, client, is_free, started_at } = item
        if (!groups[client]) {
            groups[client] = { client, id, free_courses: 0, paid_courses: 0, started_at: [], total_courses: 0 }
        }
        groups[client].started_at.push(started_at);
        if (is_free === 0) {
            groups[client].paid_courses++
        } else {
            groups[client].free_courses++
        }
        groups[client].total_courses++
        return groups;
    }, {}));

    state.trainerCoursesSummary = groupedData
}

const listTrainerCoursesSummaryFailure = (state, err) => {
    state.fetchingTrainerCoursesSummary = false
    console.log(err)
}

export default {
    [TYPES.LIST_COURSES.ACTION]: listCourses,
    [TYPES.LIST_COURSES.SUCCESS]: listCoursesSuccess,
    [TYPES.LIST_COURSES.FAILURE]: listCoursesFailure,
    [TYPES.CREATE_COURSE.ACTION]: createCourse,
    [TYPES.CREATE_COURSE.SUCCESS]: createCourseSuccess,
    [TYPES.CREATE_COURSE.FAILURE]: createCourseFailure,
    [TYPES.UPDATE_COURSE.ACTION]: updateCourse,
    [TYPES.UPDATE_COURSE.SUCCESS]: updateCourseSuccess,
    [TYPES.UPDATE_COURSE.FAILURE]: updateCourseFailure,
    [TYPES.DELETE_COURSE.ACTION]: deleteCourse,
    [TYPES.DELETE_COURSE.SUCCESS]: deleteCourseSuccess,
    [TYPES.DELETE_COURSE.FAILURE]: deleteCourseFailure,
    [TYPES.LIST_USER_COURSES_QUICK_SUMMARY.ACTION]: listUserCoursesQuickSummary,
    [TYPES.LIST_USER_COURSES_QUICK_SUMMARY.SUCCESS]: listUserCoursesQuickSummarySuccess,
    [TYPES.LIST_USER_COURSES_QUICK_SUMMARY.FAILURE]: listUserCoursesQuickSummaryFailure,
    [TYPES.GET_CLIENT_COURSES.ACTION]: getClientCourses,
    [TYPES.GET_CLIENT_COURSES.SUCCESS]: getClientCoursesSuccess,
    [TYPES.GET_CLIENT_COURSES.FAILURE]: getClientCoursesFailure,
    [TYPES.LIST_COURSE_TAGS.ACTION]: listCourseTags,
    [TYPES.LIST_COURSE_TAGS.SUCCESS]: listCourseTagsSuccess,
    [TYPES.LIST_COURSE_TAGS.FAILURE]: listCourseTagsFailure,
    [TYPES.LIST_TRAINER_COURSES.ACTION]: listTrainerCourses,
    [TYPES.LIST_TRAINER_COURSES.SUCCESS]: listTrainerCoursesSuccess,
    [TYPES.LIST_TRAINER_COURSES.FAILURE]: listTrainerCoursesFailure,

    [TYPES.CREATE_RESERVATION.ACTION]: createReservation,
    [TYPES.CREATE_RESERVATION.SUCCESS]: createReservationSuccess,
    [TYPES.CREATE_RESERVATION.FAILURE]: createReservationFailure,
    [TYPES.CREATE_RESERVATION.FAILURE]: createCourseFailure,
    [TYPES.UPDATE_RESERVATION_DETAILS.ACTION]: updateReservationDetailForClient,
    [TYPES.UPDATE_RESERVATION_DETAILS.SUCCESS]: updateReservationDetailForClientSuccess,
    [TYPES.UPDATE_RESERVATION_DETAILS.FAILURE]: updateReservationDetailForClientFailure,
    [TYPES.UPDATED_RESERVATION_STATUS.ACTION]: updatedReservationStatus,
    [TYPES.UPDATED_RESERVATION_STATUS.SUCCESS]: updatedReservationStatusSuccess,
    [TYPES.UPDATED_RESERVATION_STATUS.FAILURE]: updatedReservationStatusFailure,
    [TYPES.GET_USER_COURSES_SUMMARY.ACTION]: getUserCoursesSummary,
    [TYPES.GET_USER_COURSES_SUMMARY.SUCCESS]: getUserCoursesSummarySuccess,
    [TYPES.GET_USER_COURSES_SUMMARY.FAILURE]: getUserCoursesSummaryFailure,
    [TYPES.LIST_TRAINER_COURSES_SUMMARY.ACTION]: listTrainerCoursesSummary,
    [TYPES.LIST_TRAINER_COURSES_SUMMARY.SUCCESS]: listTrainerCoursesSummarySuccess,
    [TYPES.LIST_TRAINER_COURSES_SUMMARY.FAILURE]: listTrainerCoursesSummaryFailure
}
