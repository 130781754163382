<template>
  <div class="table-responsive">
    <b-table
        borderless
        class="table-thead-bordered table-nowrap table-align-middle card-table"
        head-variant="dark"
        :items="items"
    >
      <template #cell(item)="data">
        <a class="d-flex align-items-center" hred="#">
          <div class="flex-shrink-0">
            <img class="avatar" src="../../../assets/img/400x400/img5.jpg">
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="text-inherit mb-0">{{ data.value }}</h5>
          </div>
        </a>
      </template>
      <!-- Change -->
      <template #cell(change)="data">
        <trend-icon :to="data.value.to" :from="data.value.from" />
        {{ getPercentage(data.value.from, data.value.to) }} %
      </template>
      <!-- Sales -->
      <template #cell(sales)="data">
        <h4 class="mb-0">{{ data.value }}</h4>
      </template>
    </b-table>
  </div>
</template>
<script>
import { TrendIcon } from "@/layout"

export default {
  name: 'top-products-table',
  components: {
    TrendIcon
  },
  data () {
    return {
      items: [
        { item: 'Coffee', change: { from: 10, to: 20}, price: '$65', sold: 200, sales:'$100,999' },
      ]
    }
  },
  methods: {
    getPercentage (from, to) {
      return (100 * from) / to
    }
  }
}
</script>