<template>
  <div v-if="visible">
    <page-header
        v-if="userId === 0"
        :title="headerTitle"
        :items="headerItems"
    />
    <div class="card-header card-header-content-md-between">
      <div class="mb-2 mb-md-0">
        <b-form-group>
          <b-input-group
              class="input-group-merge input-group-flush"
          >
            <div class="input-group-prepend input-group-text">
              <i class="bi-search"></i>
            </div>
            <b-form-input
                v-model="filter"
                class=""
                type="search"
                placeholder="Search Events"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <ul class="nav nav-segment">
        <li class="nav-item">
          <a class="nav-link" :class="showCalendar ? 'active' : null" @click="handleSwitchCoursesView('calendar')"><i class="bi-grid"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="!showCalendar ? 'active' : null" @click="handleSwitchCoursesView('table')"><i class="bi-view-list"></i></a>
        </li>
      </ul>
    </div>
    <calendar-detail
        v-if="showCalendar"
        :fetching="fetching"
        :visible="visible"
        :user-type="profile.userType"
        :profile="profile"
        :course-events="events"
    />
    <user-courses-history-table
        v-if="!showCalendar"
        :table-items="events" :fetching="fetching"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import PageHeader from '@/layout/PageHeader'
import calendarDetail from '@/components/calendar/CalendarDetail'
import UserCoursesHistoryTable from '@/components/calendar/UserCoursesHistoryTable'
export default {
  name: 'mkc-calendar',
  components: {
    PageHeader,
    calendarDetail,
    UserCoursesHistoryTable
  },
  props: {
    userId: {
      type: [Number, String],
      required: false,
      default: 0
    },
    profile: {
      type: Object,
      required: false,
      default: null
    },
    visible: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data () {
    return {
      headerTitle: 'Calendar',
      headerItems: [
        {
          text: 'Calendar',
          href: '#'
        },
        {
          text: 'Courses',
          active: true
        }
      ],
      filter: null,
      showCalendar: true
    }
  },
  computed: {
    ...mapState('client', ['clients', 'clientsForAddingCourses']),
    ...mapState('trainer', ['trainers']),
    ...mapState('course', ['fetching', 'clientCourses', 'trainerCourses', 'courseList', 'courseTags']),
    events () {
      if (this.profile !== null) {
        if (this.profile.userType === 'client') {
          return this.clientCourses
        } else if (this.profile.userType === 'trainer') {
          return this.trainerCourses
        }
      }
      return []
    }
  },
  methods: {
    ...mapActions('trainer', ['getTrainers']),
    ...mapActions('client', ['getClients']),
    ...mapActions('course', ['getClientCourses', 'listTrainerCourses', 'listCourses', 'listCourseTags']),
    handleSwitchCoursesView (view) {
      if (view === 'calendar') {
        this.showCalendar = true
      } else {
        this.showCalendar = false
      }
    }
  },
  async mounted() {
    if (this.profile !==  null && this.profile.userType === 'client') {
      await this.getClientCourses({id: this.profile.id})
    } else if(this.profile !==  null && this.profile.userType === 'trainer') {
      await this.listTrainerCourses(this.profile.id)
    } else {
      console.log('123')
    }
    if (this.trainers.length === 0) {
      await this.getTrainers()
    }
    if (this.clients.length === 0) {
      await this.getClients()
    }
    if (this.courseTags.length === 0) {
      await this.listCourseTags()
    }
    if (this.courseList.length === 0) {
      await this.listCourses()
    }
  }
}
</script>
<style>
/*.dropdown-toggle::after {*/
/*  border: unset;*/
/*}*/
</style>