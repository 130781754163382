<template>
  <Bar
      :options="chartOptions"
      :data="chartData"
  />
</template>
<script>
import { Bar } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartDataLabels
)

export default {
  name: 'bar-chart',
  components: { Bar },
  props: {
    chartData: {
      type: Object || Array,
      required: true,
      default: null
    },
    chartOptions: {
      type: Object || Array,
      required: false,
      default ()  {
        return {
          maintainAspectRatio: false,
          responsive: true,
        }
      }
    }
  }
}
</script>