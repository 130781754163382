<template>
  <b-modal
      id="update-course"
      title="Update course"
      @ok="submit"
  >
    <label for="courseName" class="col-form-label form-label">Course Name</label>
    <b-form-input id="courseName" :value="getName" @input="value => input('name', value)" type="text" placeholder="Course Name" class="mb-4" required></b-form-input>
    <b-form-textarea
        id="course-description"
        placeholder="Input Course Description"
        rows="6"
        :value="getDescription"
        class="mb-1"
        @input="value => input('description', value)"
    />
    <b-form-checkbox
        id="isDefault"
        :value="getIsDefault"
        @input="value => input('isDefault', value)"
    >
      set to default Course
    </b-form-checkbox>
    <small class="text-warning" v-if="isDefault === 0">* You will not edit/delete the course if u set it to default</small>
  </b-modal>
</template>
<script>
export default {
  name: 'update-course-modal',
  data () {
    return {
      name: this.course.name,
      description: this.course.description,
      isDefault: this.course.isDefault
    }
  },
  props: {
    course: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    getName (){
      return this.course.name
    },
    getDescription () {
      return this.course.description
    },
    getIsDefault () {
      return this.course.isDefault
    }
  },
  methods: {
    input(name,value) {
      switch (name) {
        case 'name':
          this.name = value
          break
        case 'description':
          this.description = value
          break
        case 'isDefault':
          this.isDefault = value === 0
          break
      }
    },
    submit () {
      let data = {
        id: this.course.id,
        name: this.name,
        description: this.description,
        isDefault: this.isDefault
      }
      this.$emit('edit', data)
    }
  }
}
</script>