import axios from 'axios'

import { NetworkEntity } from "@/base/network"
import {
    LOGIN,
    LOGOUT,
    REFRESH_TOKEN,
    RESET_PASSWORD
} from "../auth/types"

const options = {
    endpoint: 'api',
    moduleName: 'AUTH'
}

export const AuthEntity = new NetworkEntity(options)

AuthEntity.login = function (commit, params) {
    commit(LOGIN.ACTION)
    return axios
        .post(`${this.endpoint}/login`, params)
        .then(({ data }) => {
            commit(LOGIN.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(LOGIN.FAILURE, err)
            throw err
        })
}

AuthEntity.logout = function (commit, type) {
    commit(LOGOUT.ACTION)
    return axios
        .post(`${this.endpoint}/logout`, { type })
        .then(({ data }) => {
            commit(LOGOUT.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(LOGOUT.FAILURE, err)
            throw err
        })
}

AuthEntity.refreshToken = function (commit, data) {
    commit(REFRESH_TOKEN.ACTION)
    return axios
        .post(`${this.endpoint}/refresh`, data)
        .then(({ data }) => {
            commit(REFRESH_TOKEN.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(REFRESH_TOKEN.FAILURE, err)
            throw new Error(err)
        })
}

AuthEntity.resetPassword = function (commit, data) {
    commit(RESET_PASSWORD.ACTION)
    return axios
        .post(`${this.endpoint}/reset-password`, data)
        .then(({ data }) => {
            commit(RESET_PASSWORD.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(RESET_PASSWORD.FAILURE, err)
        })
}