import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { authStore as auth } from '../components/auth/store'
import { alert } from './alert'
import { user } from './user'
import { client } from './client'
import { trainer } from './trainer'
import { employee } from './employee'
import { userProfile } from './userProfile'
import { course } from './course'
import { company } from './company'
import { product } from './product'
import { payment } from './payment'

const store = new Vuex.Store({
    modules: {
        auth,
        alert,
        user,
        trainer,
        client,
        employee,
        userProfile,
        course,
        company,
        product,
        payment
    }
})

if (module.hot) {
    module.hot.accept(
        [
            '../components/auth/store',
            './alert',
            './course',
            './user',
            './employee',
            './trainer',
            './userProfile',
            './company',
            './product',
            './payment'
        ],
        () => {
            store.hotUpdate({
                modules: {
                    auth,
                    user,
                    client,
                    trainer,
                    employee,
                    userProfile,
                    course,
                    company,
                    product,
                    payment
                }
            })
        }
    )
}

export default store