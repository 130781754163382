<template>
  <div class="card mb-3 mb-lg-5">
      <div class="card-header">
        <h4 class="card-header-title">Product information</h4>
      </div>
      <div class="card-body">
        <div class="mb-4">
          <label for="productNameLabel" class="form-label">Name</label>
          <b-form-input id="productNameLabel" type="text" />
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="mb-4">
              <label for="SKULabel" class="form-label">SKU</label>
              <b-form-input id="SKULabel" type="text" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mb-4">
              <label for="weightLabel" class="form-label">Weight</label>
              <div class="input-group">
                <b-form-input id="weightLabel" type="text" />
                <div class="input-group-append">
                  <b-form-select v-model="selected" :options="options"></b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <label class="form-label">Description <span class="form-label-secondary">(Optional)</span></label>
        <vue-editor v-model="content" />
      </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor"

export default {
  name: 'product-info',
  components: {
    VueEditor
  },
  data () {
    return {
      selected: 'kg',
      options: [
        { value: 'kg', text: 'kg' },
        { value: 'oz', text: 'oz' },
        { value: 'lb', text: 'lb' },
        { value: 'g', text: 'g' },
      ],
      content: "<p>Type your description...</p>"
    }
  }
}
</script>