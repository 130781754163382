<template>
  <div class="card h-100">
    <div class="card-body">
      <h6 class="card-subtitle">
        {{ title }}
      </h6>
      <div class="row align-items-center gx-2">
        <div class="col">
          <span class="js-counter display-4 text-dark">{{ data.value }}</span>
          <span class="text-body fs-5 ms-1">{{ data.symbol }}</span>
        </div>
      </div>
      <span class="text-body fs-6 ms-1">{{ data.updated_at }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'body-single-data-card',
  props: {
    title: {
      type:String,
      required: false,
      default: 'weight'
    },
    data: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>