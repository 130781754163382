<template>
  <div class="col">
    <h3 class="mb-5">7 members</h3>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
      <div v-for="member in members" :key="`member-`+member.id"
           class="col mb-3 mb-lg-5">
        <member-card :member="member" />
      </div>
    </div>
  </div>
</template>
<script>
import { MemberCard } from '@/layout'
export default {
  name: 'profile-members',
  components: { MemberCard },
  props: {
    userId: {
      type: [String, Number],
      required: true,
      default: null
    }
  },
  data () {
    return {
      members: [
        {
          id: 1,
          profileImage: null,
          name: 'Rachel Doe',
          role: 'member',
          status: 'active',
          tags: ['50kg', '160cm', 'Age: 29'],
          completedCourse: 25,
          upcomingCourse: 20
        },
        {
          id: 2,
          profileImage: 'aaa',
          name: 'Chenwei Song',
          role: 'employee',
          status: 'pending',
          tags: ['UI/UX', 'Sketch', 'Figma']
        }
      ]
    }
  },
  async mounted() {

  }
}
</script>