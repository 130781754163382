<template>
  <form>
    <b-row class="mb-4">
      <label for="editAddressLineLabel" class="col-sm-3 col-form-label form-label">Address</label>
      <b-col sm="9">
        <b-form-input
            id="editAddressLineLabel"
            placeholder="Address"
            :value="profile.address"
            @input="value => handleInput('address', value)"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editZipCodeLabel" class="col-sm-3 col-form-label form-label">Zip Code</label>
      <b-col sm="9">
        <b-form-input
            id="editZipCodeLabel"
            placeholder="A0A A0A"
            :value="profile.zipCode"
            @input="value => handleInput('zipCode', value)"
        />
      </b-col>
    </b-row>
    <b-row v-if="visible" class="mb-4">
      <label for="editSocialMedia" class="col col-form-label form-label">Edit Social Media</label>
      <drop-file />
    </b-row>
  </form>
</template>
<script>
import { DropFile } from '@/layout'
export default {
  name: 'edit-address-and-social-media',
  components: {
    DropFile
  },
  props: {
    profile: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    handleInput(name, value) {
      this.$emit('input', name, value)
    }
  }
}
</script>