import moment from 'moment'
import enLang from 'element-ui/lib/locale/lang/en'
import cnLang from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

import { getLocale } from '@/base/utils/getLocale'
import router from '../../router'

const ENGLISH = 'en'
const CHINESE = 'cn'

export const changeLocale = (targetLocale) => {
    let newLocale = ''

    if (targetLocale) {
        newLocale = targetLocale
    } else {
        newLocale = getLocale() === ENGLISH ? CHINESE : ENGLISH
    }

    router.app.$i18n.locale = newLocale

    if (newLocale === ENGLISH) {
        locale.use(enLang)
    } else {
        locale.use(cnLang)
    }

    moment.locale(newLocale)

    setLocaleInCookie(newLocale)

}

export const localInCookie = () => {
    const locale = document.cookie.replace(/(?:(?:^|;\s*)locale\s*=\s*([^;]*).*$)|^.*$/,'$1')

    if (locale === '') {
        return 'en'
    }
    return locale
}

export const setLocaleInCookie = (locale) => {
    document.cookie = `locale=${locale}`
}