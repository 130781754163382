import store from '@/store'
import {
    getToken,
    tokenIsExpired
} from './token'
import { betaLogoutUrl } from './urls'
import { getItem, localStorage } from './storage'
import { HOME_ROUTE } from './roles'

const routeToHome = (user) => {
    return HOME_ROUTE(user.data.roles)
}

export const routeGuard = (to, next, user) => {
    let redirect = getItem(localStorage, 'previous_path', true) || routeToHome(user)
    // if (!store.state.user.data.permission) {
    //     return next('sign-in')
    // }
    if (to.meta.permissions) {
        let hasPermission = to.meta.permissions.every(permission => store.state.user.data.permissions.includes(permission))
        if (hasPermission) {
            return next()
        }
    }
    return next(redirect)
}

export const checkIfHasPermissionsForPath = async function (to, from, next) {
    const user = store.state.user
    const token = getToken()
    if (!token || tokenIsExpired()) {
        localStorage.setItem('previous_path', to.fullPath)
        window.location = betaLogoutUrl()
        return next(false)
    }

    if (!user.signedIn) {
        await store.dispatch('user/getMe', {}, { root: true })
    }

    return routeGuard(to, next, user)
}