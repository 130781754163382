import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    GET_CLIENTS_COUNT_SUMMARY,
    LIST_CLIENTS,
    GET_CLIENT_TYPES_SUMMARY,
    GET_CLIENT_PROFILE,
    DELETE_CLIENT,
    ADD_CLIENT,
    LIST_RECIPES,
    CREATE_RECIPE,
    UPDATE_RECIPE,
    DELETE_RECIPE,
    UPDATE_TRAINER
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'CLIENT'
}

export const ClientEntity = new NetworkEntity(options)

ClientEntity.getClients = function (commit, params) {
    let q = ClientEntity.queryStringify(params)
    commit(LIST_CLIENTS.ACTION)
    return axios
        .get(`${this.endpoint}/clients${q}`)
        .then(({ data: {data} }) => {
            commit(LIST_CLIENTS.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_CLIENTS.FAILURE, err)
        })
}

ClientEntity.getClientsCountSummary = function (commit) {
    commit(GET_CLIENTS_COUNT_SUMMARY.ACTION)
    return axios
        .get(`${this.endpoint}/clients/clients-count-summary`)
        .then(({ data: {data} }) => {
            commit(GET_CLIENTS_COUNT_SUMMARY.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_CLIENTS_COUNT_SUMMARY.FAILURE, err)
        })
}

ClientEntity.getClientTypesSummary = function (commit) {
    commit(GET_CLIENT_TYPES_SUMMARY.ACTION)
    return axios
        .get(`${this.endpoint}/clients/client-types-summary`)
        .then(({ data: {data} }) => {
            commit(GET_CLIENT_TYPES_SUMMARY.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_CLIENT_TYPES_SUMMARY.FAILURE, err)
        })
}

ClientEntity.getClientProfile = function (commit, id) {
    commit(GET_CLIENT_PROFILE.ACTION)
    return axios
        .get(`${this.endpoint}/clients/${id}/profile`)
        .then(({data: {data} }) => {
            commit(GET_CLIENT_PROFILE.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_CLIENT_PROFILE.FAILURE, err)
        })
}

ClientEntity.deleteClient = function (commit, id) {
    commit(DELETE_CLIENT.ACTION)
    return axios
        .delete(`${this.endpoint}/clients/delete/${id}`)
        .then(({data}) => {
            commit(DELETE_CLIENT.SUCCESS, data)
        })
        .catch(err => {
            commit(DELETE_CLIENT.FAILURE, err)
        })
}

ClientEntity.addClient = function (commit, data) {
    commit(ADD_CLIENT.ACTION)
    return axios
        .post(`${this.endpoint}/clients/create`, data)
        .then(({ data }) => {
            commit(ADD_CLIENT.SUCCESS, data)
        })
        .catch(err => {
            commit(ADD_CLIENT.FAILURE, err)
        })

}

// list Recipes
ClientEntity.listRecipes = function (commit, id) {
    commit(LIST_RECIPES.ACTION)
    return axios
        .get(`${this.endpoint}/clients/${id}/recipes`)
        .then(({ data: {data} }) => {
            commit(LIST_RECIPES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_RECIPES.FAILURE, err)
        })
}

// Create Recipe
ClientEntity.createRecipe = function (commit, id, data) {
    commit(CREATE_RECIPE.ACTION)
    return axios
        .post(`${this.endpoint}/clients/${id}/recipe`, data)
        .then(({ data }) => {
            commit(CREATE_RECIPE.SUCCESS, data)
        })
        .catch(err => {
            commit(CREATE_RECIPE.FAILURE, err)
        })
}

// Update Recipe
ClientEntity.updateRecipe = function (commit, id, recipeId, data) {
    commit(UPDATE_RECIPE.ACTION)
    return axios
        .put(`${this.endpoint}/clients/${id}/recipe/${recipeId}`, data)
        .then(({ data }) => {
            commit(UPDATE_RECIPE.SUCCESS, data)
        })
        .catch(err => {
            commit(UPDATE_RECIPE.FAILURE, err)
        })
}

// Delete Recipe
ClientEntity.deleteRecipe = function (commit, userId, recipeId) {
    commit(DELETE_RECIPE.ACTION)
    return axios
        .delete(`${this.endpoint}/clients/${userId}/recipe/${recipeId}`)
        .then(({ data }) => {
            commit(DELETE_RECIPE.SUCCESS, data)
        })
        .catch(err => {
            commit(DELETE_RECIPE.FAILURE, err)
        })
}

ClientEntity.updateTrainer = function (commit, data) {
    commit(UPDATE_TRAINER.ACTION)
    return axios
        .post(`${this.endpoint}/clients/update-trainer`, data)
        .then(({ data }) => {
            commit(UPDATE_TRAINER.SUCCESS, data)
        })
        .catch(err => {
            commit(UPDATE_TRAINER.FAILURE, err)
        })
}