<template>
  <div class="page-header">
    <div class="row align-items-end">
      <div class="col-sm mb-2 mb-sm-0">
        <b-breadcrumb v-if="items" :items="items"></b-breadcrumb>
        <h1 class="page-header-title">{{ title }}</h1>
        <p v-if="pageHeaderText" class="page-header-text">{{ pageHeaderText }}</p>
      </div>
      <div v-if="button" class="col-sm-auto">
        <b-button variant="primary" @click="handleClick">
          <b-icon icon="person-plus-fill"></b-icon>{{ button }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    pageHeaderText: {
      type: String,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: false,
      default: null
    },
    button: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    handleClick () {
      this.$emit('button-click')
    }
  }
}
</script>