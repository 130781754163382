<template>
  <span
     v-if="status==='fulfilled'"
     class="badge bg-soft-info text-info"
     :class="isTitle ?'ms-sm-3' : null"
  >
    <span class="legend-indicator bg-info"></span>Fulfilled
  </span>
  <span
      v-else-if="status==='unfulfilled'"
      class="badge bg-soft-dark text-dark"
      :class="isTitle ?'ms-sm-3' : null"
  >
    <span class="legend-indicator bg-dark"></span>Unfulfilled
  </span>
</template>
<script>
export default {
  name: 'fulfillment-status',
  props: {
    status: {
      type: String,
      required: true,
      default: 'pending'
    },
    isTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>