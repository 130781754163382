<template>
  <b-modal id="duplicate-user-modal" >
    <template #modal-title>
      <span class="text-danger">Duplicate User Email</span>
    </template>
    <h3 class="text-warning text-center mb-3">Email <span class="text-white">'{{ userEmail }}'</span> has already been taken.</h3>
    <h5 class="text-warning text-center">Please try another</h5>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="primary"
            class="float-right"
            @click="handleBackToStepOne"
        >
          Back to Step One
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'duplicate-user-modal',
  props: {
    userEmail: {
      type: [String, null],
      default: 'user@email.com'
    }
  },
  methods: {
    handleBackToStepOne () {
      this.$emit('back')
    }
  }
}
</script>