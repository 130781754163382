<template>
  <div class="membership-overview">
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <!-- Membership Packages -->
    <div class="card mb-5">
      <div class="card-header card-header-content-between">
        <h4 class="card-header-title mb-2 mb-sm-0">
          Membership Packages
        </h4>
        <div
            v-if="usingAppAs[0] === 'admin'"
            class="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2"
        >
          <b-button variant="primary"  v-b-modal.add-membership><i class="bi bi-plus nav-icon"></i>Add Membership Package</b-button>
        </div>
      </div>
      <div class="table-responsive">
        <b-table
            v-if="!fetching"
            borderless
            select-mode="single"
            class="table-align-middle table-nowrap card-table"
            id="membership-packages-table"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="membershipPackageFilter"
            :fields="membershipFields"
            :items="membershipPackages"
        >
          <template #cell(name)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getName(data.value) }}</span>
          </template>
          <template #cell(description)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDescription(data.value) }}</span>
          </template>
          <template #cell(duration)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDuration(data.value) }}</span>
          </template>
          <template #cell(actions)="row">
            <b-button-group>
              <b-button class="btn-white" size="sm" @click="handleEditMembershipPackage(row)"><i class="bi bi-pencil-fill me-1"></i>Edit</b-button>
              <b-dropdown
                  toggle-class="btn-white btn-icon dropdown-toggle-empty"
                  size="sm"
                  right
              >
                <b-dropdown-item class="btn-outline-danger" @click="handleDeleteMembershipPackage(row)"><i class="bi bi-trash dropdown-item-icon"></i>Delete</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>
        </b-table>
        <b-spinner
            v-else
            variant="primary"
            class="align-middle"
        />
      </div>
      <div class="card-footer">
        <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
          <div class="col-sm mb-2 mb-sm-0">
            <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
              <p>Current Page: {{ membershipPage }}</p>
            </div>
          </div>
          <div class="col-sm-auto">
            <div class="d-flex justify-content-center justify-content-sm-end">
              <b-pagination
                  v-model="membershipPage"
                  :total-rows="membershipPackagesRows"
                  :per-page="perPage"
                  aria-controls="membership-packages-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Membership Packages -->
    <div class="card">
      <div class="card-header card-header-content-between">
        <h4 class="card-header-title mb-2 mb-sm-0">
          Courses Packages
        </h4>
        <div
            v-if="usingAppAs[0] === 'admin'"
            class="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2"
        >
          <b-button variant="primary"  v-b-modal.add-courses-package><i class="bi bi-plus nav-icon"></i>Add Courses Package</b-button>
        </div>
      </div>
      <div class="table-responsive">
        <b-table
            v-if="!fetching"
            id="course-packages-table"
            borderless
            select-mode="single"
            class="table-align-middle table-nowrap card-table"
            :filter="coursesPackageFilter"
            :fields="coursesFields"
            :items="coursePackages"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
          <template #cell(name)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getName(data.value) }}</span>
          </template>
          <template #cell(description)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDescription(data.value) }}</span>
          </template>
          <template #cell(duration)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDuration(data.value) }}</span>
          </template>
          <template #cell(actions)="row">
            <b-button-group>
              <b-button class="btn-white" size="sm" @click="handleEditCoursePackage(row)"><i class="bi bi-pencil-fill me-1"></i>Edit</b-button>
              <b-dropdown
                  toggle-class="btn-white btn-icon dropdown-toggle-empty"
                  size="sm"
                  right
              >
                <b-dropdown-item class="btn-white" @click="handleDeleteCoursePackage(row)"><i class="bi bi-trash dropdown-item-icon"></i>Delete</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>
        </b-table>
        <b-spinner
            v-else
            variant="primary"
            class="align-middle"
        />
        <div class="card-footer">
          <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
            <div class="col-sm mb-2 mb-sm-0">
              <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
                <p>Current Page: {{ currentPage }}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-auto">
            <div class="d-flex justify-content-center justify-content-sm-end">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="coursePackagesRows"
                  :per-page="perPage"
                  aria-controls="course-packages-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-membership-package-modal
        @submit="handleAddMembershipPackage"
    />
    <add-courses-package-modal
        @submit="handleAddCoursesPackage"
    />
    <update-membership-package-modal
        :membership-package="currentPackage"
        @update="handleUpdateCurrentPackage"
        @submit="handleUpdateMembershipPackage"

    />
    <update-courses-package-modal
        :course-package="currentPackage"
        @update="handleUpdateCurrentPackage"
        @submit="handleUpdateCoursesPackage"
    />
    <delete-package-modal
        :package-data="[currentPackage]"
        :package-type="packageType"
        @submit="handleDeletePackage"
    />
  </div>
</template>
<script>
import { PageHeader} from '@/layout'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '@/base/utils'
import {
  AddMembershipPackageModal,
  AddCoursesPackageModal,
  UpdateCoursesPackageModal,
  UpdateMembershipPackageModal,
  DeletePackageModal
} from './components'

export default {
  name: 'membership-packages-overview',
  components: {
    PageHeader,
    AddMembershipPackageModal,
    AddCoursesPackageModal,
    UpdateCoursesPackageModal,
    UpdateMembershipPackageModal,
    DeletePackageModal
  },
  data () {
    return {
      headerTitle: 'Settings',
      headerItems: [
        {
          text: 'Membership Packages',
          href: '#'
        },
        {
          text: 'Overview',
          active: true
        }
      ],
      currentPackage: {
        id: '',
        name: { cn: '', en: '' },
        description: { cn: '', en: '' },
        amount: '',
        paid: '',
        free: '',
        duration_symbol: '',
        duration: '',
        shared: ''
      },
      packageType: '',
      locale: getLocale(),
      membershipPackageFilter: null,
      coursesPackageFilter: null,
      currentPage: 1,
      membershipPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false,
      membershipFields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: false
        },
        {
          key: 'amount',
          label: 'AMOUNT($)',
          sortable: true
        },
        {
          key: 'duration',
          label: 'DURATION (days)',
          sortable: true
        },
        {
          key: 'shared',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        }
      ],
      coursesFields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: false
        },
        {
          key: 'amount',
          label: 'AMOUNT($)',
          sortable: true
        },
        {
          key: 'duration',
          sortable: true
        },
        {
          key: 'free',
          sortable: true,
        },
        {
          key: 'paid',
          sortable: true,
        },
        {
          key: 'shared',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('product', ['fetching', 'coursePackages', 'membershipPackages']),
    coursePackagesRows () {
      return this.coursePackages.length
    },
    membershipPackagesRows () {
      return this.membershipPackages.length
    }
  },
  methods:  {
    ...mapActions('product',
        [
          'getCoursePackages',
          'getMembershipPackages',
          'createCoursesPackage',
          'createMembershipPackage',
          'updateCoursePackage',
          'updateMembershipPackage',
          'deleteCoursePackage',
          'deleteMembershipPackage'
        ]),
    handleEditCoursePackage (row) {
      this.currentPackage = row.item
      this.$bvModal.show('update-courses-package-modal')
    },
    handleDeleteCoursePackage (row) {
      this.currentPackage = row.item
      this.packageType = 'coursePackage'
      this.$bvModal.show('delete-package-modal')
    },
    handleEditMembershipPackage (row) {
      this.currentPackage = row.item
      this.$bvModal.show('update-membership-package-modal')
    },
    handleDeleteMembershipPackage (row) {
      this.currentPackage = row.item
      this.packageType = 'membershipPackage'
      this.$bvModal.show('delete-package-modal')
    },
    getName (name) {
      return this.$te(`profile.clientType.${name[this.locale]}`) ?
          this.$t(`profile.clientType.${name[this.locale]}`) : name[this.locale]
    },
    getDescription (description) {
      return description[this.locale]
    },
    getDuration (duration) {
      if (this.$te(` this.$t('general.dateSymbol.${duration.duration_symbol}`)) { return '' }
      return duration.duration + ' ' + this.$t(`general.dateSymbol.${duration.duration_symbol}`)
    },
    async handleAddMembershipPackage (data) {
      await this.createMembershipPackage(data)
          .then(this.getMembershipPackages)
          .catch(err => {
            console.log(err)
          })
    },
    async handleAddCoursesPackage (data) {
      await this.createCoursesPackage(data)
          .then(this.getCoursePackages)
          .catch(err => {
            console.log(err)
          })
    },
    handleUpdateCurrentPackage ({name, value}) {
      switch (name) {
        case 'name cn':
          this.currentPackage.name.cn = value
          break
        case 'name en':
          this.currentPackage.name.en = value
          break
        case 'description cn':
          this.currentPackage.description.cn = value
          break
        case 'description en':
          this.currentPackage.description.en = value
          break
        case 'amount':
          this.currentPackage.amount = value.toFixed(2)
          break
        case 'paid':
          this.currentPackage.paid = value
          break
        case 'free':
          this.currentPackage.free = value
          break
        case 'duration_symbol':
          this.currentPackage.duration_symbol = value
          break
        case 'duration':
          this.currentPackage.duration = value
          break
        case 'shared':
          this.currentPackage.shared = value
          break
      }
    },
    async handleUpdateCoursesPackage () {
      this.currentPackage.name = JSON.stringify(this.currentPackage.name)
      this.currentPackage.description = JSON.stringify(this.currentPackage.description)
      this.currentPackage.duration = this.currentPackage.duration.duration + ' ' + this.currentPackage.duration.duration_symbol
      await this.updateCoursePackage(this.currentPackage)
          .then(this.getCoursePackages)
    },
    async handleUpdateMembershipPackage () {
      this.currentPackage.name = JSON.stringify(this.currentPackage.name)
      this.currentPackage.description = JSON.stringify(this.currentPackage.description)
      this.currentPackage.duration = this.currentPackage.duration.duration + ' ' + this.currentPackage.duration.duration_symbol
      await this.updateMembershipPackage(this.currentPackage)
          .then(this.getMembershipPackages)
    },
    async handleDeletePackage () {
      if (this.packageType === 'coursePackage') {
        await this.deleteCoursePackage(this.currentPackage.id)
            .then(this.getCoursePackages)
      }
      if (this.packageType === 'membershipPackage') {
        await this.deleteMembershipPackage(this.currentPackage.id)
            .then(this.getMembershipPackages)
      }
    },
  },
  async mounted () {
    await this.getCoursePackages()
    await this.getMembershipPackages()
    if (this.usingAppAs[0] !== 'admin') {
      this.membershipFields.pop()
      this.coursesFields.pop()
    }
  }
}
</script>