import Vue from 'vue'
import VueRouter from 'vue-router'
import { checkIfHasPermissionsForPath } from '@/base/utils'
import HomeView from '../views/HomeView.vue'

import { SignIn, NotFound } from '../components/auth/components'

import {
  MyProfile,
  MyPayments,
  Overview,
  AddEmployee,
  AddTrainer,
  AdminBoard,
  Trainers,
  ProfileBodyCompositionAnalysis
} from '@/components/users'

import {
  ClientOverview,
  AddClient,
  ClientBookings
} from '@/components/clients'

import {
  EcommerceOverview,
    Products,
  AddProduct
} from "@/components/ecommerce"

import MyCalendar from '@/components/calendar/MyCalendar'

import UserProfile from '@/components/users/userProfile/userProfile'

import { CourseOverview, MembershipPackagesOverview } from '@/components/settings'

import { DocumentationOverview } from '@/components/documentation'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
    meta: {
      permissions: []
    }
  },
  {
    path: '/profile',
    name: 'my-profile',
    component: MyProfile,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/payments',
    name: 'my-payments',
    component: MyPayments,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/profile-body-index',
    name: 'profile-body-index',
    component: ProfileBodyCompositionAnalysis,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/user/overview',
    name: 'user-overview',
    component: Overview,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/user/admin-board',
    name: 'admin-board',
    component: AdminBoard,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/user/users-trainers',
    name: 'user-trainers',
    component: Trainers,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/user/add-employee',
    name: 'add-employee',
    component: AddEmployee,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/user/add-trainer',
    name: 'add-trainer',
    component: AddTrainer,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/client/overview',
    name: 'client-overview',
    component: ClientOverview,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/client/bookings',
    name: 'client-bookings',
    component: ClientBookings,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/client/add-client',
    name: 'add-client',
    component: AddClient,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: MyCalendar,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/user/:userId/profile',
    name: 'user-profile',
    component: UserProfile,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/e-commerce/overview',
    name: 'e-commerce-overview',
    component: EcommerceOverview,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/e-commerce/products',
    name: 'e-commerce-products',
    component: Products,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/e-commerce/add-product',
    name: 'add-product',
    component: AddProduct,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/courses',
    name: 'courses-overview',
    component: CourseOverview,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/membership-packages',
    name: 'membership-packages-overview',
    component: MembershipPackagesOverview,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '/documentation',
    name: 'documentation-overview',
    component: DocumentationOverview,
    meta: {
      permissions: ['user.view']
    }
  },
  {
    path: '*',
    redirect: '/404',
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: "history", // Add this line to solve Vue-Router redirect always to /#/
  routes
})

const publicRoutes = [
  'sign-in',
]

router.beforeEach(async (to, from, next) => {
  if (!publicRoutes.includes(to.name)) {
    await checkIfHasPermissionsForPath(to, from, next)
  } else {
    return next()
  }
})

export default router
