<template>
  <div class="card card-body mb-3 mb-lg-5">
    <div class="row">
      <div class="col-2">
        <!-- Avatar -->
        <div class="avatar avatar-circle">
          <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Image Description">
          <span class="avatar-status" :class="avatarStatus"></span>
        </div>
      </div>
      <div class="col-6">
        <h3 class="page-header-title">{{ userProfile.first_name }} {{ userProfile.last_name }}</h3>
        <h5>{{ $t('profile.clientType.' + userProfile.clientType) }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'user-profile',
  props: {
    userProfile: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    avatarStatus () {
      switch (this.userStatus) {
        case 'active':
          return 'avatar-status-success'
        case 'pending':
          return 'avatar-status-warning'
        case 'suspended':
          return 'avatar-status-info'
        case 'dismissed':
          return 'avatar-status-danger'
        default:
          return 'avatar-status-success'
      }
    },
  }
}
</script>