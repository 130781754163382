<template>
  <aside class="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white navbar-vertical-aside-initialized">
    <div class="navbar-vertical-container">
      <div class="navbar-vertical-footer-offset">
        <NavLogo />
        <b-button
            variant="link"
            class="navbar-aside-toggler text-decoration-none btn-ghost-secondary btn-icon rounded-circle"
            style="opacity: 1;"
            @click="handleExpandNavBar"
        >
          <i
              v-if="showExpandedIcon"
              class="bi-arrow-bar-left navbar-toggler-short-align"
          />
          <i
              v-else
              class="bi-arrow-bar-right navbar-toggler-full-align"
          />
        </b-button>
        <nav-bar-content />
        <nav-bar-footer />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import NavLogo from '@/components/aside/Logo'
import NavBarContent from '@/components/aside/NavBarContent'
import NavBarFooter from '@/components/aside/NavBarFooter'

export default {
  name: 'aside-view',
  components: {
    NavBarContent,
    NavBarFooter,
    NavLogo
  },
  computed: {
    ...mapState('alert', ['sm', 'navbarExpanded', 'navbarExpandedMobile']),
    showExpandedIcon () {
      return this.sm ? this.navbarExpandedMobile : this.navbarExpanded
    }
  },
  methods: {
    ...mapActions('alert', ['setNavbarExpanded', 'setNavbarExpandedMobile']),
    handleExpandNavBar () {
      if (this.sm) {
        this.setNavbarExpandedMobile()
      } else {
        this.setNavbarExpanded()
      }
    }
  }
}
</script>