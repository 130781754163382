<template>
  <div>
    <page-header
        :title="headerTitle"
        :page-header-text="pageHeaderText"
    />
    <!-- Summary card -->
    <div class="card card-body mb-3 mb-lg-5">
      <div class="row col-lg-divider gx-lg-6">
        <div
            v-for="(item, index) in salesCardItems"
            :key="`sales-item-`+ index"
            class="col-lg-4"
        >
          <sales-card
              :card-sub-title="item.cardSubTitle"
              :card-title="item.cardTitle"
              :data="item.data"
          />
        </div>
      </div>
    </div>
    <!-- Sales bar chart -->
    <sales-bar-chart />
    <products-overview />
  </div>
</template>
<script>
import {
  PageHeader,
  SalesCard,
} from "@/layout";
import { SalesBarChart, ProductsOverview } from "./OverviewComponents";

export default {
  name: 'ecommerce-overview',
  components: {
    PageHeader,
    SalesCard,
    SalesBarChart,
    ProductsOverview
  },
  data () {
    return {
      headerTitle: 'Hello Mark',
      pageHeaderText: 'Here\'s what\'s happening with your store today.',
      salesCardItems: [
        {
          cardSubTitle: 'IN-STORE SALES',
          cardTitle: '$7,820.75',
          data: {orders: 200, from: 24, to: 20}
        },
        {
          cardSubTitle: 'WEBSITE SALES',
          cardTitle: '$7,820.75',
          data: {orders: 100, from: 24, to: 20}
        },
        {
          cardSubTitle: 'Discount',
          cardTitle: '$7,820.75',
          data: { orders: 300, from: -1, to: 0}
        }
      ],
      storeSelected: 'in-store',
      storeOptions: [
        { value: 'in-store', text: 'In-Store' },
        { value: 'online-store', text: 'Online Store' },
      ],
      salesDateSelected: 'this-month',
      salesDateOptions: [
        { value: 'this-month', text: 'This month' },
        { value: 'last-30-days', text: 'Last 30 Days'},
        { value: 'last-60-days',  text: 'Last 60 Days'}
      ],
      chartData: {
        "labels": ["May 1", "May 2", "May 3", "May 4", "May 5", "May 6", "May 7", "May 8", "May 9", "May 10"],
        datasets: [
            {
              "data": [200, 300, 290, 350, 150, 350, 300, 100, 125, 220],
              backgroundColor: "#377dff",
              hoverBackgroundColor: "#377dff",
              borderColor: "#377dff",
              maxBarThickness: 10
            },
          {
            "data": [200, 300, 290, 350, 150, 350, 300, 100, 125, 220],
            backgroundColor: "#e7eaf3",
            borderColor: "#e7eaf3",
            maxBarThickness: 10
          }
        ]
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            grid: {
              color: "#e7eaf3",
              drawBorder: false,
              zeroLineColor: "#e7eaf3"
            },
            ticks: {
              beginAtZero: true,
              stepSize: 100,
              fontSize: 12,
              fontColor: "#97a4af",
              fontFamily: "Open Sans, sans-serif",
              padding: 10,
              postfix: "$"
            }
          },
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                size: 12,
                family: "Open Sans, sans-serif",
              },
              color: "#97a4af",
              padding: 5
            },
            categoryPercentage: 0.5
          },
        },
        plugins: {
          tooltip: {
            hasIndicator: true,
            mode: "index",
            intersect: false,
            cornerRadius: 6,
            padding: 10,
            usePointStyle: true,
            labelPointStyles: { pointStyle: 'circle', rotation: 2 },
          },
          legend: {
            display: false
          },
        },
        hover: {
          mode: "nearest",
          intersect: true
        }
      }
      // headerItems: [
      //   {
      //     text: 'Products',
      //     href: '#'
      //   },
      //   {
      //     text: 'Overview',
      //     active: true
      //   }
      // ]
    }
  }

}
</script>