import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    coursePackages: [],
    membershipPackages: [],
    membershipDurationOptions: [
        { value: 'year', text: 'Year(s)' },
        { value: 'season', text: 'Season(s)' },
        { value: 'month', text: 'Month(s)' },
        { value: 'week', text: 'Week(s)' },
        { value: 'day', text: 'Day(s)' },
    ],
    coursesFields: [
        {
            key: 'id'
        },
        {
            key: 'name'
        },
        {
            key: 'description'
        },
        {
            key: 'amount',
            label: 'AMOUNT($)'
        },
        {
            key: 'duration'
        },
        {
            key: 'free'
        },
        {
            key: 'paid',
        },
        {
            key: 'shared'
        }
    ],
    membershipFields: [
        {
            key: 'id'
        },
        {
            key: 'name'
        },
        {
            key: 'description'
        },
        {
            key: 'amount',
            label: 'AMOUNT($)'
        },
        {
            key: 'duration',
            label: 'DURATION (days)'
        },
        {
            key: 'shared'
        }
    ]
})

export const product = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}