<template>
  <b-modal
      id="delete-course"
      @ok="submit"
  >
    <template #modal-title>
      <span class="text-danger">Delete the Course ?</span>
    </template>
    <h5 class="text-warning">You will lose this Course Data</h5>
    <b-form-input id="courseName" disabled :value="getName" type="text" placeholder="Course Name" class="mb-4" required></b-form-input>
    <b-form-textarea
        id="course-description"
        placeholder="Input Course Description"
        rows="6"
        disabled
        :value="getDescription"
        class="mb-1"
    />
    <b-form-checkbox
        id="isDefault"
        disabled
        :value="getIsDefault"
        :unchecked-value="false"
    >
      set to default Course
    </b-form-checkbox>
    <small class="text-warning" v-if="isDefault">* You will not edit/delete the course if u set it to default</small>
  </b-modal>
</template>
<script>
export default {
  name: 'delete-course-modal',
  data () {
    return {
      name: null,
      description: null,
      isDefault: false
    }
  },
  props: {
    course: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    getName (){
      return this.course.name
    },
    getDescription () {
      return this.course.description
    },
    getIsDefault () {
      return this.course.isDefault
    }
  },
  methods: {
    submit() {
      let data = {
        id: this.course.id,
      }
      this.$emit('delete', data)
    }
  }
}
</script>