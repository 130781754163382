<template>
  <b-modal
      id="view-payment-order-modal"
      size="lg"
      ok-only
  >
    <template #modal-title>
      <div class="d-sm-flex align-items-sm-center">
        <h1 class="page-header-title">Order #{{ order.id }}</h1>
        <payment-status :status="order.payment_status" is-title />
        <fulfillment-status :status="order.fulfillment_status" is-title />
        <span class="ms-2 ms-sm-3">
          <i class="bi-calendar-week"></i> {{ getOrderDate(order.created_at) }}
        </span>
      </div>
    </template>
    <h4 class="card-header-title mb-3">Order details</h4>
    <div class="card">
      <div class="table-responsive">
        <b-table
            borderless
            outlined
            select-mode="single"
            class="table-align-middle table-nowrap card-table"
            :fields="fields"
            :items="currentPackage"
        >
          <template #cell(name)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getName(JSON.parse(data.value)) }}</span>
          </template>
          <template #cell(description)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDescription(JSON.parse(data.value)) }}</span>
          </template>
        </b-table>
      </div>
      <hr>
      <div
          class="row justify-content-md-end mb-3"
      >
        <div class="col-md-8 col-lg-7 mx-3">
          <dl class="row text-sm-end">
            <dt class="col-sm-6">Free Courses:</dt>
            <dd class="col-sm-6">{{ order.extra.courses }}</dd>
            <dt class="col-sm-6">Extended Membership:</dt>
            <dd class="col-sm-6">{{ order.extra.membership_duration }} {{order.extra.membership_duration_symbol}}</dd>
            <dt class="col-sm-6">Customer: </dt>
            <dd class="col-sm-6">{{ order.client_name }}</dd>
            <dt class="col-sm-6">Sales:</dt>
            <dd class="col-sm-6">{{ order.sales }}</dd>
            <dt class="col-sm-6">Amount paid:</dt>
            <dd class="col-sm-6">${{ order.amount }}</dd>
            <dt class="col-sm-6">Payment method:</dt>
            <dd class="col-sm-6"> <payment-method :status="order.payment_method" /></dd>
          </dl>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import { getLocale } from '@/base/utils'
import moment from 'moment'

import {
  PaymentStatus,
  FulfillmentStatus,
  PaymentMethod
} from '@/layout'
export default {
  name: 'view-payment-order-modal',
  components: {
    PaymentStatus,
    FulfillmentStatus,
    PaymentMethod
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {}
    },
    orderType: {
      type: String,
      required: true,
      default: 'membershipPackage'
    }
  },
  data () {
    return {
      locale: getLocale()
    }
  },
  computed: {
    ...mapState('product', ['coursesFields', 'membershipFields']),
    fields () {
      switch (this.orderType) {
        case 'membershipPackage':
          return this.membershipFields
        case 'privateTrainingSession':
          return this.coursesFields
        default:
          return this.membershipFields
      }
    },
    currentPackage () {
      switch (this.orderType) {
        case 'membershipPackage':
          return [this.order.membershipPackage]
        case 'privateTrainingSession':
          return [this.order.coursePackage]
        default:
          return []
      }
    }
  },
  methods: {
    getName (name) {
      return this.$te(`profile.clientType.${name[this.locale]}`) ?
          this.$t(`profile.clientType.${name[this.locale]}`) : name[this.locale]
    },
    getDescription (description) {
      return description[this.locale]
    },
    getDuration (duration) {
      return duration.duration + ' ' + this.$t(`general.dateSymbol.${duration.duration_symbol}`)
    },
    getOrderDate (date) {
      return moment(date).format('YYYY/MM/DD')
    }
  }
}
</script>