<template>
  <div class="card card-lg">
    <div class="profile-cover">
      <div class="profile-cover-img-wrapper">
        <img class="profile-cover-img" src="@/assets/img/1920x400/img1.jpg">
      </div>
    </div>
    <div class="avatar avatar-xxl avatar-circle avatar-border-lg profile-cover-avatar">
      <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Image Description">
    </div>
    <div class="card-body">
      <dl class="row">
        <dt class="col-sm-6 text-sm-end">Full name:</dt>
        <dd class="col-sm-6">{{ form.first_name + ' ' + form.last_name }}</dd>
        <dt class="col-sm-6 text-sm-end">Email</dt>
        <dd class="col-sm-6">{{ form.email }}</dd>
        <dt class="col-sm-6 text-sm-end">Date of Birth:</dt>
        <dd class="col-sm-6">{{ form.dateOfBirth }}</dd>
        <dt class="col-sm-6 text-sm-end">Start of Date:</dt>
        <dd class="col-sm-6">{{ form.startOfDate }}</dd>
        <dt class="col-sm-6 text-sm-end">Phone:</dt>
        <dd class="col-sm-6">{{ form.phone }}</dd>
        <dt class="col-sm-6 text-sm-end">Address:</dt>
        <dd class="col-sm-6">{{ form.address }}</dd>
        <dt class="col-sm-6 text-sm-end">Zip Code:</dt>
        <dd class="col-sm-6">{{ form.zipCode }}</dd>
        <dt class="col-sm-6 text-sm-end">Gender:</dt>
        <dd class="col-sm-6">{{ $t(`profile.gender.${form.gender}`) }}</dd>
        <dt class="col-sm-6 text-sm-end">Client Type:</dt>
        <dd class="col-sm-6">{{ form.clientType }}</dd>
        <h3 class="">Package Summary</h3>
        <div class="table-responsive">
          <b-table
              borderless
              outlined
              select-mode="single"
              class="table-align-middle table-nowrap card-table"
              :fields="fields"
              :items="currentPackage"
          >
            <template #cell(name)="data">
              <span class="d-block h5 text-inherit mb-0">{{ getName(data.value) }}</span>
            </template>
            <template #cell(description)="data">
              <span class="d-block h5 text-inherit mb-0">{{ getDescription(data.value) }}</span>
            </template>
            <template #cell(duration)="data">
              <span class="d-block h5 text-inherit mb-0">{{ getDuration(data.value) }}</span>
            </template>
          </b-table>
        </div>
        <div class="row justify-content-md-end mb-3">
          <div class="col-md-8 col-lg-7">
            <dl class="row text-sm-end">
              <dt class="col-sm-6">Sales:</dt>
              <dd class="col-sm-6">{{ salesName }}</dd>
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getLocale } from '@/base/utils'

export default {
  name: 'confirmation-form',
  props: {
    form: {
      type: Object,
      required: false,
      default: () => {}
    },
    userType: {
      type: String,
      required: false,
      default: 'client'
    }
  },
  data () {
    return {
      locale: getLocale()
    }
  },
  computed: {
    ...mapState('product', ['coursePackages', 'membershipPackages', 'coursesFields', 'membershipFields']),
    ...mapState('employee', ['sales']),
    fields () {
      return this.form.clientType === 'pro' ? this.coursesFields : this.membershipFields
    },
    salesName () {
      let employee = this.sales.find(employee => employee.id === this.form.salesId)
      return employee.first_name + ' ' + employee.last_name
    },
    currentPackage () {
      if (this.form.clientType === 'pro') {
        return this.coursePackages.filter(item => item.id === this.form.packageId)
      } else {
        return this.membershipPackages.filter(item => item.id === this.form.packageId)
      }
    }
  },
  methods: {
    getName (name) {
      return this.$te(`profile.clientType.${name[this.locale]}`) ?
          this.$t(`profile.clientType.${name[this.locale]}`) : name[this.locale]
    },
    getDescription (description) {
      return description[this.locale]
    },
    getDuration (duration) {
      return duration.duration + ' ' + this.$t(`general.dateSymbol.${duration.duration_symbol}`)
    }
  }
}
</script>