<template>
  <div>
    <span v-if="status==='active'" class="legend-indicator bg-success"></span>
    <span v-else-if="status==='suspended'" class="legend-indicator bg-warning"></span>
    <span v-else class="legend-indicator bg-danger"></span>
  </div>
</template>
<script>
export default {
  name: 'user-status',
  props: {
    status: {
      type: String,
      require: true,
      default: 'active'
    }
  }
}
</script>