<template>
  <div>
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <div class="row">
      <div class="col-lg-8 mb-3 mb-lg-9">
        <product-info />
        <product-pictures />
      </div>
      <div class="col-lg-4">
        <product-price />
        <product-organization />
      </div>
    </div>
    <save-modal />
  </div>
</template>
<script>
import {
  PageHeader,
  SaveModal
} from "@/layout";
import {
  ProductInfo,
  ProductPrice,
  ProductOrganization,
  ProductPictures
} from "./Components";

export default {
  name: 'add-product',
  components: {
    ProductPictures,
    SaveModal,
    PageHeader,
    ProductInfo,
    ProductPrice,
    ProductOrganization
  },
  data () {
    return {
      headerTitle: 'Add Product',
      headerItems: [
        {
          text: 'Products',
          href: '#'
        },
        {
          text: 'Add Product',
          active: true
        }
      ]
    }
  }
}
</script>