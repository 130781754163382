import { default as header } from './header.json'
import { default as general } from './general.json'
import { default as profile } from './profile.json'
import { default as payment } from './payment.json'
import { default as course } from './course.json'

export default {
    header,
    general,
    profile,
    payment,
    course
}