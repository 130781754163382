<template>
  <div>
    <b-carousel
        id="carousel-fade"
        style="text-shadow: 0 0 2px"
        no-animation
        controls
        indicators
        img-width="100%"
        img-height="100%"
        background="#000000"
    >
      <b-carousel-slide
          v-for="(img, index) in Images" :key="img.name+index"
          :caption="img.date"
      >
        <template #img>
          <img
              class="d-block img-fluid w-75 m-auto"
              :src="img.src"
              alt="MonkeyKing Gallery"
              @click="openLightboxOnSlide(index)"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>

    <FsLightbox
        :toggler="toggle"
        :slide="slide"
        :sources="fsLightBoxSources"
    />
  </div>
</template>
<script>
import FsLightbox from 'fslightbox-vue'

export default {
  name: 'gallery-lightbox',
  components: {
    FsLightbox
  },
  props: {
    Images: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      toggle: false,
      slide: 0,
    }
  },
  computed: {
    fsLightBoxSources () {
      return this.Images.map(
          img => {
            console.log(img.src)
            return img.src
          }
      )
    }
  },
  methods: {
    openLightboxOnSlide (number) {
      this.slide = number + 1
      this.toggle = !this.toggle
    }
  }
}
</script>
<style>
/*h3 {*/
/*  color: #000000;*/
/*}*/
</style>