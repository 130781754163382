<template>
  <div class="card card-hover-shadow h-100">
    <div class="card-body">
      <h6 class="card-subtitle">{{ subTitle}}</h6>
      <div class="row align-items-center gx-2 mb-1">
        <div class="col-6">
          <h2 class="card-title text-inherit">{{ data.to }}</h2>
        </div>
        <div class="col-6">
          <div class="chartjs-custom" style="height: 3em;">
            <line-chart
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
          </div>
        </div>
      </div>
      <trend-icon :to="data.to" :from="data.from" />
      <span class="text-body fs-6 ms-1"> from {{ data.from }} </span>
    </div>
  </div>
</template>
<script>
import TrendIcon from "./TrendIcon";
import LineChart from "./LineChart";
export default {
  name: 'stat-card-plus',
  components: {
    TrendIcon,
    LineChart
  },
  props: {
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    data: {
      type: Object,
      required: false,
      default: null
    },
    chartData: {
      type: Object || Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      chartOptions: {
        scales: {
          y: {
            display: false
          },
          x: {
           display: false
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false
        },
        plugins: {
          legend: {
            display: false
          },
        }
      }
    }
  },
  methods: {
  }
}
</script>