import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = {
    visible: false,
    message: '',
    type: '',
    sm: false,
    md: false,
    lg: false,
    xl: false,
    navbarExpanded: true,
    navbarExpandedMobile: false
}

export const alert = {
    namespaced: true,
    state: initialState,
    actions,
    getters,
    mutations
}