<template>
  <div class="position-fixed start-50 bottom-0 translate-middle-x w-100 zi-99 mb-3" style="max-width: 40rem;">
    <div class="card card-sm bg-dark border-dark mx-2">
      <div class="card-body">
        <div class="row justify-content-center justify-content-sm-between">
          <div class="col">
            <button type="button" class="btn btn-ghost-danger">Delete</button>
          </div>
          <div class="col-auto">
            <div class="d-flex gap-3">
              <button type="button" class="btn btn-ghost-light">Discard</button>
              <button type="button" class="btn btn-primary" @click="handleSave">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'save-modal',
  props: {
    visible: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  methods: {
    handleSave () {
      console.log('handle save')
    }
  }
}
</script>