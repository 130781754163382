import { AuthEntity } from "../api";
import {
    setUpAuthenticatedUserSession,
    updateSessionTokenInfo
} from '@/base/utils/sessions'
import {axios} from '@/base/network'
import { betaLogoutUrl, localStorage, sessionStorage } from '@/base/utils'

export const login = async ({ commit }, params) => {
    try {
        const { data } = await AuthEntity.login(commit, params)
        if (data.status === 'error') {
            throw new Error('NO_PERMISSION')
        }
        if (data.token && data.status === 'success') {
            await setUpAuthenticatedUserSession(data)
            return data
        }
    } catch (e) {
        throw e.response || e
    }
}

export const refreshToken = async ({ commit }) => {
  const { data } = await AuthEntity.refreshToken(commit)
  if (data.token) {
      updateSessionTokenInfo(data.token, data.expires_in)
  }
}

export const logout = async ({ commit, dispatch }, type) => {
    await AuthEntity.logout(commit, type)
    axios.removeHeader()
    localStorage.removeItem('token')
    localStorage.removeItem('expiry_date')

    sessionStorage.clear()
    dispatch('user/clear', {}, { root: true})
    window.location = betaLogoutUrl()
}

export const resetPassword = async ({commit}, data) => {
    await AuthEntity.resetPassword(commit, data)
}

export default {
    login,
    logout,
    refreshToken,
    resetPassword
}