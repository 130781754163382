<template>
  <b-modal
      id="user-reason-existed-modal"
      title="Failed to submit Dismiss/On Leave Request"
      ok-only
  >
    <div class="card h-100 text-center">
      <div class="card-body">
        <img class="card-img" src="@/assets/svg/empty/oc-error.svg" alt="something wrong" >
      </div>
      <div class="card-footer">
        <h3 class="card-title">Oops! This user already have an OnLeave/Dismiss request.</h3>
        <p class="card-text fs-6"><span class="text-danger">Note:</span> Each User can only create OnLeave/Dismiss request once.</p>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'user-reason-existed-modal'
}
</script>