<template>
  <div class="course-overview">
    <page-header
        :title="headerTitle"
        :items="headerItems"
    />
    <div class="card">
      <div class="card-header card-header-content-between">
        <b-col lg="3" class="my-1">
          <b-form-group>
            <b-input-group
                class="input-group-merge input-group-flush"
            >
              <div class="input-group-prepend input-group-text">
                <i class="bi-search"></i>
              </div>
              <b-form-input
                  v-model="filter"
                  class=""
                  type="search"
                  placeholder="Search Course"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <div class="d-grid d-sm-flex justify-content-md-end align-items-sm-center gap-2">
          <b-button variant="primary"  v-b-modal.add-course><i class="bi bi-plus nav-icon"></i>Add Course</b-button>
        </div>
      </div>
      <div class="table-responsive">
        <b-table
            v-if="!fetching"
            borderless
            select-mode="single"
            id="courses-overview"
            class="table-align-middle table-nowrap card-table"
            :filter="filter"
            :fields="fields"
            :items="courseList"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
          <template #cell(name)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getCourseName(data.value) }}</span>
          </template>
          <template #cell(isDefault)="data">
            {{ data.value === 1? 'Yes': 'No' }}
          </template>
          <template #cell(actions)="row">
            <b-button-group>
<!--            <b-button-group v-if="!row.item.isDefault">-->
              <b-button class="btn-white" size="sm" @click="handleEditCourse(row)"><i class="bi bi-pencil-fill me-1"></i>Edit</b-button>
              <b-dropdown
                  toggle-class="btn-white btn-icon dropdown-toggle-empty"
                  size="sm"
                  right
              >
                <b-dropdown-item class="btn-white" @click="handleDeleteCourse(row)"><i class="bi bi-trash dropdown-item-icon"></i>Delete</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </template>
        </b-table>
        <b-spinner
            v-else
            variant="primary"
            class="align-middle"
        ></b-spinner>
      </div>
      <div class="card-footer">
        <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
          <div class="col-sm mb-2 mb-sm-0">
            <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
              <p>Current Page: {{ currentPage }}</p>
            </div>
          </div>
          <div class="col-sm-auto">
            <div class="d-flex justify-content-center justify-content-sm-end">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="courses-overview"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-course-modal
        @submit="handleCreateCourse"
    />
    <update-course-modal :course="currentCourse" @edit="data => handleAction('edit', data)" />
    <delete-course-modal :course="currentCourse" @delete="data => handleAction('delete', data)" />
  </div>
</template>
<script>
import { PageHeader} from '@/layout'
import { mapState, mapActions } from 'vuex'
import { AddCourseModal, UpdateCourseModal, DeleteCourseModal } from './components'

export default {
  name: 'courses-overview',
  components: {
    PageHeader,
    AddCourseModal,
    UpdateCourseModal,
    DeleteCourseModal
  },
  data () {
    return {
      currentCourse: {},
      headerTitle: 'Settings',
      headerItems: [
        {
          text: 'Courses',
          href: '#'
        },
        {
          text: 'Overview',
          active: true
        }
      ],
      filter: null,
      currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false,
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'isDefault',
          sortable: true
        },
        {
          key: 'description',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState('course', ['fetching', 'courseList']),
    rows () {
      return this.courseList.length
    }
  },
  methods: {
    ...mapActions('course', ['listCourses', 'createCourse', 'updateCourse', 'deleteCourse']),
    ...mapActions('alert', ['setAlert']),
    getCourseName (name) {
      if (this.$te('course.courseName.' + name)) {
        return this.$t('course.courseName.' + name)
      } else {
        return name
      }
    },
    handleEditCourse (row) {
      this.currentCourse = row.item
      this.$bvModal.show('update-course')
    },
    handleDeleteCourse (row) {
      this.currentCourse = row.item
      this.$bvModal.show('delete-course')
    },
    async handleCreateCourse (data) {
      await this.createCourse(data)
          .then(this.listCourses)
          .catch(err => {
            console.log(err)
          })
    },
    async handleAction(action, data) {
      if (action === 'edit') {
        await this.updateCourse(data)
            .then(this.listCourses)
            .catch(err => {
              console.log(err)
            })
      }
      if (action === 'delete') {
        await this.deleteCourse(data.id)
            .then(this.listCourses)
            .catch(err => {
              console.log(err)
            })
      }
    }
  },
  async mounted () {
    await this.listCourses()
  }
}
</script>