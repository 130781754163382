import { CourseEntity } from './api'

export const listCourses = async ({commit}) => {
    await CourseEntity.getCourses(commit)
}

export const createCourse = async ({commit}, data) => {
    await CourseEntity.createCourse(commit, data)
}

export const updateCourse = async ({commit}, data) => {
    await CourseEntity.updateCourse(commit, data)
}

export const deleteCourse = async ({commit}, id) => {
    await CourseEntity.deleteCourse(commit, id)
}

export const getClientCourses = async ({commit}, {id, params}) => {
    await CourseEntity.getClientCourse(commit, {id, params})
}

export const listUserCoursesQuickSummary = async ({commit}, {id, params}) => {
    await CourseEntity.listUserCoursesQuickSummary(commit, {id, params})
}

export const listTrainerCourses = async ({commit}, id) => {
    await CourseEntity.listTrainerCourses(commit, id)
}

export const listCourseTags = async ({commit}) => {
    await CourseEntity.listCourseTags(commit)
}

export const createReservation = async ({commit}, {data}) => {
    await CourseEntity.createReservation(commit, data)
}

export const updateReservationDetailForClient = async ({commit}, {id, data}) => {
    await CourseEntity.updateReservationDetailForClient(commit, id, data)
}

export const updatedReservationStatus = async ({commit}, {id, data}) => {
    await CourseEntity.updatedReservationStatus(commit, id, data)
}

export const getUserCoursesSummary = async ({commit}, id) => {
    await CourseEntity.getUserCoursesSummary(commit, id)
}

export const listTrainerCoursesSummary = async ({commit}, params) => {
    await CourseEntity.listTrainerCoursesSummary(commit, params)
}

export default {
    listCourses,
    createCourse,
    updateCourse,
    deleteCourse,
    listUserCoursesQuickSummary,
    getClientCourses,
    listTrainerCourses,
    listCourseTags,
    createReservation,
    updateReservationDetailForClient,
    updatedReservationStatus,
    getUserCoursesSummary,
    listTrainerCoursesSummary
}