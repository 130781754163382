import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    ME,
    LIST_ACTIVITIES,
    LIST_BODY_INDEX_RECORD,
    CREATE_USER_REASON,
    MODIFY_USER_REASON,
    ACTIVATE_USER,
    WITHDRAW_PENDING
} from '../types'

const options = {
    endpoint: 'api/user',
    moduleName: 'USER'
}

export const UserEntity = new NetworkEntity(options)

UserEntity.me = function (commit, params = {}) {
    commit(ME.ACTION)
    return axios
        .get(`api/me`, {  params: params })
        .then(({ data: { data } }) => {
            commit(ME.SUCCESS, data)
        })
        .catch(err => {
            commit(ME.FAILURE, err)
        })
}

UserEntity.listActivities = function (commit, id) {
    commit(LIST_ACTIVITIES.ACTION)
    return axios
        .get(`${this.endpoint}/${id}/activities`)
        .then(({ data: { data } }) => {
            commit(LIST_ACTIVITIES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_ACTIVITIES.FAILURE, err)
        })
}

UserEntity.listUserBodyIndexRecords = function (commit, id) {
    commit(LIST_BODY_INDEX_RECORD.ACTION)
    return axios
        .get(`${this.endpoint}/${id}/body-index-records`)
        .then(({ data: { data } }) => {
            commit(LIST_BODY_INDEX_RECORD.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_BODY_INDEX_RECORD.FAILURE, err)
        })
}

UserEntity.createUserReason = function (commit, id, data) {
    commit(CREATE_USER_REASON.ACTION)
    return axios
        .post(`${this.endpoint}/${id}/submit-reason`, data)
        .then(({data}) => {
            commit(CREATE_USER_REASON.SUCCESS, data)
        })
        .catch(err => {
            commit(CREATE_USER_REASON.FAILURE, err)
        })
}

UserEntity.modifyUserReason = function (commit, id, data) {
    commit(MODIFY_USER_REASON.ACTION)
    return axios
        .post(`${this.endpoint}/${id}/modify-reason`, data)
        .then(({data}) => {
            commit(MODIFY_USER_REASON.SUCCESS, data)
        })
        .catch(err => {
            commit(MODIFY_USER_REASON.FAILURE, err)
        })
}

UserEntity.activateUser = function (commit, id, data) {
    commit(ACTIVATE_USER.ACTION)
    return axios
        .post(`${this.endpoint}/${id}/withdraw-reason`, data)
        .then(({data}) => {
            commit(ACTIVATE_USER.SUCCESS, data)
        })
        .catch(err => {
            commit(ACTIVATE_USER.FAILURE, err)
        })
}

export default UserEntity.withdrawPending = function (commit, id) {
    commit(WITHDRAW_PENDING.ACTION)
    return axios
        .post(`${this.endpoint}/${id}/activate-user`)
        .then(({data}) => {
            commit(WITHDRAW_PENDING.SUCCESS, data)
        })
        .catch(err => {
            commit(WITHDRAW_PENDING.FAILURE, err)
        })
}