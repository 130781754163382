<template>
  <b-button-group>
    <b-button variant="outline-secondary" size="sm" @click="handleActionClick('view')">
      <i class="bi-eye-fill me-1"></i>
      {{ $t('general.actions.view') }}
    </b-button>
    <b-dropdown
        variant="out-secondary"
        toggle-class="btn-white btn-icon dropdown-toggle-empty"
        size="sm"
        right
    >
      <b-dropdown-item-button
          v-for="(action, index) in actionList"
          :key="`${action}-${index}`"
          :class="action === 'delete' ? 'btn-outline-danger': null"
          class="dropdown-item"
          v-html="getActionTranslation(action)"
          @click.native="handleActionClick(action)"
      >
      </b-dropdown-item-button>
    </b-dropdown>
  </b-button-group>
</template>
<script>
export default {
  name: 'contextual-menu',
  props: {
    menuType: {
      type: String,
      required: true,
      default: 'clientDashboard'
    },
    actionVariant: {
      type: String,
      required: true,
      default: 'active'
    },
    excludedActions: {
      type: Array,
      required: false,
      default: () => []
    },
    row: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    actionList  () {
      switch (this.menuType) {
        case 'clientDashboardForAdmin':
        case 'trainerDashboardForAdmin':
        case 'employeeDashboardForAdmin':
          switch (this.actionVariant) {
            case 'active':
              return ['onLeave', 'dismiss', 'delete']
            case 'onLeave':
              return ['modifyOnLeave', 'withdrawOnLeave', 'dismiss', 'delete']
            case 'dismissed':
              return ['modifyDismissed', 'withdrawDismissed', 'delete']
            case 'pending':
              return ['withdrawPending', 'delete']
          }
          break
        case 'clientDashboardForTrainer':
            switch (this.actionVariant) {
              case 'active':
                return ['onLeave', 'delete']
              case 'onLeave':
                return ['modifyOnLeave', 'withdrawOnLeave', 'delete']
              case 'pending':
                return ['delete']
            }
      }
      return []
    },
  },
  methods: {
    getActionTranslation (action) {
      switch (action) {
        case 'edit':
          return '<i class="bi-pencil dropdown-item-icon"></i>' + this.$t('general.actions.' + action)
        case 'delete':
          return '<i class="bi-trash dropdown-item-icon"></i>' + this.$t('general.actions.' + action)
        case 'onLeave':
          return '<i class="bi bi-clock-history dropdown-item-icon"></i>' + this.$t('general.actions.' + action)
        case 'dismiss':
          return '<i class="bi bi-person-dash dropdown-item-icon"></i>' + this.$t('general.actions.' + action)
        default:
          return this.$t('general.actions.' + action)
      }
    },
    handleActionClick (action) {
      this.$emit('contextual-menu-click', this.row, action)
    }
  }
}
</script>