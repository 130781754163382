import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const initialState = () => ({
    fetching: false,
    employees: [],
    managers: [],
    sales: [],
    employeesCountSummary: [],
    employeeProfile: {},
    addedEmployee: []
})

export const employee = {
    namespaced: true,
    state: initialState(),
    actions,
    getters,
    mutations
}