<template>
  <form>
    <b-row v-if="visible" class="mb-4">
      <label for="editUserModalCurrentPasswordLabel" class="col-sm-3 col-form-label form-label">Current Password</label>
      <b-col sm="9">
        <b-form-group class="input-group-merge">
          <b-form-input
              id="editUserModalCurrentPasswordLabel"
              :type="showPassword?'text':'password'"
          />
          <div class="input-group-append input-group-text">
            <b-button variant="link" class="p-0" @click="showHidePwIcon"><i :class="showPassword?'bi-eye':'bi-eye-slash'"></i></b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editUserNewPassword" class="col-sm-3 col-form-label form-label">New password</label>
      <b-col sm="9">
        <b-form-input
            id="editUserNewPassword"
            :type="showResetPassword?'text':'password'"
            :value="isValidPassword"
            v-model="newPassword"
            aria-describedby="input-password-feedback"
            @input="value => handleInput('password', value)"
        />
        <b-form-invalid-feedback id="input-password-feedback">
          Enter minimum eight characters, at least one letter and one number
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <label for="editUserConfirmNewPasswordLabel" class="col-sm-3 col-form-label form-label">Confirm new password</label>
      <b-col sm="9">
        <b-form-input
            id="editUserConfirmNewPasswordLabel"
            :type="showResetPassword?'text':'password'"
            :state="isValidRepeatPassword"
            :value="repeatPassword"
            aria-describedby="input-repeat-password-feedback"
        />
        <b-form-invalid-feedback id="input-repeat-password-feedback">
          Password not matching
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col sm="9" offset-sm="3">
        <b-form-checkbox v-model="showResetPassword">Show password</b-form-checkbox>
      </b-col>
    </b-row>
  </form>
</template>
<script>
export default {
  name: 'change-password-content',
  data () {
    return {
      visible: false,
      showPassword: false,
      showResetPassword: false,
      newPassword: '',
      repeatPassword: ''
    }
  },
  computed: {
    isValidPassword () {
      //Minimum eight characters, at least one letter and one number
      const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
      return regex.test(this.newPassword) || (this.newPassword ===  '' && this.repeatPassword === '')
    },
    isValidRepeatPassword () {
      return this.newPassword === this.repeatPassword
    }
  },
  methods: {
    showHidePwIcon () {
      this.showPassword = !this.showPassword
    },
    handleInput () {

    }
  }
}
</script>