import * as TYPES from './types'

const createEmployee = state => {
    state.fetching = true
}

const createEmployeeSuccess = (state, data) => {
    state.addedEmployee = data
    state.fetching = false
}

const createEmployeeFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getEmployees = state => {
    state.fetching = true
}

const getEmployeesSuccess = (state, data) => {
    state.employees = data
    state.fetching = false
}

const getEmployeesFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const listManagers = state => {
    state.fetching = true
}

const listManagersSuccess = (state, data) => {
    state.managers = data
    state.fetching = false
}

const listManagersFailure = (state, error) => {
    console.log(error)
    state.fetching = false
}

const listSales = () => {}
const listSalesSuccess = (state, data) => {
    state.sales = data
}
const listSalesFailure = (state, error) => {
    console.log(error)
}

const getEmployeesCountSummary = state => {
    state.fetching = true
}

const getEmployeesCountSummarySuccess = (state, data) => {
    state.employeesCountSummary = data
    state.fetching = false
}

const getEmployeesCountSummaryFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const deleteEmployee = () => {}
const deleteEmployeeSuccess = () => {}
const deleteEmployeeFailure = (state, error) => {
    console.log(error)
}

const getEmployeeProfile = (state) => {
    state.fetching = true
}

const getEmployeeProfileSuccess = (state, data) => {
    state.fetching = false
    state.employeeProfile = data
}

const getEmployeeProfileFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

export default {
    [TYPES.CREATE_EMPLOYEE.ACTION]: createEmployee,
    [TYPES.CREATE_EMPLOYEE.SUCCESS]: createEmployeeSuccess,
    [TYPES.CREATE_EMPLOYEE.FAILURE]: createEmployeeFailure,
    [TYPES.LIST_MANAGERS.ACTION]: listManagers,
    [TYPES.LIST_MANAGERS.SUCCESS]: listManagersSuccess,
    [TYPES.LIST_MANAGERS.FAILURE]: listManagersFailure,
    [TYPES.LIST_SALES.ACTION]: listSales,
    [TYPES.LIST_SALES.SUCCESS]: listSalesSuccess,
    [TYPES.LIST_SALES.FAILURE]: listSalesFailure,
    [TYPES.LIST_EMPLOYEE.ACTION]: getEmployees,
    [TYPES.LIST_EMPLOYEE.SUCCESS]: getEmployeesSuccess,
    [TYPES.LIST_EMPLOYEE.FAILURE]: getEmployeesFailure,
    [TYPES.GET_EMPLOYEES_COUNT_SUMMARY.ACTION]: getEmployeesCountSummary,
    [TYPES.GET_EMPLOYEES_COUNT_SUMMARY.SUCCESS]: getEmployeesCountSummarySuccess,
    [TYPES.GET_EMPLOYEES_COUNT_SUMMARY.FAILURE]: getEmployeesCountSummaryFailure,
    [TYPES.DELETE_EMPLOYEE.ACTION]: deleteEmployee,
    [TYPES.DELETE_EMPLOYEE.SUCCESS]: deleteEmployeeSuccess,
    [TYPES.DELETE_EMPLOYEE.FAILURE]: deleteEmployeeFailure,
    [TYPES.GET_EMPLOYEE_PROFILE.ACTION]: getEmployeeProfile,
    [TYPES.GET_EMPLOYEE_PROFILE.SUCCESS]: getEmployeeProfileSuccess,
    [TYPES.GET_EMPLOYEE_PROFILE.FAILURE]: getEmployeeProfileFailure
}