import * as TYPES from './types'

const getCompanySettings = state => {
    state.fetching = true
}

const getCompanySettingsSuccess = (state, data) => {
    state.companySettings = data
    state.fetching = false
}

const getCompanySettingsFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getCompanyBodyIndexFieldSettings = state => {
    state.fetching = true
}

const getCompanyBodyIndexFieldSettingsSuccess = (state, data) => {
    state.companyBodyIndexFieldSettings = data
    state.fetching = false
}

const getCompanyBodyIndexFieldSettingsFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

export default {
    [TYPES.GET_COMPANY_SETTING.ACTION]: getCompanySettings,
    [TYPES.GET_COMPANY_SETTING.SUCCESS]: getCompanySettingsSuccess,
    [TYPES.GET_COMPANY_SETTING.FAILURE]: getCompanySettingsFailure,
    [TYPES.GET_COMPANY_BODY_INDEX_FIELDS_SETTING.ACTION]: getCompanyBodyIndexFieldSettings,
    [TYPES.GET_COMPANY_BODY_INDEX_FIELDS_SETTING.SUCCESS]: getCompanyBodyIndexFieldSettingsSuccess,
    [TYPES.GET_COMPANY_BODY_INDEX_FIELDS_SETTING.FAILURE]: getCompanyBodyIndexFieldSettingsFailure
}