<template>
  <div class="card h-100">
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title mb-2 mb-sm-0">
        Recipe
      </h4>
      <b-button
          v-if="usingAppAs[0] !== 'client'"
          size="sm"
          variant="light"
          class="btn-ghost-secondary"
          v-b-modal.add-recipe
      >Add Recipe</b-button>
    </div>
    <div
        v-if="!fetchingRecipes && recipes.length !== 0"
        class="card-body"
    >
      <div
          v-for="recipe in recipes"
          :key="`recipe-${recipe.id}`"
          class="mb-4"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h5>{{ getDate(recipe.created_at) }}</h5>
          <div v-if="['admin', 'trainer'].includes(usingAppAs[0])">
            <a
                class="link"
                href="javascript:;"
                @click="showUpdateRecipeModal(recipe)"
            >Edit / </a>
            <a
                class="link text-danger"
                href="javascript:;"
                @click="showDeleteRecipeModal(recipe)"
            >Delete</a>
          </div>
        </div>
        <p class="d-block text-body mr-4">
          {{ recipe.recipe }}
        </p>
        <div class="text-end">
          -- Author {{ recipe.created_by }}
        </div>
      </div>
    </div>
    <b-spinner
        v-else-if="fetchingRecipes"
        variant="primary"
        label="Spinning"
    />
    <div
        v-else
        class="card-body"
    >
      <p class="text-center">{{ emptyRecipeDescription }}</p>
      <div class="d-flex justify-content-center">
        <b-button
            v-if="usingAppAs !== 'client'"
            variant="primary"
            v-b-modal.add-recipe
        >
          Create Recipe
        </b-button>
      </div>
    </div>
    <add-recipe-modal
        @submit="handleCreateRecipe"
    />
    <update-recipe-modal
        v-if="selectedRecipe !== null"
        :description="selectedRecipe.recipe"
        :author="`${user.first_name} ${user.last_name}`"
        @submit="handleUpdateRecipe"
    />
    <delete-recipe-modal
        v-if="selectedRecipe !== null"
        :description="selectedRecipe.recipe"
        :author="selectedRecipe.created_by"
        @submit="handleDeleteRecipe"
    />
  </div>
</template>
<script>
import { AddRecipeModal, UpdateRecipeModal, DeleteRecipeModal } from '../components'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'profile-recipe',
  components: {
    AddRecipeModal,
    UpdateRecipeModal,
    DeleteRecipeModal
  },
  props: {
    userId: {
      type: [ String, Number ],
      required: false,
      default: ''
    }
  },
  data () {
    return {
      selectedRecipe: null
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('client', ['fetchingRecipes' ,'recipes']),
    emptyRecipeDescription () {
      if (['admin', 'trainer'].includes(this.usingAppAs[0])) {
        return 'Please create recipe for your client !'
      } else {
        return 'Please contact your trainer or Administrator to generate a recipe for u!'
      }
    }
  },
  methods: {
    ...mapActions('client', ['listRecipes', 'createRecipe', 'updateRecipe', 'deleteRecipe']),
    getDate (date) {
      return moment(date).format('YYYY/MM/DD')
    },
    showUpdateRecipeModal (recipe) {
      this.selectedRecipe = recipe
      this.$bvModal.show('update-recipe-modal')
    },
    showDeleteRecipeModal (recipe) {
      this.selectedRecipe = recipe
      this.$bvModal.show('delete-recipe-modal')
    },
    async handleCreateRecipe (recipe) {
      await this.createRecipe({
        id: this.userId,
        data: { recipe: recipe }
      })
      await this.listRecipes(this.userId)
    },
    async handleUpdateRecipe (recipe) {
      await this.updateRecipe({
        id: this.userId,
        recipeId: this.selectedRecipe.id,
        data: { recipe: recipe }
      })
      await this.listRecipes(this.userId)
    },
    async handleDeleteRecipe () {
      await this.deleteRecipe({
        userId: this.user.id,
        recipeId: this.selectedRecipe.id
      })
      await this.listRecipes(this.userId)
    }
  },
  async mounted () {
    if (this.userId !== 0) {
      await this.listRecipes(this.userId)
    }
  }
}
</script>