<template>
  <div>
    <page-header
        :title="headerTitle"
    />
    <div
        v-if="!fetching"
        class="row"
    >
      <div class="col-lg-4">
        <user-profile
            :user-profile="profile"
        />
        <course-status :user-id="profile.id"/>
      </div>
      <div class="col-lg-8">
        <today-event :user-type="profile.userType" :user-id="profile.id" />
        <courses-history-table :user-id="profile.id" :user-type="profile.userType" />
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

import {
  PageHeader
} from '@/layout'
import { UserProfile, TodayEvent, CoursesHistoryTable } from './bookingComponents'
import { CourseStatus } from '@/components/users/userProfile/profileContent'

export default {
  name: 'client-bookings',
  components: {
    UserProfile,
    PageHeader,
    TodayEvent,
    CourseStatus,
    CoursesHistoryTable
  },
  data () {
    return {
      headerTitle: 'Bookings',
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('userProfile', ['fetching', 'myProfileJson']),
    profile () {
      return { ...this.user, ...this.myProfileJson, userType: this.usingAppAs[0]}
    }
  },
  methods: {
    ...mapActions('userProfile', ['getMyProfile'])
  },
  async mounted () {
    await this.getMyProfile(this.user.id)
  }
}
</script>