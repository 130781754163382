<template>
  <b-modal
      id="update-membership-package-modal"
      title="Update Membership Package"
      size="lg"
      :ok-disabled="hasAlert"
      @ok="submit"
  >
    <div v-if="hasAlert" class="alert alert-warning" role="alert">
      Please input all fields
    </div>
    <label for="membershipPackageName" class="col-form-label form-label">Name</label>
    <label class="sr-only" for="name-cn">Name(中文)</label>
    <div class="input-group input-group-md-vertical">
      <b-form-input
          type="text"
          class="form-control"
          placeholder="Name(中文)"
          :state="membershipPackage.name.cn !== ''"
          :value="membershipPackage.name.cn"
          v-on:input="value => input('name cn', value)"
      />
      <b-form-input
          type="text"
          class="form-control"
          placeholder="Name(中文)"
          :state="membershipPackage.name.en !== ''"
          :value="membershipPackage.name.en"
          v-on:input="value => input('name en', value)"
      />
    </div>
    <label for="membershipDescription" class="col-form-label form-label">Description</label>
    <div class="input-group input-group-md-vertical">
      <b-form-textarea
          type="text"
          class="form-control"
          placeholder="Description(中文)"
          :state="membershipPackage.description.cn !== ''"
          :value="membershipPackage.description.cn"
          v-on:input="value => input('description cn', value)"
      />
      <b-form-textarea
          type="text"
          class="form-control"
          placeholder="Description(English)"
          :state="membershipPackage.description.en !== ''"
          :value="membershipPackage.description.en"
          v-on:input="value => input('description en', value)"
      />
    </div>
    <label for="amount" class="col-form-label form-label">Amount</label>
    <b-input-group prepend="$" append=".00" class="w-lg-50">
      <b-form-input
          id="amount"
          class="p-4"
          type="number"
          :value="membershipPackage.amount"
          :state="membershipPackage.amount !== ''"
          v-on:input="value => input('amount', value)"
      />
    </b-input-group>
    <label for="duration" class="col-form-label form-label">Duration</label>
    <b-input-group class="input-group input-group-sm-vertical w-lg-50">
      <b-form-input
          id="duration"
          type="number"
          :value="membershipPackage.duration.duration"
          :state="membershipPackage.duration.duration !== ''"
          v-on:input="value => input('duration', value)"
      />
      <template #append>
        <b-form-select
            class="form-select p-1"
            :options="options"
            :value="membershipPackage.duration.duration_symbol"
            :state="membershipPackage.duration.duration_symbol !== ''"
            v-on:input="value => input('duration_symbol', value)"
        />
      </template>
    </b-input-group>
    <label for="shared" class="col-form-label form-label">How many people gonna use this package ?</label>
    <b-form-input
        id="shared"
        type="number"
        class="w-50"
        :value="membershipPackage.shared"
        :state="membershipPackage.shared !== ''"
        v-on:input="value => input('shared', value)"
    />
  </b-modal>
</template>
<script>
export default {
  name: 'update-membership-package-modal',
  props: {
    membershipPackage: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      options: [
        { value: 'year', text: 'Year(s)' },
        { value: 'month', text: 'Month(s)' },
        { value: 'week', text: 'Week(s)' },
        { value: 'day', text: 'Day(s)' },
      ]
    }
  },
  computed: {
    hasAlert () {
      return this.membershipPackage === null ? false: Object.values(this.membershipPackage).some(item => {return item === ''})
    }
  },
  methods: {
    input(name, value) {
      this.$emit('update', {name, value})
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>