import * as TYPES from './types'

//login & logout
const action = state => {
    state.fetching = true
}
const success = state => {
    state.fetching = false
}
const failure = (state, error) => {
    state.fetching = true
    console.log(error)
}

const resetPassword = () => {}
const resetPasswordSuccess = () => {}
const resetPasswordFailure = (state, error) => {
    console.log(error)
}

export default {
    [TYPES.LOGIN.ACTION]: action,
    [TYPES.LOGIN.SUCCESS]: success,
    [TYPES.LOGIN.FAILURE]: failure,
    [TYPES.LOGOUT.ACTION]: action,
    [TYPES.LOGOUT.SUCCESS]: success,
    [TYPES.LOGOUT.FAILURE]: failure,
    [TYPES.REFRESH_TOKEN.ACTION]: action,
    [TYPES.REFRESH_TOKEN.SUCCESS]: success,
    [TYPES.REFRESH_TOKEN.FAILURE]: failure,
    [TYPES.RESET_PASSWORD.ACTION]: resetPassword,
    [TYPES.RESET_PASSWORD.SUCCESS]: resetPasswordSuccess,
    [TYPES.RESET_PASSWORD.FAILURE]: resetPasswordFailure
}