<template>
  <div>
    <Calendar
        trim-weeks
        :attributes='attributes'
    />
  </div>
</template>
<script>
import { Calendar } from 'v-calendar';

export default {
  name: 'small-calendar',
  components: {
    Calendar,
  },
  data() {
    const todos = [
      {
        description: 'Take HIT course.',
        isComplete: false,
        dates: [
          new Date(2023, 9, 3), // May 1st
          new Date(2023, 9, 13), // May 1st
          new Date(2023, 9, 23), // May 1st
        ],
      },
    ];
    const completedCourses = [
      {
        description: 'Take training course.',
        isComplete: false,
        dates: [
          new Date(2023, 9, 3), // May 1st
          new Date(2023, 9, 13), // May 1st
          new Date(2023, 9, 23), // May 1st
        ],
        color: 'orange'
      },
    ];
    return {
      incId: todos.length,
      todos,
      completedCourses
    };
  },
  computed: {
    attributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'purple',
            fillMode: 'solid',
          },
          dates: new Date(),
        },
        // Attributes for todos
        ...this.todos.map(todo => ({
          dates: todo.dates,
          dot: {
            color: todo.color,
            class: todo.isComplete ? 'opacity-75' : '',
          },
          popover: {
            label: todo.description,
          },
          customData: todo,
        })),
        ...this.completedCourses.map(todo => ({
          dates: todo.dates,
          dot: {
            color: todo.color,
            class: todo.isComplete ? 'opacity-75' : '',
          },
          popover: {
            label: todo.description,
          },
          customData: todo,
        })),
      ];
    },
  },
}
</script>