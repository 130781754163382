<template>
  <div>
    <page-header
        :title="headerTitle"
    />
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <b-table
              v-if="!fetching"
              head-variant="light"
              borderless
              class="table-align-middle table-nowrap card-table"
              :fields="fields"
              :items="userPayments"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
          >
            <template #cell(id)="data">
              <span>#</span>{{ data.value }}
            </template>
            <template #cell(payment_status)="data">
              <payment-status :status="data.value" />
            </template>
            <template #cell(start_at)="data">
              <div>{{ getCreatedAt(data.value) }}</div>
            </template>
            <template #cell(product_type)="data">
              <div>{{ $t(`payment.productType.${data.value}`) }}</div>
            </template>
            <template #cell(fulfillment_status)="data">
              <fulfillment-status :status="data.value" />
            </template>
            <template #cell(payment_method)="data">
              <payment-method :status="data.value" />
            </template>
            <template #cell(amount)="data">
              <span>$</span>{{ data.value }}
            </template>
            <template #cell(actions)="row">
              <b-button-group>
                <b-button
                    variant="out-secondary"
                    class="btn-white"
                    size="sm"
                    @click="handleButtonClick(row.item, 'view')"
                ><i class="bi-eye me-1"></i>View</b-button>
                <b-dropdown
                    v-if="row.item.actions.includes('edit') || row.item.actions.includes('delete')"
                    variant="out-secondary"
                    toggle-class="btn-white btn-icon dropdown-toggle-empty"
                    size="sm"
                    right
                >
                  <b-dropdown-item v-if="row.item.actions.includes('edit')" @click="handleButtonClick(row.item, 'edit')"><i class="bi-pencil-fill dropdown-item-icon"></i>Edit</b-dropdown-item>
                  <b-dropdown-item class="btn-outline-danger" @click="handleButtonClick(row.item, 'delete')"><i class="bi-trash dropdown-item-icon"></i>Delete</b-dropdown-item>
                </b-dropdown>
              </b-button-group>
            </template>
          </b-table>
          <b-spinner
              v-else
              variant="primary"
              label="Spinning"
          />
        </div>
      </div>
    </div>
    <view-payment-order-modal
        v-if="Object.keys(selectedRow).length !== 0"
        :order-type="orderType" :order="selectedRow"
    />
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

import {
  PageHeader,
  PaymentStatus,
  FulfillmentStatus,
  PaymentMethod
} from '@/layout'

import { ViewPaymentOrderModal } from '@/components/users/userProfile'

export default {
  name: 'my-payment',
  components: {
    PageHeader,
    PaymentStatus,
    FulfillmentStatus,
    PaymentMethod,
    ViewPaymentOrderModal
  },
  data () {
    return {
      headerTitle: 'My Payments',
      currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false,
      selectedRow: {},
      fields: [
        {
          key: 'id',
          label: 'ORDER',
          sortable: false
        },
        {
          key: 'product_type',
          label: 'PRODUCT TYPE',
          sortable: true
        },
        {
          key: 'start_at',
          label: 'START DATE',
          sortable: true
        },
        {
          key: 'payment_status',
          label: 'PACKAGE STATUS',
          sortable: true
        },
        {
          key: 'fulfillment_status',
          sortable: true
        },
        {
          key: 'payment_method',
          sortable: true
        },
        {
          key: 'amount',
          label: 'AMOUNT',
          sortable: true
        },
        {
          key: 'actions',
          sortable: false
        },
      ],
    }
  },
  computed: {
    ...mapState('user', { user: 'data', usingAppAs: 'useAppAs' }),
    ...mapState('payment', ['fetching', 'userPayments']),
    ...mapState('company', ['companySettings']),
    ...mapState('product', ['coursePackages', 'membershipPackages', 'coursesFields', 'membershipFields']),
  },
  methods: {
    ...mapActions('payment', ['getUserPayments']),
    ...mapActions('product', [
      'getCoursePackages',
      'getMembershipPackages'
    ]),
    getCreatedAt (date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    handleButtonClick(row, action) {
      this.selectedRow = row
      setTimeout(() => {
        switch (action) {
          case 'view':
            this.$bvModal.show('view-payment-order-modal')
        }
      }, 100)
    },
  },
  async mounted() {
    await this.getUserPayments(this.user.id)
    if (this.coursePackages.length === 0) {
      await this.getCoursePackages()
    }
    if (this.membershipPackages.length === 0) {
      await this.getMembershipPackages()
    }
    if (this.companySettings === null) {
      await this.getCompanySettings()
    }
  }
}
</script>