import Axios from 'axios'

import {
    clearToken,
    getToken
} from '@/base/utils'
import { betaLogoutUrl } from "@/base/utils";

const responseSuccess = config => {
    return config
}

const ERROR_UNAUTHORIZED = 401

const responseError = error => {
    if (Axios.isCancel(error)) {
        return Promise.reject(error)
    }
    const { response: { status } } = error
    switch (status) {
        case ERROR_UNAUTHORIZED:
            clearToken()

            if (window.location.pathname !== 'login') {
                window.location = betaLogoutUrl()
            }
            break
        default:
            break
    }
    return Promise.reject(error)
}

export const axios = {
    init: async () => {
        Axios.defaults.baseURL = 'https://api.wufitness.ca'
        const authToken = getToken()
        if (authToken) {
            axios.addHeader('Authorization', `Bearer ${authToken}`)
        }

        axios.addErrorHandler()
    },
    addErrorHandler: () => Axios.interceptors.response.use(responseSuccess, responseError),
    addHeader: (key, val) => (Axios.defaults.headers.common[key] = val),
    removeHeader: key => delete Axios.defaults.headers.common[key]
}

export default axios