<template>
  <div>
    <div class="chartjs-custom mb-3 mb-sm-5" style="height: 14rem;">
      <Doughnut
          :options="chartOptions"
          :data="chartData"
          :chart-id="chartId"
          :width="width"
      />
    </div>
    <div class="row justify-content-center">
      <div class="col-auto mb-3 mb-sm-0">
        <span class="legend-indicator bg-primary"></span>
        <span class="card-title h4">{{ chartData.datasets[0].data[0] }}</span>
        {{ $t('profile.clientType.pro') }}
      </div>
      <div class="col-auto mb-3 mb-sm-0">
        <span class="legend-indicator bg-warning"></span>
        <span class="card-title h4">{{ chartData.datasets[0].data[1] }}</span>
        {{ $t('profile.clientType.yearly') }}
      </div>
      <div class="col-auto mb-3 mb-sm-0">
        <span class="legend-indicator bg-info"></span>
        <span class="card-title h4">{{ chartData.datasets[0].data[2] }}</span>
        {{ $t('profile.clientType.seasonal') }}
      </div>
      <div class="col-auto mb-3 mb-sm-0">
        <span class="legend-indicator" style="background-color: #e7eaf3;"></span>
        <span class="card-title h4">{{ chartData.datasets[0].data[3] }}</span>
        {{ $t('profile.clientType.monthly') }}
      </div>
      <div class="col-auto mb-3 mb-sm-0">
        <span class="legend-indicator bg-danger"></span>
        <span class="card-title h4">{{ chartData.datasets[0].data[4] }}</span>
        {{ $t('profile.clientType.weekly') }}
      </div>
      <div class="col-auto mb-3 mb-sm-0">
        <span class="legend-indicator bg-success"></span>
        <span class="card-title h4">{{ chartData.datasets[0].data[5] }}</span>
        {{ $t('profile.clientType.dayPass') }}
      </div>
    </div>
  </div>
</template>
<script>
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, ChartDataLabels)
export default {
  name: 'doughnut-chart',
  components: {
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: ['Pro', 'Yearly', 'Seasonal', 'Monthly', 'Weekly', 'dayPass'],
          datasets: [
            {
              backgroundColor: [
                '#377dff',
                '#ffc107',
                '#00c9db',
                '#e7eaf3',
                '#DD1B16',
                '#00ab8e'
              ],
              data: [40, 20, 60, 10, 20, 5],
              borderWidth: 5,
              borderColor: "#2c3e50",
            },
          ]
        }
      },
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        plugins: {
          legend: {
            display: false
          },
          // tooltip: {
          //   postfix: 'k',
          //   hasIndicator: true,
          //   mode: "index",
          //   intersect: false
          // },
        }
      }
    }
  }
}
</script>