<template>
  <b-modal
      id="delete-package-modal"
      size="lg"
      @ok="submit"
  >
    <template #modal-title>
      <span class="text-danger">Delete the Package ?</span>
    </template>
    <h5 class="text-warning">You will lose this Package Data</h5>
    <div class="card">
      <div class="table-responsive">
        <b-table
            borderless
            select-mode="single"
            class="table-align-middle table-nowrap card-table"
            :fields="fields"
            :items="packageData"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
          <template #cell(name)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getName(data.value) }}</span>
          </template>
          <template #cell(description)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDescription(data.value) }}</span>
          </template>
          <template #cell(duration)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDuration(data.value) }}</span>
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { getLocale } from '@/base/utils'
import { mapState } from 'vuex'
export default {
  name: 'delete-package-modal',
  props: {
    packageType: {
      type: String,
      required: true,
      default: ''
    },
    packageData: {
      type: Array,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      locale: getLocale(),
      currentPage: 1,
      perPage: 10,
      sortBy: 'id',
      sortDesc: false
    }
  },
  computed: {
    ...mapState('product', ['coursesFields', "membershipFields"]),
    fields () {
      return this.packageType === 'coursePackage' ? this.coursesFields : this.membershipFields
    }
  },
  methods: {
    getName (name) {
      return this.$te(`profile.clientType.${name[this.locale]}`) ?
          this.$t(`profile.clientType.${name[this.locale]}`) : name[this.locale]
    },
    getDescription (description) {
      return description[this.locale]
    },
    getDuration (duration) {
      return duration.duration + ' ' + this.$t(`general.dateSymbol.${duration.duration_symbol}`)
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>