<template>
  <div class="card card-body mb-3 mb-lg-5">
    <div v-if="profile.status === 'pending'">
      <h5>{{ $t('profile.activeDaysCard.pending') }}</h5>
      <h2>{{ pendingDays }}<span class="h5"> days</span></h2>
    </div>
    <h5 v-else-if="profile.userType === 'client'">{{ $t('profile.activeDaysCard.client') }}</h5>
    <h5 v-else>{{ $t('profile.activeDaysCard.employee') }}</h5>
    <div v-if="profile.status !== 'pending'" class="d-flex justify-content-between align-items-center">
      <b-progress :value="activeDays" :max="maxDays" class="flex-grow-1"></b-progress>
      <span class="ms-4">{{ activeDays }} days</span>
    </div>
    <div v-if="profile.status !== 'pending'" class="d-block fs-6">{{ maxDays - activeDays }} days left</div>
    <!-- Course Status -->
    <h5 v-if="profile.userType === 'client' && profile.clientType === 'pro'">{{ $t('profile.activeDaysCard.pro') }}</h5>
    <div v-if="profile.userType === 'client' && profile.clientType === 'pro'" class="d-flex justify-content-between align-items-center">
      <b-progress :value="profile.completed_courses" :max="profile.completed_courses + profile.upcomingCourse" class="flex-grow-1"></b-progress>
      <span class="ms-4">{{ profile.completed_courses }} courses</span>
    </div>
    <div v-if="profile.userType === 'client' && profile.clientType === 'pro'">
      {{ profile.upcoming_courses }} course(s) left
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment'
export default {
  name: 'active-days-card',
  props: {
    profile: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    activeDays () {
      let startOfDate = moment(this.profile.startOfDate)
      let now = moment()
      return now.diff(startOfDate, 'days')
    },
    maxDays () {
      let startOfDate = moment(this.profile.startOfDate)
      let endOfDate = moment(this.profile.endOfDate)
      return endOfDate.diff(startOfDate, 'days')
    },
    pendingDays () {
      let now = moment()
      let startOfDate = moment(this.profile.startOfDate)
      return startOfDate.diff(now, 'days')
    }
  }
}
</script>