<template>
  <div>
    <b-dropdown
        no-caret
        right
        variant="link"
        toggle-class="text-decoration-none btn-ghost-secondary btn-icon rounded-circle"
        menu-class="navbar-dropdown-menu-borderless"
    >
      <template #button-content>
        <b-icon icon="bell"></b-icon>
      </template>
      <b-dropdown-item>First Action</b-dropdown-item>
      <b-dropdown-item>Second Action</b-dropdown-item>
      <b-dropdown-item>Third Action</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item active>Active action</b-dropdown-item>
      <b-dropdown-item disabled>Disabled action</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  name: 'notification-icon'
}
</script>