<template>
  <div v-if="visible" class="position-fixed translate-middle-x start-50 top-0 w-100 zi-99">
    <div class="alert" :class="`alert-`+ type" role="alert">
      {{ message }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'page-alert',
  computed: {
    ...mapState('alert', ['visible', 'type', 'message'])
  }
}
</script>