<template>
  <BubbleChart
      class="js-chart-datalabels"
      :options="chartOptions"
      :data="chartData"
  />
</template>
<script>
import { Bubble as BubbleChart } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as BubbleChartJS,
  Tooltip,
  Legend,
  PointElement,
  LinearScale
} from 'chart.js'

BubbleChartJS.register(LinearScale, PointElement, Tooltip, Legend, ChartDataLabels)

export default {
  name: 'bubble-chart',
  components: { BubbleChart },
  props: {
    chartData: {
      type: Object || Array,
      required: true,
      default: null
    },
    chartOptions: {
      type: Object || Array,
      required: false,
      default ()  {
        return {
          scales: {
            y: {
              display: false
            },
            x: {
              display: false
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: false,
            datalabels: {
              color: '#ffffff',
              font: function(context) {
                let value = context.dataset.data[context.dataIndex]
                let fontSize = 25;
                if (value.r > 50) {
                  fontSize = 45;
                }
                if (value.r > 70) {
                  fontSize = 55;
                }
                return {
                  weight: 'lighter',
                  size: fontSize
                };
              },
              formatter: function (value) {
                return value.v
              },
            },
          }
        }
      }
    }
  }
}
</script>