import axios from 'axios'

import { NetworkEntity } from '@/base/network'

import {
    CREATE_EMPLOYEE,
    LIST_MANAGERS,
    LIST_EMPLOYEE,
    LIST_SALES,
    GET_EMPLOYEES_COUNT_SUMMARY,
    DELETE_EMPLOYEE,
    GET_EMPLOYEE_PROFILE
} from '../types'

const options = {
    endpoint: 'api',
    moduleName: 'EMPLOYEE'
}

export const EmployeeEntity = new NetworkEntity(options)

EmployeeEntity.createEmployee = function (commit, data) {
    commit(CREATE_EMPLOYEE.ACTION)
    return axios
        .post(`${this.endpoint}/employees/create`, data)
        .then(({data}) => {
            commit(CREATE_EMPLOYEE.SUCCESS, data)
            return data
        })
        .catch(err => {
            commit(CREATE_EMPLOYEE.FAILURE, err)
        })
}

EmployeeEntity.getEmployees = function (commit) {
    commit(LIST_EMPLOYEE.ACTION)
    return axios
        .get(`${this.endpoint}/employees`)
        .then(({ data: {data} }) => {
            commit(LIST_EMPLOYEE.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_EMPLOYEE.FAILURE, err)
        })
}

EmployeeEntity.listManagers = function (commit) {
    commit(LIST_MANAGERS.ACTION)
    return axios
        .get(`${this.endpoint}/employees/list-managers`)
        .then(({ data: {data} }) => {
            commit(LIST_MANAGERS.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_MANAGERS.FAILURE, err)
        })
}

EmployeeEntity.listSales = function (commit) {
    commit(LIST_SALES.ACTION)
    return axios
        .get(`${this.endpoint}/employees/list-sales`)
        .then(({ data: {data} }) => {
            commit(LIST_SALES.SUCCESS, data)
        })
        .catch(err => {
            commit(LIST_SALES.FAILURE, err)
        })
}

EmployeeEntity.getEmployeesCountSummary = function (commit) {
    commit(GET_EMPLOYEES_COUNT_SUMMARY.ACTION)
    return axios
        .get(`${this.endpoint}/employees/employees-count-summary`)
        .then(({ data: {data} }) => {
            commit(GET_EMPLOYEES_COUNT_SUMMARY.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_EMPLOYEES_COUNT_SUMMARY.FAILURE, err)
        })
}

EmployeeEntity.deleteEmployee = function (commit, id) {
    commit(DELETE_EMPLOYEE.ACTION)
    return axios
        .delete(`${this.endpoint}/employees/delete/${id}`)
        .then(({data}) => {
          commit(DELETE_EMPLOYEE.SUCCESS, data)
        })
        .catch(err => {
            commit(DELETE_EMPLOYEE.FAILURE, err)
        })
}

EmployeeEntity.getEmployeeProfile = function (commit, id) {
    commit(GET_EMPLOYEE_PROFILE.ACTION)
    return axios
        .get(`${this.endpoint}/employees/${id}/profile`)
        .then(({data: {data} }) => {
            commit(GET_EMPLOYEE_PROFILE.SUCCESS, data)
        })
        .catch(err => {
            commit(GET_EMPLOYEE_PROFILE.FAILURE, err)
        })
}