<template>
  <div
      class="card mb-3 mb-lg-5"
      :class="!fetching && stepItems.length === 0 ? 'card-centered' : ''"
  >
    <div class="card-header card-header-content-between">
      <h4 class="card-header-title">Activity stream</h4>
    </div>
    <b-spinner v-if="fetching" type="grow"></b-spinner>
    <div v-else-if="stepItems.length !== 0 && !fetching"  class="card-body card-body-height" style="height: 30rem;">
      <ul class="step step-icon-xs mb-0">
        <li v-for="item in stepItems" :key="`activity-`+item.id" class="step-item">
          <div class="step-content-wrapper">
            <span class="step-icon step-icon-pseudo step-icon-soft-dark"></span>
            <div class="step-content">
              <h5 class="step-title">
                <a class="text-dark" href="#">{{ item.event }}</a>
              </h5>
              <p v-if="item.event === 'STATUS_UPDATED'" class="fs-5 mb-1">
                Your status updated to
                <span v-if="item.detail === 'incomplete'" class="badge bg-soft-secondary text-secondary rounded-pill"><span class="legend-indicator bg-secondary"></span>Incomplete</span>
                <span v-if="item.detail === 'active'" class="badge bg-soft-success text-success rounded-pill"><span class="legend-indicator bg-success"></span>Active</span>
                <span v-if="item.detail === 'pending'" class="badge bg-soft-warning text-warning rounded-pill"><span class="legend-indicator bg-warning"></span>Pending</span>
                <span v-if="item.detail === 'suspended'" class="badge bg-soft-danger text-dark rounded-pill"><span class="legend-indicator bg-danger"></span>Suspended</span>
                <span v-if="item.detail === 'dismissed'" class="badge bg-soft-danger text-dark rounded-pill"><span class="legend-indicator bg-danger"></span>Suspended</span>
              </p>
              <p v-else>{{ item.detail }}</p>
              <span class="text-muted small text-uppercase">{{ getDate(item.created_at) }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="card-body card-body-height">
      <img class="avatar avatar-xxl mb-3" src="@/assets/svg/empty/oc-error.svg" alt="empty" >
      <p class="card-text">No data to show</p>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'activity-stream',
  props: {
    fetching: {
      type: Boolean,
      require: true,
      default: false
    },
    stepItems: {
      type: Array,
      require: true,
      default: null
    }
  },
  methods: {
    getDate(date){
      return moment(date).format('ll')
    }
  }
}
</script>