import * as TYPES from './types'
import { UserEntity } from './api'
import { HOME_ROUTE, localStorage } from '@/base/utils'
import router from "@/router";


export const getMe = async ({ commit }, params) => {
    await UserEntity.me(commit, params)
}

export const useAppAs = async ({ commit, state }) => {
    commit(TYPES.USE_APP_AS)
    // home_route
    const previousPath = localStorage.getItem('previous_path')
    localStorage.removeItem('previous_path')
    const route = previousPath !== null
        ? previousPath
        : HOME_ROUTE(state.data.roles)
    await router.push(route)
}

export const clear = ({ commit }) => {
    commit(TYPES.CLEAR_USER)
}

export const listActivities = async ({commit}, id) => {
    await UserEntity.listActivities(commit, id)
}

export const listUserBodyIndexRecords = async ({commit}, id) => {
    await UserEntity.listUserBodyIndexRecords(commit, id)
}

export const createUserReason = async ({commit}, {id, data}) => {
    await UserEntity.createUserReason(commit, id, data)
}

export const modifyUserReason = async ({commit}, {id, data}) => {
    await UserEntity.modifyUserReason(commit, id, data)
}

export const activateUser = async ({commit}, {id, data}) => {
    await UserEntity.activateUser(commit, id, data)
}

export const withdrawPending = async ({commit}, {id}) => {
    await UserEntity.withdrawPending(commit, id)
}

export default {
    getMe,
    useAppAs,
    listActivities,
    listUserBodyIndexRecords,
    createUserReason,
    modifyUserReason,
    activateUser,
    withdrawPending
}