<template>
  <div class="card mb-3 mb-lg-5">
    <b-alert :show="showAlert">{{ alertMessage }}</b-alert>
    <div class="card-header">
      <div class="row justify-content-between align-items-center flex-grow-1">
        <div class="col-md">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-header-title">Trainer Courses Summary
              <b-button
                  v-b-tooltip.hover
                  variant="link"
                  class="p-0"
                  title="Only Calculate Completed Courses"
              >
               <i class="bi-patch-check-fill text-primary"></i>
              </b-button>
            </h4>
          </div>
        </div>
        <div class="col-auto">
          <div class="row align-items-sm-center">
            <div class="col-sm-auto">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <span class="text-secondary me-2">Trainer:</span>
                </div>
                <div class="col-auto">
                  <b-form-select
                      v-model="trainer"
                      :options="trainerOptions"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-auto pr-0">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <span class="text-secondary me-2">Date Range:</span>
                </div>
                <div class="col-auto">
                  <b-form-datepicker
                      id="start-of-date"
                      v-model="startOfDate"
                      class="mb-2"
                      @input="handleSearchTrainerCoursesSummary"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="row align-items-center gx-0">
                <div class="col">
                  <span class="text-secondary me-2">-</span>
                </div>
                <div class="col-auto">
                  <b-form-datepicker
                      id="end-of-date"
                      v-model="endOfDate"
                      class="mb-2"
                      @input="handleSearchTrainerCoursesSummary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
          v-if="!fetchingTrainerCoursesSummary && !fetching"
          show-empty
          small
          id="trainer-course-summary-table"
          :fields="columns"
          :items="trainerCoursesSummary"
          head-variant="dark"
          sort-icon-left
          borderless
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          class="table-align-middle"
      >
        <template #cell(started_at)="data">
          <div>{{ getStartDateList(data.value) }}</div>
        </template>
      </b-table>
      <b-spinner
          v-else
          variant="primary"
          class="align-middle"
      ></b-spinner>
    </div>
    <div class="card-footer">
      <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
          <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
            <p>Current Page: {{ currentPage }}</p>
          </div>
        </div>
        <div class="col-sm-auto">
          <div class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="trainer-course-summary-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'trainer-courses-summary-table',
  data () {
    return {
      startOfDate: null,
      endOfDate: null,
      columns: [
        { key: 'id', label: 'CLIENT ID', sortable: true },
        { key: 'client', label: 'Client Name', sortable: true },
        { key: 'started_at', label: 'Course History', sortable: false },
        { key: 'paid_courses', label: 'Paid', sortable: true },
        { key: 'free_courses', label: 'Free', sortable: true },
        { key: 'total_courses', label: 'Total', sortable: true }
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      filter: null,
      trainer: null,
      showAlert: true,
      alertMessage: 'Please select Trainer and Date range to see the results.'
    }
  },
  computed: {
    ...mapState('trainer', ['fetching', 'trainers']),
    ...mapState('course', ['fetchingTrainerCoursesSummary', 'trainerCoursesSummary']),
    trainerOptions () {
      return this.trainers.map(trainer => { return { value: trainer.id, text: trainer.full_name } } )
    },
    rows () {
      return this.trainerCoursesSummary.length
    },
  },
  methods: {
    ...mapActions('course', ['listTrainerCoursesSummary']),
    getStartDateList (data) {
      data.sort((a, b) => moment(a).diff(moment(b)))
      return data.map(date => { return moment(date).format('MMM DD') }).join(', ')
    },
    handleSearchTrainerCoursesSummary () {
      if (this.trainer !== null && moment(this.startOfDate).isBefore(moment(this.endOfDate))) {
        this.showAlert = false
        let params = {
          startOfDate: this.startOfDate,
          endOfDate: this.endOfDate,
          trainer_id: this.trainer
        }
        this.listTrainerCoursesSummary(params)

      } else if (moment(this.startOfDate).isAfter(moment(this.endOfDate))) {
        this.showAlert = true
        this.alertMessage = 'The Start date should be before the end Date.'
      } else {
        moment(this.startOfDate).isBefore(moment(this.endOfDate))
        this.alertMessage = 'Please select Trainer and Date range to see the results.'
      }
    }
  }

}
</script>
<style lang="css" scoped>
.custom-select {
  background-color: #25282a;
  color: #ffffff;
}
.b-form-datepicker {
  background-color: #25282a;
}
.form-control.focus {
  background-color: #25282a !important;
}
</style>