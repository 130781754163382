<template>
  <div class="d-flex align-items-center">
    <label class="avatar avatar-xl avatar-circle avatar-uploader me-5" for="avatarUploader">
      <img id="avatarImg" class="avatar-img" :src="url" alt="Image Description">
      <input
          id="avatarUploader"
          class="js-file-attach avatar-uploader-input"
          type="file"
          @change="onFileChange"
      >
      <span class="avatar-uploader-trigger">
        <i class="bi-pencil avatar-uploader-icon shadow-sm"></i>
      </span>
    </label>
    <b-button
        variant="outline-primary"
        @click="deleteImg"
    >Delete</b-button>
  </div>
</template>
<script>
export default {
  name: 'avatar-upload',
  data () {
    return {
      url: require('../assets/img/160x160/img1.jpg')
    }
  },
  methods: {
    onFileChange (e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    deleteImg () {
      this.url = require('../assets/img/160x160/img1.jpg')
    }
  }
}
</script>