<template>
  <div
      v-if="visible"
      class="position-fixed bottom-0 end-0 me-5 mb-5"
  >
    <div class="card card-sm bg-dark border-dark mx-2">
      <div class="card-body">
        <h5>Your session is about to expire in</h5>
        <p class="fs-5 mb-1">{{ timeLeftHumanize }} minutes</p>
        <div class="row gx-3">
          <div class="col">
            <div class="d-grid">
              <b-button
                  v-if="!loading"
                  variant="primary"
                  @click="keepLoggedIn"
              >
                Continue
              </b-button>
              <b-spinner v-if="loading" small type="grow"></b-spinner>
            </div>
          </div>
          <div class="col">
            <div class="d-grid">
              <b-button
                  v-if="!loading"
                  variant="out-secondary"
                  class="btn-white"
                  @click="handleLogout()"
              >Log out</b-button>
              <b-spinner v-if="loading" small type="grow"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { getExpiryDate } from '@/base/utils'
import { AUTH_PUBLIC_ROUTE } from '@/components/auth/router'

export default {
  name: 'session-handler',
  data () {
    return {
      now: null,
      loading: false,
      minutesBeforeExpire: 10,
      visible: false
    }
  },
  computed: {
    minutesLeft () {
      return moment.duration(this.expiredDate().diff(this.now)).asMinutes()
    },
    isIdle () {
      return this.$store.state.idleVue.isIdle
    },
    timeLeftHumanize () {
      return moment(this.expiredDate().diff(this.now)).format('mm:ss')
    },
    tokenIsExpiring () {
      return this.minutesLeft < this.minutesBeforeExpire &&
          this.minutesLeft > 0 &&
          this.isIdle

    }
  },
  methods: {
    ...mapActions('auth', ['logout', 'refreshToken']),
    expiredDate () {
      return moment(getExpiryDate())
    },
    isVisible (state) {
      this.visible = state
      if (state) {
        this.loading = false
      }
    },
    async keepLoggedIn () {
      this.loading = true
      await this.refreshToken()
      this.isVisible(false)
      this.loading = false
    },
    handleLogout () {
      this.logout('User log out')
    }
  },
  mounted() {
    setInterval(() => {
      this.now = moment()
    }, 1000)
  },
  watch: {
    minutesLeft: function (value) {
      if (value > this.minutesBeforeExpire) {
        this.isVisible(false)
      }
      if (!AUTH_PUBLIC_ROUTE.includes(this.$route.name)
          && !this.isIdle && value < this.minutesBeforeExpire && this.minutesLeft > 0 && !this.loading && !this.visible
      )
      {
        this.keepLoggedIn()
      }
      else if (value <= 0) {
        this.logout('Token Expired')
      }
    },
    tokenIsExpiring: function (value) {
      if (value) {
        this.isVisible(true)
      }
    }
  }
}
</script>