<template>
  <b-modal
      id="course-quota-exhausted-modal"
      title="Course Quota Exhausted"
      ok-only
  >
    <div>
      <img class="w-50 float-end" src="@/assets/svg/empty/oc-empty-cart.svg" alt="Course Quota Exhausted"/>
      <p class="card-text text-warning">Ops! The Client cannot add course.</p>
      <p>We noticed that the client has out of his/her available course quota.
        To continue adding courses for this client, you'll need to purchase more courses.
        Please go to payment tab to add new purchase for this client.
      </p>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'course-quota-exhausted-modal',
  props: {
    clientName: {
      type: String,
      required: false,
      default: 'client name'
    }
  }
}
</script>