<template>
  <header class="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white">
    <div class="navbar-nav-wrap">
      <a class="navbar-brand" href="#" aria-label="WuFitness">
        <img class="navbar-brand-logo-short" src="@/assets/svg/logos/wlogo2.svg" alt="logo">
      </a>
      <global-search-input />
      <div class="navbar-nav-wrap-content-end">
        <ul class="navbar-nav">
          <li class="nav-item d-none d-sm-inline-block"><notification-icon /></li>
          <li class="nav-item d-sm-inline-block"> <profile-icon /></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import GlobalSearchInput from '@/components/header/GlobalSearchInput'
import NotificationIcon from '@/components/header/Notification'
import ProfileIcon from '@/components/header/Profile'
export default {
  name: 'HeaderView',
  components: {
    ProfileIcon,
    NotificationIcon,
    GlobalSearchInput
  },
}
</script>