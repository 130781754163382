import { EmployeeEntity } from './api'

export const createEmployee = async ({commit}, data) => {
    await EmployeeEntity.createEmployee(commit, data)
}

export const listManagers = async ({commit}, data) => {
    await EmployeeEntity.listManagers(commit, data)
}

export const listSales = async ({commit}) => {
    await EmployeeEntity.listSales(commit)
}

export const getEmployees = async ({commit}) => {
    await EmployeeEntity.getEmployees(commit)
}

export const getEmployeesCountSummary = async ({commit}) => {
    await EmployeeEntity.getEmployeesCountSummary(commit)
}

export const deleteEmployee = async ({commit}, id) => {
    await EmployeeEntity.deleteEmployee(commit, id)
}

export const getEmployeeProfile = async ({commit}, id) => {
    await EmployeeEntity.getEmployeeProfile(commit, id)
}

export default {
    createEmployee,
    listManagers,
    listSales,
    getEmployees,
    getEmployeesCountSummary,
    deleteEmployee,
    getEmployeeProfile
}