import { ClientEntity } from './api'

export const getClients = async ({commit}, params) => {
    await ClientEntity.getClients(commit, params)
}

export const getClientsCountSummary = async ({commit}) => {
    await ClientEntity.getClientsCountSummary(commit)
}

export const getClientTypesSummary = async ({commit}) => {
    await ClientEntity.getClientTypesSummary(commit)
}

export const getClientProfile = async ({commit}, id) => {
    await ClientEntity.getClientProfile(commit, id)
}

export const deleteClient = async ({commit}, id) => {
    await ClientEntity.deleteClient(commit, id)
}

export const addClient = async ({commit}, data) => {
    await ClientEntity.addClient(commit, data)
}

export const listRecipes = async ({commit}, id) => {
    await ClientEntity.listRecipes(commit, id)
}

export const createRecipe = async ({commit}, { id, data }) => {
    await ClientEntity.createRecipe(commit, id, data)
}

export const updateRecipe = async ({commit}, { id, recipeId, data }) => {
    await ClientEntity.updateRecipe(commit, id, recipeId, data)
}

export const deleteRecipe = async ({commit}, { userId, recipeId }) => {
    await ClientEntity.deleteRecipe(commit, userId, recipeId)
}

export const updateTrainer = async ({commit}, data) => {
    await ClientEntity.updateTrainer(commit, data)
}

export default {
    getClients,
    getClientsCountSummary,
    getClientTypesSummary,
    getClientProfile,
    deleteClient,
    addClient,
    listRecipes,
    createRecipe,
    updateRecipe,
    deleteRecipe,
    updateTrainer
}