import { axios } from "@/base/network";
import store from '@/store'
import moment from 'moment'
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

import { changeLocale } from '@/base/utils/changeLocale'

import { decodeToken, getStore, setToken } from '@/base/utils/token'

import {
    ADMIN,
    canSessionViewApp,
    CLIENT,
    EMPLOYEE,
    MANAGER,
    TRAINER
} from "@/base/utils/roles";

export async function setUpAuthenticatedUserSession (data) {
    axios.addHeader('Authorization', `Bearer ${data.token}`)

    const session = decodeToken(data.token)

    if(!canSessionViewApp(session)) {
        throw new Error('session does not have permission to login')
    }

    axios.addHeader('Authorization', `Bearer ${data.token}`)
    await store.dispatch('user/getMe', {}, { root: true })

    setToken(localStorage, data.token)
    setExpiryDate(data.expires_in)
    setUserUseAppAsValue()

    changeLocale(store.state.user.data.locale)
}

export function setExpiryDate (expiry, remember = true) {
    let date = moment().add(expiry, 's')
    if (remember) {
        localStorage.setItem('expiry_date', date.format())
    } else {
        sessionStorage.setItem('expiry_date', date.format())
    }
}

export function updateSessionTokenInfo (token, expiry) {
    axios.addHeader('Authorization', `Bearer ${token}`)
    let storage = getStore()

    setToken(storage, token)
    storage.setItem('expiry_date', moment().add(expiry, 's').format())
}

export function setUserUseAppAsValue () {
    let roles = store.state.user.data.roles
    let userRole = roles.includes(ADMIN)
    ? ADMIN
        : (roles.includes(MANAGER))
            ? MANAGER
            : (roles.includes(TRAINER))
                ? TRAINER
                : (roles.includes(EMPLOYEE))
                    ? EMPLOYEE
                    : CLIENT
    store.dispatch('user/useAppAs', { role: userRole }, { root: true })
}