<template>
  <div class="card h-100">
    <div class="card-body pb-0">
      <div class="row align-items-center mb-2">
        <div class="col-9">
          <h4 class="mb-1">{{ department.name }}</h4>
        </div>
        <div class="col-3 text-end">
          <b-dropdown right no-caret variant="light" toggle-class="btn-ghost-secondary btn-icon btn-sm rounded-circle">
            <template #button-content>
              <i class="bi-three-dots-vertical"></i>
            </template>
            <b-dropdown-item href="#">Edit</b-dropdown-item>
            <b-dropdown-item href="#">Another action</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item variant="danger" href="#">Delete</b-dropdown-item>
          </b-dropdown>
        </div>
        <p> {{ department.description }} </p>
      </div>
    </div>
    <div class="card-footer border-0 pt-0">
      <div class="list-group-item">
        <div class="row align-items-center">
          <div class="col">
            <span class="card-subtitle">Members:</span>
          </div>
          <div class="col-auto">
            <div class="avatar-group avatar-group-xs avatar-circle">
            <span class="avatar avatar-soft-dark" data-toggle="tooltip" data-placement="top" title="Antony Taylor">
                                  <span class="avatar-initials">A</span>
                                </span>
              <span class="avatar avatar-soft-info" data-toggle="tooltip" data-placement="top" title="Sara Iwens">
                                  <span class="avatar-initials">S</span>
                                </span>
              <span class="avatar" data-toggle="tooltip" data-placement="top" title="Finch Hoot">
                                  <img class="avatar-img" src="../assets/img/160x160/img1.jpg" alt="Image Description">
                                </span>
              <span class="avatar avatar-light avatar-circle" data-toggle="tooltip" data-placement="top" title="Sam Kart, Amanda Harvey and 1 more">
                                  <span class="avatar-initials">+3</span>
                                </span>
            </div>
          </div>
          <!-- End Col -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'department-card',
  props: {
    department: {
      type: Object,
      require: true,
      default: () =>{
        return {
          id: 1,
          name: 'IT',
          description: 'Our group promotes and sells products and services by leveraging online marketing tactics'
        }
      }
    }
  }
}
</script>