<template>
  <div
      v-if="$route.name !== 'sign-in'"
      id="app"
      class="has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset body"
      :class="isExpanded"
  >
    <page-alert />
    <header-view />
    <aside-view />
    <main id="content" role="main" class="main">
      <div class="content container-fluid">
        <router-view/>
      </div>
      <footer-view />
    </main>
    <session-handler />
  </div>
  <div v-else id="app">
    <main id="content" role="main" class="main">
      <page-alert />
      <div class="content container-fluid">
        <router-view/>
      </div>
      <footer-view />
    </main>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import {
  HeaderView,
  AsideView,
  FooterView
} from '@/views'
import { PageAlert } from '@/layout'
import { SessionHandler } from '@/components/auth/components'

export default {
  components: {
    FooterView,
    AsideView,
    HeaderView,
    PageAlert,
    SessionHandler
  },
  computed: {
    ...mapState('alert', ['sm', 'navbarExpanded', 'navbarExpandedMobile']),
    isExpanded () {
      if (this.sm) {
        return !this.navbarExpandedMobile ? 'navbar-vertical-aside-closed-mode navbar-vertical-aside-mini-mode': null
      }
      return !this.navbarExpanded ? 'navbar-vertical-aside-mini-mode': null
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions('alert', ['setScreenSize']),
    handleResize() {
      let width = window.innerWidth
      this.setScreenSize(width)
    }
  }
}

</script>
<style>

#app {
  font-family: Inter,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}
thead {
  background-color:#2f3235
}
/*nav {*/
/*  padding: 30px;*/
/*}*/

/*nav a {*/
/*  font-weight: bold;*/
  /*color: #2c3e50;*/
/*}*/

/*nav a.router-link-exact-active {*/
/*  color: #42b983;*/
/*}*/
</style>
