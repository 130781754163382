<template>
  <div class="col-lg-11">
    <div class="profile-cover">
      <div class="profile-cover-img-wrapper">
        <img class="profile-cover-img" src="@/assets/img/1920x400/img1.jpg" alt="Image Description">
      </div>
    </div>
    <div class="text-center mb-5">
      <!-- Avatar -->
      <div class="avatar avatar-xxl avatar-circle profile-cover-avatar">
        <img class="avatar-img" src="@/assets/img/160x160/img1.jpg" alt="Image Description">
        <span class="avatar-status" :class="avatarStatus"></span>
      </div>
      <!-- End Avatar -->

      <h1 class="page-header-title">{{ firstName }} {{ lastName }} <i class="bi-patch-check-fill fs-2 text-primary" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Top endorsed" data-bs-original-title="Top endorsed"></i></h1>

      <!-- List -->
      <ul class="list-inline list-px-2">
        <li
            v-if="clientType"
            class="list-inline-item"
        >
          <i class="bi-building me-1"></i>
          <span> {{ $t('profile.clientType.' + clientType) }}</span>
        </li>
        <li
            v-if="jobTitle"
            class="list-inline-item"
        >
          <i class="bi-building me-1"></i>
          <span>{{ jobTitle }}</span>
        </li>

        <li class="list-inline-item">
          <i class="bi-geo-alt me-1"></i>
          <a href="#">{{ address }}</a>
        </li>

        <li class="list-inline-item">
          <i class="bi-calendar-week me-1"></i>
          <span>Joined {{ getStartOfDate }}</span>
        </li>
      </ul>
      <!-- End List -->
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'profile-header',
  props: {
    firstName: {
      type: String,
      required: true,
      default: 'firstName'
    },
    lastName: {
      type: String,
      required: true,
      default: 'lastName'
    },
    userStatus: {
      type: String,
      required: true,
      default: 'active'
    },
    jobTitle: {
      type: String,
      required: false,
      default: null
    },
    clientType: {
      type: String,
      required: false,
      default: null
    },
    address: {
      type: String,
      required: false,
      default: null
    },
    startOfDate: {
      type: String,
      required: false,
      default: '2022-10-12'
    }
  },
  computed: {
    avatarStatus () {
      switch (this.userStatus) {
        case 'active':
          return 'avatar-status-success'
        case 'pending':
          return 'avatar-status-warning'
        case 'suspended':
          return 'avatar-status-info'
        case 'dismissed':
          return 'avatar-status-danger'
        default:
          return 'avatar-status-success'
      }
    },
    getStartOfDate () {
      return moment(this.startOfDate).format('MMM,YYYY')
    }
  }
}
</script>