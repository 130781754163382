<template>
  <div class="card mb-3 mb-lg-5">
    <div class="card-header card-header-content-sm-between">
      <h4 class="card-header-title mb-2 mb-sm-0">
        Sales
      </h4>
      <div class="d-grid d-sm-flex gap-2">
        <!-- In/Online Store -->
        <div class="tom-select-custom">
          <b-form-select v-model="storeSelected" class="ts-dropdown" :options="storeOptions" />
        </div>
        <!-- Date Range Select -->
        <div class="tom-select-custom">
          <b-form-select v-model="salesDateSelected" class="ts-dropdown">
            <b-form-select-option
                v-for="option in salesDateOptions"
                class="option"
                :key="`sales-`+option.value"
                :value="option.value"
            >
              {{ option.text }}
            </b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row col-lg-divider">
        <div class="col-lg-9 mb-5 mb-lg-0">
          <div class="chartjs-custom mb-4">
            <bar-chart
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
          </div>
          <div class="row justify-content-center">
            <div class="col-auto">
              <span class="legend-indicator"></span> Revenue
            </div>
            <div class="col-auto">
              <span class="legend-indicator bg-primary"></span> Orders
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="col-sm-6 col-lg-12">
            <!-- Stats -->
            <div class="d-flex justify-content-center flex-column" style="min-height: 9rem;">
              <h6 class="card-subtitle">Revenue</h6>
              <span class="d-block display-4 text-dark mb-1 me-3">$97,458.20</span>
              <span class="d-block text-success">
                      <i class="bi-graph-up me-1"></i> $2,401.02 (3.7%)
                    </span>
            </div>
            <!-- End Stats -->

            <hr class="d-none d-lg-block my-0">
          </div>
          <div class="col-sm-6 col-lg-12">
            <!-- Stats -->
            <div class="d-flex justify-content-center flex-column" style="min-height: 9rem;">
              <h6 class="card-subtitle">Orders</h6>
              <span class="d-block display-4 text-dark mb-1 me-3">67,893</span>
              <span class="d-block text-danger">
                      <i class="bi-graph-down me-1"></i> +3,301 (1.2%)
                    </span>
            </div>
            <!-- End Stats -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BarChart
} from "@/layout";
export default {
  name: 'sales-bar-chart',
  components: {
    BarChart
  },
  data () {
    return {
      storeSelected: 'in-store',
      storeOptions: [
        { value: 'in-store', text: 'In-Store' },
        { value: 'online-store', text: 'Online Store' },
      ],
      salesDateSelected: 'this-month',
      salesDateOptions: [
        { value: 'this-month', text: 'This month' },
        { value: 'last-30-days', text: 'Last 30 Days'},
        { value: 'last-60-days',  text: 'Last 60 Days'}
      ],
      chartData: {
        "labels": ["May 1", "May 2", "May 3", "May 4", "May 5", "May 6", "May 7", "May 8", "May 9", "May 10"],
        datasets: [
          {
            "data": [200, 300, 290, 350, 150, 350, 300, 100, 125, 220],
            backgroundColor: "#377dff",
            hoverBackgroundColor: "#377dff",
            borderColor: "#377dff",
            maxBarThickness: 10
          },
          {
            "data": [200, 300, 290, 350, 150, 350, 300, 100, 125, 220],
            backgroundColor: "#e7eaf3",
            borderColor: "#e7eaf3",
            maxBarThickness: 10
          }
        ]
      },
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            grid: {
              color: "#e7eaf3",
              drawBorder: false,
              zeroLineColor: "#e7eaf3"
            },
            ticks: {
              beginAtZero: true,
              stepSize: 100,
              fontSize: 12,
              fontColor: "#97a4af",
              fontFamily: "Open Sans, sans-serif",
              padding: 10,
              postfix: "$"
            }
          },
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                size: 12,
                family: "Open Sans, sans-serif",
              },
              color: "#97a4af",
              padding: 5
            },
            categoryPercentage: 0.5
          },
        },
        plugins: {
          tooltip: {
            hasIndicator: true,
            mode: "index",
            intersect: false,
            cornerRadius: 6,
            padding: 10,
            usePointStyle: true,
            labelPointStyles: { pointStyle: 'circle', rotation: 2 },
          },
          legend: {
            display: false
          },
        },
        hover: {
          mode: "nearest",
          intersect: true
        }
      }
    }
  }
}
</script>