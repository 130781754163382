<template>
  <div class="card">
    <div class="card-header card-header-content-md-between">
      <h4 class="card-header-title">Reservation History</h4>
    </div>
    <div class="table-responsive">
      <b-table
          v-if="!fetching"
          id="courses-history-table"
          show-empty
          :fields="tableColumns"
          :items="tableItems"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by="`end`"
          sort-desc
          class="table-align-middle table-nowrap card-table"
      >
        <template #cell(status)="data">
          <span :class="getStatusIcon(data.value)"></span>{{ data.value }}
        </template>
        <template #cell(tags)="data">
          {{ getTags(data.value) }}
        </template>
        <template #cell(is_free)="data">
          {{ data.value === 1 ? 'Yes' : data.value === 0 ? 'No' : null }}
        </template>
        <template #cell(title)="data">
          {{ $t('course.courseName.'+ data.value) }}
        </template>
      </b-table>
    </div>
    <div class="card-footer">
      <div class="row justify-content-center justify-content-sm-between align-items-sm-center">
        <div class="col-sm mb-2 mb-sm-0">
          <div class="d-flex justify-content-center justify-content-sm-start align-items-sm-center">
            <p>Current Page: {{ currentPage }}</p>
          </div>
        </div>
        <div class="col-sm-auto">
          <div class="d-flex justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="courses-history-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '@/base/utils'

export default {
  name: 'courses-history-table',
  props: {
    userType: {
      type: String,
      required: true,
      default: 'client'
    },
    userId: {
      type: [Number, String],
      required: true,
      default: '0'
    }
  },
  data () {
    return {
      perPage: 10,
      currentPage: 1,
      sortBy: 'id',
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'title', label: 'Course Name', sortable: true},
        { key: 'start', label: 'Start Time', sortable: true},
        { key: 'end', label: 'End Time', sortable: true},
        { key: 'tags', label: 'Tags', sortable: false},
        { key: 'status', label: 'Status', sortable: true},
        { key: 'clientName', label: 'Client Name', sortable: true},
        { key: 'trainerName', label: 'Trainer Name', sortable: true},
        { key: 'is_free', label: 'Is Free', sortable: true},
        { key: 'createdBy', label: 'CreatedBy', sortable: false},
      ]
    }
  },
  computed: {
    ...mapState('course', ['fetching', 'courseTags', 'clientCourses', 'trainerCourses']),
    locale () {
      return getLocale()
    },
    tableItems () {
      if (this.userType === 'client') {
        return this.clientCourses
      }
      return this.trainerCourses
    },
    rows () {
      if (this.userType === 'client') {
        return this.clientCourses.length
      }
      return this.trainerCourses.length
    },
  },
  methods: {
    ...mapActions('course', ['listCourseTags', 'getClientCourses', 'listTrainerCourses']),
    getStatusIcon (status) {
      switch (status) {
        case 'completed':
          return 'legend-indicator bg-primary'
        case 'scheduled':
          return 'legend-indicator bg-info'
        case 'cancelled':
          return 'legend-indicator bg-warning'
        default:
          return 'legend-indicator bg-warning'
      }
    },
    getTags(tagsIds){
      if (tagsIds === undefined || tagsIds === null)
      {
        return null
      }
      return this.courseTags.filter(tag => tagsIds.includes(tag.id)).map(tag => {
        return tag.name[this.locale]
      }).toString()
    }
  },
  async mounted() {
    if (this.userType === 'client' && this.clientCourses.length === 0) {
      await this.getClientCourses({id: this.userId})
    } else if(this.userType === 'trainer' && this.trainerCourses.length === 0) {
      await this.listTrainerCourses(this.userId)
    }
    if (this.courseTags.length === 0) {
      await this.listCourseTags()
    }
  }
}
</script>