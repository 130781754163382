<template>
  <div class="navbar-vertical-footer">
    <ul class="navbar-vertical-footer-list">
      <li class="navbar-vertical-footer-list-item">
        <b-dropdown
            dropup
            no-caret
            variant="link"
            toggle-class="text-decoration-none btn-ghost-secondary btn-icon rounded-circle"
            menu-class="navbar-dropdown-menu-borderless"
        >
          <template #button-content>
            <flag class="avatar avatar-xss avatar-circle" :iso="currentLanguage.iso" />
          </template>
          <b-dropdown-header>Switch Language</b-dropdown-header>
          <b-dropdown-item
              v-for="language in listLanguages"
              :key="`language-`+language.iso"
              @click="switchLanguage(language)"
          >
            <flag :iso="language.iso" class="avatar avatar-xss avatar-circle me-2" />
            <span class="text-truncate" :title="language.title">{{ language.title }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>
  </div>
</template>
<script>
import { changeLocale, getLocale } from '@/base/utils'

export default {
  name: 'nav-bar-footer',
  data () {
    return {
     currentLanguage: { title: 'English', iso: 'us', value: 'en' },
      languages: [
        { title: 'English', iso: 'us', value: 'en' },
        { title: '中文 (繁體)', iso: 'cn', value: 'cn'}
      ]
    }
  },
  computed: {
    listLanguages () {
      return this.languages.filter(language => language.iso !== this.currentLanguage.iso)
    }
  },
  methods: {
    switchLanguage ( language ) {
      if (getLocale() !== language) {
        this.currentLanguage = language
        changeLocale(language.value)
      }
    }
  }
}
</script>
<style>
.fi.fis {
  width: 1rem;
}
</style>