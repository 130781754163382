<template>
  <div class="row justify-content-center align-items-sm-center py-sm-10">
    <div class="col-9 col-sm-6 col-lg-4">
      <div class="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
        <img class="img-fluid" src="../../../assets/svg/empty/oc-thinking.svg" alt="404"/>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4 text-center text-sm-start">
      <h1 class="display-1 mb-0">404</h1>
      <p class="lead">Sorry, the page you're looking for cannot be found.</p>
      <b-button variant="primary">Go back to the App</b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'not-found'
}
</script>