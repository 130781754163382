<template>
  <b-modal id="edit-profile-modal" title="Edit Profile" size="lg">
    <b-tabs id="nav-scroller"
            card
            pills
            nav-class="js-tabs-to-dropdown nav nav-segment nav-fill mb-5"
            js-nav-scroller hs-nav-scroller-horizontal mb-5
            v-model="tabIndex"
    >
      <b-tab>
        <template #title>
          <i class="bi-person me-1"></i> Profile
        </template>
        <edit-profile-content
            :profile="profile"
            :user-type="userType"
            @input="handleInput"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <i class="bi-building me-1"></i> Address & Social Media
        </template>
        <edit-address-and-social-media
            :profile="profile"
            @input="handleInput"
        />
      </b-tab>
    </b-tabs>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="out-secondary"
            class="btn-outline-primary float-end ml-2"
            @click="handleCloseModal"
        >
          Close
        </b-button>
        <b-button
            v-if="showSaveButton"
            variant="primary"
            class="btn-soft-primary float-end"
            @click="handleUpdateProfile"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions } from 'vuex'

import {
  EditProfileContent,
    EditAddressAndSocialMedia
} from './EditProfileModalContent'

export default {
  name: 'edit-profile-modal',
  components: {
    EditProfileContent,
    EditAddressAndSocialMedia
  },
  props: {
    profile: {
      type: Object,
      required: false,
      default: null
    },
    userType: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      updatedProfile: {},
      showSaveButton: false,
      tabIndex: 0
    }
  },
  methods: {
    ...mapActions('userProfile', ['updateUserProfile']),
    handleInput (name, value) {
      this.showSaveButton = true
      this.updatedProfile[name] = value
    },
    async handleUpdateProfile () {
      await this.updateUserProfile({id: this.profile.id, data: this.updatedProfile})
    },
    handleCloseModal () {
      this.$bvModal.hide('edit-profile-modal')
    }
  }
}
</script>