import * as TYPES from './types'

const listTrainers = state => {
    state.fetching = true
}

const listTrainersSuccess = (state, data) => {
    state.trainers = data
    state.fetching = false
}

const listTrainersFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const addTrainer = state => {
    state.fetching = true
}
const addTrainerSuccess = (state, data) => {
    state.fetching = false
    state.addedTrainer = data
}

const addTrainerFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const deleteTrainer = (state) => {
    state.fetching = true
}

const deleteTrainerSuccess = (state) => {
    state.fetching = false
}

const deleteTrainerFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

const getTrainerProfile = (state) => {
    state.fetching = true
}

const getTrainerProfileSuccess = (state, data) => {
    state.fetching = false
    state.trainerProfile = data
}

const getTrainerProfileFailure = (state, error) => {
    state.fetching = false
    console.log(error)
}

export default {
    [TYPES.LIST_TRAINERS.ACTION]: listTrainers,
    [TYPES.LIST_TRAINERS.SUCCESS]: listTrainersSuccess,
    [TYPES.LIST_TRAINERS.FAILURE]: listTrainersFailure,
    [TYPES.ADD_TRAINER.ACTION]: addTrainer,
    [TYPES.ADD_TRAINER.SUCCESS]: addTrainerSuccess,
    [TYPES.ADD_TRAINER.FAILURE]: addTrainerFailure,
    [TYPES.DELETE_TRAINER.ACTION]: deleteTrainer,
    [TYPES.DELETE_TRAINER.SUCCESS]: deleteTrainerSuccess,
    [TYPES.DELETE_TRAINER.FAILURE]: deleteTrainerFailure,
    [TYPES.GET_TRAINER_PROFILE.ACTION]: getTrainerProfile,
    [TYPES.GET_TRAINER_PROFILE.SUCCESS]: getTrainerProfileSuccess,
    [TYPES.GET_TRAINER_PROFILE.FAILURE]: getTrainerProfileFailure
}