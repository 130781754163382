<template>
  <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" class="dz-dropzone dz-dropzone-card"></vue-dropzone>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
export default {
  name: 'drop-file',
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: "<img id=\"uploadImg\" class=\"avatar avatar-xl avatar-4x3 mb-3\" src='"+ require('../assets/svg/illustrations/oc-browse.svg') +"' alt=\"Upload Image\"><h5>Drag and drop your file here</h5><p class=\"mb-2\">or</p><span class=\"btn btn-white btn-sm\">Browse files</span>"
      },
    }
  }
}
</script>