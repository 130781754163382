<template>
  <div class="card-body add-client-package">
    <div class="row mb-4">
      <label for="clientType" class="col-sm-3 col-form-label form-label">Client Type</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-select
              id="clientType"
              class="form-select p-1"
              :options="clientTypeOptions"
              :value="null"
              v-model="clientType"
              :state="clientType !== null"
              @input="value => updateValue(value, 'clientType')"
          />
        </div>
      </div>
    </div>
    <div
        class="row mb-4"
        v-if="clientType==='pro'"
    >
      <label for="trainer" class="col-sm-3 col-form-label form-label">Trainer</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-select
              id="trainer"
              class="form-select p-1"
              :options="trainerOptions"
              :value="null"
              v-model="reports_to"
              :state="reports_to !== null"
              @input="value => updateValue(value, 'reports_to')"
          />
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <label for="packageType" class="col-sm-3 col-form-label form-label">Package Type</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-select
              id="packageType"
              class="form-select p-1"
              :options="packagesOptions"
              :value="null"
              v-model="clientPackage.packageId"
              :state="clientPackage.packageId !== null"
              @input="value => updateValue(value, 'packageId')"
          />
        </div>
      </div>
    </div>
    <div
        v-if="clientPackage.packageId !== null"
        class="row mb-4"
    >
      <div class="table-responsive">
        <b-table
            borderless
            outlined
            select-mode="single"
            class="table-align-middle table-nowrap card-table"
            :fields="fields"
            :items="currentPackage"
        >
          <template #cell(name)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getName(data.value) }}</span>
          </template>
          <template #cell(description)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDescription(data.value) }}</span>
          </template>
          <template #cell(duration)="data">
            <span class="d-block h5 text-inherit mb-0">{{ getDuration(data.value) }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row mb-4">
      <label for="extra" class="col-sm-3 col-form-label form-label">Extra Gift</label>
      <div class="col-sm-9">
        <label for="duration" class="col-form-label form-label">Membership extended</label>
        <b-input-group class="input-group input-group-sm-vertical w-lg-50">
          <b-form-input
              id="duration"
              type="number"
              :value="extra.membership_duration"
              :state="extra.membership_duration !== ''"
              v-model="extra.membership_duration"
              @input="value => updateValue(value, 'membership_duration')"
          />
          <template #append>
            <b-form-select
                class="form-select p-1"
                :options="membershipDurationOptions"
                :state="extra.membership_duration_symbol !== ''"
                v-model="extra.membership_duration_symbol"
                @input="value => updateValue(value, 'membership_duration_symbol')"
            />
          </template>
        </b-input-group>
        <label for="extra-courses" class="col-form-label form-label">Extra Course(s)</label>
        <b-form-input
            id="extra-courses"
            type="number"
            class="w-50"
            :state="extra.courses !== ''"
            v-model="extra.courses"
            @input="value => updateValue(value, 'courses')"
        />
      </div>
    </div>
    <div class="row mb-4">
      <label for="sales" class="col-sm-3 col-form-label form-label">Sales</label>
      <div class="col-sm-9">
        <b-form-select
            id="sales"
            class="form-select p-1"
            :options="salesOptions"
            :value="null"
            v-model="salesId"
            :state="salesId !== null"
            @input="value => updateValue(value, 'salesId')"
        />
      </div>
    </div>
    <div class="row mb-4">
      <label for="paymentMethod" class="col-sm-3 col-form-label form-label">Payment Method</label>
      <div class="col-sm-9">
        <b-form-select
            id="paymentMethod"
            class="form-select p-1"
            :options="paymentMethodOptions"
            v-model="paymentMethod"
            :state="paymentMethod !== null"
            @input="value => updateValue(value, 'paymentMethod')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { getLocale } from '@/base/utils'

export default {
  name: 'add-membership-package',
  data () {
    return {
      clientType: null,
      clientPackage: {
        packageId: null,
        extra: null
      },
      extra: {
        courses: 0,
        membership_duration: 0,
        membership_duration_symbol: 'day'
      },
      reports_to: null,
      salesId: null,
      paymentMethod: null,
      packagesOptions: [],
      locale: getLocale(),
      currentPackage: null
    }
  },
  computed: {
    ...mapState('company', ['companySettings']),
    ...mapState('product', ['coursePackages', 'membershipPackages', 'membershipDurationOptions', 'coursesFields', 'membershipFields']),
    ...mapState('employee', ['sales']),
    ...mapState('trainer', ['trainers']),
    ...mapState('client', ['initialClientType']),
    ...mapState('payment', ['paymentMethodOptions']),
    fields () {
      return this.clientType === 'pro' ? this.coursesFields : this.membershipFields
    },
    clientTypeOptions () {
      if (this.companySettings === null ) {
        return null
      }
      return this.companySettings.find(field => field.name === 'clientType')
          .options.map(option => { return {value: option, text: this.$t(`profile.clientType.${option}`) }})
    },
    trainerOptions () {
      if (this.trainers.length === 0) {
        return null
      }
      return this.trainers.filter(trainer => trainer.status === 'active' ).map(trainer => {
        return { value: trainer.id, text: trainer.name.first_name + ' ' + trainer.name.last_name }
      })
    },
    salesOptions () {
      if (this.sales.length !== 0) {
        return this.sales.map(employee => {
          return { value: employee.id, text: employee.first_name + ' ' +employee.last_name  }
        })
      }
      return []
    }
  },
  methods: {
    ...mapActions('product', [
      'getCoursePackages',
      'getMembershipPackages'
    ]),
    ...mapActions('employee', ['listSales']),
    ...mapActions('trainer', ['getTrainers']),
    updateValue (value, key) {
      if (key === 'clientType') {
        this.updatePackageOptions()
      } else if (key === 'packageId') {
        this.updateCurrentPackage()
      }
      this.$emit('input', value, key)
    },
    updateCurrentPackage () {
        if (this.clientType === 'pro' && this.clientPackage.packageId !== null) {
          this.currentPackage = this.coursePackages.filter(item => item.id === this.clientPackage.packageId)
        } else if (this.clientPackage.packageId !== null) {
          this.currentPackage = this.membershipPackages.filter(item => item.id === this.clientPackage.packageId)
        }
    },
    getDescription (description) {
      return description[this.locale]
    },
    getDuration (duration) {
      return duration.duration + ' ' + this.$t(`general.dateSymbol.${duration.duration_symbol}`)
    },
    updatePackageOptions () {
        if (this.coursePackages.length !== 0 && this.clientType === 'pro') {
          this.packagesOptions = this.coursePackages.map(item => {
            return { value: item.id, text: this.getName(item.name) + ' paid: ' + item.paid + ' free: ' + item.free}
          })
        } else {
            if (this.initialClientType.includes(this.clientType)) {
              let durationSymbol = this.matchClientType()
              this.packagesOptions = this.membershipPackages
                  .filter(item => item.duration.duration_symbol === durationSymbol)
                  .map(item => {
                    return { value: item.id, text: this.getName(item.name) }
                  })
            }
        }
    },
    matchClientType () {
      switch (this.clientType) {
        case 'yearly':
          return 'year'
        case 'seasonal':
          return 'season'
        case 'monthly':
          return 'month'
        case 'weekly':
          return 'week'
        case 'dayPass':
          return 'day'
      }

    },
    getName (name) {
      return this.$te(`profile.clientType.${name[this.locale]}`) ?
          this.$t(`profile.clientType.${name[this.locale]}`) : name[this.locale]
    }
  },
  async mounted () {
    if (this.coursePackages.length === 0) {
      await this.getCoursePackages()
    }
    if (this.membershipPackages.length === 0) {
      await this.getMembershipPackages()
    }
    if (this.sales.length === 0) {
      await this.listSales()
    }
    if (this.trainers.length === 0) {
      await this.getTrainers()
    }
  }
}
</script>
<style lang="css" scoped>
.add-client-package {
  .form-select {
    background-color: #25282a;
  }
}
</style>