<template>
  <div class="card-body add-user-profile">
    <div class="row mb-4">
      <label class="col-sm-3 col-form-label form-label">Profile</label>
      <div class="col-sm-9">
        <avatar-upload />
      </div>
    </div>
    <!-- Full Name -->
    <div class="row mb-4">
      <label for="firstName" class="col-sm-3 col-form-label form-label">Full Name</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="firstName"
              type="text"
              required
              placeholder="First Name"
              :state="form.first_name !== null"
              v-model="form.first_name"
              @input="value => updateValue(value, 'first_name')"
          />
          <b-form-input
              id="LastName"
              type="text"
              required
              placeholder="Last Name"
              v-model="form.last_name"
              :state="form.last_name !== null"
              @input="value => updateValue(value, 'last_name')"
          />
        </div>
      </div>
    </div>
    <!-- Email -->
    <div class="row mb-4">
      <label for="email" class="col-sm-3 col-form-label form-label">Email</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="email"
              type="email"
              :state="isValidEmail"
              v-model="form.email"
              @input="value => updateValue(value, 'email')"
              required
              placeholder="Enter Email"
              aria-describedby="input-email-feedback"
          />
          <b-form-invalid-feedback id="input-email-feedback">
            {{ $t('profile.signIn.invalidEmail') }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <!-- Date of Birth -->
    <div class="row mb-4">
      <label for="dateOfBirth" class="col-sm-3 col-form-label form-label">Date of Birth</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="dateOfBirth"
              type="date"
              v-model="form.dateOfBirth"
              :state="form.dateOfBirth !== null"
              @input="value => updateValue(value, 'dateOfBirth')"
          />
        </div>
      </div>
    </div>
    <!-- Gender -->
    <div class="row mb-4">
      <label for="gender" class="col col-form-label form-label">Gender</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-select
              id="gender"
              class="form-select p-1"
              :options="genderOptions"
              :value="null"
              v-model="form.gender"
              :state="form.gender !== null"
              @input="value => updateValue(value, 'gender')"
          />
        </div>
      </div>
    </div>
    <!-- Start /End of Date -->
    <div class="row mb-4">
      <label for="startOfDate" class="col-sm-3 col-form-label form-label">Start of Date</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="startOfDate"
              type="date"
              required
              v-model="form.startOfDate"
              :state="form.startOfDate !== null"
              @input="value => updateValue(value, 'startOfDate')"
          />
        </div>
      </div>
      <label for="endOfDate" class="col-sm-3 col-form-label form-label">End of Date</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="endOfDate"
              type="date"
              required
              v-model="form.endOfDate"
              :state="isValidEndOfDate"
              aria-describedby="input-live-help input-live-feedback"
              @input="value => updateValue(value, 'endOfDate')"
          />
          <b-form-invalid-feedback id="endOfDate">
            The end of Date should be after the start of Date
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <!-- Role -->
    <div class="row mb-4">
      <label for="role" class="col-sm-3 col-form-label form-label">Role</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical col-form-label">
          <b-form-radio-group
              id="role"
              v-model="form.role"
              :options="roleOptions"
              :state="form.role !== null"
              required
              @input="value => updateValue(value, 'role')"
          />
        </div>
      </div>
    </div>
    <!-- Department -->
    <div v-if="userType ==='employee'" class="row mb-4">
      <label for="jobTitle" class="col-sm-3 col-form-label form-label">Job title</label>
      <div class="col-sm-9">
        <div class="input-group input-group-sm-vertical">
          <b-form-input
              id="jobTitle"
              type="text"
              placeholder="developer"
              v-model="form.jobTitle"
              :state="form.jobTitle !== null"
              @input="value => updateValue(value, 'jobTitle')"
          />
          <b-form-select
              id="department"
              class="form-select p-1"
              :options="departmentOptions"
              :value="null"
              v-model="form.department"
              :state="form.department !== null"
              @input="value => updateValue(value, 'department')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AvatarUpload } from '@/layout'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'add-user-profile',
  components: {
    AvatarUpload
  },
  data () {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        dateOfBirth: null,
        startOfDate: null,
        endOfDate: null,
        jobTitle: null,
        department: null,
        gender: null,
        role: null,
      }
    }
  },
  props: {
    userType: {
      type: String,
      required: false,
      default: 'employee'
    }
  },
  computed: {
    ...mapState('company', ['companySettings']),
    isValidEmail () {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    },
    genderOptions () {
      if (this.companySettings === null) {
        return null
      }
      return this.companySettings.find(field => field.name === 'gender')
          .options.map(option => { return {value: option, text: this.$t(`profile.gender.${option}`)} })
    },
    roleOptions () {
      if (this.userType === 'employee') {
        return [
          { text: 'Admin', value: 'admin' },
          { text: 'Manager', value: 'manager' },
          { text: 'Employee', value: 'employee' }
        ]
      }
      return [{ text: 'Trainer', value: 'trainer' }]
    },
    departmentOptions () {
      if (this.companySettings === null) {
        return null
      }
      if (this.form.role === 'admin') {
        return [{ value: 'admin', text: 'Admin' }]
      }
      return this.companySettings.find(field => field.name === 'department')
          .options.map(option => { return {value: option, text: this.$t(`profile.department.${option}`)} })
    },
    isValidEndOfDate () {
      return moment(this.form.endOfDate).isAfter(this.form.startOfDate)
    }
  },
  methods: {
    updateValue (value, key) {
      this.$emit('input', value, key)
    }
  }
}
</script>